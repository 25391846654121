import React from "react";
import {
  uploadsUrl,
  equipmentsUrl,
  E001Url,
  F001Url,
  F003Url,
  G001Url,
  H001Url,
  J001Url,
  K001Url,
  M001Url,
  P001Url,
  S001Url,
  U001Url,
  R001Url,
  T001Url,
  C001Url,
  D001Url,
  V001Url,
  L001Url,
  W001Url,
  TESTUrl,
  N003Url,
  N001Url,
  Q001Url,
} from "../../helper/url.helper";
import { authActions } from "../../redux/actions/auth.actions";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../redux/reducers";
import { clear } from "redux-localstorage-simple";

import "./Sidebar.scss";

import { BsFillPersonFill } from "react-icons/bs"; //保護者
import { PiNotePencilFill } from "react-icons/pi"; //カルテ
import { AiFillSchedule } from "react-icons/ai"; //個別支援計画
import { AiFillHome } from "react-icons/ai"; //FC施設
import { AiFillWechat } from "react-icons/ai"; //チャット
import { FaFileSignature, FaPeopleRobbery } from "react-icons/fa6"; //トレーニング
import { BsFillBellFill } from "react-icons/bs"; //新着情報
import { BsCalendar3 } from "react-icons/bs"; //イベント/スケジュール
import { BsFillInboxesFill } from "react-icons/bs"; //備品
import { AiOutlineUpload } from "react-icons/ai"; //アップロード
import { AiOutlineDownload } from "react-icons/ai"; //ダウンロード
import { TbStretching } from "react-icons/tb"; //プログラム
import { ImBooks } from "react-icons/im"; //監査ドキュメント管理
import { AiFillFolderOpen } from "react-icons/ai"; //フォーマット管理
import { FaUserTie } from "react-icons/fa"; //本部スタッフ/スタッフ
import { BsPersonVcardFill } from "react-icons/bs";
import { BiMobileAlt, BiSolidLogOut } from "react-icons/bi"; //ログアウト
import { userInfoSelector } from "../../redux/selectors/auth.selector";
import { HEADQUARTER_USER, STAFF } from "../../constants.js";
import { FCStaffInfoSchema } from "../../generated";
import SidebarItem from "./SidebarItem";
import { cartActions } from "../../redux/actions/cart.actions";
import { listActions } from "../../redux/actions/list.actions";

const Sidebar = () => {
  const userInfo = useSelector(userInfoSelector);
  const dispatch = useDispatch();
  const wsState = useSelector((s: State) => s.wsReducer);
  let fcId = 0;
  if (userInfo?.user_type == STAFF) {
    fcId = (userInfo as FCStaffInfoSchema)?.facilities[0].facility_id;
  }

  const logout = () => {
    wsState.socket?.emit("handle_logout");
    dispatch(authActions.logout());
    dispatch(cartActions.clearCart());
    dispatch(listActions.clearFcList());
    dispatch(listActions.clearPgList());
    clear();
  };

  return (
    <>
      <SidebarItem
        args={[0]}
        url={C001Url}
        icon={<BsFillPersonFill />}
        title="保護者"
      />
      <SidebarItem
        url={D001Url}
        icon={<PiNotePencilFill />}
        title="カルテ"
      />
      <SidebarItem
        args={[0]}
        url={E001Url}
        icon={<AiFillSchedule />}
        title="個別支援計画"
      />
      <SidebarItem
        args={[fcId]}
        url={K001Url}
        icon={<BsCalendar3 />}
        title="スケジュール"
      />
      <SidebarItem
        url={F001Url}
        icon={<AiFillHome />}
        title="FC施設"
        showUser={HEADQUARTER_USER}
      />
      <SidebarItem
        args={[0, 0]}
        url={G001Url}
        icon={<AiFillWechat />}
        title="チャット"
      />
      <SidebarItem
        args={[0, fcId, 0, 0]}
        url={H001Url}
        icon={<FaPeopleRobbery />}
        title="トレーニング"
      />
      <SidebarItem
        url={J001Url}
        icon={<BsFillBellFill />}
        title="新着情報"
      />
      <SidebarItem
        args={[fcId, 0, 0]}
        url={V001Url}
        icon={<BsCalendar3 />}
        title="イベント"
      />
      <SidebarItem
        url={L001Url}
        icon={<BsFillInboxesFill />}
        title="備品"
      />
      <SidebarItem
        url={N001Url}
        icon={<AiOutlineUpload />}
        title="アップロード"
        showUser={HEADQUARTER_USER}
      />
      <SidebarItem
        url={N003Url}
        icon={<AiOutlineDownload />}
        title="ダウンロード"
        showUser={STAFF}
      />
      <SidebarItem
        url={P001Url}
        icon={<TbStretching />}
        title="PG"
      />
      <SidebarItem
        args={[fcId, 0, 0]}
        url={M001Url}
        icon={<ImBooks />}
        title="監査ドキュメント管理"
      />
      <SidebarItem
        args={[fcId]}
        url={W001Url}
        icon={<FaFileSignature />}
        title="署名依頼帳票"
      />
      <SidebarItem
        url={R001Url}
        icon={<FaUserTie />}
        title="本部スタッフ"
        showUser={HEADQUARTER_USER}
      />
      <SidebarItem
        url={Q001Url}
        icon={<BsPersonVcardFill />}
        title="マイページ"
      />
      <SidebarItem
        args={[fcId]}
        url={F003Url}
        icon={<FaUserTie />}
        title="スタッフ"
        showUser={STAFF}
      />
      <div onClick={logout} className={"sidebar-item sidebar-item-logout "}>
        <BiSolidLogOut />
        <p className="menu_title">ログアウト</p>
      </div>
    </>
  );
};

export default Sidebar;

import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { State } from "../../redux/reducers";
import { authSelector, userInfoSelector } from "../../redux/selectors/auth.selector";
import { G002ChatMessageCreateSchema, G002Schema } from "../../generated";
import { getG002, putChatMessage } from "../../services/api.service";
import { USER_TYPE_NUM_HEADQUARTER_USER, USER_TYPE_NUM_STAFF, initialChatDataModel, initialChatMessageModel } from "../../models/chat.model";
import "./G002.scss";
import { HEADQUARTER_USER, PARENT, RECORD_LIMIT, STAFF } from "../../constants";
import CustomTextarea from "../../components/CustomTextarea/CustomTextarea";
import ChatBubble from "../../components/ChatBubble/ChatBubble";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import moment from "moment";
import { LuSendHorizonal } from "react-icons/lu";
import { MdOutlineEmojiEmotions } from "react-icons/md";
import { MdClose } from "react-icons/md";
import Picker, { EmojiStyle } from "emoji-picker-react";
import { useAuth } from "../../helper/auth.helper";
import { notifyError } from "../../helper/settings.helper";

interface iG002 {
  user_id: number;
  user_type: string;
  setRead: React.Dispatch<React.SetStateAction<boolean>>;
}

const G002: React.VFC<iG002> = ({ user_id, user_type, setRead }) => {
  const { checkLoggedIn } = useAuth();
  const authState = useSelector(authSelector);
  const userInfo = useSelector(userInfoSelector);
  const wsState = useSelector((s: State) => s.wsReducer);
  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const [data, setData] = useState<G002Schema>(initialChatDataModel);
  const myUserType = userInfo?.user_type === HEADQUARTER_USER ? USER_TYPE_NUM_HEADQUARTER_USER : USER_TYPE_NUM_STAFF;
  const [textAreaHeight, setTextAreaHeight] = useState<number>();
  const [chatMessage, setChatMessage] = useState<G002ChatMessageCreateSchema>(initialChatMessageModel);
  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  const [showPicker, setShowPicker] = useState<boolean>(false);
  const pickerRef = useRef<HTMLDivElement | null>(null);

  const setChatInfo = useCallback(() => {
    setChatMessage({
      headquarter_staff_id: userInfo?.user_type === HEADQUARTER_USER ? userInfo.id : (user_type === HEADQUARTER_USER ? user_id : undefined),
      staff_id: user_type === STAFF ? user_id : userInfo?.id,
      parent_id: (user_type === PARENT ? user_id : undefined),
      message: "",
    })
  }, [userInfo, user_id, user_type]);

  const onEmojiClick = (emojiObject: any, event: any) => {
    setChatMessage({ ...chatMessage, message: chatMessage.message + emojiObject.emoji });
    setShowPicker(false);
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  const fetchChatMessages = useCallback(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      setChatInfo();
      getG002(user_type, user_id, undefined, authState).then((res) => {
        if (isMounted) {
          res.data.messages.reverse();
          setData(res.data);
          scrollToBottom();
          setRead((v) => !v)
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    fetchData();
    return () => {
      isMounted = false;
    }
  }, [user_id, user_type, authState, changeIsLoading, checkLoggedIn, setRead, setChatInfo]);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target as Node)) {
        setShowPicker(false);
      }
    }
    document.addEventListener("mousedown", handleClick);
    return () => { document.removeEventListener("mousedown", handleClick); };
  });

  useEffect(() => {
    fetchChatMessages();
  }, [fetchChatMessages]);

  useEffect(() => {
    wsState.socket?.on("receive_chat", () => {
      fetchChatMessages();
    });
    return () => {
      wsState.socket?.off("receive_chat");
    };
  }, [wsState.socket, fetchChatMessages]);

  const clickRegister = () => {
    if (chatMessage.message !== "") {
      changeIsLoading();
      putChatMessage(chatMessage, RECORD_LIMIT, authState).then((res) => {
        setChatMessage(prevMessage => ({ ...prevMessage, message: "" }));
        changeIsLoading();
        fetchChatMessages();
      }).catch((err) => {
        checkLoggedIn(err);
        notifyError("登録に失敗しました。");
        console.error(err);
        changeIsLoading();
      });
    }

    wsState.socket?.emit("send_chat", {
      headquarter_id: user_type === HEADQUARTER_USER ? user_id : (userInfo?.user_type === HEADQUARTER_USER ? userInfo.id : undefined),
      staff_id: user_type === STAFF ? user_id : userInfo?.id,
      parent_id: user_type === PARENT ? user_id : undefined,
      messages: chatMessage.message,
    });
  };

  useEffect(() => {
    let client_h: number = document.getElementById("message_send_area")?.clientHeight ?? 0;
    setTextAreaHeight(client_h);
  }, [chatMessage]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <div className={"chat_room_name"}>{data?.name}</div>
      <div ref={chatContainerRef} className="chat_log_items" style={{ height: `calc(100% - (100px + ${textAreaHeight}px))` }}>
        {data?.messages.map((message, i) => (
          <div key={i}>
            <ChatBubble
              isTalkMe={message.message_from === myUserType ? true : false}
              profile_pic_url={data.profile_pic_url}
              message={message.message}
              isRead={message.message_from === myUserType && message.is_read}
              sendDate={(i === 0 || moment(message.created_at).format("YYYY-MM-DD") !== (moment(data.messages[i - 1]?.created_at).format("YYYY-MM-DD"))) && moment(message.created_at).format("YYYY年MM月DD日")}
              sendTime={moment(message.created_at).format("HH:mm")}
            />
          </div>
        ))}
      </div>
      <div id="message_send_area" className="message_send_area">
        <div className="message_icon" onClick={() => showPicker ? setShowPicker(false) : setShowPicker(true)}>
          {showPicker ? <MdClose size="2rem" /> : <MdOutlineEmojiEmotions size="2rem" />}
        </div>
        <div className="message_input">
          <CustomTextarea
            placeholder="メッセージを入力"
            value={chatMessage.message}
            onChange={(v: any) => setChatMessage({ ...chatMessage, message: v })}
          />
        </div>
        <div className="message_icon" onClick={() => clickRegister()}>
          <LuSendHorizonal size="2rem" />
        </div>
      </div>
      {showPicker &&
        <div ref={pickerRef}>
          <Picker onEmojiClick={onEmojiClick} width="100%" emojiStyle={EmojiStyle.GOOGLE} />
        </div>
      }
    </>
  );
};

export default G002;

import { reducerWithInitialState } from "typescript-fsa-reducers";
import { cartActions } from "../actions/cart.actions";
import { initialCartModel } from "../../models/order.model";
import {
  addOrderToCart,
  decrementCartItemNum,
  deleteOrderItemFromCart,
  incrementCartItem, updateCartItem,
} from "../../helper/cart.helper";

export const cartReducer = reducerWithInitialState(initialCartModel())
  .case(cartActions.addToCart, (state, payload) => {
    return addOrderToCart(state, payload);
  })
  .case(cartActions.updateCartItem, (state, payload) => {
    return updateCartItem(state, payload);
  })
  .case(cartActions.fetchCartItem, (state, payload) => {
    return {
      ...state,
    };
  })
  .case(cartActions.deleteCart, (state, payload) => {
    return deleteOrderItemFromCart(state, payload);
  })
  .case(cartActions.incrementCartItemNum, (state, payload) => {
    return incrementCartItem(state, payload);
  })
  .case(cartActions.decrementCartItemNum, (state, payload) => {
    return decrementCartItemNum(state, payload);
  })
  .case(cartActions.clearCart, () => {
    return initialCartModel();
  });

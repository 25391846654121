export const equipmentsUrl = "/equipments";
export const notificationsUrl = "/notifications";
export const orderUrl = "/orders";
export const trainingUrl = "/training";
export const subscriptionUrl = "/subscriptions";
export const uploadsUrl = "/uploads";
export const TESTUrl = "/TEST"; // TEST

export const B001Url = "/B001"; //ダッシュボード
export const C001Url = "/C001/:facility_id"; //保護者一覧
export const C002Url = "/C002/:parent_id"; //保護者詳細
export const C003Url = "/C003"; //保護者新規登録

export const D001Url = "/D001"; //お子様一覧
export const D002Url = "/D002/:child_id/:parent_id"; //お子様詳細
export const D003Url = "/D003/:child_id"; //身長・体重一覧
export const D004Url = "/D004/:health_id/:child_id"; //身長・体重詳細
export const D006Url = "/D006/:child_id"; //アセスメントシート一覧
export const D007Url = "/D007/:child_id"; //受給者証一覧
export const D008Url = "/D008/:child_id/:disability_certificate_id"; //受給者証詳細
export const D009Url = "/D009/:assessment_id/:child_id"; //アセスメントシート詳細
export const D010Url = "/D010/:child_id"; //固定時間入力

export const E001Url = "/E001/:child_id"; //個別支援計画一覧
export const E002Url = "/E002/:isp_id/:child_id"; //個別支援計画詳細

export const F001Url = "/F001"; //FC一覧
export const F002Url = "/F002/:fc_id"; //FC詳細
export const F003Url = "/F003/:fc_id"; //スタッフ一覧
export const F004Url = "/F004/:staff_id"; //スタッフ詳細

export const G001Url = "/G001/:chat_user_id/:chat_user_type"; //チャット一覧
export const G002Url = "/G002/:user_id"; //チャット詳細

export const H001Url = "/H001/:child_id/:facility_id/:begin_date/:end_date"; //トレーニング一覧
export const H002Url = "/H002/:training_id/:reference_id/:from"; //トレーニング計画
export const H003Url = "/H003/:training_id/:from"; //トレーニング結果

export const J001Url = "/J001"; //新着情報一覧
export const J002Url = "/J002/:notification_make_id"; //新着送信情報詳細
export const J003Url = "/J003/:notification_id"; //新着受信情報詳細

export const K001Url = "/K001/:facility_id"; //カレンダー
export const K002Url = "/K002/:facility_id/:begin_date/:end_date/:status"; //スケジュール一覧
export const K003Url = "/K003/:facility_id/:target_date/:holiday"; //祝日設定
export const L001Url = "/L001"; //備品一覧
export const L002Url = "/L002"; //受発注一覧
export const L003Url = "/L003/:order_id"; //受発注詳細
export const L004Url = "/L004/:equipment_id"; //備品詳細（本部）
export const L007Url = "/L007"; //備品詳細（FC）
export const L008Url = "/L008"; //カート（FC）

export const V001Url = "/V001/:facility_id/:begin_date/:end_date"; //イベント一覧
export const V003Url = "/V003/:event_id/:from"; //イベント詳細
export const K005Url = "/K005/:facility_id/:schedule_reservation_frame_id"; //スケジュール詳細
export const K006Url = "/K006/:facility_id"; //予約枠追加
export const V002Url = "/V002/:event_id"; //イベント参加管理
export const M001Url = "/M001/:facility_id/:doc_type/:status"; //監査ドキュメント管理
export const M002Url = "/M002/:audit_doc_id"; //監査ドキュメント詳細
export const N001Url = "/N001"; //アップロード一覧
export const N002Url = "/N002/:upload_data_id"; //アップロード詳細
export const N003Url = "/N003"; //ダウンロード

export const P001Url = "/P001"; //プログラム一覧
export const P002Url = "/P002/:program_item_id"; //プログラム詳細
export const Q001Url = "/Q001"; //ログインスタッフ情報
export const R001Url = "/R001"; //本部スタッフ一覧
export const R002Url = "/R002/:headquarter_user_id"; //本部スタッフ詳細

export const S001Url = "/S001"; //システム設定
export const T001Url = "/T001"; //フォーマット管理
export const U001Url = "/U001"; //ウェアラブル端末管理

export const W001Url = "/W001/:facility_id"; //署名依頼帳票一覧
export const W002Url = "/W002/:signature_request_make_id"; //署名依頼帳票詳細

export const ContactUrl = "/Contact"; //問い合わせフォーム

export const getForHistoryPush = (url: string, args: any[]) => {
  const splitUrl = url.split("/");
  let state: { [key: string]: any } = {};
  let pathname = "/";

  splitUrl.map((x, i) => {
    if (i == 0) return;
    if (i == 1) {
      pathname += x;
      return;
    }
    pathname += "/" + String(args[i - 2]);
    state[x.replace(":", "")] = args[i - 2] == 0 ? undefined : args[i - 2];
  });

  return {
    pathname: pathname,
    state: state,
  };
};

export const getFileNameByUrl = (url: string) => {
  const splitUrl = url.split("/");
  const fileNameWithKey = splitUrl[splitUrl.length - 1];
  const splitFileName = fileNameWithKey.split("?");
  const splitByHyphen = splitFileName[0].split("-");
  const replacedFileName = splitFileName[0].replace(`${splitByHyphen[0]}-`, "")
  return decodeURIComponent(replacedFileName);
};
import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router";
import { useSelector } from "react-redux";
import { authSelector } from "../../redux/selectors/auth.selector";
import "./E002.scss";
import { MEETING_FILE, MONITORING_FILE, PLAN_FILE, PLAN_IDEA_FILE, STATUS_INDIVIDUAL_SUPPORT_PLAN, initialE002Model, validationIspModel } from "../../models/individual_support_plan.model";
import { E002Schema, GeneralForListSchema, Result } from "../../generated";
import { ValidationError, initialValidationError } from "../../models/validate.model";
import ConfirmModal from "../../components/ConfirmModal";
import ChildInfoTop from "../../components/ChildInfoTop/ChildInfoTop";
import Select from "../../components/Select/CustomSelect";
import Title from "../../components/Title/Title";
import CustomRow from "../../components/CustomRow/CustomRow";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import CustomButton, { CB_CLASS } from "../../components/Button/CustomButton";
import FileUploader from "../../components/FileUploader/FileUploader";
import Label from "../../components/Label/Label";
import { raiseAction } from "../../helper/api.helper";
import { notify, notifyError } from "../../helper/settings.helper";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import {
	RequestSign,
	deleteIsp,
	fetchGeneralsForList,
	fetchIsp,
	putDocs,
	putIsp,
} from "../../services/api.service";
import moment from "moment";
import { useAuth } from "../../helper/auth.helper";
import CustomDate from "../../components/Calendar/CustomDate";
import { validationMessage } from "../../config/validationMessage";
import { isPdfFile } from "../../helper/file.helper";

interface iE002 {
	isp_id: number;
	child_id: number;
}

interface iFiles {
	fileType: number;
	file: File;
}

const E002 = () => {
	const { checkLoggedIn } = useAuth();
	const location = useLocation<iE002>();
	const ispId = location.state.isp_id;
	const childId = location.state.child_id;
	const history = useHistory();
	const authState = useSelector(authSelector);
	const [isLoading, changeIsLoading] = useLoadingIndicator(undefined);
	const [data, setData] = useState<E002Schema>(initialE002Model);
	const [validationError, setValidationError] = useState<ValidationError<E002Schema>>(initialValidationError());
	const [isValidated, setIsValidated] = useState<boolean | undefined>(undefined);
	const [showRegister, setShowRegister] = useState(false);
	const [showDelete, setShowDelete] = useState(false);
	const [showNotify, setShowNotify] = useState(false);
	const [statusForSelect, setStatusForSelect] = useState<GeneralForListSchema[]>([]);
	const [uploadFiles, setUploadFiles] = useState<iFiles[]>([]);
	const [reload, setReload] = useState(true);
	const [msgMeetingFile, setMsgMeetingFile] = useState("");
	const [msgMonitoring, setMsgMonitoring] = useState("");
	const [msgPlanFile, setMsgPlanFile] = useState("");
	const [msgPlanIdeaFile, setMsgPlanIdeaFile] = useState("");

	const addFile = (fileType: number, file: File) => {
		if (uploadFiles.some(x => x.fileType == fileType)) {
			const prevFiles = [...uploadFiles];
			const filteredList = prevFiles.filter(x => x.fileType == fileType);
			if (file) {
				filteredList[0].file = file;
			} else {
				prevFiles.splice(prevFiles.indexOf(filteredList[0]), 1);
			}
			setUploadFiles(prevFiles);
		} else {
			if (file) {
				setUploadFiles((prevFiles) => [...prevFiles, { fileType, file }]);
			}
		}
	};

	const getFile = (fileType: number) => {
		const filteredList = uploadFiles.filter(x => x.fileType == fileType);
		if (filteredList.length == 0) return null;
		return filteredList[0].file;
	};

	const getSelectValue = (val: number, selectList: GeneralForListSchema[]) => {
		if (val == 0) return;
		return selectList.filter((x) => x.value == val)[0];
	};

	const checkIspFileExtension = useCallback((filesList: iFiles[]) => {
		return !filesList.map(x => {
			if (!isPdfFile(x.file.name)) {
				switch (x.fileType) {
					case MEETING_FILE:
						setMsgMeetingFile(validationMessage.noChoseOnlyPdfFile);
						break;
					case PLAN_IDEA_FILE:
						setMsgPlanIdeaFile(validationMessage.noChoseOnlyPdfFile);
						break;
					case PLAN_FILE:
						setMsgPlanFile(validationMessage.noChoseOnlyPdfFile);
						break;
					case MONITORING_FILE:
						setMsgMonitoring(validationMessage.noChoseOnlyPdfFile);
						break;
				}
				return false;
			}
			return true;
		}).includes(false);
	}, []);

	useEffect(() => {
		if (reload) {
			let isMounted = true;
			const fetchData = async () => {
				changeIsLoading();
				fetchIsp(authState, childId, ispId).then((res) => {
					if (isMounted) {
						setData(res.data);
						setReload(false);
					}
					changeIsLoading();
				}).catch((err) => {
					if (isMounted) {
						checkLoggedIn(err);
						notifyError("データの取得に失敗しました。");
						console.error(err);
					}
					changeIsLoading();
				});
			};
			fetchData();
			return () => {
				isMounted = false;
			}
		}
	}, [authState, changeIsLoading, checkLoggedIn, ispId, childId, reload]);

	useEffect(() => {
		let isMounted = true;
		const fetchData = async () => {
			changeIsLoading();
			fetchGeneralsForList(authState, STATUS_INDIVIDUAL_SUPPORT_PLAN).then((res) => {
				if (isMounted) {
					setStatusForSelect(res.data);
				}
				changeIsLoading();
			}).catch((err) => {
				if (isMounted) {
					checkLoggedIn(err);
					notifyError("データの取得に失敗しました。");
					console.error(err);
				}
				changeIsLoading();
			});
		};
		fetchData();
		return () => {
			isMounted = false;
		}
	}, [authState, changeIsLoading, checkLoggedIn]);

	const clickRegister = () => {
		const validatedData = validationIspModel(data);
		if (!checkIspFileExtension(uploadFiles)) {
			validatedData.ok = false;
		}
		if (validatedData.ok) {
			changeIsLoading();
			putIsp(data, authState).then((res) => {
				if (uploadFiles.length > 0) {
					putDocs(res.data, uploadFiles as [{ fileType: number, file: File, }], authState).then((__) => {
						setShowRegister(false);
						notify("個別支援計画を更新しました。");
						changeIsLoading();
						history.goBack();
					}).catch((err) => {
						notifyError("登録に失敗しました。");
						console.error(err);
						changeIsLoading();
					});
				} else {
					setShowRegister(false);
					notify("個別支援計画を更新しました。");
					changeIsLoading();
					history.goBack();
				}
			}).catch((err) => {
				checkLoggedIn(err);
				notifyError("登録に失敗しました。");
				console.error(err);
				changeIsLoading();
			});
		} else {
			setShowRegister(false);
			notifyError("入力不正があります。");
			setIsValidated(true);
			setValidationError(validatedData.validationError);
		}
	};

	const success_delete = () => {
		setShowDelete(false);
		notify("個別支援計画を削除しました。");
		changeIsLoading();
		history.goBack();
	}

	const clickDelete = () => {
		changeIsLoading();
		deleteIsp(ispId, authState).then((res) => {
			raiseAction(
				res.data,
				() => success_delete(),
				(v: Result) => {
					notifyError(String(v.msg));
					setShowDelete(false);
					changeIsLoading();
				});
		}).catch((err) => {
			checkLoggedIn(err);
			notifyError("削除に失敗しました。");
			console.error(err);
			changeIsLoading();
		});
	};

	const clickRequestSign = (fileType: number) => {
		changeIsLoading();
		RequestSign(ispId, fileType, authState).then((_) => {
			setShowNotify(false);
			notify("署名依頼通知を送信しました。");
			changeIsLoading();
			setReload(true);
		}).catch((err) => {
			checkLoggedIn(err);
			notifyError("登録に失敗しました。");
			console.error(err);
			changeIsLoading();
		})
	};

	useEffect(() => {
		if (isValidated) {
			const validatedData = validationIspModel(data);
			setMsgPlanFile("");
			if (!checkIspFileExtension(uploadFiles)) {
				validatedData.ok = false;
			}
			if (validatedData.ok) {
				setValidationError(initialValidationError());
			} else {
				setIsValidated(true);
				setValidationError(validatedData.validationError);
			}
		}
	}, [data, isValidated, checkIspFileExtension, uploadFiles]);

	return (
		<>
			<LoadingSpinner isLoading={isLoading} />
			<Title title={ispId ? "個別支援計画編集" : "個別支援計画登録"} />
			<div className="plate">
				<ChildInfoTop
					user_type={authState.userInfo?.user_type}
					profile_pic_url={data?.profile_pic_url}
					id={data?.child_id}
					name={data?.child_name}
					name_kana={data?.child_name_kana}
					facility_name={data?.facility_name}
					no_underbar={false}
				/>
				<CustomRow
					title="ステータス"
					required={true}
					error_msg={validationError?.messageOf.status}
					content={
						<Select
							value={getSelectValue(data?.status, statusForSelect)}
							options={statusForSelect}
							className="E002_select mx-2"
							onChange={(e: any) => setData({ ...data, status: e.value })}
							placeholder="ステータス"
						/>
					}
				/>
				<CustomRow
					title="支援期間"
					required={true}
					error_msg={validationError?.messageOf.start_date}
					content={
						<>
							<CustomDate val={data.start_date} className="mx-2" setter={(v: string) => setData({ ...data, start_date: v ? moment(v).format("YYYY-MM-DD") : "" })} />
							<Label value={"～　" + (data.start_date && moment(data.start_date).add(6, "months").add(-1, "days").format("YYYY/M/D"))} />
						</>
					}
				/>
				<CustomRow
					title="モニタリングの記録"
					required={false}
					error_msg={msgMonitoring}
					content={
						<FileUploader file={getFile(MONITORING_FILE)} setFile={(file: File) => addFile(MONITORING_FILE, file)} url={data.monitoring_file_url} setMsg={setMsgMonitoring} />
					}
				/>
				<CustomRow
					title="個別支援計画案"
					required={false}
					error_msg={msgPlanIdeaFile}
					content={
						<FileUploader file={getFile(PLAN_IDEA_FILE)} setFile={(file: File) => addFile(PLAN_IDEA_FILE, file)} url={data.plan_idea_file_url} setMsg={setMsgPlanIdeaFile} />
					}
				/>
				<CustomRow
					title="支援会議記録"
					required={false}
					error_msg={msgMeetingFile}
					content={
						<FileUploader file={getFile(MEETING_FILE)} setFile={(file: File) => addFile(MEETING_FILE, file)} url={data.meeting_file_url} setMsg={setMsgMeetingFile} />
					}
				/>
				<CustomRow
					title="個別支援計画"
					required={false}
					error_msg={msgPlanFile}
					content={
						<>
							<FileUploader file={getFile(PLAN_FILE)} setFile={(file: File) => addFile(PLAN_FILE, file)} url={data.plan_file_url} setMsg={setMsgPlanFile} noChangeFile={data.plan_is_signed ? true : false} />
							{(data.plan_file_url && !data.plan_is_signed) &&
								<>
									<CustomButton
										label={data.plan_is_notified ? "署名依頼再通知" : "署名依頼通知"}
										class={[CB_CLASS.BLUE]}
										onClick={() => setShowNotify(true)}
									/>
									<ConfirmModal
										target="署名依頼通知"
										show={showNotify}
										setShow={setShowNotify}
										func={() => clickRequestSign(PLAN_FILE)}
										confirmText={"署名依頼通知を送信してもよろしいでしょうか。"}
									/>
								</>
							}
						</>
					}
				/>
				<div className="plate d-flex flex-column align_center">
					<CustomButton
						label="登録"
						class={[CB_CLASS.RED]}
						onClick={() => setShowRegister(true)}
					/>
					<ConfirmModal
						target="登録"
						show={showRegister}
						setShow={setShowRegister}
						func={clickRegister}
						confirmText={"登録してよろしいでしょうか。"}
					/>
					{ispId &&
						<>
							<CustomButton
								label="削除"
								class={[CB_CLASS.RED]}
								onClick={() => setShowDelete(true)}
							/>
							<ConfirmModal
								target="削除"
								show={showDelete}
								setShow={setShowDelete}
								func={clickDelete}
								confirmText={"削除してよろしいでしょうか。"}
							/>
						</>
					}
				</div>
			</div>
		</>
	);
};

export default E002;

// ↓後で消す
/*
スタッフの店舗内でのロール
Pythonのmodelsディレクトリに書かれている定数と合わせてあります。変えないで。
*/
export const FC_STAFF_ROLE_OWNER = 1;
export const FC_STAFF_ROLE_MANAGER = 2;
export const FC_STAFF_ROLE_LEADER = 3;
export const FC_STAFF_ROLE_TRAINER = 4;
// ↑後で消す

import { FCStaffAllInfoSchema } from "../generated";
import { initialValidationError, ValidatedData } from "./validate.model";
import { validationMessage } from "../config/validationMessage";
import {
  checkFlgObj,
  checkValue,
  checkEmail,
  checkValueNotRequire,
  checkValueKana,
  checkPasswordNotRequire,
  checkSelectOrRadio,
} from "../helper/validation.helper";

export const initialStaffModel = (fcId: number): FCStaffAllInfoSchema => ({
  family_name: "",
  family_name_kana: "",
  given_name: "",
  given_name_kana: "",
  id: 0,
  rank: 0,
  training: "",
  email: "",
  password: "",
  password_check: "",
  profile_pic_url: "",
  facility_id_list: [fcId || 0],
});

export const validationStaffModel = (
  staff: FCStaffAllInfoSchema
): ValidatedData<FCStaffAllInfoSchema> => {
  const error = initialValidationError<FCStaffAllInfoSchema>();

  const checkFlg: checkFlgObj = {
    isOk: true,
  };

  let errorMsg: string | undefined;

  error.messageOf.family_name = [
    checkValue(checkFlg, "姓", staff.family_name, 30),
    checkValue(checkFlg, "名", staff.given_name, 30)
  ].filter(x => x != undefined).join(" ");

  error.messageOf.family_name_kana = [
    checkValueKana(checkFlg, "姓（フリガナ）", staff.family_name_kana, 30),
    checkValueKana(checkFlg, "名（フリガナ）", staff.given_name_kana, 30)
  ].filter(x => x != undefined).join(" ");

  error.messageOf.facility_id_list = checkSelectOrRadio(checkFlg, "施設", staff.facility_id_list[0]);

  error.messageOf.rank = checkSelectOrRadio(checkFlg, "役職", staff.rank);

  error.messageOf.training = checkValueNotRequire(
    checkFlg,
    "研修内容（資格）",
    staff.training,
    30
  );

  error.messageOf.email = checkEmail(checkFlg, staff.email);

  errorMsg = checkPasswordNotRequire(
    checkFlg,
    "新しいパスワード",
    staff.password,
    staff.password_check,
    8
  );
  validationMessage.emptyRePassword === errorMsg ||
  validationMessage.inconsistentPassword === errorMsg
    ? (error.messageOf.password_check = errorMsg)
    : (error.messageOf.password = errorMsg);

  if (checkFlg.isOk) {
    return {
      ok: true,
      validatedData: staff,
    };
  }

  return {
    ok: false,
    validationError: error,
  };
};

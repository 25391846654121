import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import { Provider, useDispatch, useSelector } from "react-redux";
import store from "./redux/store";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import {
  authSelector,
  loggedInSelector,
  loggingInSelector,
} from "./redux/selectors/auth.selector";
import AuthenticatedRoute from "./components/Route/AuthenticatedRoute";
import NotAuthenticatedRoute from "./components/Route/NotAuthenticatedRoute";
import Login from "./screens/A001/Login";
import B001 from "./screens/B001/B001";
import C001 from "./screens/C001/C001";
import C002 from "./screens/C002/C002";
import D001 from "./screens/D001/D001";
import D002 from "./screens/D002/D002";
import F001 from "./screens/F001/F001";
import F002 from "./screens/F002/F002";
import F003 from "./screens/F003/F003";
import F004 from "./screens/F004/F004";
import G001 from "./screens/G001/G001";
import H001 from "./screens/H001/H001";
import Schedule from "./screens/K001/K001";
import K002 from "./screens/K002/K002";
import K005 from "./screens/K005/K005";
import M001 from "./screens/M001/M001";
import K006 from "./screens/K006/K006";
import P001 from "./screens/P001/P001";
import Q001 from "./screens/Q001/Q001";
import R001 from "./screens/R001/R001";
import R002 from "./screens/R002/R002";
import { fetchUserInfoAction } from "./redux/actions/auth.actions";
import {
  B001Url,
  C001Url,
  C002Url,
  D001Url,
  D002Url,
  E001Url,
  F001Url,
  F002Url,
  F003Url,
  F004Url,
  G001Url,
  H001Url,
  H002Url,
  H003Url,
  J001Url,
  J002Url,
  J003Url,
  K001Url,
  K002Url,
  V001Url,
  V003Url,
  K005Url,
  K006Url,
  V002Url,
  M001Url,
  M002Url,
  P001Url,
  P002Url,
  Q001Url,
  R001Url,
  R002Url,
  subscriptionUrl,
  trainingUrl,
  uploadsUrl,
  D007Url,
  D008Url,
  L001Url,
  L002Url,
  E002Url,
  D006Url,
  D009Url,
  D003Url,
  D004Url,
  W001Url,
  W002Url,
  L004Url,
  L003Url,
  TESTUrl,
  N001Url,
  N002Url,
  N003Url,
  ContactUrl as ContactUrl,
  D010Url,
  K003Url,
} from "./helper/url.helper";
import SubscriptionRoutes from "./screens/Subscriptions/SubscriptionRoutes";
import { ToastContainer } from "react-toastify";
import { connectWebsocketAction } from "./redux/actions/ws.actions";
import H002 from "./screens/H002/H002";
import H003 from "./screens/H003/H003";
import P002 from "./screens/P002/P002";
import J001 from "./screens/J001/J001";
import J002 from "./screens/J002/J002";
import J003 from "./screens/J003/J003";
import M002 from "./screens/M002/M002";
import V001 from "./screens/V001/V001";
import V003 from "./screens/V003/V003";
import V002 from "./screens/V002/V002";
import D007 from "./screens/D007/D007";
import D008 from "./screens/D008/D008";
import L001 from "./screens/L001/L001";
import L002 from "./screens/L002/L002";
import L003 from "./screens/L003/L003";
import E001 from "./screens/E001/E001";
import E002 from "./screens/E002/E002";
import D006 from "./screens/D006/D006";
import D009 from "./screens/D009/D009";
import D003 from "./screens/D003/D003";
import D004 from "./screens/D004/D004";
import W001 from "./screens/W001/W001";
import W002 from "./screens/W002/W002";
import L004 from "./screens/L004/L004";
import TEST from "./screens/TEST/TEST";
import N001 from "./screens/N001/N001";
import N002 from "./screens/N002/N002";
import N003 from "./screens/N003/N003";
import Contact from "./screens/Contact/Contact";
import PrivacyPolicy from "./screens/PrivacyPolicy/PrivacyPolicy";
import D010 from "./screens/D010/D010";
import K003 from "./screens/K003/K003";

const Root = () => {
  const dispatch = useDispatch();
  const loggedIn = useSelector(loggedInSelector);
  const loggingIn = useSelector(loggingInSelector);
  const authState = useSelector(authSelector);
  const token = authState.token;

  React.useEffect(() => {
    if (token) {
      dispatch(fetchUserInfoAction(token, false));
    }

    if (!loggingIn && !loggedIn && token) {
      dispatch(fetchUserInfoAction(token, true));
      //  TODO本来このelse ifはいらないはずだが、これがないとリロードした時にsocketioのsidが取得できない
    } else if (!loggingIn && loggedIn && token) {
      dispatch(connectWebsocketAction());
    }
  }, [loggingIn, loggedIn, token, dispatch]);

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact={true} render={() => <Redirect to={B001Url} />} />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={B001Url}
          exact
          component={B001}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={C001Url}
          component={C001}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={C002Url}
          exact
          component={C002}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={D001Url}
          component={D001}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={D002Url}
          component={D002}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={D003Url}
          component={D003}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={D004Url}
          component={D004}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={D006Url}
          component={D006}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={D007Url}
          component={D007}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={D008Url}
          component={D008}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={D009Url}
          component={D009}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={D010Url}
          component={D010}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={E001Url}
          component={E001}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={E002Url}
          component={E002}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={K005Url}
          exact
          component={K005}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={K006Url}
          exact
          component={K006}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={F001Url}
          component={F001}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={F002Url}
          component={F002}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={F003Url}
          component={F003}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={F004Url}
          component={F004}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={G001Url}
          component={G001}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={H001Url}
          component={H001}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={H002Url}
          component={H002}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={H003Url}
          component={H003}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={K001Url}
          exact
          component={Schedule}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={K002Url}
          exact
          component={K002}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={K003Url}
          exact
          component={K003}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={L001Url}
          exact
          component={L001}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={L002Url}
          exact
          component={L002}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={L003Url}
          exact
          component={L003}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={L004Url}
          exact
          component={L004}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={V001Url}
          exact
          component={V001}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={V003Url}
          exact
          component={V003}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={K006Url}
          exact
          component={K006}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={V002Url}
          exact
          component={V002}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={M001Url}
          exact
          component={M001}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={M002Url}
          exact
          component={M002}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={N001Url}
          exact
          component={N001}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={N002Url}
          exact
          component={N002}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={N003Url}
          exact
          component={N003}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={P001Url}
          component={P001}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={P002Url}
          component={P002}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={J001Url}
          component={J001}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={J002Url}
          component={J002}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={J003Url}
          component={J003}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={Q001Url}
          component={Q001}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={R001Url}
          component={R001}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={R002Url}
          component={R002}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={W001Url}
          component={W001}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={W002Url}
          component={W002}
        />
        <AuthenticatedRoute
          isLoggedIn={loggedIn}
          path={subscriptionUrl}
          component={SubscriptionRoutes}
        />
        <NotAuthenticatedRoute
          isLoggedIn={loggedIn}
          path="/login"
          component={Login}
        />
        <NotAuthenticatedRoute
          isLoggedIn={false}
          path={ContactUrl}
          component={Contact}
        />
        <NotAuthenticatedRoute
          isLoggedIn={false}
          path="/privacy_policy"
          component={PrivacyPolicy}
        />
      </Switch>
    </BrowserRouter>
  );
};

const App = () => (
  <Provider store={store}>
    <ToastContainer />
    <Root />
  </Provider>
);

export default App;

import "./Icon.scss";
import { BsFillChatDotsFill } from "react-icons/bs";
import { COLOR_BLUE, STAFF } from "../../constants";

function Chat(props: any) {
  return (
    props.user_type == STAFF ?
      <BsFillChatDotsFill
        className="icon hover_blue"
        color={COLOR_BLUE}
        size={35}
        onClick={(event) => {
          event.stopPropagation();
          props.func();
        }}
        style={{
          marginTop: "4px"
        }}
      />
      : <></>
  );
};

export default Chat;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { authSelector, userInfoSelector } from "../../redux/selectors/auth.selector";
import "./C003.scss";
import {
  ParentChildrenCreateSchema,
  ChildForParentCreateSchema,
  FCStaffInfoSchema,
  Result,
} from "../../generated";
import {
  initialCreateParentChildModel,
  validationCreateParentChildModel,
} from "../../models/parent.model";
import {
  ValidationError,
  initialValidationError,
} from "../../models/validate.model";
import "./C003.scss";
import { Table } from "react-bootstrap";
import Title from "../../components/Title/Title";
import CustomRow from "../../components/CustomRow/CustomRow";
import TextBox from "../../components/TextBox/TextBox";
import CustomButton, { CB_CLASS } from "../../components/Button/CustomButton";
import { notify, notifyError } from "../../helper/settings.helper";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import { createParentChildren } from "../../services/api.service";
import Delete from "../../components/Icon/Delete";
import Plus from "../../components/Icon/Plus";
import CustomDate from "../../components/Calendar/CustomDate";
import CustomModal from "../../components/CustomModal/CustomModal";
import { STAFF } from "../../constants";
import { raiseAction } from "../../helper/api.helper";
import { useAuth } from "../../helper/auth.helper";
import { format } from "date-fns-tz";

interface C003Props {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
}

const C003: React.VFC<C003Props> = ({ showModal, setShowModal, setReload }) => {
  const { checkLoggedIn } = useAuth();
  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const authState = useSelector(authSelector);
  const [validationError, setValidationError] = useState<
    ValidationError<ParentChildrenCreateSchema>
  >(initialValidationError());
  const [isValidated, setIsValidated] = useState<boolean | undefined>(undefined);
  const userInfo = useSelector(userInfoSelector);
  const fcId = userInfo?.user_type == STAFF ? (userInfo as FCStaffInfoSchema)?.facilities[0].facility_id : 0;
  const [data, setData] = useState<ParentChildrenCreateSchema>(initialCreateParentChildModel(fcId));

  const newChild = () => {
    const child: ChildForParentCreateSchema = {
      family_name: "",
      given_name: "",
      facility_id: fcId,
      join_date: format(new Date(), "yyyy-MM-dd"),
    };
    return child;
  };

  const addChild = () => {
    const child: ChildForParentCreateSchema = newChild();
    let dataPrev = { ...data };
    dataPrev.children.push(child);
    setData(dataPrev);
  };

  const deleteChild = (index: number) => {
    let dataPrev = { ...data };
    dataPrev.children.splice(index, 1);
    setData(dataPrev);
  };

  const setChildFamilyNameValue = (index: number, v: any) => {
    let dataPrev = { ...data };
    dataPrev.children[index].family_name = v;
    setData(dataPrev);
  };

  const setChildGivenNameValue = (index: number, v: any) => {
    let dataPrev = { ...data };
    dataPrev.children[index].given_name = v;
    setData(dataPrev);
  };

  const setChildJoinDateValue = (index: number, v: any) => {
    let dataPrev = { ...data };
    dataPrev.children[index].join_date = v;
    setData(dataPrev);
  };

  const clickRegister = () => {
    const validatedData = validationCreateParentChildModel(data);
    if (validatedData.ok) {
      changeIsLoading();
      createParentChildren(authState, data).then((res) => {
        raiseAction(res.data, (v: Result) => {
          setData(initialCreateParentChildModel(fcId));
          setShowModal(false);
          notify("保護者・お子様を新規登録しました。");
          changeIsLoading();
          setReload(true);
        }, (v: Result) => {
          notifyError(String(v.msg));
          changeIsLoading();
        });
      }).catch((err) => {
        checkLoggedIn(err);
        notifyError("登録に失敗しました。");
        console.error(err);
        changeIsLoading();
      });
    } else {
      notifyError("入力不正があります。");
      setIsValidated(true);
      setValidationError(validatedData.validationError);
    }
  };

  useEffect(() => {
    if (isValidated) {
      const validatedData = validationCreateParentChildModel(data);
      if (validatedData.ok) {
        setValidationError(initialValidationError());
      } else {
        setIsValidated(true);
        setValidationError(validatedData.validationError);
      }
    }
  }, [data, isValidated]);

  return (
    <>
      <CustomModal
        show={showModal}
        setShow={setShowModal}
        size={"lg"}
        contents={
          <>
            <Title title="保護者新規登録" go_back={false} />
            <div className="plate">
              <CustomRow
                title="名前"
                required={true}
                error_msg={validationError.messageOf.family_name}
                content={
                  <>
                    <TextBox
                      type="text"
                      placeholder="姓"
                      value={data.family_name}
                      onChange={(v: any) =>
                        setData({ ...data, family_name: v })
                      }
                    />
                    <TextBox
                      type="text"
                      placeholder="名"
                      value={data.given_name}
                      onChange={(v: any) => setData({ ...data, given_name: v })}
                    />
                  </>
                }
              />
              <CustomRow
                title="メールアドレス"
                required={true}
                error_msg={validationError.messageOf.email}
                content={
                  <>
                    <TextBox
                      type="email"
                      placeholder="メールアドレス"
                      value={data.email}
                      onChange={(v: any) => setData({ ...data, email: v })}
                    />
                  </>
                }
              />

              <CustomRow
                title="お子様名、入会日"
                required={true}
                error_msg={validationError.messageOf.children}
                content={
                  <>
                    <Table className="table-borderless">
                      <tbody>
                        {data.children.map((x, i) => (
                          <tr key={i}>
                            <td className="item C003_textBox">
                              <TextBox
                                type="text"
                                placeholder="姓"
                                value={x.family_name}
                                onChange={(v: any) =>
                                  setChildFamilyNameValue(i, v)
                                }
                              />
                            </td>
                            <td className="item C003_textBox">
                              <TextBox
                                type="text"
                                placeholder="名"
                                value={x.given_name}
                                onChange={(v: any) =>
                                  setChildGivenNameValue(i, v)
                                }
                              />
                            </td>
                            <td>
                              <CustomDate
                                className="mx-2"
                                setter={(v: string) => setChildJoinDateValue(i, v)}
                                val={x.join_date}
                              />
                            </td>
                            <td className="item px-0">
                              {i > 0 && (
                                <Delete
                                  func={() => {
                                    deleteChild(i);
                                  }}
                                />
                              )}
                            </td>
                            <td className="item px-0">
                              {i === data.children.length - 1 && (
                                <Plus
                                  func={() => {
                                    addChild();
                                  }}
                                />
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </>
                }
              />
              <div className="plate d-flex justify_center">
                <CustomButton
                  label="登録"
                  class={[CB_CLASS.RED]}
                  onClick={() => clickRegister()}
                />
              </div>
            </div>
          </>
        }
      />
    </>
  );
};

export default C003;

import { FormControl } from "react-bootstrap";
import "./CustomDate.scss";

function CustomTime(props: any) {
  return (
    <FormControl
      type="time"
      className={"custom_date item " + props.className || ""}
      placeholder="HH:mm"
      value={(props.val?.length == 4 ? `0${props.val}` : props.val) || ""}
      onChange={(e) => props.setter(e.target.value)}
      style={{ width: props.width || 90 }}
    />
  );
};

export default CustomTime;

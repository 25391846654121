import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { useSelector } from "react-redux";
import { authSelector } from "../../redux/selectors/auth.selector";
import "./N002.scss";
import { N002Schema, GeneralForListSchema, Result } from "../../generated";
import { ValidationError, initialValidationError } from "../../models/validate.model";
import {
	deleteUploadData,
	fetchGeneralsForListMulti,
	fetchUploadData,
	putUploadData,
	putUploadDataFile,
} from "../../services/api.service";
import ConfirmModal from "../../components/ConfirmModal";
import CustomButton, { CB_CLASS } from "../../components/Button/CustomButton";
import CustomRow from "../../components/CustomRow/CustomRow";
import FileUploader from "../../components/FileUploader/FileUploader";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import Select from "../../components/Select/CustomSelect";
import Title from "../../components/Title/Title";
import { raiseAction } from "../../helper/api.helper";
import { notify, notifyError } from "../../helper/settings.helper";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import moment from "moment";
import { UPLOAD_CATEGORY, UPLOAD_STATUS, initialN002Model, validationUploadDataModel } from "../../models/uploadData.model";
import TextBox from "../../components/TextBox/TextBox";
import CustomTextarea from "../../components/CustomTextarea/CustomTextarea";
import { validationMessage } from "../../config/validationMessage";
import Label from "../../components/Label/Label";
import { useAuth } from "../../helper/auth.helper";

interface iN002 {
	upload_data_id: number;
}

const N002 = () => {
	const { checkLoggedIn } = useAuth();
	const location = useLocation<iN002>();
	const uploadDataId = location.state.upload_data_id;
	const history = useHistory();
	const authState = useSelector(authSelector);
	const [isLoading, changeIsLoading] = useLoadingIndicator(undefined);
	const [data, setData] = useState<N002Schema>(initialN002Model);
	const [validationError, setValidationError] = useState<ValidationError<N002Schema>>(initialValidationError());
	const [isValidated, setIsValidated] = useState<boolean | undefined>(undefined);
	const [showRegister, setShowRegister] = useState(false);
	const [showDelete, setShowDelete] = useState(false);
	const [statusForSelect, setStatusForSelect] = useState<GeneralForListSchema[]>([]);
	const [categoryForSelect, setCategoryForSelect] = useState<GeneralForListSchema[]>([]);
	const [uploadFile, setUploadFile] = useState<File>();
	const [msgUploadFile, setMsgUploadFile] = useState("");
	const [reload, setReload] = useState(true);

	const getSelectValue = (val: number, selectList: GeneralForListSchema[]) => {
		if (val == 0) return;
		return selectList.filter((x) => x.value == val)[0];
	};

	useEffect(() => {
		if (uploadDataId && reload) {
			let isMounted = true;
			const fetchData = async () => {
				changeIsLoading();
				fetchUploadData(uploadDataId, authState).then((res) => {
					if (isMounted) {
						setData(res.data);
						setReload(false);
					}
					changeIsLoading();
				}).catch((err) => {
					if (isMounted) {
						checkLoggedIn(err);
						notifyError("データの取得に失敗しました。");
						console.error(err);
					}
					changeIsLoading();
				});
			};
			fetchData();
			return () => {
				isMounted = false;
			}
		}
	}, [authState, changeIsLoading, checkLoggedIn, uploadDataId, reload]);

	useEffect(() => {
		let isMounted = true;
		const fetchData = async () => {
			changeIsLoading();
			fetchGeneralsForListMulti(authState, [UPLOAD_CATEGORY, UPLOAD_STATUS]).then((res: any) => {
				if (isMounted) {
					setCategoryForSelect(res.data[UPLOAD_CATEGORY]);
					setStatusForSelect(res.data[UPLOAD_STATUS]);
				}
				changeIsLoading();
			}).catch((err) => {
				if (isMounted) {
					checkLoggedIn(err);
					notifyError("データの取得に失敗しました。");
					console.error(err);
				}
				changeIsLoading();
			});
		};
		if (statusForSelect.length == 0) {
			fetchData();
		}
		return () => {
			isMounted = false;
		}
	}, [authState, changeIsLoading, checkLoggedIn, statusForSelect.length]);

	const clickRegister = () => {
		const validatedData = validationUploadDataModel(data);
		if (!data.file_url && !uploadFile) {
			validatedData.ok = false;
			setMsgUploadFile(validationMessage.emptyUploadFile);
		}
		if (validatedData.ok) {
			changeIsLoading();
			setMsgUploadFile("");
			putUploadData(data, authState).then((res) => {
				if (uploadFile) {
					putUploadDataFile(res.data, uploadFile, authState).then((_) => {
						setShowRegister(false);
						notify("アップロードデータをを更新しました。");
						changeIsLoading();
						history.goBack();
					}).catch((err) => {
						notifyError("登録に失敗しました。");
						console.error(err);
						changeIsLoading();
					});
				} else {
					setShowRegister(false);
					notify("アップロードデータをを更新しました。");
					changeIsLoading();
					history.goBack();
				}
			}).catch((err) => {
				checkLoggedIn(err);
				notifyError("登録に失敗しました。");
				console.error(err);
				changeIsLoading();
			});
		} else {
			setShowRegister(false);
			notifyError("入力不正があります。");
			setIsValidated(true);
			setValidationError(validatedData.validationError);
		}
	};

	const success_delete = () => {
		setShowRegister(false);
		notify("アップロードデータを削除しました。");
		changeIsLoading();
		history.goBack();
	}

	const clickDelete = () => {
		changeIsLoading();
		deleteUploadData(uploadDataId, authState).then((res) => {
			raiseAction(
				res.data,
				() => success_delete(),
				(v: Result) => {
					notifyError(String(v.msg));
					setShowRegister(false);
					changeIsLoading();
				});
		}).catch((err) => {
			checkLoggedIn(err);
			notifyError("削除に失敗しました。");
			console.error(err);
			changeIsLoading();
		});
	};

	useEffect(() => {
		if (isValidated) {
			setMsgUploadFile("");
			const validatedData = validationUploadDataModel(data);
			if (!data.file_url && !uploadFile) {
				validatedData.ok = false;
				setMsgUploadFile(validationMessage.emptyUploadFile);
			}
			if (validatedData.ok) {
				setValidationError(initialValidationError());
			} else {
				setIsValidated(true);
				setValidationError(validatedData.validationError);
			}
		}
	}, [data, isValidated, uploadFile]);

	return (
		<>
			<LoadingSpinner isLoading={isLoading} />
			<Title title={uploadDataId ? "アップロードデータ編集" : "アップロードデータ登録"} />
			<div className="plate">
				<CustomRow
					title="タイトル"
					required={true}
					error_msg={validationError?.messageOf?.title}
					content={
						<TextBox
							placeholder="タイトル"
							value={data.title}
							onChange={(value: string) => {
								setData({ ...data, title: value });
							}}
						/>
					}
				/>
				<CustomRow
					title="説明"
					required={false}
					content={
						<CustomTextarea
							placeholder="説明"
							value={data.description}
							onChange={(v: any) => setData({ ...data, description: v })}
						/>
					}
				/>
				<CustomRow
					title="カテゴリ"
					required={true}
					error_msg={validationError?.messageOf?.category}
					content={
						<Select
							value={getSelectValue(data.category, categoryForSelect)}
							options={categoryForSelect}
							className="N002_form mx-2"
							onChange={(e: any) => {
								setData({ ...data, category: e.value });
							}}
							placeholder="カテゴリ"
						/>
					}
				/>
				<CustomRow
					title="ファイル"
					required={true}
					error_msg={msgUploadFile}
					content={<FileUploader setFile={(file: File) => setUploadFile(file)} url={data.file_url} setMsg={setMsgUploadFile} />}
				/>
				<CustomRow
					title="公開状況"
					required={true}
					error_msg={validationError?.messageOf?.status}
					content={
						<Select
							value={getSelectValue(data.status, statusForSelect)}
							options={statusForSelect}
							className="N002_form mx-2"
							onChange={(e: any) => {
								setData({ ...data, status: e.value });
							}}
							placeholder="公開状況"
						/>
					}
				/>
				{data.upload_date &&
					<CustomRow
						title="アップロード日"
						required={false}
						content={
							<Label value={moment(data.upload_date).format("YYYY/MM/DD")} />
						}
					/>}
				<div className="plate d-flex flex-column align_center">
					<CustomButton
						label="登録"
						class={[CB_CLASS.RED]}
						onClick={() => setShowRegister(true)}
					/>
					<ConfirmModal
						target="登録"
						show={showRegister}
						setShow={setShowRegister}
						func={clickRegister}
						confirmText={"登録してよろしいでしょうか。"}
					/>
					{uploadDataId &&
						<>
							<CustomButton
								label="削除"
								class={[CB_CLASS.RED]}
								onClick={() => setShowDelete(true)}
							/>
							<ConfirmModal
								target="削除"
								show={showDelete}
								setShow={setShowDelete}
								func={clickDelete}
								confirmText={"削除してよろしいでしょうか。"}
							/>
						</>
					}
				</div>
			</div>
		</>
	)
};

export default N002;

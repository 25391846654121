import RequiredBadge from "../RequiredBadge";
import "./CustomRow.scss";
import Label from "../Label/Label";
import ErrorMsg from "../ErrorMsg/ErrorMsg";

function CustomRow(props: any) {
  const on_same_row = props.on_same_row == undefined ? true : props.on_same_row;
  return (
    <div className="custom_row row py-3">
      <div className="row-title on_same_row">
        <Label value={props.title} />
        {props.required && <RequiredBadge />}
      </div>
      <div className={props.full_width ? "" : "content"}>
        <ErrorMsg error_msg={props.error_msg} />
        <div className={on_same_row ? "on_same_row" : ""}>{props.content}</div>
      </div>
    </div>
  );
}

export default CustomRow;

import { B001Schema, FCFacilityF002Schema } from "../generated";
import { initialValidationError, ValidatedData } from "./validate.model";
import {
  checkFlgObj,
  checkValue,
  checkNumber,
  checkValueNotRequire,
} from "../helper/validation.helper";

export const HOLIDAY_FLG = "1";
export const WORKING_DAY_FLG = "0";
export const DEFAULT_REGUlAR_HOLIDAY = "0000000";

export const initialFacilityModel = (): FCFacilityF002Schema => ({
  id: 0,
  facility_name: "",
  office_number: "",
  email: "",
  phone_number: "",
  post_code: "",
  address_line: "",
  weekday_start_time: "11:00",
  weekday_end_time: "19:00",
  weekday_training_start_min: "45",
  holiday_start_time: "9:00",
  holiday_end_time: "17:00",
  holiday_training_start_min: "00",
  regular_holiday: DEFAULT_REGUlAR_HOLIDAY,
});

export const initialB001Schema = (): B001Schema => ({
  trainings: [],
  events: [],
  approval_pending_training_num: 0,
  approval_pending_event_num: 0,
  audit_docs: [],
  individual_support_plans: [],
  assessments: [],
  notifications: [],
  chat_messages_headquarter: [],
  chat_messages_parent: [],
  chat_messages_staff: [],
  orders: [],
  unregistered_fixed_time_slot: [],
});

export const validationFacilityModel = (
  facility: FCFacilityF002Schema
): ValidatedData<FCFacilityF002Schema> => {
  const error = initialValidationError<FCFacilityF002Schema>();

  const checkFlg: checkFlgObj = {
    isOk: true,
  };

  error.messageOf.facility_name = checkValue(checkFlg, "施設名", facility.facility_name, 30);

  error.messageOf.office_number = checkValueNotRequire(checkFlg, "事業所番号", facility.office_number, 10);

  error.messageOf.phone_number = checkNumber(
    checkFlg,
    "電話番号",
    facility.phone_number,
    11
  );

  error.messageOf.post_code = checkNumber(
    checkFlg,
    "郵便番号",
    facility.post_code,
    7
  );

  error.messageOf.address_line = checkValue(
    checkFlg,
    "住所",
    facility.address_line,
    100,
  );

  if (checkFlg.isOk) {
    return {
      ok: true,
      validatedData: facility,
    };
  }

  return {
    ok: false,
    validationError: error,
  };
};

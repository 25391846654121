import { reducerWithInitialState } from "typescript-fsa-reducers";
import { chatReloadActions } from "../actions/chatReload.actions";
interface ChatReloadState {
  reload: boolean;
}

const initialChatReloadState = (): ChatReloadState => ({
  reload: true,
});

export const chatReloadReducer = reducerWithInitialState(
  initialChatReloadState()
)
  .case(chatReloadActions.chatReloadTrue, () => {
    return {
      reload: true,
    };
  })
  .case(chatReloadActions.chatReloadFalse, () => {
    return {
      reload: false,
    };
  })
  .case(chatReloadActions.initChatReload, (state) => {
    return {
      ...state,
    };
  });

import { Button, Modal } from "react-bootstrap";
import React, { VFC } from "react";

type ConfirmModalProps = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  func: () => void;
  contents: any;
  confirmMessage: string;
  target: string;
};

const ConfirmModalCustomContents: VFC<ConfirmModalProps> = ({
  show,
  setShow,
  func,
  contents,
  confirmMessage,
  target
}) => {
  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{target}の実行</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {contents}
        <p>{confirmMessage}</p>
        <p>よろしければ実行をクリックしてください。</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          中止
        </Button>
        <Button variant="primary" onClick={func}>
          実行
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModalCustomContents;

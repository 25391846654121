import { Form } from "react-bootstrap";

const Label = (props: any) => {
  return (
    <div className="item">
      <Form.Label
        style={{ margin: 0 }}
        className={props.className}
      >
        {props.value}
      </Form.Label>
    </div>
  );
};

export default Label;

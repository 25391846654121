import { FaJira } from "react-icons/fa6";
import { validationMessage } from "../config/validationMessage";
import { J002Schema, J003Schema } from "../generated";
import { initialValidationError, ValidatedData } from "./validate.model";

export const NOTIFICATION_TYPE = {
  HEADQUARTER: 1,
  FC: 2,
  PARENT: 3,
  SYSTEM: 4,
};

export const RECIPIENT_TYPE = {
  FC: 1,
  RANK: 2,
  PARENT: 3,
};

export const NOTIFICATION_STATUS = {
  UNREAD: 1,
  READ: 2,
};

export const NOTIFICATION_SEND_STATUS = {
  UNSENT: 1,
  SENT: 2,
}

export const initialJ002ModelForHeadQuarterUser = (fcId: number | undefined, fcName: string): J002Schema => ({
  id: 0,
  facility_id: fcId,
  facility_name: fcName,
  title: "",
  body: "",
  notification_recipients: [],
});

export const initialJ002ModelForStaff = (fcId: number | undefined, fcName: string): J002Schema => ({
  id: 0,
  facility_id: fcId,
  facility_name: fcName,
  title: "",
  body: "",
  notification_recipients: [{ type: NOTIFICATION_TYPE.PARENT, recipient: 0 }],
});

export const validationJ002Model = (
  programData: J002Schema
): ValidatedData<J002Schema> => {
  const error = initialValidationError<J002Schema>();
  let ok = true;

  if (programData.title === "") {
    error.messageOf.title = validationMessage.emptyTitle;
    ok = false;
  }

  if (programData.title.length > 255) {
    error.messageOf.title = validationMessage.limitNotificationTitleLength;
    ok = false;
  }

  if (programData.body.length > 255) {
    error.messageOf.body = validationMessage.limitNotificationTextLength;
    ok = false;
  }

  if (programData.notification_recipients.filter(x => x.type == RECIPIENT_TYPE.FC).length == 0) {
    error.messageOf.notification_recipients = validationMessage.noEnteredRecipientsFc;
    ok = false;
  }

  if (programData.notification_recipients.filter(x => x.type !== RECIPIENT_TYPE.FC).length == 0) {
    error.messageOf.notification_recipients = validationMessage.noEnteredRecipients;
    ok = false;
  }

  if (ok) {
    return {
      ok: true,
      validatedData: programData,
    };
  }

  return {
    ok: false,
    validationError: error,
  };
};

export const initialJ003Model = (): J003Schema => ({
  facility_name: "",
  title: "",
  body: "",
  notification_recipients: [],
});

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  authSelector,
  loggedInSelector,
} from "../../redux/selectors/auth.selector";
import { getTokenAction } from "../../redux/actions/auth.actions";
import { Link, useHistory } from "react-router-dom";
import {
  LoginModel,
  initialLoginModel,
  validateLoginModel,
} from "../../models/login.model";
import Header from "../../components/Header/Header";
import "./Auth.scss";
import { validationMessage } from "../../config/validationMessage";
import {
  initialValidationError,
  ValidationError,
} from "../../models/validate.model";
import axios from "axios";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import { clear } from "redux-localstorage-simple";
import { Alert, Form } from "react-bootstrap";
import { Button } from "react-bootstrap";

const Login = () => {
  document.body.classList.add("login_no_scroll");
  const dispatch = useDispatch();
  const authState = useSelector(authSelector);
  const loggedIn = useSelector(loggedInSelector);
  const history = useHistory();
  const [isLoading, changeIsLoading] = useLoadingIndicator();

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isValidate, setIsValidate] = useState<boolean>(false);
  const [loginData, setLoginData] = useState<LoginModel>(initialLoginModel);
  const [validationError, setValidationError] = useState<
    ValidationError<LoginModel>
  >(initialValidationError());

  const handleLogin = () => {
    changeIsLoading();
    const validateData = validateLoginModel(loginData);
    if (validateData.ok) {
      dispatch(getTokenAction(loginData));
      changeIsLoading();
    } else {
      changeIsLoading();
      setIsValidate(true);
      setValidationError(validateData.validationError);
    }
  };

  const handleHttpError = async (error: any) => {
    if (error !== null) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          setErrorMessage(validationMessage.loginFailed);
        }
      }
    }
  };

  useEffect(() => {
    changeIsLoading();
    if (loggedIn) {
      history.push("/");
      changeIsLoading();
    } else {
      clear();
      changeIsLoading();
    }
  }, [loggedIn, history, changeIsLoading]);

  useEffect(() => {
    if (isValidate) {
      const validateData = validateLoginModel(loginData);
      if (validateData.ok) {
        setValidationError(initialValidationError());
      } else {
        setIsValidate(true);
        setValidationError(validateData.validationError);
      }
    }
  }, [loginData, isValidate]);

  useEffect(() => {
    if (authState.fetchUserInfoError) {
      handleHttpError(authState.fetchUserInfoError);
    }
  }, [authState]);

  return (
    <React.Fragment>
      <Header />
      <LoadingSpinner isLoading={isLoading} />
      <div className="sic_kids_admin_auth">
        <div className="sic_kids_admin_auth_container">
          <h1 className="sic_kids_admin_auth_title">ログイン</h1>
          <div style={{ width: "100%", textAlign: "center" }}>
            <div style={{ display: "inline-block", maxWidth: "450px", width: "100%" }} >
              {validationError.messageOf.email !== undefined &&
                <Alert variant="danger" className="login_alert">
                  <p>{validationError.messageOf.email}</p>
                </Alert>
              }
              <Form.Control
                type="email"
                placeholder="メールアドレス"
                value={loginData.email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setLoginData({ ...loginData, email: e.target.value });
                }}
                className="login_text mb-2"
              />
              {validationError.messageOf.password !== undefined &&
                <Alert variant="danger" className="login_alert">
                  <p>{validationError.messageOf.password}</p>
                </Alert>
              }
              <Form.Control
                type="password"
                placeholder="パスワード"
                value={loginData.password}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setLoginData({ ...loginData, password: e.target.value });
                }}
                className="login_text"
              />
              {errorMessage &&
                <Alert variant="danger" className="login_alert mt-2">
                  <p>{errorMessage}</p>
                </Alert>
              }
              <Button className="sic_kids_admin_auth_btn login_btn" onClick={handleLogin}>
                ログイン
              </Button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Login;

export type ValidationError<T> = {
  messageOf: {
    [P in keyof T]+?: string;
  };
};

export const initialValidationError = <T>(): ValidationError<T> => ({
  messageOf: {},
});

export type ValidatedData<T, S extends T = T> =
  | {
      ok: true;
      validatedData: S;
    }
  | {
      ok: false;
      validationError: ValidationError<T>;
    };

import "./W002.scss";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router";
import {
  FCStaffInfoSchema,
  GeneralForListSchema, W002Schema,
} from "../../generated";
import { authSelector, userInfoSelector } from "../../redux/selectors/auth.selector";
import {
  deleteW002,
  fetchGeneralsForList, fetchTemplateUrl, fetchW002, notifyW002, putW002
} from "../../services/api.service";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import CustomButton, { CB_CLASS } from "../../components/Button/CustomButton"
import Title from "../../components/Title/Title"
import { LIST_SIGNATURE_REQUEST, STAFF } from "../../constants";
import CustomRow from "../../components/CustomRow/CustomRow";
import FileUploader from "../../components/FileUploader/FileUploader";
import CustomDate from "../../components/Calendar/CustomDate";
import ConfirmModal from "../../components/ConfirmModal";
import Download from "../../components/Icon/Download";
import { getFormatDate } from "../../helper/date.helper";
import { initialW002Model, validationW002Model } from "../../models/signature_request.model";
import { validationMessage } from "../../config/validationMessage";
import { notify, notifyError } from "../../helper/settings.helper";
import { ValidationError, initialValidationError } from "../../models/validate.model";
import Label from "../../components/Label/Label";
import { TEMPLATE_FILE_TYPE } from "../../models/template.model";
import { useAuth } from "../../helper/auth.helper";

interface iLocation {
  signature_request_make_id: number;
}

const W002 = () => {
  const { checkLoggedIn } = useAuth();
  const maxWidth = 1300;
  const location = useLocation<iLocation>();
  const signatureRequestMakeId = location.state.signature_request_make_id;
  const userInfo = useSelector(userInfoSelector);
  const fcId = userInfo?.user_type == STAFF ? (userInfo as FCStaffInfoSchema)?.facilities[0].facility_id : 0;
  const authState = useSelector(authSelector);
  const [docTypeList, setDocTypeList] = useState<GeneralForListSchema[]>([]);
  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const [uploadFile, setUploadFile] = useState();
  const [showRegister, setShowRegister] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showNotify, setShowNotify] = useState(false);
  const [isValidated, setIsValidated] = useState<boolean | undefined>(undefined);
  const [msgUploadFile, setMsgUploadFile] = useState("");
  const [data, setData] = useState<W002Schema>(initialW002Model());
  const history = useHistory();
  const [validationError, setValidationError] = useState<ValidationError<W002Schema>>(initialValidationError());
  const [templateUrl, setTemplateUrl] = useState("");
  const [reload, setReload] = useState(true);

  useEffect(() => {
    if (signatureRequestMakeId && reload) {
      let isMounted = true;
      const fetchData = async () => {
        changeIsLoading();
        fetchW002(authState, signatureRequestMakeId).then((res) => {
          if (isMounted) {
            setData(res.data);
            setReload(false);
          }
          changeIsLoading();
        }).catch((err) => {
          if (isMounted) {
            checkLoggedIn(err);
            notifyError("データの取得に失敗しました。");
            console.error(err);
          }
          changeIsLoading();
        });
      };
      fetchData();
      return () => {
        isMounted = false;
      }
    }
  }, [authState, changeIsLoading, checkLoggedIn, signatureRequestMakeId, reload]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchTemplateUrl(authState, TEMPLATE_FILE_TYPE.CHANGE_CONSENT_FORM_FOR_IMPORTANT_INFORMATION_MANUAL).then((res) => {
        if (isMounted) {
          setTemplateUrl(res.data);
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    fetchData();
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchGeneralsForList(authState, LIST_SIGNATURE_REQUEST).then((res) => {
        if (isMounted) {
          setDocTypeList(res.data);
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (docTypeList.length == 0) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, docTypeList.length]);

  const getDocType = () => {
    if (docTypeList.filter(x => x.value == data.doc_type).length > 0) {
      return docTypeList.filter(x => x.value == data.doc_type)[0].label
    }
    return "";
  };

  const clickRegister = () => {
    const validatedData = validationW002Model(data);
    if (!uploadFile) {
      validatedData.ok = false;
      setMsgUploadFile(validationMessage.emptyUploadFile);
    }
    if (validatedData.ok) {
      // これがないとputW002の引数の型エラーになる
      if (!uploadFile) return;
      changeIsLoading();
      setMsgUploadFile("");
      putW002(authState, fcId, data.doc_type, data.publication_date, uploadFile).then((_) => {
        setShowRegister(false);
        notify("署名依頼の通知を実行しました。");
        changeIsLoading();
        history.goBack();
      }).catch((err) => {
        setShowRegister(false);
        checkLoggedIn(err).then(isLoggedIn => {
          if (isLoggedIn) {
            notifyError("署名依頼登録に失敗しました。フォーマットに誤りが無いか確認願います。");
          }
        });
        console.error(err);
        changeIsLoading();
      });
    } else {
      setShowRegister(false);
      notifyError("入力不正があります。");
      setIsValidated(true);
      setValidationError(validatedData.validationError);
    }
  };

  const clickDelete = () => {
    changeIsLoading();
    deleteW002(authState, signatureRequestMakeId).then((_) => {
      setShowDelete(false);
      notify("署名依頼を削除しました。");
      changeIsLoading();
      history.goBack();
    }).catch((err) => {
      checkLoggedIn(err);
      notifyError("削除に失敗しました。");
      console.error(err);
      changeIsLoading();
    });
  };

  const clickNotify = () => {
    changeIsLoading();
    notifyW002(authState, signatureRequestMakeId).then((_) => {
      setShowDelete(false);
      notify("署名依頼通知を再送しました。");
      changeIsLoading();
      history.goBack();
    }).catch((err) => {
      checkLoggedIn(err);
      notifyError("登録に失敗しました。");
      console.error(err);
      changeIsLoading();
    });
  };

  useEffect(() => {
    if (isValidated) {
      setMsgUploadFile("");
      const validatedData = validationW002Model(data);
      if (!data.file_url && !uploadFile) {
        validatedData.ok = false;
        setMsgUploadFile(validationMessage.emptyUploadFile);
      }
      if (validatedData.ok) {
        setValidationError(initialValidationError());
      } else {
        setIsValidated(true);
        setValidationError(validatedData.validationError);
      }
    }
  }, [data, isValidated, uploadFile]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Title title="署名依頼帳票詳細" />
      <div className="plate">
        {signatureRequestMakeId ? (
          <>
            <CustomRow title="帳票種別" content={
              <Label value={getDocType()} />
            } />
            <CustomRow title="帳票" content={
              <FileUploader noChangeFile={true} url={data.file_url} />
            } />
            <CustomRow title="作成日" content={
              <Label value={getFormatDate(data.publication_date)} />
            } />
            <div className="item">
              <CustomButton label="削除" class={[CB_CLASS.RED]} min_width={120} onClick={() => setShowDelete(true)} />
              <ConfirmModal target="削除" show={showDelete} setShow={setShowDelete} func={clickDelete}
                confirmText={"紐づく通知、署名依頼、署名済情報が全て削除されます。"}
              />
              <CustomButton label="再通知" class={[CB_CLASS.BLUE]} min_width={120} onClick={() => setShowNotify(true)} />
              <ConfirmModal target="再通知" show={showNotify} setShow={setShowNotify} func={clickNotify}
                confirmText={"未署名の保護者に対し、再通知を送ります。"}
              />
            </div>
          </>
        ) : (
          <>
            <CustomRow title="帳票種別" content={
              <div className="item on_same_row">
                <Label value={getDocType()} />
                {templateUrl && <Download url={templateUrl} />}
              </div>
            } />
            <CustomRow title="帳票" required={true} error_msg={msgUploadFile} content={
              <FileUploader setFile={setUploadFile} file={uploadFile} url={data.file_url} setMsg={setMsgUploadFile} />
            } />
            <CustomRow title="作成日" required={true} error_msg={validationError?.messageOf?.publication_date} content={
              <div className="item">
                <CustomDate val={data.publication_date} setter={(v: string) => setData({ ...data, publication_date: v })} width="90px" />
              </div>
            } />
            <div className="item">
              <CustomButton label="登録して通知" class={[CB_CLASS.RED]} min_width={120} onClick={() => setShowRegister(true)} />
              <ConfirmModal target="登録して通知" show={showRegister} setShow={setShowRegister} func={clickRegister}
                confirmText={"登録後、作成日が入会日から退会日に一致するお子様の保護者宛てに通知されます。"}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default W002;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import {
  initialSubscriptionContentUpdateModel,
  SubscriptionContentModel,
  SubscriptionContentUpdateModel,
  validatonSubscriptionContentModel,
  validatonSubscriptionContentUpdateodel,
} from "../../models/subscriptionContents.model";
import { authSelector } from "../../redux/selectors/auth.selector";
// import {
//   fetchSubscriptionContent,
//   editSubscriptionContent, deleteSubscriptionContent,
// } from "../../services/api.service";
import BorderLine from "../../components/BorderLine";
import Select from "react-select";
import { publicationStatus } from "../../helper/data.helper";
import "./SubscriptionEdit.scss";
import { subscriptionUrl } from "../../helper/url.helper";
import {notify, notifyError} from "../../helper/settings.helper";
import RequiredBadge from "../../components/RequiredBadge";
import {
  initialValidationError,
  ValidationError,
} from "../../models/validate.model";
import { validationMessage } from "../../config/validationMessage";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";

const SubscriptionContentEdit = () => {
  const authState = useSelector(authSelector);
  const history = useHistory();
  const location = useLocation();

  const [content, setContent] = useState<SubscriptionContentUpdateModel>(
    initialSubscriptionContentUpdateModel
  );
  const [contentId, _] = useState(location.state as number);
  const [contentUrl, setContentUrl] = useState<undefined | string>(undefined);
  const [contentVideo, setContentVideo] = useState<File | undefined>(undefined);
  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const [isValidated, setIsValidated] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<
    ValidationError<SubscriptionContentModel>
  >(initialValidationError());

  const handleChange = () => {
    const validatedData = validatonSubscriptionContentUpdateodel(content);
    if (validatedData.ok) {
      changeIsLoading();
      // editSubscriptionContent(contentId, content, contentVideo, authState)
      //   .then((res) => {
      //     console.log(res.data);
      //     notify("コンテンツを編集しました");
      //     history.push(subscriptionUrl);
      //     changeIsLoading();
      //   })
      //   .catch((err) => {
      //     console.error(err);
      //     notifyError("コンテンツエラー！");
      //     changeIsLoading();
      //   });
    } else {
      setIsValidated(true);
      setValidationError(validatedData.validationError);
    }
  };

  const handleDelete = () => {
    // deleteSubscriptionContent(contentId, authState)
    //   .then((res) => {
    //     console.log(res.data);
    //     notify("コンテンツを編集しました");
    //     history.push(subscriptionUrl);
    //     changeIsLoading();
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //     notifyError("コンテンツエラー！");
    //     changeIsLoading();
    //   });
  };

  useEffect(() => {
    if (isValidated) {
      const validateData = validatonSubscriptionContentUpdateodel(content);
      if (validateData.ok) {
        setValidationError(initialValidationError());
      } else {
        setIsValidated(true);
        setValidationError(validateData.validationError);
      }
    }
  }, [content, isValidated]);

  const [isExistVideo, setIsExistVideo] = useState(true);

  useEffect(() => {
    changeIsLoading();
    // fetchSubscriptionContent(contentId, authState)
    //   .then((res) => {
    //     console.log(res.data);
    //     setContent(res.data);
    //     setContentUrl(res.data.url);
    //     if (res.data.video_name === "") {
    //       setIsExistVideo(false);
    //     }
    //     changeIsLoading();
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //     changeIsLoading();
    //   });
  }, [contentId, authState, changeIsLoading]);

  return (
    <div className="edit_content_container">
      <LoadingSpinner isLoading={isLoading} />
      <h1 className="edit_content_title">サブスクリプション動画編集</h1>
      <div className="edit_content_inner">
        <div className="edit_content_item_wrapper">
          <div className="edit_content_item">
            <div className="edit_content_item_left">
              <p>タイトル</p>
              <RequiredBadge />
            </div>
            <div className="edit_content_item_right">
              <input
                placeholder="タイトルを入力"
                className="edit_content_item_input"
                value={content.title}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setContent({ ...content, title: e.target.value });
                }}
              />
            </div>
          </div>
          <BorderLine />

          <div className="edit_content_item">
            <div className="edit_content_item_left">
              <p>サブスクリプション動画</p>
              <RequiredBadge />
            </div>
            <div className="edit_content_item_right">
              {contentUrl ? (
                <>
                  <video
                    controls
                    src={contentUrl}
                    className={"edit_content_item_video"}
                  />
                  <p>動画を変更する場合はファイルを選択</p>
                </>
              ) : (
                <p>動画がありません。</p>
              )}
              <input
                placeholder="トレーニング名"
                className="edit_content_item_input"
                type="file"
                accept="video/*"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.currentTarget.files !== null) {
                    const file = e.currentTarget.files[0];
                    setContentVideo(file);
                  }
                }}
              />
            </div>
          </div>
          <BorderLine />

          <div className="edit_content_item">
            <div className="edit_content_item_left">
              <p>説明文</p>
              <RequiredBadge />
            </div>
            <div className="edit_content_item_right">
              <textarea
                rows={30}
                cols={30}
                placeholder="動画の説明を記入"
                className="edit_content_item_textarea"
                value={content.description}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setContent({ ...content, description: e.target.value });
                }}
              />
            </div>
          </div>
          <BorderLine />

          <div className="edit_content_item">
            <div className="edit_content_item_left">
              <p>公開状況</p>
              <RequiredBadge />
            </div>
            <div className="edit_content_item_right">
              <Select
                value={publicationStatus.find(
                    (option) => option.value === content.status
                )}
                options={publicationStatus}
                className="add_program_item_input"
                onChange={(e: any) => {
                  setContent({ ...content, status: e.value });
                }}
              />
            </div>
          </div>
          <BorderLine />
        </div>
        <p className="add_content_error-message error-message">
          {validationError.messageOf.title !== undefined && (
            <span className="error-message">
              {validationError.messageOf.title}
            </span>
          )}
          {isValidated && !isExistVideo && (
            <span className="error-message">
              {validationMessage.emptyVideo}
            </span>
          )}
          {validationError.messageOf.description !== undefined && (
            <span className="error-message">
              {validationError.messageOf.description}
            </span>
          )}
        </p>
        <button className="edit_content_item_button" onClick={handleChange}>
          編集を完了する
        </button>
        <button className="edit_content_item_button" onClick={handleDelete}>
          削除
        </button>
      </div>
    </div>
  );
};

export default SubscriptionContentEdit;

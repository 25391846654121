import { E002Schema } from "../generated";
import { checkFlgObj, checkSelectOrRadio, checkValue } from "../helper/validation.helper";
import { ValidatedData, initialValidationError } from "./validate.model";

export const ISP_PLAN = 1;
export const ISP_PROCESSING_ = 2;
export const ISP_PROCESSING_DONE_MONITORING = 3;
export const ISP_CLOSED = 4;

export const MEETING_FILE = 1;
export const PLAN_FILE = 2;
export const MONITORING_FILE = 3;
export const PLAN_IDEA_FILE = 4;

export const STATUS_INDIVIDUAL_SUPPORT_PLAN = "個別支援計画ステータス";

export const initialE002Model = (): E002Schema => ({
  id: 0,
  child_id: 0,
  status: 0,
  start_date: "",
  facility_name: "",
  child_name: "",
  child_name_kana: "",
  profile_pic_url: "",
  meeting_file_url: "",
  plan_file_url: "",
  plan_is_signed: false,
  monitoring_file_url: "",
});

export const validationIspModel = (
  data: E002Schema
): ValidatedData<E002Schema> => {
  const error = initialValidationError<E002Schema>();

  const checkFlg: checkFlgObj = {
    isOk: true,
  };

  error.messageOf.status = checkSelectOrRadio(checkFlg, "ステータス", data.status);

  error.messageOf.start_date = checkValue(checkFlg, "支援期間", data.start_date);

  if (checkFlg.isOk) {
    return {
      ok: true,
      validatedData: data,
    };
  }

  return {
    ok: false,
    validationError: error,
  };
};
import actionCreatorFactory from "typescript-fsa";
import { io, Socket } from "socket.io-client";
import { GetState } from "../reducers";
import { BASE_PATH } from "../../generated/base";
import { setupWebSocketActionHandlers } from "../../helper/ws.helper";

const actionCreator = actionCreatorFactory();

const connectWebsocketActions = actionCreator.async<
  {},
  { sid: string; socket: Socket },
  {}
>("WS_CONNECT");

export const wsActions = {
  connectStarted: connectWebsocketActions.started,
  connectFailed: connectWebsocketActions.failed,
  connectDone: connectWebsocketActions.done,
  disconnect: actionCreator("WS_DISCONNECT"),
  updateChatMessageHandler: actionCreator<(message: any) => void>(
    "WS_UPDATE_CHAT_MESSAGE_HANDLER"
  ),
  deleteChatMessageHandler: actionCreator("WS_DELETE_CHAT_MESSAGE_HANDLER"),
};

export const connectWebsocketAction = () => {
  return (dispatch: Function, getState: GetState) => {
    const wsState = getState().wsReducer;
    if (wsState.connected) {
      return;
    }
    dispatch(wsActions.connectStarted({}));
    const socket = io(BASE_PATH, {
      transports: ["websocket"],
    });

    setupWebSocketActionHandlers(socket, dispatch, getState);
  };
};

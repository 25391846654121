import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./ContainerWithSidebar.scss";
import "./Sidebar.scss";
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { authSelector, userInfoSelector } from "../../redux/selectors/auth.selector";
import { Q001Url, getForHistoryPush } from "../../helper/url.helper";
import { useMediaQuery } from "react-responsive";
import SidebarInResponsive from "./SidebarInResponsive";
import { STAFF } from "../../constants";
import { FCStaffInfoSchema } from "../../generated";

interface ContainerWithSidebarProps {
  children: React.ReactNode;
}

const ContainerWithSidebar: React.FC<ContainerWithSidebarProps> = ({
  children,
}) => {
  const history = useHistory();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1200px)" });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1201px)",
  });
  const authState = useSelector(authSelector);
  const userInfo = useSelector(userInfoSelector);
  let fcName = "";
  if (userInfo?.user_type == STAFF) {
    fcName = (userInfo as FCStaffInfoSchema)?.facilities[0].facility_name;
  }

  return (
    <div className={isTabletOrMobile ? "outside position-relative" : "outside"}>
      <div className={isTabletOrMobile ? "menu-responsive" : "menu"}>
        {isTabletOrMobile && <SidebarInResponsive fcName={fcName} />}
        {isDesktopOrLaptop && (
          <div className="side-bar-container">
            <p className="title" style={{ textAlign: "center" }}>
              {fcName ? (
                <Link to="/B001">{fcName}</Link>
              ) : (
                <Link to="/B001">S.I.C.KIDS本部</Link>
              )}
            </p>
            <div className="menu-item">
              <Sidebar />
            </div>
          </div>
        )}
      </div>
      <div className="center">{children}</div>
      <div className="right">
        <div
          style={{
            position: "fixed",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div onClick={() => history.push(getForHistoryPush(Q001Url, []))} style={{ cursor: "pointer", textAlign: "center" }}>
            <div className="profile-pic">
              <img
                src={
                  authState.userInfo?.profile_pic_url ||
                  `${process.env.PUBLIC_URL}/img/staff_register.png`
                }
              />
            </div>
            <div>
              {authState.userInfo?.family_name ??
                "" + authState.userInfo?.given_name ??
                ""}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContainerWithSidebar;

import { reducerWithInitialState } from "typescript-fsa-reducers";
import { listActions } from "../actions/list.actions";
import { makeList, makePgList } from "../../helper/list.helper";
import { initialListModel, initialPgListModel } from "../../models/list.model";

export const fcListReducer = reducerWithInitialState(initialListModel())
  .case(listActions.makeFcList, (_, payload) => {
    return makeList(payload);
  })
  .case(listActions.clearFcList, () => {
    return initialListModel();
  });

export const pgListReducer = reducerWithInitialState(initialPgListModel())
  .case(listActions.makePgList, (_, payload) => {
    return makePgList(payload);
  })
  .case(listActions.clearPgList, () => {
    return initialPgListModel();
  });

import { G002ChatMessageCreateSchema, G002Schema } from "../generated";

export const USER_TYPE_NUM_HEADQUARTER_USER = 1;
export const USER_TYPE_NUM_STAFF = 2;
export const USER_TYPE_NUM_PARENT = 3;

export const initialChatDataModel = (): G002Schema => ({
  messages: [],
  name: "",
  profile_pic_url: "",
});

export const initialChatMessageModel = (): G002ChatMessageCreateSchema => ({
  headquarter_staff_id: 0,
  staff_id: 0,
  parent_id: 0,
  message: "",
});
import "./V001.scss";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authSelector, userInfoSelector } from "../../redux/selectors/auth.selector";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useLocation } from "react-router-dom";
import CustomButton, { CB_CLASS } from "../../components/Button/CustomButton"
import Title from "../../components/Title/Title"
import Select from "../../components/Select/CustomSelect";
import CustomDate from "../../components/Calendar/CustomDate"
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import {
  EventV001Schema,
  FCFacilitiesSchema,
  GeneralForListSchema,
} from "../../generated";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import { V003Url, V002Url, getForHistoryPush } from "../../helper/url.helper"
import Status, { STATUS_BLUE, STATUS_GRAY, STATUS_RED, StatusSchema } from "../../components/Status/Status";
import { fetchFcFacilities, fetchGeneralsForList, fetchV001, fetchV001Count } from "../../services/api.service";
import { HEADQUARTER_USER, LIST_EVENT_STATUS } from "../../constants";
import { EVENT_STATUS } from "../../models/event.model";
import { getState } from "../../helper/page.helper";
import { useAuth } from "../../helper/auth.helper";
import moment from "moment";
import { notifyError } from "../../helper/settings.helper";
import PaginationTableLite from "../../components/PaginationTableLite/PaginationTableLite";
import { fcListSelector } from "../../redux/selectors/list.selector";
import { listActions } from "../../redux/actions/list.actions";
import { FC_LIST_TYPE, getFcList, getFcListForRedux, getFcListFromRedux } from "../../helper/list.helper";

const status_list: StatusSchema[] = [
  { num: EVENT_STATUS.PUBLISHED, class: STATUS_RED },
  { num: EVENT_STATUS.UNPUBLISHED, class: STATUS_BLUE },
  { num: EVENT_STATUS.END, class: STATUS_GRAY }
];

interface iLocation {
  facility_id: number;
  begin_date: string;
  end_date: string;
}

export const headquarter_value = -1;

const V001: React.VFC = () => {
  const { checkLoggedIn } = useAuth();
  const maxWidth = 1300;
  const authState = useSelector(authSelector);
  const history = useHistory();
  const location = useLocation<iLocation>();
  let state = getState(location);
  if (state.facility_id == undefined) state.facility_id = 0;
  if (state.begin_date == undefined) state.begin_date = moment().subtract(1, "months").format("yyyy-MM-DD");
  const [search, setSearch] = useState<iLocation>(state);
  const [fcForSelect, setFcForSelect] = useState<GeneralForListSchema[]>([]);
  const [statusList, setStatusList] = useState<GeneralForListSchema[]>([]);
  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const [data, setData] = useState<EventV001Schema[]>([]);
  const [getData, setGetData] = useState(true);
  const userInfo = useSelector(userInfoSelector);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordCount, setRecordCount] = useState(0);
  const limitCount = 10;
  const fcListState = useSelector(fcListSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchFcFacilities(authState, "").then((res) => {
        if (isMounted) {
          setFcForSelect(getFcList(FC_LIST_TYPE.ADD_INIT_HEAD, res.data));
          dispatch(listActions.makeFcList(getFcListForRedux(res.data)));
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (fcForSelect.length == 0) {
      if (fcListState.select_list.length == 0) {
        fetchData();
      } else {
        setFcForSelect(getFcListFromRedux(FC_LIST_TYPE.ADD_INIT_HEAD, fcListState.select_list));
      }
    }
    return () => {
      isMounted = false;
    }
  }, [authState, dispatch, changeIsLoading, checkLoggedIn, fcForSelect.length, fcListState.select_list]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchGeneralsForList(authState, LIST_EVENT_STATUS).then((res) => {
        if (isMounted) {
          setStatusList(res.data);
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (statusList.length == 0) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, statusList.length]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      try {
        const [resCount, resData] = await Promise.all([
          fetchV001Count(authState, search.facility_id == headquarter_value ? undefined : search.facility_id, search.begin_date, search.end_date),
          fetchV001(authState, search.facility_id == headquarter_value ? undefined : search.facility_id, search.begin_date, search.end_date, (currentPage - 1) * limitCount, limitCount)
        ]);

        if (isMounted) {
          setRecordCount(resCount.data);
          setData(resData.data);
          setGetData(false);
        }
        changeIsLoading();
      } catch (err) {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      }
    };
    if (getData) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, search, getData, currentPage]);

  const getCreatedByName = (fcId: number | undefined) => {
    if (fcForSelect.length == 0) return null;
    if (fcId == undefined)
      return fcForSelect.filter(x => x.value == headquarter_value)[0].label;
    if (fcForSelect.filter(x => x.value == fcId).length > 0)
      return fcForSelect.filter(x => x.value == fcId)[0].label;
  };

  const getSelectedValue = (fcId: number | undefined) => {
    if (fcId == 0) return null;
    return fcForSelect.filter(x => x.value == fcId)[0];
  };

  const changeCurrentPage = (v: number) => {
    setGetData(true);
    setCurrentPage(v);
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Title title="イベント一覧" />
      <div className="plate">
        <div className="on_same_row justify" style={{ maxWidth: maxWidth }}>
          <div className="on_same_row">
            {userInfo?.user_type == HEADQUARTER_USER &&
              <div className="item">
                <Select
                  value={getSelectedValue(search.facility_id)}
                  options={fcForSelect}
                  className="V001_select"
                  onChange={(e: any) => {
                    setSearch({ ...search, facility_id: e.value });
                    setGetData(true);
                  }}
                  placeholder="主催"
                />
              </div>
            }
            <div className="on_same_row item">
              <CustomDate
                val={search.begin_date}
                setter={(v: string) => {
                  setSearch({ ...search, begin_date: v });
                  setGetData(true);
                }}
                width="90px"
              />
              <div className="wave_str">～</div>
              <CustomDate
                val={search.end_date}
                setter={(v: string) => {
                  setSearch({ ...search, end_date: v });
                  setGetData(true);
                }}
                width="90px"
              />
            </div>
          </div>
          <CustomButton label="＋イベントを作成" class={[CB_CLASS.RED]} onClick={() => history.push(getForHistoryPush(V003Url, [0, "V001"]))} />
        </div>
      </div>
      <PaginationTableLite
        currentPage={currentPage}
        setCurrentPage={changeCurrentPage}
        recordCount={recordCount}
        limit={limitCount}
        tr_select={false}
        headers={["主催", "タイトル", "開催日時", "公開状況", "上限人数", "参加人数"]}
        list={data.map((x, i) => (
          <tr
            key={i}
            className={(userInfo?.user_type == HEADQUARTER_USER || (x.facility_id ?? 0) > 0) ? "V001_tr" : ""}
            onClick={() => (userInfo?.user_type == HEADQUARTER_USER || (x.facility_id ?? 0) > 0) && history.push(getForHistoryPush(V002Url, [x.id]))}
          >
            <td className="item">
              {getCreatedByName(x.facility_id)}
            </td>
            <td className="item" >
              {x.main_title}
            </td>
            <td className="item">
              {`${x.dates} ${x.start_time}-${x.end_time}`}
            </td>
            <td className="item" valign="middle" align="center">
              <Status
                max_width={130}
                label_list={statusList}
                color_list={status_list}
                status={x.status}
              />
            </td>
            <td className="item">
              {x.limit || "上限なし"}
            </td>
            <td className="item">
              {x.num}
            </td>
          </tr>
        ))}
      />
    </>
  );
};

export default V001;

import { L008CartItemSchema } from "../generated";
import { MIN_ORDER_NUM } from "../models/equipment.model";
import { CartModel, makeCartModel } from "../models/order.model";

export const addOrderToCart = (
  cart: CartModel,
  selectedOrder: L008CartItemSchema,
) => {
  const list = [...cart.order_list, selectedOrder];
  const cartObj = makeCartModel(list);
  return cartObj;
};

export const updateCartItem = (
  cart: CartModel,
  selectedOrder: L008CartItemSchema,
) => {
  let orderInCart = cart.order_list.findIndex(order => order.equipment_id === selectedOrder.equipment_id);
  cart.order_list[orderInCart].num = selectedOrder.num;
  const cartObj = makeCartModel(cart.order_list);
  return cartObj;
};

export const deleteOrderItemFromCart = (
  cart: CartModel,
  selectedOrderEquipmentId: number,
) => {
  cart
  const list = cart.order_list.filter((order) => {
    return order.equipment_id !== selectedOrderEquipmentId;
  });

  const cartObj = makeCartModel(list);
  return cartObj;
};

export const incrementCartItem = (
  cart: CartModel,
  selectedOrderEquipmentId: number,
) => {
  cart.order_list.find((order) => {
    if (order.equipment_id === selectedOrderEquipmentId) {
      order.num++;
    }
  });

  const list = [...cart.order_list];
  const cartObj = makeCartModel(list);
  return cartObj;
};

export const decrementCartItemNum = (
  cart: CartModel,
  selectedOrderEquipmentId: number,
) => {
  cart.order_list.find((order) => {
    if (order.equipment_id === selectedOrderEquipmentId) {
      order.num > MIN_ORDER_NUM && order.num--;
    }
  });

  const list = [...cart.order_list];
  const cartObj = makeCartModel(list);
  return cartObj;
};

import "./P001.scss";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  GeneralForListSchema, P001Schema,
} from "../../generated";
import { authSelector } from "../../redux/selectors/auth.selector";
import {
  fetchGeneralsForListMulti,
  fetchP001,
  fetchP001Count,
} from "../../services/api.service";
import Select from "../../components/Select/CustomSelect";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import CustomButton, { CB_CLASS } from "../../components/Button/CustomButton"
import Title from "../../components/Title/Title"
import Status, { STATUS_RED, STATUS_BLUE, StatusSchema } from "../../components/Status/Status"
import { initialGeneralForList } from "../../models/general.model";
import { HEADQUARTER_USER, LIST_PG_STATUS, LIST_REFLECTION, LIST_STEP, LIST_TRAINING_TOOL } from "../../constants";
import { P002Url, getForHistoryPush } from "../../helper/url.helper";
import { PROGRAM_ITEM_STATUS_PUBLISHED, PROGRAM_ITEM_STATUS_UNPUBLISHED } from "../../models/programItem.model";
import Play from "../../components/Icon/Play";
import { useAuth } from "../../helper/auth.helper";
import TextBox from "../../components/TextBox/TextBox";
import { notifyError } from "../../helper/settings.helper";
import PaginationTableLite from "../../components/PaginationTableLite/PaginationTableLite";
import { windowOpen } from "../../helper/page.helper";

interface iSearch {
  step: number,
  reflection: number,
  program_name: string,
}

const P001 = () => {
  const { checkLoggedIn } = useAuth();
  const maxWidth = 1300;
  const authState = useSelector(authSelector);
  const history = useHistory();
  const [data, setData] = useState<P001Schema[]>([]);
  const [getData, setGetData] = useState(true);
  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const [search, setSearch] = useState<iSearch>({
    step: 0,
    reflection: 0,
    program_name: "",
  });
  const [stepForSelect, setStepForSelect] = useState<GeneralForListSchema[]>([]);
  const [reflectionForSelect, setReflectionForSelect] = useState<GeneralForListSchema[]>([]);
  const [trainingToolForSelect, setTrainingToolForSelect] = useState<GeneralForListSchema[]>([]);
  const [statusForSelect, setStatusForSelect] = useState<GeneralForListSchema[]>([]);
  const status_list: StatusSchema[] = [
    { num: PROGRAM_ITEM_STATUS_PUBLISHED, class: STATUS_RED },
    { num: PROGRAM_ITEM_STATUS_UNPUBLISHED, class: STATUS_BLUE },
  ];
  const [currentPage, setCurrentPage] = useState(1);
  const [recordCount, setRecordCount] = useState(0);
  const limitCount = 10;

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      const keywords = [LIST_PG_STATUS, LIST_STEP, LIST_REFLECTION, LIST_TRAINING_TOOL];
      fetchGeneralsForListMulti(authState, keywords).then((res: any) => {
        if (isMounted) {
          keywords.map(x => res.data[x].unshift(initialGeneralForList()));
          setStatusForSelect(res.data[LIST_PG_STATUS]);
          setStepForSelect(res.data[LIST_STEP]);
          setReflectionForSelect(res.data[LIST_REFLECTION]);
          setTrainingToolForSelect(res.data[LIST_TRAINING_TOOL]);
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (trainingToolForSelect.length == 0) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, trainingToolForSelect.length]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      try {
        const [resCount, resData] = await Promise.all([
          fetchP001Count(authState, search.step, search.reflection, search.program_name),
          fetchP001(authState, search.step, search.reflection, search.program_name, (currentPage - 1) * limitCount, limitCount)
        ]);

        if (isMounted) {
          setRecordCount(resCount.data);
          setData(resData.data);
          setGetData(false);
        }
        changeIsLoading();
      } catch (err) {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      }
    };
    if (getData) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, search, getData, currentPage]);

  const getSelectValue = (val: number, selectList: GeneralForListSchema[]) => {
    if (val == 0) return null;
    return selectList.filter(x => x.value == val)[0];
  };

  const changeCurrentPage = (v: number) => {
    setGetData(true);
    setCurrentPage(v);
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Title title="PG一覧" />
      <div className="plate">
        <div className="on_same_row justify" style={{ maxWidth: maxWidth }}>
          <div className="on_same_row">
            <TextBox
              placeholder="プログラム名"
              value={search.program_name}
              onChange={(value: string) => {
                setSearch({ ...search, program_name: value });
                setGetData(true);
              }}
            />
            <Select
              value={getSelectValue(search.step, stepForSelect)}
              options={stepForSelect}
              className="P001_select item"
              onChange={(e: any) => {
                setSearch({ ...search, step: e.value });
                setGetData(true);
              }}
              placeholder="Step"
            />
            <Select
              value={getSelectValue(search.reflection, reflectionForSelect)}
              options={reflectionForSelect}
              className="P001_select item"
              onChange={(e: any) => {
                setSearch({ ...search, reflection: e.value });
                setGetData(true);
              }}
              placeholder="反射"
            />
          </div>
          {authState.userInfo?.user_type === HEADQUARTER_USER &&
            <CustomButton label="＋PGを追加" min_width={150} class={[CB_CLASS.RED]}
              onClick={() => history.push(getForHistoryPush(P002Url, [0]))}
            />
          }
        </div>
      </div>
      <PaginationTableLite
        currentPage={currentPage}
        setCurrentPage={changeCurrentPage}
        recordCount={recordCount}
        limit={limitCount}
        max_width={maxWidth}
        headers={authState.userInfo?.user_type === HEADQUARTER_USER ?
          ["Step", "反射", "PG", "教具", "公開状況", ""]
          : ["Step", "反射", "PG", "教具", ""]}
        list={data.map((x, i) => (
          <tr key={i} onClick={() => authState.userInfo?.user_type === HEADQUARTER_USER && windowOpen(getForHistoryPush(P002Url, [x.id]))}>
            <td className="item">
              {getSelectValue(x.step, stepForSelect)?.label}
            </td>
            <td className="item">
              {x.reflections?.map((y, j) => {
                return <div key={j} className="item">{reflectionForSelect.filter(z => z.value == y.reflection)[0]?.label}</div>
              })}
            </td>
            <td className="item">
              {x.title}
            </td>
            <td className="item" >
              {x.training_tools?.map((y, j) => {
                return <div key={j} className="item">{`${trainingToolForSelect.filter(z => z.value == y.training_tool)[0]?.label}${y.remarks ? `（${y.remarks}）` : ""}`}</div>
              })}
            </td>
            {authState.userInfo?.user_type === HEADQUARTER_USER &&
              <td className="item" align="center">
                <Status label_list={statusForSelect} color_list={status_list} status={x.status} max_width={100} />
              </td>
            }
            <td className="item" align="center">
              <Play video_url={x.video_url} video_preparing={x.video_preparing} />
            </td>
          </tr>
        ))}
      />
    </>
  );
};

export default P001;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { R002Url, getForHistoryPush } from "../../helper/url.helper";
import { HeadquarterUserR001Schema } from "../../generated";
import { fetchHeadQuarterUserForList } from "../../services/api.service";
import { authSelector } from "../../redux/selectors/auth.selector";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import CustomButton, { CB_CLASS, CB_ALIGN } from "../../components/Button/CustomButton";
import Title from "../../components/Title/Title";
import PaginationTable from "../../components/PaginationTable/PaginationTable";
import Pict, { SIZES } from "../../components/Pict/User";
import { useAuth } from "../../helper/auth.helper";
import { notifyError } from "../../helper/settings.helper";

const R001 = () => {
  const { checkLoggedIn } = useAuth();
  const maxWidth = 900;
  const authState = useSelector(authSelector);
  const history = useHistory();
  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const [data, setData] = useState<HeadquarterUserR001Schema[]>([]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchHeadQuarterUserForList(authState).then((res) => {
        if (isMounted) {
          setData(res.data);
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (data.length == 0) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, data.length]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Title title="本部スタッフ一覧" />
      <div className="plate">
        <div style={{ maxWidth: maxWidth }}>
          <CustomButton
            label="＋本部スタッフを追加"
            class={[CB_CLASS.RED]}
            align={CB_ALIGN.RIGHT}
            onClick={() => history.push(getForHistoryPush(R002Url, [0]))}
          />
        </div>
      </div>
      <PaginationTable
        limit={13}
        max_width={maxWidth}
        headers={["アイコン", "スタッフ名"]}
        list={data.map((x, i) => (
          <tr key={i} onClick={() => history.push(getForHistoryPush(R002Url, [x.id]))}>
            <td className="item" valign="middle" align="center">
              <Pict size={SIZES.M} url={x.profile_pic_url} />
            </td>
            <td className="item">{x.family_name + x.given_name}</td>
          </tr>
        ))}
      />
    </>
  );
};

export default R001;

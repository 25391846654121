import { checkEmail, checkFlgObj, checkValue } from "../../helper/validation.helper";
import { ValidatedData, initialValidationError } from "../../models/validate.model";

export interface iContact {
  "email": string;
  "name": string;
  "message": string;
}

export const initialContactModel = (): iContact => ({
  email: "",
  name: "",
  message: "",
});

export const validationContactModel = (
  data: iContact
): ValidatedData<iContact> => {
  const error = initialValidationError<iContact>();

  let checkFlg: checkFlgObj = {
    isOk: true,
  };
  error.messageOf.email = checkEmail(checkFlg, data.email);

  error.messageOf.name = checkValue(checkFlg, "名前", data.name);

  error.messageOf.message = checkValue(checkFlg, "お問い合わせ内容", data.message);

  if (checkFlg.isOk) {
    return {
      ok: true,
      validatedData: data,
    };
  }

  return {
    ok: false,
    validationError: error,
  };
};

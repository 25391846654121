import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { useSelector } from "react-redux";
import { authSelector } from "../../redux/selectors/auth.selector";
import {
	deleteHealthRecord,
	fetchHealthRecord,
	putHealthRecord,
} from "../../services/api.service";
import { D004Schema } from "../../generated";
import { initialD004Model, validationHealthRecordModel } from "../../models/child.model";
import { ValidationError, initialValidationError } from "../../models/validate.model";
import ChildInfoTop from "../../components/ChildInfoTop/ChildInfoTop";
import ConfirmModal from "../../components/ConfirmModal";
import CustomButton, { CB_CLASS } from "../../components/Button/CustomButton";
import CustomDate from "../../components/Calendar/CustomDate";
import CustomRow from "../../components/CustomRow/CustomRow";
import Label from "../../components/Label/Label";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import TextBox from "../../components/TextBox/TextBox";
import Title from "../../components/Title/Title";
import { notify, notifyError } from "../../helper/settings.helper";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import moment from "moment";
import { useAuth } from "../../helper/auth.helper";
import NumBox, { NUM_BOX_TYPE } from "../../components/TextBox/NumBox";

interface iD004 {
	health_id: number;
	child_id: number;
}

const D004 = () => {
	const { checkLoggedIn } = useAuth();
	const location = useLocation<iD004>();
	const healthId = location.state.health_id;
	const childId = location.state.child_id;
	const history = useHistory();
	const authState = useSelector(authSelector);
	const [isLoading, changeIsLoading] = useLoadingIndicator(undefined);
	const [data, setData] = useState<D004Schema>(initialD004Model);
	const [validationError, setValidationError] = useState<ValidationError<D004Schema>>(initialValidationError());
	const [isValidated, setIsValidated] = useState<boolean | undefined>(undefined);
	const [showRegister, setShowRegister] = useState(false);
	const [showDelete, setShowDelete] = useState(false);
	const [reload, setReload] = useState(true);

	useEffect(() => {
		if (reload) {
			let isMounted = true;
			const fetchData = async () => {
				changeIsLoading();
				fetchHealthRecord(childId, healthId, authState).then((res) => {
					if (isMounted) {
						setData(res.data);
						!res.data.measurement_date && setData({ ...res.data, measurement_date: moment().format("yyyy-MM-DD") });
						setReload(false);
					}
					changeIsLoading();
				}).catch((err) => {
					if (isMounted) {
						checkLoggedIn(err);
						notifyError("データの取得に失敗しました。");
						console.error(err);
					}
					changeIsLoading();
				});
			};
			fetchData();
			return () => {
				isMounted = false;
			}
		}
	}, [authState, changeIsLoading, checkLoggedIn, childId, healthId, reload]);

	const clickRegister = () => {
		const validatedData = validationHealthRecordModel(data);
		if (validatedData.ok) {
			changeIsLoading();
			putHealthRecord(authState, data).then((res) => {
				setShowRegister(false);
				notify("身長・体重データを更新しました。");
				changeIsLoading();
				history.goBack();
			})
		} else {
			setShowRegister(false);
			notifyError("入力不正があります。");
			setIsValidated(true);
			setValidationError(validatedData.validationError);
		}
	};

	const clickDelete = () => {
		changeIsLoading();
		deleteHealthRecord(healthId, authState).then((res) => {
			setShowRegister(false);
			notify("アセスメントシートを削除しました。");
			changeIsLoading();
			history.goBack();
		}).catch((err) => {
			checkLoggedIn(err);
			notifyError("削除に失敗しました。");
			console.error(err);
			changeIsLoading();
		});
	};

	useEffect(() => {
		if (isValidated) {
			const validatedData = validationHealthRecordModel(data);
			if (validatedData.ok) {
				setValidationError(initialValidationError());
			} else {
				setIsValidated(true);
				setValidationError(validatedData.validationError);
			}
		}
	}, [data, isValidated]);

	return (
		<>
			<LoadingSpinner isLoading={isLoading} />
			<Title title={healthId ? "身長・体重編集" : "身長・体重登録"} />
			<div className="plate">
				<ChildInfoTop
					user_type={authState.userInfo?.user_type}
					profile_pic_url={data?.child_profile_pic_url}
					id={data.child_id}
					name={data.child_name}
					name_kana={data.child_name_kana}
					facility_name={data.facility_name}
					no_underbar={false}
				/>
				<CustomRow
					title="日付"
					required={true}
					error_msg={validationError.messageOf.measurement_date}
					content={
						<CustomDate val={data.measurement_date} className="mx-2" setter={(v: string) => setData({ ...data, measurement_date: v })} />
					}
				/>
				<CustomRow
					title="身長"
					required={false}
					error_msg={validationError.messageOf.height}
					content={
						<>
							<NumBox
								value={data.height}
								placeholder="身長"
								width="150px"
								type={NUM_BOX_TYPE.MEASUREMENT}
								onChange={(v: any) => setData({ ...data, height: v == "" ? undefined : v })}
							/>
							<Label value="cm" />
						</>
					}
				/>
				<CustomRow
					title="体重"
					required={false}
					error_msg={validationError.messageOf.weight}
					content={
						<>
							<NumBox
								value={data.weight}
								placeholder="体重"
								width="150px"
								type={NUM_BOX_TYPE.MEASUREMENT}
								onChange={(v: any) => setData({ ...data, weight: v == "" ? undefined : v })}
							/>
							<Label value="kg" />
						</>
					}
				/>
				<div className="plate d-flex flex-column align_center">
					<CustomButton
						label="登録"
						class={[CB_CLASS.RED]}
						onClick={() => setShowRegister(true)}
					/>
					<ConfirmModal
						target="登録"
						show={showRegister}
						setShow={setShowRegister}
						func={clickRegister}
						confirmText={"登録してよろしいでしょうか。"}
					/>
					{healthId &&
						<>
							<CustomButton
								label="削除"
								class={[CB_CLASS.RED]}
								onClick={() => setShowDelete(true)}
							/>
							<ConfirmModal
								target="削除"
								show={showDelete}
								setShow={setShowDelete}
								func={clickDelete}
								confirmText={"削除してよろしいでしょうか。"}
							/>
						</>
					}
				</div>
			</div>
		</>
	);
};

export default D004;

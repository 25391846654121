import "./Icon.scss";
import { MdOutlineFileDownload } from "react-icons/md";
import { COLOR_BLUE } from "../../constants";
import { handleDownload } from "../../helper/download.helper.";

function Download(props: any) {

  return (
    <MdOutlineFileDownload
      className="icon hover_blue"
      color={COLOR_BLUE}
      size={50}
      onClick={(event) => {
        event.stopPropagation();
        handleDownload(props.url);
      }} 
    />
  );
};

export default Download;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./D001.scss";
import {
  ChildForListSchema,
  FCStaffInfoSchema,
  GeneralForListSchema,
  FCFacilitiesSchema,
} from "../../generated";
import { initialGeneralForList } from "../../models/general.model";
import { HEADQUARTER_USER, STAFF } from "../../constants";
import {
  authSelector,
  userInfoSelector,
} from "../../redux/selectors/auth.selector";
import {
  fetchD001,
  fetchD001Count,
  fetchFcFacilities,
} from "../../services/api.service";
import { D002Url, getForHistoryPush } from "../../helper/url.helper";
import { makeStringDateWithSlash } from "../../helper/time.helper";
import { calcAge } from "../../helper/calculation.helper";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import Label from "../../components/Label/Label";
import Title from "../../components/Title/Title";
import TextBox from "../../components/TextBox/TextBox";
import CheckBox from "../../components/CheckBox/CheckBox";
import Pict, { SIZES } from "../../components/Pict/User";
import Select from "../../components/Select/CustomSelect";
import { useAuth } from "../../helper/auth.helper";
import NumBox, { NUM_BOX_TYPE } from "../../components/TextBox/NumBox";
import { notifyError } from "../../helper/settings.helper";
import PaginationTableLite from "../../components/PaginationTableLite/PaginationTableLite";
import { fcListSelector } from "../../redux/selectors/list.selector";
import { listActions } from "../../redux/actions/list.actions";
import { FC_LIST_TYPE, getFcList, getFcListForRedux, getFcListFromRedux } from "../../helper/list.helper";
import { ORDER_AGE, ORDER_ASC, ORDER_DESC, ORDER_JOIN_DATE, ORDER_NAME } from "../../models/child.model";

interface iSearch {
  facilityId: number,
  childName: string,
  begin_age: number | undefined,
  end_age: number | undefined,
  endFlg: boolean,
  sortElement: number,
  sortOrder: number,
}

const D001 = () => {
  const { checkLoggedIn } = useAuth();
  const maxWidth = 1300;
  const authState = useSelector(authSelector);
  const userInfo = useSelector(userInfoSelector);
  const history = useHistory();
  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const [data, setData] = useState<ChildForListSchema[]>([]);
  const [getData, setGetData] = useState(true);
  const facilityId = userInfo?.user_type == STAFF ? (userInfo as FCStaffInfoSchema)?.facilities[0].facility_id : 0;
  const [fcForSelect, setFcForSelect] = useState<GeneralForListSchema[]>([]);
  const [getFcForSelect, setGetFcForSelect] = useState(true);
  const [search, setSearch] = useState<iSearch>({
    facilityId: facilityId,
    childName: "",
    begin_age: undefined,
    end_age: undefined,
    endFlg: false,
    sortElement: 1,
    sortOrder: 1,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [recordCount, setRecordCount] = useState(0);
  const limitCount = 9;
  const fcListState = useSelector(fcListSelector);
  const dispatch = useDispatch();
  const [sortElementForSelect, setSortElementForSelect] = useState<GeneralForListSchema[]>([
    {value: ORDER_NAME, label: "名前順"},
    {value: ORDER_JOIN_DATE, label: "入会日順"},
    {value: ORDER_AGE, label: "年齢順"}
  ]);
  const [sortOrderForSelect, setSortOrderFcForSelect] = useState<GeneralForListSchema[]>([
    {value: ORDER_ASC, label: "昇順"},
    {value: ORDER_DESC, label: "降順"}
  ]);
  
  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      try {
        const [resCount, resData] = await Promise.all([
          fetchD001Count(authState, search.facilityId, search.childName, search.begin_age, search.end_age, search.endFlg),
          fetchD001(authState, search.facilityId, search.childName, search.begin_age, search.end_age, search.endFlg, search.sortElement, search.sortOrder,(currentPage - 1) * limitCount, limitCount)
        ]);

        if (isMounted) {
          setRecordCount(resCount.data);
          setData(resData.data);
          setGetData(false);
        }
        changeIsLoading();
      } catch (err) {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      }
    };
    if (getData) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, search, getData, currentPage]);

  useEffect(() => {
    if (userInfo?.user_type == HEADQUARTER_USER) {
      let isMounted = true;
      const fetchData = async () => {
        changeIsLoading();
        fetchFcFacilities(authState, "").then((res) => {
          if (isMounted) {
            setGetFcForSelect(false);
            setFcForSelect(getFcList(FC_LIST_TYPE.ADD_INIT, res.data));
            dispatch(listActions.makeFcList(getFcListForRedux(res.data)));
          }
          changeIsLoading();
        }).catch((err) => {
          if (isMounted) {
            checkLoggedIn(err);
            notifyError("データの取得に失敗しました。");
            console.error(err);
          }
          changeIsLoading();
        });
      };
      if (getFcForSelect) {
        if (fcListState.select_list.length == 0) {
          fetchData();
        } else {
          setGetFcForSelect(false);
          setFcForSelect(getFcListFromRedux(FC_LIST_TYPE.ADD_INIT, fcListState.select_list));
        }
      }
      return () => {
        isMounted = false;
      }
    }
  }, [authState, dispatch, changeIsLoading, checkLoggedIn, userInfo, getFcForSelect, fcListState.select_list]);

  const getSelectValue = (val: number, selectList: GeneralForListSchema[]) => {
    if (val == 0) return null;
    return selectList.filter(x => x.value == val)[0];
  };

  const changeCurrentPage = (v: number) => {
    setGetData(true);
    setCurrentPage(v);
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Title title="お子様一覧" />
      <div className="plate">
        <div className="d-flex justify" style={{ maxWidth: maxWidth }}>
          <div className="on_same_row flex-wrap">
            {userInfo?.user_type == HEADQUARTER_USER &&
              <Select
                value={getSelectValue(search.facilityId, fcForSelect)}
                options={fcForSelect}
                className="D001_select item"
                onChange={(e: any) => {
                  setSearch({ ...search, facilityId: e.value });
                  setGetData(true);
                }}
                placeholder="施設"
              />
            }
            <TextBox
              class="item"
              placeholder="お子様名"
              width="200px"
              value={search.childName}
              onChange={(value: string) => {
                setSearch({ ...search, childName: value });
                setGetData(true);
              }}
            />
            <NumBox
              value={search.begin_age}
              placeholder="年齢"
              type={NUM_BOX_TYPE.THOUSAND}
              onChange={(value: any) => {
                setSearch({ ...search, begin_age: !value ? undefined : value });
                setGetData(true);
              }}
            />
            <Label value="才から" />
            <NumBox
              value={search.end_age}
              placeholder="年齢"
              type={NUM_BOX_TYPE.THOUSAND}
              onChange={(value: any) => {
                setSearch({ ...search, end_age: !value ? undefined : value });
                setGetData(true);
              }}
            />
          </div>
          <div className="endFlg_check">
            <CheckBox
              checked={search.endFlg}
              onChange={(value: any) => {
                setSearch({ ...search, endFlg: value.target.checked });
                setGetData(true);
              }}
              label="退会済のお子様を表示する"
            />
          </div>
        </div>
        <div className="on_same_row">
          <Select
            value={getSelectValue(search.sortElement, sortElementForSelect)}
            options={sortElementForSelect}
            className="D001_select item"
            onChange={(e: any) => {
              setSearch({ ...search, sortElement: e.value });
              setGetData(true);
            }}
          />
          <Select
            value={getSelectValue(search.sortOrder, sortOrderForSelect)}
            options={sortOrderForSelect}
            className="D001_select item"
            onChange={(e: any) => {
              setSearch({ ...search, sortOrder: e.value });
              setGetData(true);
            }}
          />
        </div>
      </div>
      <PaginationTableLite
        currentPage={currentPage}
        setCurrentPage={changeCurrentPage}
        recordCount={recordCount}
        limit={limitCount}
        headers={userInfo?.user_type == HEADQUARTER_USER
          ? ["所属施設", "アイコン", "お子様名", "年齢", "入会日"]
          : ["アイコン", "お子様名", "年齢", "入会日"]
        }
        list={data.map((x, i) => (
          <tr
            key={i}
            onClick={() => history.push(getForHistoryPush(D002Url, [x.id]))}
          >
            {userInfo?.user_type == HEADQUARTER_USER &&
              <td className="item">{x.facility_name}</td>
            }
            <td className="item">
              <Pict url={x.profile_pic_url} size={SIZES.M} />
            </td>
            <td className="item">{x.family_name + x.given_name}</td>
            <td className="item">{x.birthday && calcAge(x.birthday)}</td>
            <td className="item">
              {x.join_date && makeStringDateWithSlash(x.join_date)}
            </td>
          </tr>
        ))}
      />
    </>
  );
};

export default D001;

import "./H002.scss";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import {
  FCFacilitiesSchema,
  GeneralForListSchema,
  FCStaffInfoSchema,
  ProgramItemForSelectSchema,
  TrainingH002Schema,
  ProgramSelectSchema,
  ReflectionSchema,
  ChildH002Schema,
} from "../../generated";
import { authSelector, userInfoSelector } from "../../redux/selectors/auth.selector";
import {
  fetchGeneralsForListMulti,
  fetchFcFacilities,
  fetchProgramForSelect,
  fetchStaffById,
  fetchTrainingH002,
  deleteTraining,
  putH002,
  fetchChildrenForH002,
} from "../../services/api.service";
import Select from "../../components/Select/CustomSelect";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import CustomButton, { CB_ALIGN, CB_CLASS, CB_SIZE } from "../../components/Button/CustomButton"
import CustomDate from "../../components/Calendar/CustomDate"
import Title from "../../components/Title/Title"
import User, { SIZES } from "../../components/Pict/User"
import {
  initialTrainingH002Model,
  initialProgramSelectModel,
  validationTrainingH002Model,
  SERVICE_PROVISION_STATUS,
  TRAINING_STATUS_TRAINED,
  TRAINING_STATUS_SENT,
  TRAINING_STATUS_SIGNED,
  TRAINING_STATUS_NOT_PLANNED,
} from "../../models/training.model";
import { initialGeneralForList } from "../../models/general.model";
import { DEFAULT_TRAINING_MINUTE, HEADQUARTER_USER, LIST_CONSULTATION_SUPPORT_ADDITION, LIST_REFLECTION, LIST_SERVICE_PROVISION_STATUS, LIST_STEP, LIST_TRAINING_TOOL, STAFF } from "../../constants";
import CustomRow from "../../components/CustomRow/CustomRow";
import { D002Url, H003Url, getForHistoryPush } from "../../helper/url.helper";
import { FormLabel } from "react-bootstrap";
import Delete from "../../components/Icon/Delete";
import Play from "../../components/Icon/Play";
import DraggableList from "../../components/DraggableList/DraggableList";
import ConfirmModal from "../../components/ConfirmModal";
import { notify, notifyError } from "../../helper/settings.helper";
import Label from "../../components/Label/Label";
import { ValidationError, initialValidationError } from "../../models/validate.model";
import { format } from "date-fns-tz";
import { getState } from "../../helper/page.helper";
import { useAuth } from "../../helper/auth.helper";
import { getFormatDate } from "../../helper/date.helper";
import { validationMessage } from "../../config/validationMessage";
import { pgListSelector } from "../../redux/selectors/list.selector";
import { listActions } from "../../redux/actions/list.actions";
import { fcListSelector } from "../../redux/selectors/list.selector";
import { FC_LIST_TYPE, getFcList, getFcListForRedux, getFcListFromRedux } from "../../helper/list.helper";
import CustomTime from "../../components/Calendar/CustomTime";
import { addMinutes } from "../../helper/time.helper";

interface iLocation {
  training_id: number;
  reference_id: number;
  from: string;
}

interface iSelects {
  reflection: GeneralForListSchema[],
  program: GeneralForListSchema[],
}

interface iSelected {
  step: number,
  reflection: number,
}

const initialSelected = (): iSelected => ({
  step: 0,
  reflection: 0
});

const H002 = () => {
  const { checkLoggedIn } = useAuth();
  const location = useLocation<iLocation>();
  const state = getState(location);
  const [trainingId, setTrainingId] = useState(state.training_id);
  const reference_id = state.reference_id;
  const authState = useSelector(authSelector);
  const history = useHistory();
  const userInfo = useSelector(userInfoSelector);
  const [facilityId, setFacilityId] = useState(userInfo?.user_type == STAFF ? (userInfo as FCStaffInfoSchema)?.facilities[0].facility_id : 0);
  const [data, setData] = useState<TrainingH002Schema>(initialTrainingH002Model(facilityId));
  const [fcForSelect, setFcForSelect] = useState<GeneralForListSchema[]>([]);
  const [childrenList, setChildrenList] = useState<ChildH002Schema[]>([]);
  const [childForSelect, setChildForSelect] = useState<GeneralForListSchema[]>([]);
  const [staffForSelect, setStaffForSelect] = useState<GeneralForListSchema[]>([]);
  const [getChild, setGetChild] = useState(true);
  const [stepForSelect, setStepForSelect] = useState<GeneralForListSchema[]>([]);
  const [reflectionList, setReflectionList] = useState<GeneralForListSchema[]>([]);
  const [serviceProvisionStatusForSelect, setServiceProvisionStatusForSelect] = useState<GeneralForListSchema[]>([]);
  const [consultationSupportAdditionForSelect, setConsultationSupportAdditionForSelect] = useState<GeneralForListSchema[]>([]);
  const [programList, setProgramList] = useState<ProgramItemForSelectSchema[]>([]);
  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const [selectList, setSelectList] = useState<iSelects[]>([]);
  const [selectedList, setSelectedList] = useState<iSelected[]>([initialSelected()]);
  const [showRegister, setShowRegister] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [isValidated, setIsValidated] = useState<boolean | undefined>(undefined);
  const [validationError, setValidationError] = useState<ValidationError<TrainingH002Schema>>(initialValidationError());
  const noInputList = [
    SERVICE_PROVISION_STATUS.ABSENCE,
    SERVICE_PROVISION_STATUS.ABSENCE_1,
    SERVICE_PROVISION_STATUS.INTERNAL_COUNSELING_SUPPORT_ALLOWANCE_ONLY,
  ];
  const noInput = noInputList.indexOf(data.service_provision_status || 0) > -1;
  const [reload, setReload] = useState(true);
  const pgListState = useSelector(pgListSelector);
  const fcListState = useSelector(fcListSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (trainingId && reload) {
      let isMounted = true;
      const fetchData = async () => {
        changeIsLoading();
        fetchTrainingH002(authState, trainingId).then((res) => {
          if (isMounted) {
            if ([TRAINING_STATUS_TRAINED, TRAINING_STATUS_SENT, TRAINING_STATUS_SIGNED].indexOf(res.data.status) > -1) {
              history.goBack();
            }
            if (res.data.program_selects.length == 0) {
              res.data.program_selects = [initialProgramSelectModel()];
            } else {
              setSelectedList(res.data.program_selects.map(x => ({ step: x.program_item.step, reflection: x.program_item.reflections[0].reflection })));
            }
            setData(res.data);
            setFacilityId(res.data.facility_id);
            setReload(false);
          }
          changeIsLoading();
        }).catch((err) => {
          if (isMounted) {
            checkLoggedIn(err);
            notifyError("データの取得に失敗しました。");
            console.error(err);
          }
          changeIsLoading();
        });
      };
      fetchData();
      return () => {
        isMounted = false;
      }
    }
  }, [authState, changeIsLoading, checkLoggedIn, trainingId, history, reload]);

  useEffect(() => {
    if (reference_id) {
      let isMounted = true;
      const fetchData = async () => {
        changeIsLoading();
        fetchTrainingH002(authState, reference_id).then((res) => {
          if (isMounted) {
            res.data.id = 0;
            res.data.training_date = format(new Date, "yyyy-MM-dd");
            res.data.program_selects = res.data.program_selects.filter(x => x.played);
            if (res.data.program_selects.length == 0) {
              res.data.program_selects = [initialProgramSelectModel()];
            } else {
              setSelectedList(res.data.program_selects.map(x => ({ step: x.program_item.step, reflection: x.program_item.reflections[0].reflection })));
            }
            setData(res.data);
            setFacilityId(res.data.facility_id);
          }
          changeIsLoading();
        }).catch((err) => {
          if (isMounted) {
            checkLoggedIn(err);
            notifyError("データの取得に失敗しました。");
            console.error(err);
          }
          changeIsLoading();
        });
      };
      fetchData();
      return () => {
        isMounted = false;
      }
    }
  }, [authState, changeIsLoading, checkLoggedIn, reference_id]);

  useEffect(() => {
    if (userInfo?.user_type == HEADQUARTER_USER) {
      let isMounted = true;
      const fetchData = async () => {
        changeIsLoading();
        fetchFcFacilities(authState, "").then((res) => {
          if (isMounted) {
            setFcForSelect(getFcList(FC_LIST_TYPE.DEFAULT, res.data));
            dispatch(listActions.makeFcList(getFcListForRedux(res.data)));
          }
          changeIsLoading();
        }).catch((err) => {
          if (isMounted) {
            checkLoggedIn(err);
            notifyError("データの取得に失敗しました。");
            console.error(err);
          }
          changeIsLoading();
        });
      };
      if (fcForSelect.length == 0) {
        if (fcListState.select_list.length == 0) {
          fetchData();
        } else {
          setFcForSelect(getFcListFromRedux(FC_LIST_TYPE.DEFAULT, fcListState.select_list));
        }
      }
      return () => {
        isMounted = false;
      }
    }
  }, [authState, dispatch, changeIsLoading, checkLoggedIn, userInfo, fcForSelect.length, fcListState.select_list]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchGeneralsForListMulti(
        authState,
        [LIST_STEP, LIST_REFLECTION, LIST_SERVICE_PROVISION_STATUS, LIST_CONSULTATION_SUPPORT_ADDITION]
      ).then((res) => {
        if (isMounted) {
          const resGenData = res.data as any;
          resGenData[LIST_STEP].unshift(initialGeneralForList());
          setStepForSelect(resGenData[LIST_STEP]);
          setReflectionList(resGenData[LIST_REFLECTION]);
          setServiceProvisionStatusForSelect(resGenData[LIST_SERVICE_PROVISION_STATUS]);
          setConsultationSupportAdditionForSelect(resGenData[LIST_CONSULTATION_SUPPORT_ADDITION]);
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    }
    if (stepForSelect.length == 0) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [authState, changeIsLoading, checkLoggedIn, stepForSelect.length]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchProgramForSelect(authState, []).then((res) => {
        if (isMounted) {
          setProgramList(res.data);
          dispatch(listActions.makePgList(res.data));
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (programList.length == 0) {
      if (pgListState.select_list.length == 0) {
        fetchData();
      } else {
        setProgramList(pgListState.select_list);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [authState, dispatch, changeIsLoading, checkLoggedIn, programList.length, pgListState.select_list]);

  useEffect(() => {
    setSelectList(selectedList.map(x => ({
      reflection: programList
        .filter(pg => pg.step == x.step)
        .map(pg => reflectionList.filter(y => pg.reflections.filter(rf => rf.reflection == y.value).length > 0)[0])
        .filter((pg, i, self) => self.indexOf(pg) === i),
      program: programList
        .filter(pg => pg.step == x.step && pg.reflections.filter(rf => rf.reflection == x.reflection).length > 0)
        .map(pg => ({ value: pg.id, label: pg.title }))
    })));
  }, [selectedList, programList, reflectionList])

  useEffect(() => {
    if (facilityId > 0) {
      let isMounted = true;
      const fetchData = async () => {
        changeIsLoading();
        try {
          const [resChildren, resStaff] = await Promise.all([
            fetchChildrenForH002(authState, facilityId),
            fetchStaffById(authState, facilityId),
          ]);

          if (isMounted) {
            setChildForSelect(resChildren.data.map(x => ({
              value: x.id,
              label: x.family_name + x.given_name
            })));
            setChildrenList(resChildren.data);
            setStaffForSelect(resStaff.data.map(x => ({
              value: x.id,
              label: x.family_name + x.given_name
            })));
            changeIsLoading();
            setGetChild(false);
          }
        } catch (err) {
          if (isMounted) {
            checkLoggedIn(err);
            notifyError("データの取得に失敗しました。");
            console.error(err);
          }
          changeIsLoading();
        }
      };
      if (getChild) {
        fetchData();
      }
      return () => {
        isMounted = false;
      }
    } else {
      setChildForSelect([]);
      setChildrenList([]);
      setStaffForSelect([]);
    }
  }, [authState, changeIsLoading, checkLoggedIn, facilityId, getChild]);

  useEffect(() => {
    let newValidation: ValidationError<TrainingH002Schema> = initialValidationError();
    if (isValidated) {
      const validatedData = validationTrainingH002Model(data);
      if (!validatedData.ok) {
        setIsValidated(true);
        newValidation = validatedData.validationError;
      }
    }
    if (data.training_date && data.child) {
      const disability_certificate_list = data.child.disability_certificates.filter(x => x.date_from <= data.training_date && data.training_date <= x.date_end);
      if (disability_certificate_list.length == 0) {
        newValidation.messageOf.child_id = validationMessage.noDisabilityCertificate;
      } else if (disability_certificate_list[0].number == "") {
        newValidation.messageOf.child_id = validationMessage.emptyDisabilityCertificate;
      }
    }
    setValidationError(newValidation);
  }, [data, isValidated]);

  const setFcValue = (val: number) => {
    let dataPrev = { ...data };
    dataPrev.facility_id = val;
    setData(dataPrev);
    setFacilityId(val);
    setGetChild(true);
  };

  const setStepValue = (val: number, index: number) => {
    let newSelectedList = [...selectedList];
    newSelectedList[index].step = val;
    setSelectedList(newSelectedList);
    clearSelectProgram(index);
  };

  const setReflectValue = (val: number, index: number) => {
    let newSelectedList = [...selectedList];
    newSelectedList[index].reflection = val;
    setSelectedList(newSelectedList);
    clearSelectProgram(index);
  };

  const clearSelectProgram = (index: number) => {
    let dataPrev = { ...data };
    dataPrev.program_selects[index].program_item.id = 0;
    dataPrev.program_selects[index].program_item.video_url = undefined;
    dataPrev.program_selects[index].program_item.video_preparing = false;
    dataPrev.program_selects[index].program_item.training_tools = [];
    setData(dataPrev);
  };

  const setProgramValue = (val: number, index: number) => {
    let dataPrev = { ...data };
    dataPrev.program_selects[index].program_item.id = val;
    const program = programList.filter(x => x.id == val)[0];
    dataPrev.program_selects[index].program_item.video_url = program.video_url;
    dataPrev.program_selects[index].program_item.video_preparing = program.video_preparing;
    dataPrev.program_selects[index].program_item.training_tools = program.training_tools;
    dataPrev.program_selects[index].program_item.reflections = program.reflections;
    setData(dataPrev);
  };

  const getSelectValue = (val: number | undefined, list: GeneralForListSchema[]) => {
    if (val == 0 || val == undefined) return null;
    if (list.filter(x => x?.value ?? 0 == val).length > 0) {
      return list.filter(x => x.value == val)[0];
    }
  };

  const clickAddRow = () => {
    let dataPrev = { ...data };
    dataPrev.program_selects.push(initialProgramSelectModel());
    setData(dataPrev);
    setSelectedList([...selectedList, initialSelected()]);
  };

  const deleteItem = (index: number) => {
    let newSelectedList = [...selectedList];
    newSelectedList.splice(index, 1);
    setSelectedList(newSelectedList);
    let newData = { ...data };
    newData.program_selects.splice(index, 1);
    setData(newData);
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;
    const newData = { ...data };
    const [movedItem] = newData.program_selects.splice(result.source.index, 1);
    newData.program_selects.splice(result.destination.index, 0, movedItem);
    setData(newData);
    const newSelectedList = [...selectedList];
    const [movedSelected] = newSelectedList.splice(result.source.index, 1);
    newSelectedList.splice(result.destination.index, 0, movedSelected);
    setSelectedList(newSelectedList);
  };

  const clickRegister = () => {
    changeIsLoading();
    const validatedData = validationTrainingH002Model(data);
    if (validatedData.ok) {
      putH002(authState, data).then((res) => {
        if (typeof res.data === "number") {
          notify("トレーニング計画を登録しました。");
          // 馬場さん希望で、登録後の画面遷移無し
          setTrainingId(res.data);
          setReload(true);
        } else {
          notifyError(res.data.msg || "トレーニング計画の登録に失敗しました。");
        }
        setShowRegister(false);
        changeIsLoading();
      }).catch((err) => {
        checkLoggedIn(err);
        setShowRegister(false);
        notifyError("トレーニング計画の登録に失敗しました。実施日時の重複がないことを確認し、再度実行願います。");
        console.error(err);
        changeIsLoading();
      });
    } else {
      setShowRegister(false);
      notifyError("入力不正があります。");
      setIsValidated(true);
      setValidationError(validatedData.validationError);
      changeIsLoading();
    }
  };

  const clickDelete = () => {
    changeIsLoading();
    deleteTraining(authState, trainingId).then((_) => {
      setShowDelete(false);
      notify("トレーニング計画を削除しました。");
      changeIsLoading();
      history.goBack();
    }).catch((err) => {
      checkLoggedIn(err);
      notifyError("削除に失敗しました。");
      console.error(err);
      changeIsLoading();
    });
  };

  const getSelectedReflectionName = (item: number) => {
    let name = "";
    if (reflectionList.length !== 0) {
      name = reflectionList.filter((x) => x.value == item)[0].label;
    }
    return name;
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Title title="トレーニング計画" />
      <div className="plate">
        {trainingId ? (
          <>
            {userInfo?.user_type == HEADQUARTER_USER &&
              <CustomRow title="施設" content={
                <Label value={getSelectValue(data.facility_id, fcForSelect)?.label} />
              } />
            }
            <CustomRow title="お子様" content={
              <>
                <User
                  size={SIZES.S}
                  className="ms-5"
                  url={data.child?.profile_pic_url}
                  func={() => history.push(getForHistoryPush(D002Url, [data.child_id]))}
                />
                <Label value={`${data.child?.family_name ?? ""} ${data.child?.given_name ?? ""}`} />
              </>
            } />
          </>
        ) : (
          <>
            {userInfo?.user_type == HEADQUARTER_USER &&
              <CustomRow title="施設" required={true} error_msg={validationError.messageOf.facility_id} content={
                <Select
                  value={getSelectValue(data.facility_id, fcForSelect)}
                  options={fcForSelect}
                  className="H002_select mx-2"
                  onChange={(e: any) => setFcValue(e.value)}
                  placeholder="施設"
                />
              } />
            }
            <CustomRow title="お子様" required={true} error_msg={validationError.messageOf.child_id} content={
              <>
                <User
                  size={SIZES.S}
                  className="ms-5"
                  url={data.child?.profile_pic_url}
                  func={() => history.push(getForHistoryPush(D002Url, [data.child_id]))}
                />
                <Select
                  value={getSelectValue(data.child_id, childForSelect)}
                  options={childForSelect}
                  className="H002_select mx-2"
                  onChange={(e: any) => {
                    const newData = { ...data };
                    newData.child_id = e.value;
                    const child = childrenList.filter(x => x.id == e.value);
                    newData.child = child.length > 0 ? child[0] : undefined;
                    setData(newData);
                  }}
                  placeholder="お子様"
                />
              </>
            } />
          </>
        )}
        <CustomRow title="出欠タイプ" required={true} error_msg={validationError.messageOf.service_provision_status} content={
          <Select
            value={getSelectValue(data.service_provision_status || 0, serviceProvisionStatusForSelect)}
            options={serviceProvisionStatusForSelect}
            className="H002_select mx-2"
            onChange={(e: any) => {
              let newData = { ...data };
              newData.service_provision_status = e.value == 0 ? undefined : e.value;
              // 「家族支援加算のみ」以外の場合は、家族支援加算の値をクリア
              if (e.value != SERVICE_PROVISION_STATUS.INTERNAL_COUNSELING_SUPPORT_ALLOWANCE_ONLY) {
                newData.consultation_support_addition = undefined;
              }
              // 項目非表示の場合は、非表示項目の情報をクリア
              if (noInputList.indexOf(e.value) > -1) {
                newData.staff_id = undefined;
                newData.program_selects = [initialProgramSelectModel()];
                newData.remarks = undefined;
                setSelectedList([initialSelected()]);
              }
              setData(newData);
            }}
            placeholder="選択"
          />
        } />
        {trainingId ? (
          <CustomRow title="実施日時" content={
            noInput ? (
              <Label value={getFormatDate(data.training_date)} />
            ) : (
              <Label value={`${getFormatDate(data.training_date)} ${data.start_time}-${data.end_time}`} />
            )
          } />
        ) : (
          <CustomRow title="実施日時" required={true} error_msg={validationError.messageOf.training_date} content={
            <>
              <CustomDate className="mx-2" val={data.training_date} setter={(val: any) => setData({ ...data, training_date: val })} width={150} />
              {!noInput &&
                <>
                  <CustomTime val={data.start_time} setter={(v: string) => setData({...data, start_time: v, end_time: addMinutes(v, DEFAULT_TRAINING_MINUTE)})} />
                  <div className="wave_str">～</div>
                  <CustomTime val={data.end_time} setter={(v: string) => setData({ ...data, end_time: v })} />
                </>
              }
            </>
          } />
        )}
        {!noInput &&
          <>
            <CustomRow title="担当者" error_msg={validationError.messageOf.staff_id} content={
              <Select
                value={getSelectValue(data.staff_id || 0, staffForSelect)}
                options={staffForSelect}
                className="H002_select mx-2"
                onChange={(e: any) => setData({ ...data, staff_id: e.value })}
                placeholder="スタッフ"
              />
            } />
            <div className="mx-2 mt-2 on_same_row" style={{ flexWrap: "wrap" }}>
              {reflectionList.length > 0 && data.child?.reflections.map((x, i) => (
                <div className="mb-2" key={i}>
                  <CustomButton
                    label={getSelectedReflectionName(x.item)}
                    class={[CB_CLASS.BLUE]}
                    size={CB_SIZE.S}
                    disabled={true}
                  />
                </div>
              ))}
            </div>
            <DraggableList onDragEnd={handleDragEnd} items={
              data.program_selects.map((x: ProgramSelectSchema, i: number) => (
                {
                  id: i, content: (
                    <div className="item on_same_row align-items-center">
                      <Select
                        value={getSelectValue(selectedList[i].step, stepForSelect)}
                        options={stepForSelect}
                        className="H002_select"
                        onChange={(e: any) => setStepValue(e.value, i)}
                        placeholder="Step"
                      />
                      {selectList[i]?.reflection.length > 0 &&
                        <Select
                          value={getSelectValue(selectedList[i].reflection, selectList[i].reflection)}
                          options={selectList[i].reflection}
                          className="H002_select mx-2"
                          onChange={(e: any) => setReflectValue(e.value, i)}
                          placeholder="反射"
                        />
                      }
                      {selectList[i]?.program.length > 0 &&
                        <Select
                          value={getSelectValue(x.program_item.id, selectList[i].program)}
                          options={selectList[i].program}
                          className="H002_program_select"
                          onChange={(e: any) => setProgramValue(e.value, i)}
                          placeholder="プログラム"
                        />
                      }
                      {(x.program_item?.id ?? 0) > 0 &&
                        <FormLabel className="mx-2">
                          {x.program_item.reflections.length > 0 &&
                            `${x.program_item.reflections.map((y: ReflectionSchema) =>
                              getSelectValue(y.reflection, reflectionList)?.label[0]
                            ).join("、")}`}
                        </FormLabel>
                      }
                      <Play video_url={x.program_item.video_url} video_preparing={x.program_item.video_preparing} />
                      {i > 0 &&
                        <Delete func={() => { deleteItem(i) }} />
                      }
                    </div>
                  )
                }
              ))
            } />
            <CustomButton label="＋行を追加" class={[CB_CLASS.RED]} align={CB_ALIGN.LEFT} onClick={clickAddRow} />
          </>
        }
        {data.service_provision_status == SERVICE_PROVISION_STATUS.INTERNAL_COUNSELING_SUPPORT_ALLOWANCE_ONLY &&
          <CustomRow title="家族支援加算" error_msg={validationError.messageOf.consultation_support_addition} content={
            <Select
              value={getSelectValue(data.consultation_support_addition, consultationSupportAdditionForSelect)}
              options={consultationSupportAdditionForSelect}
              className="H003_select mx-2"
              onChange={(e: any) => setData({ ...data, consultation_support_addition: e.value })}
              placeholder="選択"
            />
          } />
        }
        <div className="item">
          <CustomButton label="登録" class={[CB_CLASS.RED]} onClick={() => setShowRegister(true)} />
          <ConfirmModal target="登録" show={showRegister} setShow={setShowRegister} func={clickRegister}
            confirmText={"登録してよろしいでしょうか。"}
          />
          {trainingId &&
            <>
              <CustomButton label="削除" class={[CB_CLASS.RED]} onClick={() => setShowDelete(true)} />
              <ConfirmModal target="削除" show={showDelete} setShow={setShowDelete} func={clickDelete}
                confirmText={"削除してよろしいでしょうか。※予約枠に空きが出来ます。"}
              />
            </>
          }
          {data.status != TRAINING_STATUS_NOT_PLANNED && (
            noInput ?
              <CustomButton label="署名依頼へ" class={[CB_CLASS.BLUE]} onClick={() => history.push(getForHistoryPush(H003Url, [trainingId, "H002"]))} />
              :
              <CustomButton label="結果入力" class={[CB_CLASS.BLUE]} onClick={() => history.push(getForHistoryPush(H003Url, [trainingId, "H002"]))} />
          )}
        </div>
      </div>
    </>
  );
};

export default H002;

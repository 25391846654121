import "./Icon.scss";
import { AiFillPlusCircle } from "react-icons/ai";
import { COLOR_RED, COLOR_BLUE } from "../../constants";

function Plus(props: any) {
  return (
    <AiFillPlusCircle
      className={"icon " + (props.color === "red" ? "hover_red " : "hover_blue ") + props.class}
      color={props.color === "red" ? COLOR_RED : COLOR_BLUE }
      size={35}
      onClick={(event) => {
        event.stopPropagation();
        props.func();
      }}
      style={{
        marginTop: "4px",
      }}
    />
  );
};

export default Plus;

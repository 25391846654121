import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import {
  ChildD008Schema,
  GeneralForListSchema,
  Result,
} from "../../generated";
import { authSelector } from "../../redux/selectors/auth.selector";
import {
  deleteDisabilityCertificate,
  fetchD008,
  fetchGeneralsForList,
  putD008,
} from "../../services/api.service";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import CustomButton, { CB_CLASS } from "../../components/Button/CustomButton"
import Title from "../../components/Title/Title"
import { LIST_DISABILITY_CERTIFICATE_SUPPORT_TYPE } from "../../constants";
import ChildInfoTop from "../../components/ChildInfoTop/ChildInfoTop";
import CustomRow from "../../components/CustomRow/CustomRow";
import TextBox from "../../components/TextBox/TextBox";
import { ValidationError, initialValidationError } from "../../models/validate.model";
import Select from "../../components/Select/CustomSelect";
import NumBox, { NUM_BOX_TYPE } from "../../components/TextBox/NumBox";
import CustomDate from "../../components/Calendar/CustomDate";
import ConfirmModal from "../../components/ConfirmModal";
import { notify, notifyError } from "../../helper/settings.helper";
import { initialChildD008Schema, validationChildD008Model } from "../../models/child.model";
import { useAuth } from "../../helper/auth.helper";
import { getYyyyMmDd } from "../../helper/date.helper";
import { raiseAction } from "../../helper/api.helper";

interface iLocation {
  child_id: number,
  disability_certificate_id: number,
}

const D008 = () => {
  const { checkLoggedIn } = useAuth();
  const [data, setData] = useState<ChildD008Schema>(initialChildD008Schema())
  const location = useLocation<iLocation>();
  const child_id = location.state.child_id;
  const disability_certificate_id = location.state.disability_certificate_id;
  const authState = useSelector(authSelector);
  const history = useHistory();
  const [disabilityCertificateForSelect, setDisabilityCertificateForSelect] = useState<GeneralForListSchema[]>([]);
  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const [validationError, setValidationError] = useState<ValidationError<ChildD008Schema>>(initialValidationError());
  const [isValidated, setIsValidated] = useState<boolean | undefined>(undefined);
  const [showRegister, setShowRegister] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [reload, setReload] = useState(true);

  useEffect(() => {
    if (reload) {
      let isMounted = true;
      const fetchData = async () => {
        changeIsLoading();
        fetchD008(authState, child_id, disability_certificate_id).then((res) => {
          if (isMounted) {
            setData(res.data);
            setReload(false);
          }
          changeIsLoading();
        }).catch((err) => {
          if (isMounted) {
            checkLoggedIn(err);
            notifyError("データの取得に失敗しました。");
            console.error(err);
          }
          changeIsLoading();
        });
      };
      fetchData();
      return () => {
        isMounted = false;
      }
    }
  }, [authState, changeIsLoading, checkLoggedIn, child_id, disability_certificate_id, reload]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchGeneralsForList(authState, LIST_DISABILITY_CERTIFICATE_SUPPORT_TYPE).then((res) => {
        if (isMounted) {
          setDisabilityCertificateForSelect(res.data);
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (disabilityCertificateForSelect.length == 0) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, disabilityCertificateForSelect.length]);

  useEffect(() => {
    if (isValidated) {
      const validatedData = validationChildD008Model(data);
      if (validatedData.ok) {
        setValidationError(initialValidationError());
      } else {
        setIsValidated(true);
        setValidationError(validatedData.validationError);
      }
    }
  }, [data, isValidated]);

  const getSelectValue = (val: number, selectList: GeneralForListSchema[]) => {
    if (val == 0) return null;
    return selectList.filter(x => x.value == val)[0];
  };

  const clickRegister = () => {
    const validatedData = validationChildD008Model(data);
    if (validatedData.ok) {
      changeIsLoading();
      putD008(authState, data).then((_) => {
        setShowRegister(false);
        notify("受給者証情報を登録しました。");
        changeIsLoading();
        history.goBack();
      }).catch((err) => {
        checkLoggedIn(err);
        notifyError("登録に失敗しました。");
        console.error(err);
        changeIsLoading();
      });
    } else {
      setShowRegister(false);
      notifyError("入力不正があります。");
      setIsValidated(true);
      setValidationError(validatedData.validationError);
    }
  };

  const clickDelete = () => {
    changeIsLoading();
    deleteDisabilityCertificate(authState, disability_certificate_id).then((res) => {
      raiseAction(
        res.data,
        () => {
          setShowDelete(false);
          notify("受給者証情報を削除しました。");
          changeIsLoading();
          history.goBack();
        },
        (v: Result) => {
          setShowDelete(false);
          notifyError(String(v.msg));
          changeIsLoading();
        });
    }).catch((err) => {
      checkLoggedIn(err);
      notifyError("削除に失敗しました。");
      console.error(err);
      changeIsLoading();
    });
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Title title="受給者証詳細" />
      <div className="plate">
        <ChildInfoTop
          profile_pic_url={data.child_profile_pic_url}
          id={data.child_id}
          name={data.child_name}
          name_kana={data.child_name_kana}
          facility_name={data.facility_name}
          user_type={authState.userInfo?.user_type}
        />
        <CustomRow title="受給者証番号" error_msg={validationError.messageOf.number} content={
          <TextBox
            type="text"
            placeholder="受給者証番号"
            value={data.number}
            onChange={(v: string) => setData({ ...data, number: v || "" })}
          />
        } />
        <CustomRow title="支援の種類" required={true} error_msg={validationError.messageOf.support_type} content={
          <Select
            value={getSelectValue(data.support_type, disabilityCertificateForSelect)}
            options={disabilityCertificateForSelect}
            className="w-100 mx-2"
            onChange={(e: any) => setData({ ...data, support_type: e.value })}
            placeholder="支援の種類"
          />
        } />
        <CustomRow title="契約日数" required={true} error_msg={validationError.messageOf.date_count} content={
          <NumBox value={data.date_count} type={NUM_BOX_TYPE.DISABILITY_CERTIFICATE} onChange={(value: any) => setData({ ...data, date_count: value })} />
        } />
        <CustomRow title="給付期間" required={true} error_msg={validationError.messageOf.date_from} content={
          <>
            <CustomDate className="mx-2" val={data.date_from} setter={(val: any) => {
              const newData = { ...data };
              newData.date_from = val;
              if (val) {
                let date_end = new Date(val);
                date_end.setFullYear(date_end.getFullYear() + 1, date_end.getMonth(), date_end.getDate() - 1);
                newData.date_end = getYyyyMmDd(date_end)
              }
              setData(newData);
            }} />
            <div className="wave_str">～</div>
            <CustomDate className="mx-2" val={data.date_end} setter={(val: any) => setData({ ...data, date_end: val })} />
          </>
        } />
      </div>
      <div className="item">
        <CustomButton label="登録" class={[CB_CLASS.RED]} onClick={() => setShowRegister(true)} />
        <ConfirmModal target="登録" show={showRegister} setShow={setShowRegister} func={clickRegister}
          confirmText={"登録してよろしいでしょうか。"}
        />
        {disability_certificate_id &&
          <>
            <CustomButton label="削除" class={[CB_CLASS.RED]} onClick={() => setShowDelete(true)} />
            <ConfirmModal target="削除" show={showDelete} setShow={setShowDelete} func={clickDelete}
              confirmText={"削除してよろしいでしょうか。"}
            />
          </>
        }
      </div>
    </>
  );
};

export default D008;

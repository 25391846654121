import internal from "stream";
import { validationMessage } from "../config/validationMessage";
import { initialValidationError, ValidatedData } from "./validate.model";

export interface SubscriptionContentModel {
  title: string;
  description: string;
}

export interface SubscriptionContentUpdateModel {
  id: number;
  title: string;
  description: string;
  status: number;
}

export const initialSubscriptionContentModel =
  (): SubscriptionContentModel => ({
    title: "",
    description: "",
  });

export const initialSubscriptionContentUpdateModel =
  (): SubscriptionContentUpdateModel => ({
    id: 0,
    title: "",
    description: "",
    status: 0,
  });

export const validatonSubscriptionContentModel = (
  contentData: SubscriptionContentModel
): ValidatedData<SubscriptionContentModel> => {
  const error = initialValidationError<SubscriptionContentModel>();
  let ok = true;

  if (contentData.title === "") {
    error.messageOf.title = validationMessage.emptyTitle;
    ok = false;
  }

  if (contentData.title.length > 40) {
    error.messageOf.title = validationMessage.limitTitleLength;
    ok = false;
  }

  if (contentData.description === "") {
    error.messageOf.description = validationMessage.emptyText;
    ok = false;
  }

  if (contentData.description.length > 400) {
    error.messageOf.description = validationMessage.limitTextLength;
    ok = false;
  }

  if (ok) {
    return {
      ok: true,
      validatedData: contentData,
    };
  }

  return {
    ok: false,
    validationError: error,
  };
};

export const validatonSubscriptionContentUpdateodel = (
  contentData: SubscriptionContentUpdateModel
): ValidatedData<SubscriptionContentUpdateModel> => {
  const error = initialValidationError<SubscriptionContentUpdateModel>();
  let ok = true;

  if (contentData.title === "") {
    error.messageOf.title = validationMessage.emptyTitle;
    ok = false;
  }

  if (contentData.title.length > 40) {
    error.messageOf.title = validationMessage.limitTitleLength;
    ok = false;
  }

  if (contentData.description === "") {
    error.messageOf.description = validationMessage.emptyText;
    ok = false;
  }

  if (contentData.description.length > 400) {
    error.messageOf.description = validationMessage.limitTextLength;
    ok = false;
  }

  if (contentData.status === undefined) {
    error.messageOf.status = validationMessage.publicationStatus;
    ok = false;
  }

  if (ok) {
    return {
      ok: true,
      validatedData: contentData,
    };
  }

  return {
    ok: false,
    validationError: error,
  };
};

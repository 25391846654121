import Select from "react-select";

function CustomSelect(props: any) {
  return (
    <Select
      value={props.value}
      options={props.options}
      className={props.className}
      onChange={(e: any) => props.onChange(e)}
      placeholder={props.placeholder}
      isSearchable={props.options.length > 9}
    />
  );
};

export default CustomSelect;

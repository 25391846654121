import React from "react";
import { Route, Redirect } from "react-router-dom";

interface INotAuthenticatedRoute {
  isLoggedIn: boolean;
  path: string;
  exact?: boolean;
  component: React.ComponentClass | React.FunctionComponent;
}

const NotAuthenticatedRoute: React.FC<INotAuthenticatedRoute> = ({
  isLoggedIn,
  path,
  exact,
  component,
}) => {
  if (isLoggedIn) {
    return <Redirect to="/" />;
  } else {
    return <Route path={path} exact={exact} component={component} />;
  }
};

export default NotAuthenticatedRoute;

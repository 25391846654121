import { GeneralForListSchema, ProgramItemForSelectSchema } from "../generated";

export const initialListModel = (): ListModel => ({
  select_list: [],
});

export interface ListModel {
  select_list: GeneralForListSchema[];
};

export const makeListModel: (list: GeneralForListSchema[]) => ListModel = (
  list
) => ({
  select_list: list,
});

export const initialPgListModel = (): PgListModel => ({
  select_list: [],
});

export interface PgListModel {
  select_list: ProgramItemForSelectSchema[];
};

export const makePgListModel: (list: ProgramItemForSelectSchema[]) => PgListModel = (
  list
) => ({
  select_list: list,
});

import { FormControl } from "react-bootstrap";
import "./CustomDate.scss";

function CustomDateForRow(props: any) {
  return (
    <FormControl
      type="date"
      className="custom_date"
      placeholder="年/月/日"
      value={props.val}
      onChange={(e) => props.setter(e, props.index)}
      style={{ width: props.width }}
    />
  );
};

export default CustomDateForRow;

export const CHANGE_NAME = 1;
export const CHANGE_EMAIL = 2;
export const CHANGE_PASSWORD = 3;
export const CHANGE_IMAGE = 4;

import { initialValidationError, ValidatedData } from "./validate.model";
import { validationMessage } from "../config/validationMessage";
import {
  checkFlgObj,
  checkValue,
  checkEmail,
  checkValueKana,
  checkNowPasswordRequire,
  checkNewPasswordRequire,
} from "../helper/validation.helper";

export interface ChangeNameSchema {
  family_name: string;
  given_name: string;
  family_name_kana: string;
  given_name_kana: string;
}

export interface ChangeEmailSchema {
  email: string;
}

export interface ChangePasswordSchema {
  password: string;
  new_password: string;
  new_password_check: string;
}

export const initialSettingModel = {
  family_name: "",
  given_name: "",
  family_name_kana: "",
  given_name_kana: "",
  email: "",
  password: "",
  new_password: "",
  new_password_check: "",
};

export const validationChangeNameModel = (change: ChangeNameSchema): ValidatedData<ChangeNameSchema> => {
  const error = initialValidationError<ChangeNameSchema>();
  const checkFlg: checkFlgObj = {
    isOk: true,
  };

  error.messageOf.family_name =  [
    checkValue(checkFlg, "姓", change.family_name, 30),
    checkValue(checkFlg, "名", change.given_name, 30)
  ].filter(x => x != undefined).join(" ");

  error.messageOf.family_name_kana = [
    checkValueKana(checkFlg, "姓（フリガナ）", change.family_name_kana, 30),
    checkValueKana(checkFlg, "名（フリガナ）", change.given_name_kana, 30),
  ].filter(x => x != undefined).join(" ");

  if (checkFlg.isOk) {
    return {
      ok: true,
      validatedData: change,
    };
  }

  return {
    ok: false,
    validationError: error,
  };
};

export const validationChangeEmailModel = (change: ChangeEmailSchema): ValidatedData<ChangeEmailSchema> => {
  const error = initialValidationError<ChangeEmailSchema>();
  const checkFlg: checkFlgObj = {
    isOk: true,
  };

  error.messageOf.email = checkEmail(checkFlg, change.email);

  if (checkFlg.isOk) {
    return {
      ok: true,
      validatedData: change,
    };
  }

  return {
    ok: false,
    validationError: error,
  };
};

export const validationChangePasswordModel = (change: ChangePasswordSchema): ValidatedData<ChangePasswordSchema> => {
  const error = initialValidationError<ChangePasswordSchema>();
  const checkFlg: checkFlgObj = {
    isOk: true,
  };

  let errorMsg: string | undefined;

  error.messageOf.password = checkNowPasswordRequire(
    checkFlg,
    "現在のパスワード",
    change.password
  );

  errorMsg = checkNewPasswordRequire(
    checkFlg,
    "新しいパスワード",
    change.new_password,
    change.new_password_check,
    8
  );
  validationMessage.emptyRePassword === errorMsg ||
  validationMessage.inconsistentPassword === errorMsg
    ? (error.messageOf.new_password_check = errorMsg)
    : (error.messageOf.new_password = errorMsg);

  errorMsg = undefined;

  if (checkFlg.isOk) {
    return {
      ok: true,
      validatedData: change,
    };
  }

  return {
    ok: false,
    validationError: error,
  };
};

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { authSelector } from "../../redux/selectors/auth.selector";
import { D003HealthRecordSchema, D003Schema } from "../../generated";
import { initialD003Model } from "../../models/child.model";
import { fetchD003, fetchD003List, fetchD003ListCount } from "../../services/api.service";
import { D004Url, getForHistoryPush } from "../../helper/url.helper";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import ChildInfoTop from "../../components/ChildInfoTop/ChildInfoTop";
import CustomButton, { CB_CLASS } from "../../components/Button/CustomButton";
import Title from "../../components/Title/Title";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import moment from "moment";
import { useAuth } from "../../helper/auth.helper";
import { notifyError } from "../../helper/settings.helper";
import PaginationTableLite from "../../components/PaginationTableLite/PaginationTableLite";

interface iD003 {
  child_id: number;
}

const D003 = () => {
  const { checkLoggedIn } = useAuth();
  const maxWidth = 900;
  const authState = useSelector(authSelector);
  const history = useHistory();
  const location = useLocation<iD003>();
  const childId = location.state.child_id;
  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const [childData, setChildData] = useState<D003Schema>(initialD003Model);
  const [data, setData] = useState<D003HealthRecordSchema[]>([]);
  const [getData, setGetData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordCount, setRecordCount] = useState(0);
  const limitCount = 9;

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      try {
        const [resCount, resData] = await Promise.all([
          fetchD003ListCount(childId, authState),
          fetchD003List(childId, authState, (currentPage - 1) * limitCount, limitCount)
        ]);

        if (isMounted) {
          setRecordCount(resCount.data);
          setData(resData.data);
          setGetData(false);
        }
        changeIsLoading();
      } catch (err) {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      }
    };
    if (getData) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, childId, currentPage, getData]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchD003(childId, authState).then(res => {
        if (isMounted) {
          setChildData(res.data);
        }
        changeIsLoading();
        setGetData(false);
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    fetchData();
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, childId]);

  const changeCurrentPage = (v: number) => {
    setGetData(true);
    setCurrentPage(v);
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Title title="身長・体重一覧" />
      <div className="plate">
        <div className="display_flex justify" style={{ maxWidth: maxWidth }}>
          <ChildInfoTop
            user_type={authState.userInfo?.user_type}
            profile_pic_url={childData.child_profile_pic_url}
            id={childId}
            name={childData.child_name}
            name_kana={childData.child_name_kana}
            facility_name={childData.facility_name}
            no_underbar={true}
          />
          <CustomButton
            label="＋身長・体重データを追加"
            class={[CB_CLASS.RED]}
            onClick={() => history.push(getForHistoryPush(D004Url, [0, childId]))}
          />
        </div>
      </div>
      <div>
        <PaginationTableLite
          currentPage={currentPage}
          setCurrentPage={changeCurrentPage}
          recordCount={recordCount}
          limit={limitCount}
          headers={["日付", "身長", "体重"]}
          max_width={maxWidth}
          list={data.map((x, i) => (
            <tr key={i} onClick={() => history.push(getForHistoryPush(D004Url, [x.id, childId]))}>
              <td className="item">{moment(x.measurement_date).format("YYYY/MM/DD")}</td>
              <td className="item">{x.height}</td>
              <td className="item">{x.weight}</td>
            </tr>
          ))}
        />
      </div>
    </>
  );
};

export default D003;

import { reducerWithInitialState } from "typescript-fsa-reducers";
import { wsActions } from "../actions/ws.actions";
import { Socket } from "socket.io-client";
export interface WsState {
  connecting: boolean;
  connectError: any;
  connected: boolean;
  sid: string | null;
  socket: Socket | null;
}

const initialState: WsState = {
  connecting: false,
  connectError: null,
  connected: false,
  sid: null,
  socket: null,
};

export const wsReducer = reducerWithInitialState(initialState)
  .case(wsActions.connectStarted, (state) => {
    return {
      ...state,
      connecting: true,
    };
  })
  .case(wsActions.connectDone, (state, data) => {
    return {
      ...state,
      connecting: false,
      connectError: null,
      connected: true,
      sid: data.result.sid,
      socket: data.result.socket,
    };
  })
  .case(wsActions.connectFailed, (state, data) => {
    return {
      ...initialState,
      connectError: data.error,
    };
  })
  .case(wsActions.disconnect, () => {
    return { ...initialState };
  });

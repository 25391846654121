import { Form } from "react-bootstrap";
import "./Select.scss";

function SelectSimple(props: any) {
  return (
    <Form>
      <Form.Group controlId="selectControl">
        <Form.Label hidden />
        <Form.Control
          as="select"
          className={"custom_select " + props.className || ""}
          value={props.list.indexOf(props.val)}
          onChange={(e) => { props.onChange(Number(e.target.value)) }}
          style={{ width: props.width }}
        >
          {props.list.map((x: string, i: number) => (
            <option key={i} value={i}>{x}</option>
          ))}
        </Form.Control>
      </Form.Group>
    </Form>
  );
};

export default SelectSimple;

import { reducerWithInitialState } from "typescript-fsa-reducers";
import { authActions, errorStateAction } from "../actions/auth.actions";
import { TokenSchema, HeadquarterUserInfoSchema, FCStaffInfoSchema } from "../../generated";
import { removeToken } from "../../helper/token.helper";

export interface AuthState {
  loggingIn: boolean;
  loggedIn: boolean;
  fetchingUserInfo: boolean;
  fetchedUserInfo: boolean;
  fetchUserInfoError: Error | null;
  token?: TokenSchema;
  userInfo?: HeadquarterUserInfoSchema | FCStaffInfoSchema;
}

export const initialAuthState = (): AuthState => ({
  loggingIn: false,
  loggedIn: false,
  fetchingUserInfo: false,
  fetchedUserInfo: false,
  fetchUserInfoError: null,
  token: undefined,
  userInfo: undefined,
});

export interface loggedInErrorState {
  alreadyError: boolean;
}

export const initialLoggedInErrorState = (): loggedInErrorState => ({
  alreadyError: false,
});

export const authReducer = reducerWithInitialState(initialAuthState())
  .case(authActions.fetchUserInfoStarted, (state, payload) => {
    return {
      ...state,
      loggingIn: payload.loggingIn,
      loggedIn: payload.loggedIn,
    };
  })
  .case(authActions.fetchUserInfoDone, (state, payload) => {
    return {
      ...state,
      loggingIn: payload.params.loggingIn,
      loggedIn: payload.params.loggedIn,
      token: payload.result.token,
      userInfo: payload.result.userInfo,
    };
  })
  .case(authActions.fetchUserInfoFailed, (state, payload) => {
    return {
      ...state,
      loggingIn: payload.params.loggingIn,
      loggedIn: payload.params.loggedIn,
      fetchUserInfoError: payload.error,
    };
  })
  .case(authActions.logout, () => {
    removeToken();
    return initialAuthState();
  });

export const errorStateReducer = reducerWithInitialState(initialLoggedInErrorState())
  .case(errorStateAction.changeStatus, (state, payload) => {
    return {
      ...state,
      alreadyError: payload,
    };
  });

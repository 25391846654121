import { Form } from "react-bootstrap";
import "./TextBox.scss";

export const NUM_BOX_TYPE = {
  MINUTE: 1,
  TEMPERATURE: 2,
  THOUSAND: 3,
  DISABILITY_CERTIFICATE: 4,
  PRICE: 5,
  MEASUREMENT: 6,
  START_MINUTE: 7,
};

function NumBox(props: any) {
  const type = props.type || NUM_BOX_TYPE.MINUTE;
  let length = 0;
  let max = 0;
  switch (type) {
    case NUM_BOX_TYPE.MINUTE:
      max = 60;
      length = 2;
      break;
    case NUM_BOX_TYPE.TEMPERATURE:
      max = 42;
      length = 4;
      break;
    case NUM_BOX_TYPE.THOUSAND:
      max = 999;
      length = 3;
      break;
    case NUM_BOX_TYPE.DISABILITY_CERTIFICATE:
      max = 30;
      length = 2;
      break;
    case NUM_BOX_TYPE.PRICE:
      max = 9999999;
      length = 7;
      break;
    case NUM_BOX_TYPE.MEASUREMENT:
      max = 999;
      length = 5;
      break;
    case NUM_BOX_TYPE.START_MINUTE:
      max = 59;
      length = 5;
      break;
  }
  const min = 0;
  return (
    <Form.Control
      type="number"
      inputMode={type == NUM_BOX_TYPE.MINUTE ? "numeric" : "decimal"}
      step={[NUM_BOX_TYPE.TEMPERATURE, NUM_BOX_TYPE.MEASUREMENT].indexOf(type) > -1 ? 0.1 : 1}
      className="mx-2"
      placeholder={props.placeholder}
      value={props.value ?? ""}
      onChange={(e) => {
        let inputValue = e.target.value;
        let num = Number(inputValue);
        if (inputValue.toString().length <= length && min <= num && num <= max) {
          if (type == NUM_BOX_TYPE.START_MINUTE) {
            inputValue = ( "00" + num ).slice( -2 );
          }
          props.onChange(inputValue);
        }
      }}
      style={{ width: props.width || 80 }}
      min={min}
      max={max}
    />
  );
}

export default NumBox;

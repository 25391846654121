import "./K002.scss";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authSelector, userInfoSelector } from "../../redux/selectors/auth.selector";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useLocation } from "react-router-dom";
import CustomButton, { CB_CLASS } from "../../components/Button/CustomButton"
import Title from "../../components/Title/Title"
import Select from "../../components/Select/CustomSelect";
import CustomDate from "../../components/Calendar/CustomDate"
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import {
  FCStaffInfoSchema,
  GeneralForListSchema,
  SRFSchema
} from "../../generated";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import { fetchK002, fetchGeneralsForList, fetchFcFacilities, fetchK002Count } from "../../services/api.service";
import { HEADQUARTER_USER, LIST_RESERVATION_STATUS, STAFF } from "../../constants";
import { getReservationNum, getApprovedNum } from "../../helper/schedule.helper"
import { initialGeneralForList } from "../../models/general.model";
import { K005Url, K006Url, getForHistoryPush } from "../../helper/url.helper"
import { getState } from "../../helper/page.helper";
import { useAuth } from "../../helper/auth.helper";
import moment from "moment";
import { notifyError } from "../../helper/settings.helper";
import PaginationTableLite from "../../components/PaginationTableLite/PaginationTableLite";
import { fcListSelector } from "../../redux/selectors/list.selector";
import { listActions } from "../../redux/actions/list.actions";
import { FC_LIST_TYPE, getFcList, getFcListForRedux, getFcListFromRedux } from "../../helper/list.helper";

interface K002Location {
  facility_id: number,
  begin_date: string,
  end_date: string,
  status: number,
}

const K002: React.VFC = () => {
  const maxWidth = 1300;
  const { checkLoggedIn } = useAuth();
  const authState = useSelector(authSelector);
  const history = useHistory();
  const location = useLocation<K002Location>();
  const state = getState(location);
  const userInfo = useSelector(userInfoSelector);
  const [fcId, setFcId] = useState(state.facility_id ? state.facility_id : (userInfo?.user_type == STAFF ? (userInfo as FCStaffInfoSchema)?.facilities[0].facility_id : 0));
  const [fcForSelect, setFcForSelect] = useState<GeneralForListSchema[]>([]);
  const [beginDate, setBeginDate] = useState(state.begin_date || moment().format("yyyy-MM-DD"));
  const [endDate, setEndDate] = useState(state.end_date);
  const [reservationStatusList, setReservationStatusList] = useState<GeneralForListSchema[]>([]);
  const [reservationStatus, setReservationStatus] = useState<number | undefined>(state.status);
  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const [data, setData] = useState<SRFSchema[]>([]);
  const [getData, setGetData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordCount, setRecordCount] = useState(0);
  const limitCount = 13;
  const fcListState = useSelector(fcListSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userInfo?.user_type == HEADQUARTER_USER) {
      let isMounted = true;
      const fetchData = async () => {
        changeIsLoading();
        fetchFcFacilities(authState, "").then((res) => {
          if (isMounted) {
            setFcForSelect(getFcList(FC_LIST_TYPE.DEFAULT, res.data));
            dispatch(listActions.makeFcList(getFcListForRedux(res.data)));
            if (fcId == 0) setFcId(res.data[0].id);
          }
          changeIsLoading();
        }).catch((err) => {
          if (isMounted) {
            checkLoggedIn(err);
            notifyError("データの取得に失敗しました。");
            console.error(err);
          }
          changeIsLoading();
        });
      };
      if (fcForSelect.length == 0) {
        if (fcListState.select_list.length == 0) {
          fetchData();
        } else {
          setFcForSelect(getFcListFromRedux(FC_LIST_TYPE.DEFAULT, fcListState.select_list));
          if (fcId == 0) setFcId(fcListState.select_list[0].value);
        }
      }
      return () => {
        isMounted = false;
      }
    }
  }, [authState, dispatch, changeIsLoading, checkLoggedIn, userInfo, fcId, fcForSelect.length, fcListState.select_list]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchGeneralsForList(authState, LIST_RESERVATION_STATUS).then((res) => {
        if (isMounted) {
          res.data.unshift(initialGeneralForList());
          setReservationStatusList(res.data);
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (reservationStatusList.length == 0) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, reservationStatusList.length]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      try {
        const [resCount, resData] = await Promise.all([
          fetchK002Count(authState, fcId, beginDate, endDate, reservationStatus),
          fetchK002(authState, fcId, beginDate, endDate, reservationStatus, (currentPage - 1) * limitCount, limitCount)
        ]);

        if (isMounted) {
          setRecordCount(resCount.data);
          setData(resData.data);
          setGetData(false);
        }
        changeIsLoading();
      } catch (err) {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      }
    };
    if (getData) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, fcId, beginDate, endDate, reservationStatus, getData, currentPage]);

  const setReservationStatusValue = (val: number) => {
    setReservationStatus(val == 0 ? undefined : val);
  };

  const getSelectValue = (val: number | undefined, list: GeneralForListSchema[]) => {
    if (val == undefined || val == 0) return null;
    if (list.filter(x => x.value == val).length == 0) return null;
    return list.filter(x => x.value == val)[0];
  };

  const changeCurrentPage = (v: number) => {
    setGetData(true);
    setCurrentPage(v);
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Title title="スケジュール一覧" />
      <div className="plate">
        <div className="on_same_row justify" style={{ maxWidth: maxWidth }}>
          <div className="on_same_row">
            {userInfo?.user_type == HEADQUARTER_USER &&
              <div className="item">
                <Select
                  value={fcForSelect.filter(x => x.value == fcId)[0]}
                  options={fcForSelect}
                  className="K002_select"
                  onChange={(e: any) => {
                    setFcId(e.value);
                    setGetData(true);
                  }}
                  placeholder="施設"
                />
              </div>
            }
            <div className="on_same_row item">
              <CustomDate
                val={beginDate}
                setter={(v: any) => {
                  setBeginDate(v);
                  setGetData(true);
                }}
                width="90px"
              />
              <div className="wave_str">～</div>
              <CustomDate
                val={endDate}
                setter={(v: any) => {
                  setEndDate(v);
                  setGetData(true);
                }}
                width="90px"
              />
            </div>
            <div className="item">
              <Select
                value={getSelectValue(reservationStatus, reservationStatusList)}
                options={reservationStatusList}
                className="K002_select"
                onChange={(e: any) => {
                  setReservationStatusValue(e.value);
                  setGetData(true);
                }}
                placeholder="予約状況"
              />
            </div>
          </div>
          <CustomButton label="＋予約枠を作成" class={[CB_CLASS.RED]} onClick={() => history.push(getForHistoryPush(K006Url, [fcId]))} />
        </div>
      </div>
      <PaginationTableLite
        currentPage={currentPage}
        setCurrentPage={changeCurrentPage}
        recordCount={recordCount}
        limit={limitCount}
        headers={["日程", "開始", "終了", "枠数", "予約数", "残数"]}
        list={data.map((x, i) => (
          <tr key={i} onClick={() => history.push(getForHistoryPush(K005Url, [fcId, x.id]))}>
            <td className="item">
              {x.date}
            </td>
            <td className="item" >
              {x.start_time}
            </td>
            <td className="item">
              {x.end_time}
            </td>
            <td className="item">
              {x.num}
            </td>
            <td className="item">
              {getReservationNum(x.schedule_details)}
            </td>
            <td className="item">
              {x.num - getApprovedNum(x.schedule_details)}
            </td>
          </tr>
        ))}
      />
    </>
  );
};

export default K002;

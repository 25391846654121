import { validationMessage } from "../config/validationMessage";

export interface checkFlgObj {
  isOk: boolean;
}

export function setFlgObj(checkFlg: checkFlgObj, judge: boolean): void {
  checkFlg.isOk = judge;
}

export const checkValue = (
  checkFlg: checkFlgObj,
  target: string,
  value?: any,
  maxLength?: number
) => {
  let Message: string | undefined;

  if (!value) {
    Message = "* " + target + validationMessage.empty;
    setFlgObj(checkFlg, false);
    return Message;
  }

  if (maxLength != undefined && value.length > maxLength) {
    Message = "* " + target + "は" + maxLength + validationMessage.limitLength;
    setFlgObj(checkFlg, false);
    return Message;
  }

  return Message;
};

export const checkValueKana = (
  checkFlg: checkFlgObj,
  target: string,
  value?: string,
  maxLength?: number
) => {
  let Message: string | undefined;

  if (!value) {
    Message = "* " + target + validationMessage.empty;
    setFlgObj(checkFlg, false);
    return Message;
  }

  if (!value.match(/^[ァ-ヶー 　]*$/)) {
    Message = "* " + target + "は" + validationMessage.wrongStringKana;
    setFlgObj(checkFlg, false);
    return Message;
  }

  if (maxLength != undefined && value.length > maxLength) {
    Message = "* " + target + "は" + maxLength + validationMessage.limitLength;
    setFlgObj(checkFlg, false);
    return Message;
  }

  return Message;
};

export const checkEmail = (checkFlg: checkFlgObj, value?: string) => {
  const maxLength = 256;
  let Message: string | undefined;

  if (!value) {
    Message = validationMessage.emptyEmail;
    setFlgObj(checkFlg, false);
    return Message;
  }

  if (value.length > maxLength) {
    Message = validationMessage.limitEmailLength;
    setFlgObj(checkFlg, false);
    return Message;
  }

  if (!value.match(/[\w\-._]+@[\w\-._]+\.[A-Za-z]+/)) {
    Message = validationMessage.wrongEmail;
    setFlgObj(checkFlg, false);
  }

  return Message;
};

export const checkNumber = (
  checkFlg: checkFlgObj,
  target: string,
  value?: string,
  maxLength?: number
) => {
  let Message: string | undefined;

  if (!value) {
    Message = "* " + target + validationMessage.empty;
    setFlgObj(checkFlg, false);
    return Message;
  }

  if (maxLength != undefined && value.length > maxLength) {
    Message = "* " + target + "は" + maxLength + validationMessage.limitLength;
    setFlgObj(checkFlg, false);
    return Message;
  }

  if (!value.match(/^[0-9]+$/)) {
    Message = "* " + target + "は" + validationMessage.wrongNumber;
    setFlgObj(checkFlg, false);
    return Message;
  }

  return Message;
};

export const checkSelectOrRadio = (
  checkFlg: checkFlgObj,
  target: string,
  value?: number,
) => {
  let Message: string | undefined;

  if (!value) {
    Message = "* " + target + validationMessage.select;
    setFlgObj(checkFlg, false);
    return Message;
  }

  return Message;
};

export const checkArray = (
  checkFlg: checkFlgObj,
  target: string,
  value?: any
) => {
  let Message: string | undefined;

  if (value == undefined || value.length === 0) {
    Message = "* " + target + validationMessage.select;
    setFlgObj(checkFlg, false);
    return Message;
  }

  return Message;
};

export const checkValueNotRequire = (
  checkFlg: checkFlgObj,
  target: string,
  value?: string,
  maxLength?: number
) => {
  let Message: string | undefined;

  if (!value) {
    return Message;
  }

  if (maxLength != undefined && value.length > maxLength) {
    Message = "* " + target + "は" + maxLength + validationMessage.limitLength;
    setFlgObj(checkFlg, false);
    return Message;
  }

  return Message;
};

export const checkValueKanaNotRequire = (
  checkFlg: checkFlgObj,
  target: string,
  value?: string,
  maxLength?: number
) => {
  let Message: string | undefined;

  if (!value) {
    return Message;
  }

  if (!value.match(/^[ァ-ヶー 　]*$/)) {
    Message = "* " + target + "は" + validationMessage.wrongStringKana;
    setFlgObj(checkFlg, false);
    return Message;
  }

  if (maxLength != undefined && value.length > maxLength) {
    Message = "* " + target + "は" + maxLength + validationMessage.limitLength;
    setFlgObj(checkFlg, false);
    return Message;
  }

  return Message;
};

export const checkNumberNotRequire = (
  checkFlg: checkFlgObj,
  target: string,
  value?: any,
  maxLength?: number
) => {
  let Message: string | undefined;

  if (!value) {
    return Message;
  }

  if (maxLength != undefined && value.length > maxLength) {
    Message = "* " + target + "は" + maxLength + validationMessage.limitLength;
    setFlgObj(checkFlg, false);
    return Message;
  }

  if (typeof value === "string") {
    if (!value.match(/^[0-9]+$/)) {
      Message = "* " + target + "は" + validationMessage.wrongNumber;
      setFlgObj(checkFlg, false);
      return Message;
    }
  }

  return Message;
};

export const checkPasswordNotRequire = (
  checkFlg: checkFlgObj,
  target: string,
  password?: string,
  confirmPassword?: string,
  minLength?: number
) => {
  let Message: string | undefined;

  if (password == undefined || password == "") {
    return Message;
  }

  if (!password.match(/^[a-zA-Z0-9]+$/)) {
    Message = "* " + target + "は" + validationMessage.wrongAlphanumeric;
    setFlgObj(checkFlg, false);
    return Message;
  }

  if (minLength != undefined && password.length < minLength) {
    Message = "* " + target + "は" + minLength + validationMessage.shortageLength;
    setFlgObj(checkFlg, false);
    return Message;
  }

  if (password && !confirmPassword) {
    Message = validationMessage.emptyRePassword;
    setFlgObj(checkFlg, false);
    return Message;
  }

  if (password !== confirmPassword) {
    Message = validationMessage.inconsistentPassword;
    setFlgObj(checkFlg, false);
    return Message;
  }

  return Message;
};

export const checkNowPasswordRequire = (
  checkFlg: checkFlgObj,
  target: string,
  value?: string
) => {
  let Message: string | undefined;

  if (!value) {
    Message = "* " + target + validationMessage.empty;
    setFlgObj(checkFlg, false);
    return Message;
  }

  if (!value.match(/^[a-zA-Z0-9]+$/)) {
    Message = "* " + target + "は" + validationMessage.wrongAlphanumeric;
    setFlgObj(checkFlg, false);
    return Message;
  }
};

export const checkNewPasswordRequire = (
  checkFlg: checkFlgObj,
  target: string,
  password?: string,
  confirmPassword?: string,
  minLength?: number
) => {
  let Message: string | undefined;

  if (!password) {
    Message = "* " + target + validationMessage.empty;
    setFlgObj(checkFlg, false);
    return Message;
  }

  if (!password.match(/^[a-zA-Z0-9]+$/)) {
    Message = "* " + target + "は" + validationMessage.wrongAlphanumeric;
    setFlgObj(checkFlg, false);
    return Message;
  }

  if (minLength != undefined && password.length < minLength) {
    Message = "* " + target + "は" + minLength + validationMessage.shortageLength;
    setFlgObj(checkFlg, false);
    return Message;
  }

  if (password && !confirmPassword) {
    Message = validationMessage.emptyRePassword;
    setFlgObj(checkFlg, false);
    return Message;
  }

  if (password !== confirmPassword) {
    Message = validationMessage.inconsistentPassword;
    setFlgObj(checkFlg, false);
    return Message;
  }

  return Message;
};

import React, { useEffect, useState } from "react";
import { addMonths } from "date-fns";

import "./B001.scss";
import { getForHistoryPush, H001Url, V001Url, K002Url, M001Url, J003Url, D009Url, E001Url, G001Url, L002Url, D010Url } from "../../helper/url.helper";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authSelector, userInfoSelector } from "../../redux/selectors/auth.selector";
import { HEADQUARTER_USER, LIST_NOTIFICATION_STATUS, LIST_ORDER_DETAIL_DELIVERY_STATUS, PARENT, STAFF } from "../../constants.js";
import { B001Schema, FCStaffInfoSchema, GeneralForListSchema } from "../../generated";
import { format } from "date-fns-tz";
import { getFormatDate, getFormatDateTime, getYearMonth } from "../../helper/date.helper";
import { SCHEDULE_DETAIL_STATUS } from "../../models/scheduleReservationFrame.model";
import { AUDIT_DOC_STATUS } from "../../models/audit_doc.model";
import Status, { STATUS_BLUE, STATUS_RED, STATUS_WHITE, StatusSchema } from "../../components/Status/Status";
import { NOTIFICATION_STATUS } from "../../models/notification.model";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import { fetchB001, fetchB001Headquarter, fetchFcFacilities, fetchGeneralsForList, fetchGeneralsForListMulti } from "../../services/api.service";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { initialB001Schema } from "../../models/fc.model";
import Select from "../../components/Select/CustomSelect";
import { useAuth } from "../../helper/auth.helper";
import Pict, { SIZES } from "../../components/Pict/User";
import ToolTip from "../../components/ToolTip/ToolTip";
import { ORDER_DETAIL_DELIVERY_STATUS } from "../../models/order.model";
import { notifyError } from "../../helper/settings.helper";
import { fcListSelector } from "../../redux/selectors/list.selector";
import { listActions } from "../../redux/actions/list.actions";
import { FC_LIST_TYPE, getFcList, getFcListForRedux, getFcListFromRedux } from "../../helper/list.helper";

const B001 = () => {
  const { checkLoggedIn } = useAuth();
  const userInfo = useSelector(userInfoSelector);
  const [fcId, setFcId] = useState(userInfo?.user_type == STAFF ? (userInfo as FCStaffInfoSchema)?.facilities[0].facility_id : 0);
  const today = format(new Date(), "yyyy-MM-dd");
  const history = useHistory();
  const [data, setData] = useState<B001Schema>(initialB001Schema());
  const [getData, setGetData] = useState(true);
  const authState = useSelector(authSelector);
  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const [notificationStatusList, setNotificationStatusList] = useState<GeneralForListSchema[]>([]);
  const [orderDetailDeliveryStatusList, setOrderDetailDeliveryStatusList] = useState<GeneralForListSchema[]>([]);
  const [fcForSelect, setFcForSelect] = useState<GeneralForListSchema[]>([]);
  const notification_status_list: StatusSchema[] = [
    { num: NOTIFICATION_STATUS.UNREAD, class: STATUS_RED },
    { num: NOTIFICATION_STATUS.READ, class: STATUS_WHITE },
  ];
  const order_detail_delivery_status_list: StatusSchema[] = [
    { num: ORDER_DETAIL_DELIVERY_STATUS.MITAIOU, class: STATUS_RED },
    { num: ORDER_DETAIL_DELIVERY_STATUS.TAIOUCHU, class: STATUS_BLUE },
  ]
  const fcListState = useSelector(fcListSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      if (fcId > 0) {
        fetchB001(authState, fcId).then((res: any) => {
          if (isMounted) {
            setData(res.data);
          }
          changeIsLoading();
        }).catch((err) => {
          if (isMounted) {
            checkLoggedIn(err);
            notifyError("データの取得に失敗しました。");
            console.error(err);
          }
          changeIsLoading();
        });
      } else {
        fetchB001Headquarter(authState).then((res: any) => {
          if (isMounted) {
            setData(res.data);
            setGetData(false);
          }
          changeIsLoading();
        }).catch((err) => {
          if (isMounted) {
            checkLoggedIn(err);
            notifyError("データの取得に失敗しました。");
            console.error(err);
          }
          changeIsLoading();
        });
      }
    };
    if (getData) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, fcId, getData]);

  useEffect(() => {
    if (userInfo?.user_type == HEADQUARTER_USER) {
      let isMounted = true;
      const fetchData = async () => {
        changeIsLoading();
        fetchFcFacilities(authState, "").then((res) => {
          if (isMounted) {
            setFcForSelect(getFcList(FC_LIST_TYPE.ADD_HEAD, res.data));
            dispatch(listActions.makeFcList(getFcListForRedux(res.data)));
          }
          changeIsLoading();
        }).catch((err) => {
          if (isMounted) {
            checkLoggedIn(err);
            notifyError("データの取得に失敗しました。");
            console.error(err);
          }
          changeIsLoading();
        });
      };
      if (fcForSelect.length == 0) {
        if (fcListState.select_list.length == 0) {
          fetchData();
        } else {
          setFcForSelect(getFcListFromRedux(FC_LIST_TYPE.ADD_HEAD, fcListState.select_list));
        }
      }
      return () => {
        isMounted = false;
      }
    }
  }, [authState, dispatch, changeIsLoading, checkLoggedIn, userInfo, fcListState.select_list, fcForSelect.length]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchGeneralsForListMulti(authState, [LIST_NOTIFICATION_STATUS, LIST_ORDER_DETAIL_DELIVERY_STATUS]).then((res: any) => {
        if (isMounted) {
          setNotificationStatusList(res.data[LIST_NOTIFICATION_STATUS]);
          setOrderDetailDeliveryStatusList(res.data[LIST_ORDER_DETAIL_DELIVERY_STATUS]);
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    fetchData();
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, userInfo]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <div className="B001_plate">
        {userInfo?.user_type == HEADQUARTER_USER &&
          <div className="item">
            <Select
              value={fcForSelect.filter(x => x.value == fcId)[0]}
              options={fcForSelect}
              placeholder="施設"
              onChange={(e: any) => {
                setFcId(e.value);
                setGetData(true);
              }} />
          </div>
        }
        <div className="dashboard_menu_container">
          {fcId == 0 ? (
            <div className="dashboard_menu">
              <div className="dashboard_menu_item" style={{ width: "50%" }}>
                <div className="dark_pink">
                  <h6>チャット</h6>
                </div>
                <div className="dashboard_item_detail B001_hover">
                  {data.chat_messages_staff.map((x, i) =>
                    <div className="on_same_row" key={i} onClick={() => history.push(getForHistoryPush(G001Url, [x.user_id, STAFF]))}>
                      <Pict
                        url={x.profile_pic_url}
                        size={SIZES.M}
                      />
                      <div className="item">{x.name.length > 15 ? x.name.substring(0, 15) + "..." : x.name}</div>
                      <div className="item unread_count">{x.not_read_num}</div>
                      <ToolTip
                        text={
                          x.not_read_message_first
                        }
                        content={
                          <div className="item hide_over" style={{ maxWidth: "340px" }}>
                            {x.not_read_message_first}
                          </div>
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="dashboard_menu_item" style={{ width: "49%" }}>
                <div className="purple">
                  <h6>受注情報</h6>
                </div>
                {
                  data.orders.length > 0 ? (
                    <table width="100%" className="item">
                      <thead>
                        <tr className="B001_table_title">
                          <td>{"施設"}</td>
                          <td align="center">{"商品種類数"}</td>
                          <td align="center">{"商品合計数"}</td>
                          <td></td>
                        </tr>
                      </thead>
                      <tbody>
                        {data.orders.map((x, i) =>
                          <tr key={i} className="dashboard_item_detail B001_hover" onClick={() => history.push(getForHistoryPush(L002Url, []))}>
                            <td className="item hide_over" style={{ maxWidth: "100px" }}>
                              {x.facility_name}
                            </td>
                            <td align="center">{x.order_detail_num}</td>
                            <td align="center">{x.order_detail_equipment_num}</td>
                            <td className="item" valign="middle" align="center">
                              <Status
                                max_width={60}
                                min_width={60}
                                label_list={orderDetailDeliveryStatusList}
                                color_list={order_detail_delivery_status_list}
                                status={x.status}
                              />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  ) : (
                    <div className="dashboard_item_detail"></div>
                  )
                }
              </div>
            </div>
          ) : (
            <div className="dashboard_menu">
              <div className="dashboard_menu_item" style={{ width: "59%" }}>
                <div className="pink">
                  <h6>今日のトレーニング</h6>
                </div>
                <div className="dashboard_item_detail B001_hover" onClick={() => history.push(getForHistoryPush(H001Url, [0, fcId, today, today]))}>
                  {data.trainings.map((x, i) =>
                    <div className="on_same_row" key={i}>
                      <div className="item">{x.time}</div>
                      <div className="item">{x.children.join("、")}</div>
                    </div>
                  )}
                </div>
                <div className="yellow">
                  <h6>イベント</h6>
                </div>
                <div className="dashboard_item_detail B001_hover" onClick={() => history.push(getForHistoryPush(V001Url, [fcId, today, 0]))}>
                  {data.events.map((x, i) =>
                    <div className="on_same_row" key={i}>
                      <div className="item">{getFormatDate(x.dates)}</div>
                      <div className="item">{x.main_title}</div>
                    </div>
                  )}
                </div>
                {data.chat_messages_headquarter.length > 0 &&
                  <>
                    <div className="light_green">
                      <h6>チャット（本部）</h6>
                    </div>
                    <div className="dashboard_item_detail B001_hover">
                      {data.chat_messages_headquarter.map((x, i) =>
                        <div key={i} >
                          <ToolTip text={x.not_read_message_first} content={
                            <div className="on_same_row" onClick={() => history.push(getForHistoryPush(G001Url, [x.user_id, HEADQUARTER_USER]))}>
                              <Pict
                                url={x.profile_pic_url}
                                size={SIZES.M}
                              />
                              <div className="item">{x.name.length > 15 ? x.name.substring(0, 15) + "..." : x.name}</div>
                              <div className="item unread_count">{x.not_read_num}</div>
                              <div className="item hide_over" style={{ maxWidth: "340px" }}>
                                {x.not_read_message_first}
                              </div>
                            </div>
                          } />
                        </div>
                      )}
                    </div>
                  </>
                }
                {data.chat_messages_parent.length > 0 &&
                  <>
                    <div className="dark_pink">
                      <h6>チャット（保護者）</h6>
                    </div>
                    <div className="dashboard_item_detail B001_hover">
                      {data.chat_messages_parent.map((x, i) =>
                        <ToolTip text={x.not_read_message_first} content={
                          <div className="on_same_row" key={i} onClick={() => history.push(getForHistoryPush(G001Url, [x.user_id, PARENT]))}>
                            <Pict
                              url={x.profile_pic_url}
                              size={SIZES.M}
                            />
                            <div className="item">{x.name}</div>
                            <div className="item unread_count">{x.not_read_num}</div>
                            <div className="item hide_over" style={{ maxWidth: "340px" }}>
                              {x.not_read_message_first}
                            </div>
                          </div>
                        } />
                      )}
                    </div>
                  </>
                }
                <div className="purple">
                  <h6>新着情報</h6>
                </div>
                {
                  data.notifications.length > 0 ? (
                    <table width="100%" className="item">
                      <thead>
                        <tr className="B001_table_title">
                          <td>{"タイトル"}</td>
                          <td>{"差出人"}</td>
                          <td>{"受信日時"}</td>
                          <td></td>
                        </tr>
                      </thead>
                      <tbody>
                        {data.notifications.map((x, i) =>
                          <tr key={i} className="dashboard_item_detail B001_hover" onClick={() => history.push(getForHistoryPush(J003Url, [x.notification_id]))}>
                            <td className="item hide_over" style={{ maxWidth: "150px" }}>
                              {x.title}
                            </td>
                            <td>{x.sent_from}</td>
                            <td>{getFormatDateTime(new Date(x.created_at))}</td>
                            <td className="item" valign="middle" align="center">
                              <Status
                                max_width={60}
                                min_width={60}
                                label_list={notificationStatusList}
                                color_list={notification_status_list}
                                status={x.read_flg ? 2 : 1}
                              />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  ) : (
                    <div className="dashboard_item_detail"></div>
                  )
                }
              </div>
              <div className="dashboard_menu_item" style={{ width: "40%" }}>
                {data.unregistered_fixed_time_slot.length > 0 && (
                <>
                  <div className="light_red">
                    <h6>固定時間未入力</h6>
                  </div>
                  <table width="100%" className="item">
                    <tbody>
                      {data.unregistered_fixed_time_slot.map((x, i) =>
                        <tr key={i} className="dashboard_item_detail B001_hover" onClick={() => history.push(getForHistoryPush(D010Url, [x.child_id]))}>
                          <td>{x.child_name}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </>
                )}
                <div className="green">
                  <h6>承認待ち</h6>
                </div>
                <table width="100%" className="item B001_table">
                  <tbody>
                    <tr className="dashboard_item_detail B001_hover" onClick={() => history.push(getForHistoryPush(K002Url, [fcId, today, 0, SCHEDULE_DETAIL_STATUS.RESERVED]))}>
                      <td>スケジュール</td>
                      <td>{`${data.approval_pending_training_num}件`}</td>
                    </tr>
                    <tr className="dashboard_item_detail B001_hover" onClick={() => history.push(getForHistoryPush(V001Url, [fcId, today, 0]))}>
                      <td>イベント</td>
                      <td>{`${data.approval_pending_event_num}件`}</td>
                    </tr>
                  </tbody>
                </table>
                <div className="orange">
                  <h6>未アップロード監査ドキュメント</h6>
                </div>
                {data.audit_docs.length > 0 ? (
                  <table width="100%" className="item B001_table">
                    <tbody>
                      {data.audit_docs.map((x, i) =>
                        <tr key={i} className="dashboard_item_detail B001_hover" onClick={() => history.push(getForHistoryPush(M001Url, [fcId, x.doc_type, AUDIT_DOC_STATUS.NOT_YET]))}>
                          <td>{x.doc_name}</td>
                          <td>{`${x.num}件`}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                ) : (
                  <div className="dashboard_item_detail"></div>
                )}
                <div className="blue">
                  <h6>個別支援計画対象者</h6>
                </div>
                {data.individual_support_plans.length > 0 ? (
                  <table width="100%" className="item">
                    <thead>
                      <tr className="B001_table_title">
                        <td>{"お子様名"}</td>
                        <td>{"支援期間"}</td>
                      </tr>
                    </thead>
                    <tbody>
                      {data.individual_support_plans.map((x, i) =>
                        <tr key={i} className="dashboard_item_detail B001_hover" onClick={() => history.push(getForHistoryPush(E001Url, [x.child_id]))}>
                          <td>{x.child_name}</td>
                          <td>{`${getYearMonth(new Date(x.start_date))} - ${getYearMonth(addMonths(new Date(x.start_date), 5))}`}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                ) : (
                  <div className="dashboard_item_detail"></div>
                )}
                <div className="light_blue">
                  <h6>アセスメント対象者</h6>
                </div>
                {data.assessments.length > 0 ? (
                  <table width="100%" className="item">
                    <thead>
                      <tr className="B001_table_title">
                        <td>{"お子様名"}</td>
                        <td>{"前回回答日"}</td>
                      </tr>
                    </thead>
                    <tbody>
                      {data.assessments.map((x, i) =>
                        <tr key={i} className="dashboard_item_detail B001_hover" onClick={() => history.push(getForHistoryPush(D009Url, [x.assessment_id, x.child_id]))}>
                          <td>{x.child_name}</td>
                          <td>{x.last_answer_date && getFormatDate(x.last_answer_date)}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                ) : (
                  <div className="dashboard_item_detail"></div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default B001;

import actionCreatorFactory from "typescript-fsa";
import { L008CartItemSchema } from "../../generated";

const actionCreator = actionCreatorFactory();

export enum CartActionTypes {
  ADD_TO_CART = "ADD_TO_CART",
  FETCH_CART_ITEM = "FETCH_CART_ITEM",
  DELETE_FROM_CART = "DELETE_FROM_CART",
  UPDATE_CART_ITEM = "UPDATE_CART_ITEM",
  INCREMENT_CART_ITEM_NUM = "INCREMENT_CART_ITEM_NUM",
  DECREMENT_CART_ITEM_NUM = "DECREMENT_CART_ITEM_NUM",
  CLEAR_CART = "CLEAR_CART",
};

export const cartActions = {
  addToCart: actionCreator<L008CartItemSchema>(CartActionTypes.ADD_TO_CART),
  fetchCartItem: actionCreator<L008CartItemSchema>(CartActionTypes.FETCH_CART_ITEM),
  deleteCart: actionCreator<number>(CartActionTypes.DELETE_FROM_CART),
  updateCartItem: actionCreator<L008CartItemSchema>(CartActionTypes.UPDATE_CART_ITEM),
  incrementCartItemNum: actionCreator<number>(CartActionTypes.INCREMENT_CART_ITEM_NUM),
  decrementCartItemNum: actionCreator<number>(CartActionTypes.DECREMENT_CART_ITEM_NUM),
  clearCart: actionCreator(CartActionTypes.CLEAR_CART),
};

import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../redux/reducers";
import { fetchFcFacilities, fetchGeneralsForList, getG001ForHeadquarterUser, getG001ForStaff } from "../../services/api.service";
import {
  FCFacilitiesSchema,
  G001Schema,
  GeneralForListSchema,
} from "../../generated";
import { authSelector, userInfoSelector } from "../../redux/selectors/auth.selector";
import "./G001.scss";
import G002 from "../G002/G002";
import { HEADQUARTER_USER, PARENT, STAFF } from "../../constants";
import { initialGeneralForList } from "../../models/general.model";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import Pict, { SIZES } from "../../components/Pict/User";
import TextBox from "../../components/TextBox/TextBox";
import Select from "../../components/Select/CustomSelect";
import Split from "react-split"
import { useAuth } from "../../helper/auth.helper";
import { useLocation } from "react-router-dom";
import { notifyError } from "../../helper/settings.helper";
import { fcListSelector } from "../../redux/selectors/list.selector";
import { listActions } from "../../redux/actions/list.actions";
import { FC_LIST_TYPE, getFcList, getFcListForRedux, getFcListFromRedux } from "../../helper/list.helper";

interface iG001 {
  chat_user_id: number,
  chat_user_type: string,
}

const G001 = () => {
  const { checkLoggedIn } = useAuth();
  const authState = useSelector(authSelector);
  const userInfo = useSelector(userInfoSelector);
  const location = useLocation<iG001>();
  const [data, setData] = useState<G001Schema[]>();
  const wsState = useSelector((s: State) => s.wsReducer);
  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const [userId, setUserId] = useState<number | undefined>(undefined);
  const [userType, setUserType] = useState<string>("");
  const [fcForSelect, setFcForSelect] = useState<GeneralForListSchema[]>([]);
  const [search, setSearch] = useState({
    facilityId: 0,
    rank: 2, // マネージャーのみ取得
    user_type_partner: "",
    name: "",
  });
  const [read, setRead] = useState(false)
  const fcListState = useSelector(fcListSelector);
  const dispatch = useDispatch();

  const getSelectValue = (val: number, selectList: GeneralForListSchema[]) => {
    if (!val) return null;
    return selectList.filter((x) => x.value == val)[0];
  };

  const pushG002 = (id: number, type: string) => {
    setUserId(id);
    setUserType(type);
  };

  const fetchChatUsers = useCallback(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      if (userInfo?.user_type === HEADQUARTER_USER) {
        getG001ForHeadquarterUser(search.facilityId, search.rank, search.name, authState).then((res) => {
          if (isMounted) {
            setData(res.data);
            res.data.map((value) => {
              wsState.socket?.emit("create_chat_room", {
                headquarter_id: userInfo?.id,
                staff_id: value.user_type === STAFF ? value.id : undefined,
                parent_id: value.user_type === PARENT ? value.id : undefined,
              });
            });
          }
          changeIsLoading();
        }).catch((err) => {
          if (isMounted) {
            checkLoggedIn(err);
            notifyError("データの取得に失敗しました。");
            console.error(err);
          }
          changeIsLoading();
        });
      } else {
        getG001ForStaff(search.user_type_partner, search.name, authState).then((res) => {
          if (isMounted) {
            setData(res.data);
            res.data.map((value) => {
              wsState.socket?.emit("create_chat_room", {
                headquarter_id: value.user_type === HEADQUARTER_USER ? value.id : undefined,
                staff_id: userInfo ? userInfo.id : undefined,
                parent_id: value.user_type === PARENT ? value.id : undefined,
              });
            });
          }
          changeIsLoading();
        }).catch((err) => {
          if (isMounted) {
            checkLoggedIn(err);
            notifyError("データの取得に失敗しました。");
            console.error(err);
          }
          changeIsLoading();
        });
      };
    }
    fetchData();
    return () => {
      isMounted = false;
    }
  }, [search, userInfo, authState, wsState.socket, changeIsLoading, checkLoggedIn]);

  useEffect(() => {
    fetchChatUsers();
  }, [fetchChatUsers, read])

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchFcFacilities(authState, "").then((res) => {
        if (isMounted) {
          setFcForSelect(getFcList(FC_LIST_TYPE.ADD_INIT, res.data));
          dispatch(listActions.makeFcList(getFcListForRedux(res.data)));
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (fcForSelect.length == 0) {
      if (fcListState.select_list.length == 0) {
        fetchData();
      } else {
        setFcForSelect(getFcListFromRedux(FC_LIST_TYPE.ADD_INIT, fcListState.select_list));
      }
    }
    return () => {
      isMounted = false;
    }
  }, [authState, dispatch, changeIsLoading, checkLoggedIn, fcForSelect.length, fcListState.select_list]);

  useEffect(() => {
    wsState.socket?.on("receive_chat", () => {
      fetchChatUsers();
    });
    return () => {
      wsState.socket?.off("receive_chat");
    };
  }, [wsState.socket, fetchChatUsers]);

  useEffect(() => {
    location.state.chat_user_id && pushG002(location.state.chat_user_id, location.state.chat_user_type);
  }, [location])

  return (
    <Split
      sizes={[25, 75]}
      gutterSize={20}
      direction="horizontal"
      className="plate d-flex chat-container"
    >
      <div className="chat_rooms_container">
        <LoadingSpinner isLoading={isLoading} />
        {userInfo?.user_type === HEADQUARTER_USER &&
          <>
            <Select
              value={getSelectValue(search.facilityId, fcForSelect)}
              options={fcForSelect}
              className="G001_form w-100"
              onChange={(e: any) => {
                setSearch({ ...search, facilityId: e.value });
              }}
              placeholder="施設"
            />
          </>}
        {userInfo?.user_type === STAFF &&
          <Select
            options={[
              { value: HEADQUARTER_USER, label: "本部スタッフ" },
              { value: PARENT, label: "保護者" },
            ]}
            className="G001_form w-100"
            onChange={(e: any) => {
              setSearch({ ...search, user_type_partner: e.value });
            }}
            placeholder="対象"
          />}
        <TextBox
          class="G001_form"
          placeholder="名前"
          value={search.name}
          onChange={(value: string) => {
            setSearch({ ...search, name: value });
          }}
        />
        <div className="chat_rooms">
          {data?.map((room, i) => (
            <div
              key={i}
              className="chat_rooms_item d-flex on_same_row"
              onClick={() => pushG002(room.id, room.user_type)}
            >
              <Pict url={room.profile_pic_url} size={SIZES.M} />
              <div className="chat_rooms_info d-flex on_same_row">
                <div>
                  <div className="on_same_row">
                    <small className="mx-1">{room.facility_name}</small>
                  </div>
                  <div className="mx-1">{(room.family_name + room.given_name).length > 10 ? (room.family_name + room.given_name).substring(0, 10) + "..." : (room.family_name + room.given_name)}</div>
                </div>
                {room.unread_count !== 0 && (
                  <div className="unread_count">
                    {room.unread_count}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="col-9">
        {userId && <G002 user_id={userId} user_type={userType} setRead={setRead} />}
      </div>
    </Split>
  );
};

export default G001;

import { useDispatch, useSelector } from "react-redux";
import { State } from "../redux/reducers";
import { clear } from "redux-localstorage-simple";
import { authActions, errorStateAction } from "../redux/actions/auth.actions";
import { cartActions } from "../redux/actions/cart.actions";
import { notifyError } from "./settings.helper";
import { useCallback } from "react";
import { errorStateSelector } from "../redux/selectors/auth.selector";

// python側にあるregexと揃えてあります
export const regex =
  /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;

export const useAuth = () => {
  const dispatch = useDispatch();
  const wsState = useSelector((s: State) => s.wsReducer);
  const errorState = useSelector(errorStateSelector);

  const checkLoggedIn = useCallback(async (err: any) => {
    if (err?.response?.status && err.response.status == 401 && !errorState.alreadyError) {
      dispatch(errorStateAction.changeStatus(true));
      notifyError("認証エラーが発生しました。再ログインをお願いします。");
      wsState.socket?.emit("handle_logout");
      dispatch(authActions.logout());
      dispatch(cartActions.clearCart());
      clear();
      return false;
    }
    return true;
  }, [dispatch, wsState, errorState]);

  return { checkLoggedIn };
};
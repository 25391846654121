import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { authSelector } from "../../redux/selectors/auth.selector";
import { GeneralForListSchema, L003Schema, Result } from "../../generated";
import { fetchGeneralsForList, fetchOrder, putChangeStatus } from "../../services/api.service";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import Title from "../../components/Title/Title";
import PaginationTable from "../../components/PaginationTable/PaginationTable";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import EquipmentPict from "../../components/Pict/EquipmentPict";
import SelectForTable, { COLOR_CLASS, SelectStatusInfo } from "../../components/Select/SelectForTable";
import ConfirmModal from "../../components/ConfirmModal";
import { notify, notifyError } from "../../helper/settings.helper";
import { useAuth } from "../../helper/auth.helper";
import { HEADQUARTER_USER } from "../../constants";
import Status, { STATUS_BLUE, STATUS_GREEN, STATUS_RED, StatusSchema } from "../../components/Status/Status";
import { DELIVERY_STATUS } from "../../models/equipment.model";
import { getYyyyMmDd } from "../../helper/date.helper";

interface iL003 {
  order_id: number;
}

const L003 = () => {
  const { checkLoggedIn } = useAuth();
  const authState = useSelector(authSelector);
  const location = useLocation<iL003>();
  const orderId = location.state.order_id;
  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const [data, setData] = useState<L003Schema[]>([]);
  const [showRegister, setShowRegister] = useState(false);
  const [statusForSelect, setStatusForSelect] = useState<GeneralForListSchema[]>([]);

  const [changeStatus, setChangeStatus] = useState({
    id: 0,
    status: 0,
  });
  const selectStatusList: SelectStatusInfo[] = statusForSelect.map((x, i) => {
    return { value: x.value, label: x.label, color: [COLOR_CLASS.RED, COLOR_CLASS.GREEN, COLOR_CLASS.BLUE][i] }
  });
  const labelStatusList: StatusSchema[] = selectStatusList.map((x, i) => {
    return { num: x.value, class: [STATUS_RED, STATUS_GREEN, STATUS_BLUE][i] }
  });
  const [reload, setReload] = useState(true);

  const clickRegister = () => {
    changeIsLoading();
    putChangeStatus(changeStatus.id, changeStatus.status, authState).then((res) => {
      if (res.data.result) {
        const targetIndex = data.findIndex(record => record.id === changeStatus.id);
        if (data[targetIndex].delivery_status !== changeStatus.status) {
          setData(prevData => {
            const updateData = [...prevData];
            updateData[targetIndex].delivery_status = changeStatus.status;
            return updateData;
          });
        }
        setShowRegister(false);
        notify("発送ステータスを更新しました。");
        changeIsLoading();
      } else {
        notifyError(String(res.data.msg));
        setShowRegister(false);
        changeIsLoading();
      }
    }).catch((err) => {
      checkLoggedIn(err);
      notifyError("登録に失敗しました。");
      console.error(err);
      changeIsLoading();
    });
  };

  useEffect(() => {
    if (reload) {
      let isMounted = true;
      const fetchData = async () => {
        changeIsLoading();
        fetchOrder(orderId, authState).then((res) => {
          if (isMounted) {
            setData(res.data);
            setReload(false);
          }
          changeIsLoading();
        }).catch((err) => {
          if (isMounted) {
            checkLoggedIn(err);
            notifyError("データの取得に失敗しました。");
            console.error(err);
          }
          changeIsLoading();
        });
      };
      fetchData();
      return () => {
        isMounted = false;
      }
    }
  }, [authState, changeIsLoading, checkLoggedIn, orderId, reload]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchGeneralsForList(authState, DELIVERY_STATUS).then((res) => {
        if (isMounted) {
          setStatusForSelect(res.data);
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (statusForSelect.length == 0) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, statusForSelect.length])

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Title title={authState.userInfo?.user_type === HEADQUARTER_USER ? "受注詳細" : "発注詳細"} />
      <PaginationTable
        limit={9}
        tr_select={false}
        headers={["施設", "画像", "備品名", "品番", "大きさ", "色", "合計金額", "数量", "発注日", "ステータス"]}
        list={data.map((x, i) => (
          <tr key={i}>
            <td className="item">{x.facility_name}</td>
            <td className="item"><EquipmentPict url={x.image_url} /></td>
            <td className="item hide_over" style={{ maxWidth: "150px" }}>{x.name}</td>
            <td className="item hide_over" style={{ maxWidth: "100px" }}>{x.serial}</td>
            <td className="item hide_over" style={{ maxWidth: "100px" }}>{x.size || "設定なし"}</td>
            <td className="item hide_over" style={{ maxWidth: "100px" }}>{x.color || "設定なし"}</td>
            <td className="item">{x.price.toLocaleString() + "円"}</td>
            <td className="item">{x.num}</td>
            <td className="item">{getYyyyMmDd(new Date(x.order_date))}</td>
            <td className="item" valign="middle" align="center">
              {authState.userInfo?.user_type === HEADQUARTER_USER ?
                <SelectForTable
                  status={x.delivery_status}
                  options={selectStatusList}
                  onChange={(e: any) => {
                    setChangeStatus({ id: x.id, status: e.value });
                    setShowRegister(true);
                  }}
                />
                : <Status
                  label_list={statusForSelect}
                  color_list={labelStatusList}
                  status={x.delivery_status}
                  max_width={150}
                />
              }
            </td>
          </tr>
        ))}
      />
      <ConfirmModal
        target="登録"
        show={showRegister}
        setShow={setShowRegister}
        func={clickRegister}
        confirmText={"ステータスを変更してよろしいでしょうか。"}
      />
    </>
  );
};

export default L003;

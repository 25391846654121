
export const windowOpen = (data: any) => {
  const dataStr = JSON.stringify(data.state);
  window.open(`${data.pathname}?data=${dataStr}`, "_blank");
};

export const getState = (location: any) => {
  if (location.state == undefined) {
    return JSON.parse(decodeURIComponent(String(new URLSearchParams(location.search).get("data"))));
  } else {
    return location.state;
  }
};


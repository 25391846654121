import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../redux/selectors/auth.selector";
import "./F002.scss";
import { FCFacilityF002Schema } from "../../generated";
import { ValidationError, initialValidationError } from "../../models/validate.model";
import { DEFAULT_REGUlAR_HOLIDAY, HOLIDAY_FLG, initialFacilityModel, validationFacilityModel } from "../../models/fc.model";
import { TIME_LIST } from "../../constants";
import CustomRow from "../../components/CustomRow/CustomRow";
import CheckBox from "../../components/CheckBox/CheckBox";
import Label from "../../components/Label/Label";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import TextBox from "../../components/TextBox/TextBox";
import Title from "../../components/Title/Title";
import CustomButton, { CB_CLASS, CB_SIZE } from "../../components/Button/CustomButton";
import ConfirmModal from "../../components/ConfirmModal";
import SelectSimple from "../../components/Select/SelectSimple";
import { notify, notifyError } from "../../helper/settings.helper";
import { getState } from "../../helper/page.helper";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import { deleteFcFacilityAllInfo, fetchAddress, fetchFcFacilityAllInfo, putFcFacilityAllInfo } from "../../services/api.service";
import { useAuth } from "../../helper/auth.helper";
import { listActions } from "../../redux/actions/list.actions";
import NumBox, { NUM_BOX_TYPE } from "../../components/TextBox/NumBox";

interface iF002 {
  fc_id: number;
}

const F002 = () => {
  const { checkLoggedIn } = useAuth();
  const location = useLocation<iF002>();
  const authState = useSelector(authSelector);
  const state = getState(location);
  const facility_id = state.fc_id;
  const history = useHistory();
  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const [data, setData] = useState<FCFacilityF002Schema>(initialFacilityModel);
  const [validationError, setValidationError] = useState<ValidationError<FCFacilityF002Schema>>(initialValidationError());
  const [isValidated, setIsValidated] = useState<boolean | undefined>(undefined);
  const [showRegister, setShowRegister] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [reload, setReload] = useState(true);
  const dispatch = useDispatch();

  const replaceCharacterAtIndex = (inputString: string, index: number, newCharacter: string) => {
    if (index < 0 || index >= inputString.length) return inputString;
    let modifyString = inputString.substring(0, index) + newCharacter + inputString.substring(index + 1);
    return modifyString;
  }

  const changeRegularHoliday = (value: boolean, dayOfWeek: number) => {
    setData((prevData) => {
      const newData = { ...prevData };
      newData.regular_holiday = newData.regular_holiday;
      newData.regular_holiday = replaceCharacterAtIndex(newData.regular_holiday, dayOfWeek, value ? "1" : "0");
      return newData;
    });
  }

  const setAddressForAPI = () => {
    changeIsLoading();
    fetchAddress(data.post_code).then((result) => {
      result !== null && setData({ ...data, address_line: result })
      changeIsLoading();
    }).catch((err) => {
      console.warn(err);
      changeIsLoading();
    });
  }

  useEffect(() => {
    if (facility_id && reload) {
      let isMounted = true;
      const fetchData = async () => {
        changeIsLoading();
        fetchFcFacilityAllInfo(facility_id, authState).then((res) => {
          if (isMounted) {
            if (!res.data.weekday_training_start_min) {
              res.data.weekday_training_start_min = "45";
            }
            if (!res.data.holiday_training_start_min) {
              res.data.holiday_training_start_min = "00";
            }
            if (!res.data.regular_holiday) {
              res.data.regular_holiday = DEFAULT_REGUlAR_HOLIDAY;
            }
            setData(res.data);
            setReload(false);
          }
          changeIsLoading();
        }).catch((err) => {
          if (isMounted) {
            checkLoggedIn(err);
            notifyError("データの取得に失敗しました。");
            console.error(err);
          }
          changeIsLoading();
        });
      };
      fetchData();
      return () => {
        isMounted = false;
      }
    }
  }, [authState, facility_id, changeIsLoading, checkLoggedIn, reload]);

  const clickRegister = () => {
    const validatedData = validationFacilityModel(data);
    if (validatedData.ok) {
      changeIsLoading();
      putFcFacilityAllInfo(data, authState).then(() => {
        setShowRegister(false);
        notify("施設情報を更新しました。");
        dispatch(listActions.clearFcList());
        changeIsLoading();
        history.goBack();
      }).catch((err) => {
        checkLoggedIn(err);
        notifyError("登録に失敗しました。");
        console.error(err);
        changeIsLoading();
      });
    } else {
      setShowRegister(false);
      notifyError("入力不正があります。");
      setIsValidated(true);
      setValidationError(validatedData.validationError);
    }
  };

  const clickDelete = () => {
    changeIsLoading();
    deleteFcFacilityAllInfo(facility_id, authState).then((_) => {
      setShowDelete(false);
      notify("施設を削除しました。");
      changeIsLoading();
      history.goBack();
    }).catch((err) => {
      checkLoggedIn(err);
      notifyError("削除に失敗しました。");
      console.error(err);
      changeIsLoading();
    });
  };

  useEffect(() => {
    if (isValidated) {
      const validatedData = validationFacilityModel(data);
      if (validatedData.ok) {
        setValidationError(initialValidationError());
      } else {
        setIsValidated(true);
        setValidationError(validatedData.validationError);
      }
    }
  }, [data, isValidated]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Title title={facility_id ? "施設編集" : "施設登録"} />
      <div className="plate">
        <CustomRow
          title="施設名"
          required={true}
          error_msg={validationError.messageOf.facility_name}
          content={
            <TextBox
              type="text"
              placeholder="施設名"
              value={data.facility_name}
              onChange={(v: any) => setData({ ...data, facility_name: v })}
            />
          }
        />
        <CustomRow
          title="事業所番号"
          required={false}
          error_msg={validationError.messageOf.office_number}
          content={
            <TextBox
              type="text"
              placeholder="事業所番号"
              value={data.office_number}
              onChange={(v: any) => setData({ ...data, office_number: v })}
            />
          }
        />
        <CustomRow
          title="電話番号"
          required={true}
          error_msg={validationError.messageOf.phone_number}
          content={
            <TextBox
              type="text"
              placeholder="電話番号（ハイフン無し）"
              value={data.phone_number}
              onChange={(v: any) => setData({ ...data, phone_number: v })}
            />
          }
        />
        <CustomRow
          title="郵便番号"
          required={true}
          error_msg={validationError.messageOf.post_code}
          content={
            <>
              <TextBox
                type="text"
                placeholder="ハイフン無し"
                value={data.post_code}
                onChange={(v: any) => setData({ ...data, post_code: v })}
              />
              <CustomButton
                label={"住所自動入力"}
                size={CB_SIZE.S}
                class={[CB_CLASS.BLUE]}
                onClick={() => setAddressForAPI()}
              />
            </>
          }
        />
        <CustomRow
          title="住所"
          required={true}
          error_msg={validationError.messageOf.address_line}
          content={
            <TextBox
              type="text"
              placeholder="住所"
              value={data.address_line}
              onChange={(v: any) => setData({ ...data, address_line: v })}
            />
          }
        />
        <CustomRow
          title="営業時間"
          required={true}
          content={
            <div>
              <div className="on_same_row">
                <Label className="me-3" value="平日" />
                <SelectSimple list={TIME_LIST} val={data.weekday_start_time} width={80} onChange={(index: number) => setData({ ...data, weekday_start_time: TIME_LIST[index] })} />
                <div className="wave_str">～</div>
                <SelectSimple list={TIME_LIST} val={data.weekday_end_time} width={80} onChange={(index: number) => setData({ ...data, weekday_end_time: TIME_LIST[index] })} />
                <Label className="ms-3" value="トレーニング開始分数" />
                <NumBox type={NUM_BOX_TYPE.START_MINUTE} value={data.weekday_training_start_min} width={70} onChange={(e: string) => setData({ ...data, weekday_training_start_min: e })}/>
                <Label className="me-3" value="分" />
              
              </div>
              <div className="on_same_row">
                <Label className="me-3" value="休日" />
                <SelectSimple list={TIME_LIST} val={data.holiday_start_time} width={80} onChange={(index: number) => setData({ ...data, holiday_start_time: TIME_LIST[index] })} />
                <div className="wave_str">～</div>
                <SelectSimple list={TIME_LIST} val={data.holiday_end_time} width={80} onChange={(index: number) => setData({ ...data, holiday_end_time: TIME_LIST[index] })} />
                <Label className="ms-3" value="トレーニング開始分数" />
                <NumBox type={NUM_BOX_TYPE.START_MINUTE} value={data.holiday_training_start_min} width={70} onChange={(e: string) => setData({ ...data, holiday_training_start_min: e })}/>
                <Label className="me-3" value="分" />

              </div>
            </div>
          }
        />
        <CustomRow
          title="定休日"
          required={false}
          content={
            <div>
              {["月", "火", "水", "木", "金", "土", "日"].map((x, i) => (
                <div key={i}>
                  <CheckBox
                    checked={data.regular_holiday[i] === HOLIDAY_FLG ? true : false}
                    label={x + "曜日"} onChange={(v: any) => changeRegularHoliday(v.target.checked, i)}
                  />
                </div>
              ))}
            </div>
          }
        />
        <div className="plate d-flex flex-column align_center">
          <CustomButton
            label="登録"
            class={[CB_CLASS.RED]}
            onClick={() => setShowRegister(true)}
          />
          <ConfirmModal
            target="登録"
            show={showRegister}
            setShow={setShowRegister}
            func={clickRegister}
            confirmText={"登録してよろしいでしょうか。"}
          />
          <CustomButton
            label="削除"
            class={[CB_CLASS.RED]}
            onClick={() => setShowDelete(true)}
          />
          <ConfirmModal
            target="削除"
            show={showDelete}
            setShow={setShowDelete}
            func={clickDelete}
            confirmText={"削除してよろしいでしょうか。"}
          />
        </div>
      </div>
    </>
  );
};

export default F002;

import React from "react";
import { Button } from "react-bootstrap";
import "./Panel.scss";

export const PANEL_COLOR = {
  NONE: "panel_none",
  RED: "panel_red",
  GRAY: "panel_gray",
  MORE_GRAY: "panel_more_gray",
};

function Panel(props: any) {
  return (
    <Button
      className={"custom_panel " + (props.is_none ? PANEL_COLOR.NONE : props.color)}
      onClick={(event) => {
        event.stopPropagation();
        props.onClick();
      }}
      disabled={props.disabled}
    >
      {props.label}
    </Button>
  );
};

export default Panel;
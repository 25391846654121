import actionCreatorFactory from "typescript-fsa";

const actionCreator = actionCreatorFactory();

export enum ChatReloadActionTypes {
  INIT_CHAT_RELOAD = "INIT_CHAT_RELOAD",
  CHAT_RELOAD_TRUE = "CHAT_RELOAD_TRUE",
  CHAT_RELOAD_FALSE = "CHAT_RELOAD_FALSE",
}

export const chatReloadActions = {
  initChatReload: actionCreator<{ reload: true }>(
    ChatReloadActionTypes.INIT_CHAT_RELOAD
  ),
  chatReloadTrue: actionCreator<{ reload: true }>(
    ChatReloadActionTypes.CHAT_RELOAD_TRUE
  ),
  chatReloadFalse: actionCreator<{ reload: false }>(
    ChatReloadActionTypes.CHAT_RELOAD_FALSE
  ),
};

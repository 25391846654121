import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import {
  ChildD007Schema,
  GeneralForListSchema,
} from "../../generated";
import { authSelector } from "../../redux/selectors/auth.selector";
import {
  fetchD007,
  fetchGeneralsForList,
} from "../../services/api.service";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import CustomButton, { CB_CLASS } from "../../components/Button/CustomButton"
import PaginationTable from "../../components/PaginationTable/PaginationTable"
import Title from "../../components/Title/Title"
import { LIST_DISABILITY_CERTIFICATE_SUPPORT_TYPE } from "../../constants";
import { D008Url, getForHistoryPush } from "../../helper/url.helper";
import { getState } from "../../helper/page.helper";
import { makeStringDateWithSlash } from "../../helper/time.helper";
import ChildInfoTop from "../../components/ChildInfoTop/ChildInfoTop";
import Label from "../../components/Label/Label";
import { initialChildD007Schema } from "../../models/child.model";
import Status, { STATUS_RED } from "../../components/Status/Status";
import { useAuth } from "../../helper/auth.helper";
import { notifyError } from "../../helper/settings.helper";

interface iLocation {
  child_id: number;
}

const D007 = () => {
  const { checkLoggedIn } = useAuth();
  const maxWidth = 1300;
  const [data, setData] = useState<ChildD007Schema>(initialChildD007Schema());
  const [getData, setGetData] = useState(true);
  const location = useLocation<iLocation>();
  const state = getState(location);
  const child_id = state.child_id;
  const authState = useSelector(authSelector);
  const history = useHistory();
  const [disabilityCertificateList, setDisabilityCertificateList] = useState<GeneralForListSchema[]>([]);
  const [isLoading, changeIsLoading] = useLoadingIndicator();

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchD007(authState, child_id).then((res) => {
        if (isMounted) {
          setData(res.data);
        }
        changeIsLoading();
        setGetData(false);
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (getData) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, child_id, getData]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchGeneralsForList(authState, LIST_DISABILITY_CERTIFICATE_SUPPORT_TYPE).then((res) => {
        if (isMounted) {
          setDisabilityCertificateList(res.data);
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (disabilityCertificateList.length == 0) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, disabilityCertificateList.length]);

  const getSelectValue = (val: number, selectList: GeneralForListSchema[]) => {
    if (selectList.filter(x => x.value == val).length == 0) return null;
    return selectList.filter(x => x.value == val)[0].label;
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Title title="受給者証一覧" />
      <div className="plate">
        <div className="on_same_row justify" style={{ maxWidth: maxWidth }}>
          <ChildInfoTop
            no_underbar={true}
            profile_pic_url={data.child_profile_pic_url}
            id={data.child_id}
            name={data.child_name}
            name_kana={data.child_name_kana}
            facility_name={data.facility_name}
            user_type={authState.userInfo?.user_type}
          />
          {data.disability_certificates.filter(record => record.is_temp === true).length === 0 &&
            <CustomButton
              label="＋受給者証を追加"
              min_width={200}
              class={[CB_CLASS.RED]}
              onClick={() => history.push(getForHistoryPush(D008Url, [data.child_id, 0]))}
            />}
        </div>
      </div>
      <PaginationTable
        limit={10}
        max_width={maxWidth}
        headers={["受給者証番号", "支援の種類", "支給日数", "給付期間", ""]}
        list={data.disability_certificates.map((x, i) => (
          <tr key={i} onClick={() => { history.push(getForHistoryPush(D008Url, [data.child_id, x.id])) }}>
            <td className="item">
              <Label value={x.number} />
            </td>
            <td className="item">
              <Label value={getSelectValue(x.support_type, disabilityCertificateList)} />
            </td>
            <td className="item">
              <Label value={x.date_count} />
            </td>
            <td className="item">
              <Label value={`${x.date_from ? makeStringDateWithSlash(x.date_from) : ""} - ${x.date_end ? makeStringDateWithSlash(x.date_end) : ""}`} />
            </td>
            <td className="item" valign="middle" align="center">
              {x.is_temp &&
                <Status
                  label_list={[{ value: 1, label: "要更新" }]}
                  color_list={[{ num: 1, class: STATUS_RED }]}
                  status={1}
                  max_width={150}
                />
              }
            </td>
          </tr>
        ))}
      />
    </>
  );
};

export default D007;

import React from "react";
import { Button } from "react-bootstrap";
import "./CustomButton.scss";

export const CB_CLASS = {
  BLUE: "button_blue",
  RED: "button_red",
  GRAY: "button_gray",
  LIGHT_GRAY: "button_light_gray",
  RED_SHADOW: "button_red_shadow"
};

export const CB_SIZE = {
  M: "cb_size_m",
  S: "cb_size_s",
};

export const CB_ALIGN = {
  LEFT: "left",
  RIGHT: "right",
  CENTER: "center",
};

function CustomButton(props: any) {
  let class_name = "custom_button";
  (props.class as Array<String>).map((x) => (class_name += " " + x));
  class_name += " " + (props.size || CB_SIZE.M);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: props.align || CB_ALIGN.CENTER,
        alignItems: "center",
      }}
    >
      <Button
        className={class_name}
        onClick={(event) => {
          event.stopPropagation();
          props.onClick && props.onClick();
        }}
        onDoubleClick={(event) => {
          event.stopPropagation();
          props.onDoubleClick && props.onDoubleClick();
        }}
        style={{ minWidth: props.min_width || 130 }}
        disabled={props.disabled}
      >
        {props.label}
      </Button>
    </div>
  );
}

export default CustomButton;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { authSelector } from "../../redux/selectors/auth.selector";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useLocation } from "react-router-dom";
import { ChildInfoSchema, D010Schema, GeneralForListSchema } from "../../generated";
import CustomButton, { CB_ALIGN, CB_CLASS } from "../../components/Button/CustomButton"
import Title from "../../components/Title/Title"
import SelectForRow from "../../components/Select/SelectForRow"
import Delete from "../../components/Icon/Delete"
import Select from "../../components/Select/CustomSelect";
import { DEFAULT_TRAINING_MINUTE } from "../../constants.js"
import { fetchChildInfo, fetchD010, putD010 } from "../../services/api.service";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import ConfirmModal from "../../components/ConfirmModal";
import { notify, notifyError } from "../../helper/settings.helper";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { useAuth } from "../../helper/auth.helper";
import { WEEK_DAY_LIST, initialD010Schema, validationD010Model } from "../../models/child.model";
import ErrorMsg from "../../components/ErrorMsg/ErrorMsg";
import { validationMessage } from "../../config/validationMessage";
import ChildInfoTop from "../../components/ChildInfoTop/ChildInfoTop";
import CustomTime from "../../components/Calendar/CustomTime";
import { addMinutes } from "../../helper/time.helper";

interface D010Location {
  child_id: number;
}

const D010: React.VFC = () => {
  const { checkLoggedIn } = useAuth();
  const authState = useSelector(authSelector);
  const history = useHistory();
  const location = useLocation<D010Location>();
  const childId = location.state.child_id;
  const [items, setItems] = useState<D010Schema[]>([]);
  const [childInfo, setChildInfo] = useState<ChildInfoSchema>();
  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const [showRegister, setShowRegister] = useState(false);
  const [reload, setReload] = useState(true);
  const [validationError, setValidationError] = useState("");
  const [isValidated, setIsValidated] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (reload) {
      let isMounted = true;
      const fetchData = async () => {
        changeIsLoading();
        try {
          const [resD010, resChildInfo] = await Promise.all([
            fetchD010(authState, childId),
            fetchChildInfo(childId, authState),
          ]);
          if (isMounted) {
            setItems(resD010.data);
            setChildInfo(resChildInfo.data);
            setReload(false);
          }
          changeIsLoading();
        } catch (err) {
          if (isMounted) {
            checkLoggedIn(err);
            notifyError("データの取得に失敗しました。");
            console.error(err);
          }
          changeIsLoading();
        }
      };
      fetchData();
      return () => {
        isMounted = false;
      }
    }
  }, [authState, changeIsLoading, checkLoggedIn, childId, reload]);

  const deleteItem = (index: number) => {
    const item_list = [...items];
    item_list[index] = initialD010Schema();
    item_list.splice(index, 1);
    setItems(item_list);
  };

  const setWeekDay = (e: any, index: number) => {
    const item_list = [...items];
    const item = item_list[index];
    item.week_day =  Number(e.value);
    setItems(item_list);
  };

  const setStartTime = (v: string, i: number) => {    
    const updatedItems = [...items];
    updatedItems[i] = {
      ...items[i], 
      start_time: v, 
      end_time: addMinutes(v, DEFAULT_TRAINING_MINUTE),
    };
    setItems(updatedItems);
  };

  const setEndTime = (v: string, index: number) => {
    const item_list = [...items];
    const item = item_list[index];
    item.end_time = v;
    setItems(item_list);
  };

  const clickRegister = () => {
    const validatedData = validationD010Model(items);
    if (validatedData.ok) {
      changeIsLoading();
      putD010(authState, childId, items).then((_) => {
        setShowRegister(false);
        notify("固定時間を登録しました。");
        changeIsLoading();
        history.goBack();
      }).catch((err) => {
        checkLoggedIn(err);
        setShowRegister(false);
        notifyError("登録に失敗しました。");
        changeIsLoading();
        setReload(true);
      })
    } else {
      setShowRegister(false);
      notifyError("入力不正があります。");
      setIsValidated(true);
      setValidationError(validationMessage.duplicateWeekday);
    }
  };

  useEffect(() => {
    if (isValidated) {
      const validatedData = validationD010Model(items);
      if (validatedData.ok) {
        setValidationError("");
      } else {
        setIsValidated(true);
        setValidationError(validationMessage.duplicateWeekday);
      }
    }
  }, [items, isValidated]);

  const getSelectValue = (val: number, selectList: GeneralForListSchema[]) => {
    return selectList.filter(x => x.value == val)[0];
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Title title="固定日時入力" />
      <div className="plate">
        <ChildInfoTop
          profile_pic_url={childInfo?.child_profile_pic_url}
          id={childInfo?.child_id}
          name={childInfo?.child_name}
          name_kana={childInfo?.child_name_kana}
          facility_name={childInfo?.facility_name}
          user_type={authState.userInfo?.user_type}
          no_underbar={true}
        />
        {!(items.length > 0) ? "固定枠の登録はありません。" :
        <>
          <ErrorMsg error_msg={validationError} />
          <table>
            <thead>
              <tr>
                <th>曜日</th>
                <th>時間</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {items.map((x, i) => (
                <React.Fragment key={i}>
                  <tr className="hover_white">
                    <td className="item">
                    <Select
                      value={getSelectValue(x.week_day, WEEK_DAY_LIST)}
                      options={WEEK_DAY_LIST}
                      className="D001_select item"
                      onChange={(e: any) => {
                        setWeekDay(e, i);
                      }}
                      placeholder="曜日"
                    />
                    </td>
                    <td className="on_same_row item cursor_default" >
                      <CustomTime val={x.start_time} setter={(v: string) => setStartTime(v, i)} />
                      <div className="wave_str">～</div>
                      <CustomTime val={x.end_time} setter={(v: string) => setEndTime(v, i)} />
                    </td>
                    <td className="item">
                      <Delete func={() => { deleteItem(i) }} />
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </>
        }
      </div>
      <div className="plate">
        <CustomButton label="＋行を追加" align={CB_ALIGN.LEFT} class={[CB_CLASS.RED]} onClick={() => setItems([...items, initialD010Schema()])} />
        <CustomButton label="登録" align={CB_ALIGN.LEFT} class={[CB_CLASS.RED]} onClick={() => setShowRegister(true)} />
        <ConfirmModal target="登録" show={showRegister} setShow={setShowRegister} func={clickRegister}
          confirmText={"登録してよろしいでしょうか。"}
        />
      </div>
    </>
  );
};

export default D010;

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { authSelector } from "../../redux/selectors/auth.selector";
import { N001Schema, GeneralForListSchema } from "../../generated";
import { fetchGeneralsForListMulti, fetchN001, fetchN001Count } from "../../services/api.service";
import { N002Url, getFileNameByUrl, getForHistoryPush } from "../../helper/url.helper";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import CustomButton, { CB_CLASS } from "../../components/Button/CustomButton";
import Download from "../../components/Icon/Download";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import Title from "../../components/Title/Title";
import moment from "moment";
import Select from "../../components/Select/CustomSelect";
import { UPLOAD_CATEGORY, UPLOAD_STATUS } from "../../models/uploadData.model";
import { initialGeneralForList } from "../../models/general.model";
import ToolTip from "../../components/ToolTip/ToolTip";
import { useAuth } from "../../helper/auth.helper";
import { notifyError } from "../../helper/settings.helper";
import PaginationTableLite from "../../components/PaginationTableLite/PaginationTableLite";

const N001 = () => {
  const { checkLoggedIn } = useAuth();
  const maxWidth = 1300;
  const authState = useSelector(authSelector);
  const history = useHistory();
  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const [data, setData] = useState<N001Schema[]>([]);
  const [getData, setGetData] = useState(true);
  const [categoryForSelect, setCategoryForSelect] = useState<GeneralForListSchema[]>([]);
  const [statusForSelect, setStatusForSelect] = useState<GeneralForListSchema[]>([]);
  const [search, setSearch] = useState({
    category: 0,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [recordCount, setRecordCount] = useState(0);
  const limitCount = 9;

  const getSelectValue = (val: number, selectList: GeneralForListSchema[]) => {
    if (val == 0) return null;
    return selectList.filter(x => x.value == val)[0];
  };

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      try {
        const [resCount, resData] = await Promise.all([
          fetchN001Count(search.category, authState),
          fetchN001(search.category, authState, (currentPage - 1) * limitCount, limitCount)
        ]);

        if (isMounted) {
          setRecordCount(resCount.data);
          setData(resData.data);
          setGetData(false);
        }
        changeIsLoading();
      } catch (err) {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      }
    };
    if (getData) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, search, getData, currentPage]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchGeneralsForListMulti(authState, [UPLOAD_CATEGORY, UPLOAD_STATUS]).then((res: any) => {
        if (isMounted) {
          res.data[UPLOAD_CATEGORY].unshift(initialGeneralForList());
          setCategoryForSelect(res.data[UPLOAD_CATEGORY]);
          setStatusForSelect(res.data[UPLOAD_STATUS]);
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (statusForSelect.length == 0) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, statusForSelect.length]);

  const changeCurrentPage = (v: number) => {
    setGetData(true);
    setCurrentPage(v);
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Title title="アップロードデータ一覧" />
      <div className="plate">
        <div className="on_same_row justify" style={{ maxWidth: maxWidth }}>
          <div className="item">
            <Select
              value={getSelectValue(search.category, categoryForSelect)}
              options={categoryForSelect}
              className="C001_select"
              onChange={(e: any) => {
                setSearch({ ...search, category: e.value });
                setGetData(true);
              }}
              placeholder="カテゴリ"
            />
          </div>
          <CustomButton
            label="＋アップロードデータを追加"
            class={[CB_CLASS.RED]}
            onClick={() => history.push(getForHistoryPush(N002Url, [0]))}
          />
        </div>
      </div>
      <div>
        <PaginationTableLite
          currentPage={currentPage}
          setCurrentPage={changeCurrentPage}
          recordCount={recordCount}
          limit={limitCount}
          headers={["カテゴリ", "タイトル", "説明", "公開状況", "アップロード日", ""]}
          list={data.map((x, i) => (
            <tr key={i} onClick={() => history.push(getForHistoryPush(N002Url, [x.id]))}>
              <td className="item">{getSelectValue(x.category, categoryForSelect)?.label}</td>
              <td className="item">{x.title}</td>
              <td className="item w-25 text-wrap">{x.description || "-"}</td>
              <td className="item">{getSelectValue(x.status, statusForSelect)?.label}</td>
              <td className="item">{moment(x.upload_date).format("YYYY/MM/DD")}</td>
              <td className="item">{x.file_url &&
                <ToolTip text={getFileNameByUrl(x.file_url)} content={
                  <Download url={String(x.file_url)} />
                } />
              }</td>
            </tr>
          ))}
        />
      </div>
    </>
  );
};

export default N001;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { authSelector, userInfoSelector } from "../../redux/selectors/auth.selector";
import { FCFacilitiesSchema, FCStaffInfoSchema, GeneralForListSchema, L002Schema } from "../../generated";
import { fetchFcFacilities, fetchL002, fetchL002Count } from "../../services/api.service";
import { L003Url, getForHistoryPush } from "../../helper/url.helper";
import { HEADQUARTER_USER, STAFF } from "../../constants.js";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import Title from "../../components/Title/Title";
import Select from "../../components/Select/CustomSelect";
import CustomDate from "../../components/Calendar/CustomDate";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import { initialGeneralForList } from "../../models/general.model";
import moment from "moment";
import { useAuth } from "../../helper/auth.helper";
import { notifyError } from "../../helper/settings.helper";
import PaginationTableLite from "../../components/PaginationTableLite/PaginationTableLite";
import { fcListSelector } from "../../redux/selectors/list.selector";
import { listActions } from "../../redux/actions/list.actions";
import { FC_LIST_TYPE, getFcList, getFcListForRedux, getFcListFromRedux } from "../../helper/list.helper";

interface L002Prop {
	orderStatus: number;
}

const L002_TABLE: React.VFC<L002Prop> = ({
	orderStatus,
}) => {
	const { checkLoggedIn } = useAuth();
	const maxWidth = 900;
	const authState = useSelector(authSelector);
	const history = useHistory();
	const userInfo = useSelector(userInfoSelector);
	const facilityId = userInfo?.user_type == STAFF ? (userInfo as FCStaffInfoSchema)?.facilities[0].facility_id : 0;
	const [fcForSelect, setFcForSelect] = useState<GeneralForListSchema[]>([]);
	const [isLoading, changeIsLoading] = useLoadingIndicator();
	const [data, setData] = useState<L002Schema[]>([]);
	const [getData, setGetData] = useState(true);
	const [search, setSearch] = useState({
		facilityId: facilityId,
		begin_date: "",
		end_date: "",
	});
	const [currentPage, setCurrentPage] = useState(1);
	const [recordCount, setRecordCount] = useState(0);
	const limitCount = 13;
	const fcListState = useSelector(fcListSelector);
	const dispatch = useDispatch();

	useEffect(() => {
		let isMounted = true;
		const fetchData = async () => {
			changeIsLoading();
			try {
				const [resCount, resData] = await Promise.all([
					fetchL002Count(orderStatus, search.facilityId, search.begin_date, search.end_date, authState),
					fetchL002(orderStatus, search.facilityId, search.begin_date, search.end_date, authState, (currentPage - 1) * limitCount, limitCount)
				]);

				if (isMounted) {
					setRecordCount(resCount.data);
					setData(resData.data);
					setGetData(false);
				}
				changeIsLoading();
			} catch (err) {
				if (isMounted) {
					checkLoggedIn(err);
					notifyError("データの取得に失敗しました。");
					console.error(err);
				}
				changeIsLoading();
			}
		};
		if (getData) {
			fetchData();
		}
		return () => {
			isMounted = false;
		}
	}, [authState, changeIsLoading, checkLoggedIn, orderStatus, search, getData, currentPage]);

	useEffect(() => {
		if (userInfo?.user_type == HEADQUARTER_USER) {
			let isMounted = true;
			const fetchData = async () => {
				changeIsLoading();
				fetchFcFacilities(authState, "").then((res) => {
					if (isMounted) {
						setFcForSelect(getFcList(FC_LIST_TYPE.ADD_INIT, res.data));
						dispatch(listActions.makeFcList(getFcListForRedux(res.data)));
					}
					changeIsLoading();
				}).catch((err) => {
					if (isMounted) {
						checkLoggedIn(err);
						notifyError("データの取得に失敗しました。");
						console.error(err);
					}
					changeIsLoading();
				});
			};
			if (fcForSelect.length == 0) {
				if (fcListState.select_list.length == 0) {
					fetchData();
				} else {
					setFcForSelect(getFcListFromRedux(FC_LIST_TYPE.ADD_INIT, fcListState.select_list));
				}
			}
			return () => {
				isMounted = false;
			}
		}
	}, [authState, dispatch, changeIsLoading, checkLoggedIn, userInfo, fcForSelect.length, fcListState.select_list]);

	const getSelectValue = (val: number, selectList: GeneralForListSchema[]) => {
		if (val == 0) return null;
		return selectList.filter(x => x.value == val)[0];
	};

	const changeCurrentPage = (v: number) => {
		setGetData(true);
		setCurrentPage(v);
	};

	return (
		<>
			<LoadingSpinner isLoading={isLoading} />
			<Title title={authState.userInfo?.user_type === HEADQUARTER_USER ? "受注一覧" : "発注一覧"} />
			<div className="plate">
				<div className="on_same_row" style={{ maxWidth: maxWidth }}>
					{userInfo?.user_type === HEADQUARTER_USER &&
						<Select
							value={getSelectValue(search.facilityId, fcForSelect)}
							options={fcForSelect}
							className="L002_select mx-2"
							onChange={(e: any) => {
								setSearch({ ...search, facilityId: e.value });
								setGetData(true);
							}}
							placeholder="発注元施設"
						/>
					}
					<CustomDate val={search.begin_date} setter={(v: string) => {
						setSearch({ ...search, begin_date: v });
						setGetData(true);
					}} />
					<div className="wave_str">～</div>
					<CustomDate val={search.end_date} setter={(v: string) => {
						setSearch({ ...search, end_date: v });
						setGetData(true);
					}} />
				</div>
			</div>
			<PaginationTableLite
				currentPage={currentPage}
				setCurrentPage={changeCurrentPage}
				recordCount={recordCount}
				limit={limitCount}
				max_width={maxWidth}
				headers={authState.userInfo?.user_type === HEADQUARTER_USER ?
					["発注店舗", "発注日時", "合計数", "合計金額"]
					: ["発注日時", "合計数", "合計金額"]}
				list={data.map((x, i) => (
					<tr key={i} onClick={() => history.push(getForHistoryPush(L003Url, [x.id]))}>
						{authState.userInfo?.user_type === HEADQUARTER_USER &&
							<td className="item">{x.facility_name}</td>
						}
						<td className="item">{moment(x.created_at).format("YYYY/MM/DD")}</td>
						<td className="item">{x.num}</td>
						<td className="item">{x.price.toLocaleString() + " 円"}</td>
					</tr>
				))}
			/>
		</>
	);
};

export default L002_TABLE;

import "./Icon.scss";
import { MdSports } from "react-icons/md";
import { COLOR_BLUE } from "../../constants";

function Whistle(props: any) {
  return (
    <MdSports
      className="icon hover_blue"
      color={COLOR_BLUE}
      size={50}
      onClick={(event) => {
        event.stopPropagation();
        props.func();
      }} />
  );
};

export default Whistle;

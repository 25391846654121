import { useQuery } from "./query.helper";
import { useHistory } from "react-router-dom";

export const useTab = <T extends string>(defaultKey: T) => {
  const query = useQuery();
  const history = useHistory();
  const key = (query.get("tab") || defaultKey) as T;
  const setKey = (newKey: T) => {
    history.push({
      search: `?tab=${newKey}`,
    });
  };
  return { key, setKey };
};

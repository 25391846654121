import { FCFacilitiesSchema } from "../generated";

export interface SelectOption {
  value: number;
  label: string;
}

export const sampleTrainingData = [
  { value: 1, label: "導入" },
  { value: 2, label: "トレーニング1" },
  { value: 3, label: "トレーニング2" },
];

export const sampleReflection = [
  { value: 1, label: "モロー反射" },
  { value: 2, label: "恐怖麻痺反射" },
  { value: 3, label: "探索・吸啜反射" },
  { value: 4, label: "足底反射" },
  { value: 5, label: "手掌把握反射" },
  { value: 6, label: "脊髄ガラント反射" },
  { value: 7, label: "緊張性迷路反射" },
  { value: 8, label: "非対称性緊張性頸反射" },
  { value: 9, label: "対称性緊張性頸反射" },
];

export const sampleTrainingItemData = [
  { value: 1, label: "ボルダリング" },
  { value: 2, label: "手型・足型マット" },
  { value: 3, label: "ソフトハードル" },
  { value: 4, label: "お手玉" },
  { value: 5, label: "カラーマーカー" },
];

export const publicationStatus = [
  { value: 1, label: "公開" },
  { value: 2, label: "非公開" },
];

export const getStringSubscriptionContentStatus = (value: number) =>
  publicationStatus.find((d) => d.value === value)?.label;

export const getStringTrainingContentStatus = (value: number) =>
  publicationStatus.find((d) => d.value === value)?.label;

export const makeFacilitiesOptions = (facilityList: FCFacilitiesSchema[]) => {
  return facilityList.reduce((facilityAcc, facility) => {
    const facilitiesOption = {
      value: facility.id,
      label: facility.facility_name,
    };
    return [...facilityAcc, facilitiesOption];
  }, [] as SelectOption[]);
};

import "./CheckBox.scss";
import { Form } from "react-bootstrap";

function CheckBox(props: any) {
  return (
    <div className="mx-2 item">
      <Form.Check checked={props.checked || false} label={props.label} disabled={props.disabled} onChange={(e) => props.onChange(e)}/>
    </div>
  );
}

export default CheckBox;

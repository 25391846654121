import React from "react";
import { Button } from "react-bootstrap";
import "./CustomButton.scss";

export const SB_COLOR = {
  YELLOW: "button_yellow",
  BLUE: "button_blue",
  RED: "button_red",
  GREEN: "button_green",
  NONE: "button_none",
};

function SwitchButton(props: any) {
  return (
    <div>
      <Button
        className={"mx-2 custom_button " + (props.is_none ? SB_COLOR.NONE : props.color)}
        onClick={(event) => {
          event.stopPropagation();
          props.onClick();
        }}
        style={{ minWidth: 130 }}
      >
        {props.label}
      </Button>
    </div>
  );
};

export default SwitchButton;

import { validationMessage } from "../config/validationMessage";
import { AuditDocM002Schema } from "../generated";
import { ValidatedData, initialValidationError } from "./validate.model";
import { format } from "date-fns-tz";

export const AUDIT_DOC_TYPE = {
  STAFF_MTG_MINUTES: 1,                                          // 職員会議録
  DAILY_REPORT: 2,                                               // 業務日誌（日報）
  IMPORTANT_INFORMATION_MANUAL: 3,                               // 重要事項説明書
  CONTRACT_DOCUMENT: 4,                                          // 契約書
  ASSESSMENT_SHEET: 5,                                           // アセスメントシート
  INDIVIDUAL_SUPPORT_PLAN: 6,                                    // 個別支援計画
  SERVICE_REPRESENTATIVE_MTG_RECORDS: 7,                         // 支援会議記録
  MONITORING_RECORDS: 8,                                         // モニタリングの記録
  TRAINING_PLAN: 9,                                              // トレーニング計画
  TRAINING_RESULT: 10,                                           // 支援実施記録
  DAY_SERVICE_PROVISION_RECORD_SHEET: 11,                        // 放課後デイサービス提供実績記録票
  CHILD_DEVELOPMENT_SUPPORT_PROVISION_RECORD_SHEET: 12,          // 自動発達支援提供実績記録票
  ELECTRONIC_SIGNATURE_CONSENT_FORM: 13,                         // 書面の電子交付に関する承諾書
  ABSENTEEISM_RECORD_SHEET: 14,                                  // 欠席時対応加算記録表
  CHANGE_CONSENT_FORM_FOR_IMPORTANT_INFORMATION_MANUAL: 15,      // 重要事項説明の変更同意書
  INDIVIDUAL_SUPPORT_PLAN_IDEA: 16,                              // 個別支援計画案
  ACCIDENT_REPORT: 17,                                           // 事故報告書
  NEAR_MISS_REPORT: 18,                                          // ヒヤリハット
  TRAINING_REPORT: 19,                                           // 研修報告書
  ABUSE_PREVENTION_COMMITTEE_RECORDS: 20,                        // 虐待防止委員会記録
  COMMITTEE_RECORDS_FOR_PROPER_USE_OF_PHYSICAL_RESTRAINTS: 21,   // 身体拘束適正化のための委員会記録
  EVACUATION_DRILL_IMPLEMENTATION_RECORD: 22,                    // 避難訓練実施記録
  PHYSICAL_RESTRAINT_REPORT: 23,                                 // 身体拘束報告書
  ABUSE_REPORT: 24,                                              // 虐待報告書
  COMPLAINT_HANDLING_RECORD: 25,                                 // 苦情対応記録
  COUNSELING_AND_SUPPORT_RECORD: 26,                             // 相談支援記録
  CONSENT_FOR_THE_TREATMENT_OF_PERSONAL_DATA: 27,                // 個人情報取扱同意書
};

export const AUDIT_DOC_SELF_CREATE = [
  AUDIT_DOC_TYPE.STAFF_MTG_MINUTES,                                        // 職員会議録
  AUDIT_DOC_TYPE.DAILY_REPORT,                                             // 業務日誌（日報）
  AUDIT_DOC_TYPE.ABSENTEEISM_RECORD_SHEET,                                 // 欠席時対応加算記録表
  AUDIT_DOC_TYPE.ACCIDENT_REPORT,                                          // 事故報告書
  AUDIT_DOC_TYPE.NEAR_MISS_REPORT,                                         // ヒヤリハット
  AUDIT_DOC_TYPE.TRAINING_REPORT,                                          // 研修報告書
  AUDIT_DOC_TYPE.ABUSE_PREVENTION_COMMITTEE_RECORDS,                       // 虐待防止委員会記録
  AUDIT_DOC_TYPE.COMMITTEE_RECORDS_FOR_PROPER_USE_OF_PHYSICAL_RESTRAINTS,  // 身体拘束適正化のための委員会記録
  AUDIT_DOC_TYPE.EVACUATION_DRILL_IMPLEMENTATION_RECORD,                   // 避難訓練実施記録
  AUDIT_DOC_TYPE.PHYSICAL_RESTRAINT_REPORT,                                // 身体拘束報告書
  AUDIT_DOC_TYPE.ABUSE_REPORT,                                             // 虐待報告書
  AUDIT_DOC_TYPE.COMPLAINT_HANDLING_RECORD,                                // 苦情対応記録
  AUDIT_DOC_TYPE.COUNSELING_AND_SUPPORT_RECORD,                            // 相談支援記録
];

export const AUDIT_DOC_STATUS = {
  NOT_YET: 1,
  DONE: 2,
};

export const initialM002Model = (fcId: number): AuditDocM002Schema => ({
  id: 0,
  facility_id: fcId,
  child_id: undefined,
  doc_type: 0,
  make_date: format(new Date(), "yyyy-MM-dd"),
  status: 0,
  child_name: "",
  child_name_kana: "",
  facility_name: "",
});

export const validationM002Model = (
  data: AuditDocM002Schema
): ValidatedData<AuditDocM002Schema> => {
  const error = initialValidationError<AuditDocM002Schema>();
  let ok = true;

  if (!data.facility_id) {
    error.messageOf.facility_id = validationMessage.noEnteredTargetFacility;
    ok = false;
  }

  if (!data.doc_type) {
    error.messageOf.doc_type = validationMessage.noEnteredDocType;
    ok = false;
  }

  if (!data.make_date) {
    error.messageOf.make_date = validationMessage.emptyMakeDate;
    ok = false;
  }

  if (ok) {
    return {
      ok: true,
      validatedData: data,
    };
  }

  return {
    ok: false,
    validationError: error,
  };
};

import React, { useRef, useState } from "react";
import "./CustomImage.scss";

function CustomImage(props: any) {
  const imageRef = useRef<HTMLInputElement | null>(null);
  const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);
  const [imageUrl, setImageUrl] = useState("");

  const onChangeInputFile = (e: React.ChangeEvent<HTMLInputElement>, p: any) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event: any) => {
        setImageSrc(event.target.result);
      };
      reader.readAsDataURL(file);
      p.setUploadFile(file);
      setImageUrl(URL.createObjectURL(file));
    }
  };

  const readOnly = props.readOnly ? true : false;

  return (
    <>
      {props.uploadFile ? (
        <div className="mx-2 custom_image">
          <div className="custom_image_preview">
            <img src={`${imageSrc}`} onClick={() => window.open(imageUrl, "_blank")} style={{ cursor: "pointer" }} />
          </div>
          {readOnly == false &&
            <>
              <button
                className="custom_image_upload_input"
                onClick={() => imageRef.current?.click()}
              >
                画像を変更
              </button>
              <input
                type="file"
                ref={imageRef}
                hidden
                accept="image/*"
                onChange={(e) => onChangeInputFile(e, props)}
              />
            </>
          }
        </div>
      ) : (
        <div className="mx-2 custom_image border_dashed">
          {props.imageUrl ? (<>
            <div className="custom_image_preview">
              <img src={props.imageUrl} onClick={() => window.open(props.imageUrl, "_blank")} style={{ cursor: "pointer" }} />
            </div>
          </>) : (<>
            <div className="custom_image_upload">
              <img
                src={`${process.env.PUBLIC_URL}/img/image_sample.png`}
                height={45}
                width={60}
              />
            </div>
          </>)}
          {readOnly == false &&
            <>
              <button
                className="custom_image_upload_input"
                onClick={() => imageRef.current?.click()}
              >
                画像を選択
              </button>
              <input
                type="file"
                ref={imageRef}
                hidden
                accept="image/*"
                onChange={(e) => onChangeInputFile(e, props)}
              />
            </>
          }
        </div>
      )}
    </>
  );
}

export default CustomImage;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  initialSubscriptionContentModel,
  SubscriptionContentModel,
  validatonSubscriptionContentModel,
} from "../../models/subscriptionContents.model";
// import { createSubscriptionContent } from "../../services/api.service";
import BorderLine from "../../components/BorderLine";
import { authSelector } from "../../redux/selectors/auth.selector";
import { subscriptionUrl } from "../../helper/url.helper";
import "./AddSubscription.scss";
import { validationMessage } from "../../config/validationMessage";
import {notify, notifyError} from "../../helper/settings.helper";
import RequiredBadge from "../../components/RequiredBadge";
import {
  initialValidationError,
  ValidationError,
} from "../../models/validate.model";
import { useExpanded } from "react-table";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";

const AddSubscriptionContents = () => {
  const authState = useSelector(authSelector);
  const history = useHistory();
  const [isLoading, changeIsLoading] = useLoadingIndicator();

  const [contentCreate, setContentCreate] = useState<SubscriptionContentModel>(
    initialSubscriptionContentModel()
  );
  const [contentVideo, setContentVideo] = useState<File | undefined>(undefined);
  const [validationError, setValidationError] = useState<
    ValidationError<SubscriptionContentModel>
  >(initialValidationError());
  const [isValidated, setIsValidated] = useState<boolean>(false);

  const handleAddContent = () => {
    const validatedData = validatonSubscriptionContentModel(contentCreate);
    if (validatedData.ok) {
      setIsValidated(true);
      setValidationError(initialValidationError());
    } else {
      setIsValidated(true);
      setValidationError(validatedData.validationError);
    }
    if (validatedData.ok && contentVideo !== undefined) {
      changeIsLoading();
      // createSubscriptionContent(authState, contentCreate, contentVideo)
      //   .then((res) => {
      //     console.log(res.data);
      //     notify("コンテンツを追加しました");
      //     history.push(`${subscriptionUrl}`);
      //     changeIsLoading();
      //   })
      //   .catch((err) => {
      //     console.error(err);
      //     notifyError("コンテンツエラー！");
      //     changeIsLoading();
      //   });
    }
  };

  useEffect(() => {
    if (isValidated) {
      const validateData = validatonSubscriptionContentModel(contentCreate);
      if (validateData.ok) {
        setValidationError(initialValidationError());
      } else {
        setIsValidated(true);
        setValidationError(validateData.validationError);
      }
    }
  }, [contentCreate, isValidated]);

  return (
    <div className="add_content_container">
      <LoadingSpinner isLoading={isLoading} />
      <h1 className="add_content_title">サブスクリプション動画新規追加</h1>
      <div className="add_content_inner">
        <div className="add_content_item_wrapper">
          <div className="add_content_item">
            <div className="add_content_item_left">
              <p>タイトル</p>
              <RequiredBadge />
            </div>
            <div className="add_content_item_right">
              <input
                placeholder="タイトルを入力"
                className="add_content_item_input"
                value={contentCreate.title}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setContentCreate({ ...contentCreate, title: e.target.value });
                }}
              />
            </div>
          </div>
          <BorderLine />

          <div className="add_content_item">
            <div className="add_content_item_left">
              <p>サブスクリプション動画</p>
              <RequiredBadge />
            </div>
            <div className="add_content_item_right">
              <input
                placeholder="トレーニング動画名"
                className="edit_content_item_input"
                type="file"
                accept="video/*"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.currentTarget.files !== null) {
                    const file = e.currentTarget.files[0];
                    setContentVideo(file);
                  }
                }}
              />
            </div>
          </div>
          <BorderLine />
          <div className="add_content_item">
            <div className="add_content_item_left">
              <p>説明文</p>
              <RequiredBadge />
            </div>
            <div className="add_content_item_right">
              <textarea
                rows={30}
                cols={30}
                placeholder="動画の説明を記入"
                className="add_content_item_textarea"
                value={contentCreate.description}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setContentCreate({
                    ...contentCreate,
                    description: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <BorderLine />
        </div>
        <p className="add_content_error-message error-message">
          {validationError.messageOf.title !== undefined && (
            <span className="error-message">
              {validationError.messageOf.title}
            </span>
          )}
          {isValidated && contentVideo === undefined && (
            <span className="error-message">
              {validationMessage.emptyVideo}
            </span>
          )}
          {validationError.messageOf.description !== undefined && (
            <span className="error-message">
              {validationError.messageOf.description}
            </span>
          )}
        </p>
        <button className="add_content_item_button" onClick={handleAddContent}>
          追加
        </button>
      </div>
    </div>
  );
};

export default AddSubscriptionContents;

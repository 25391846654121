import { Tab, Tabs } from "react-bootstrap";
import { useTab } from "../../helper/tab.helper";
import L002_TABLE from "./L002_TABLE";

const TAB = {
	NEW: { value: "1", label: "未対応" },
	PROCESSING: { value: "2", label: "対応中" },
	CLOSED: { value: "3", label: "対応済" },
};

const L002 = () => {
	const { key, setKey } = useTab(TAB.NEW.value);
	return (
		<div className="plate">
			<Tabs
				defaultActiveKey={TAB.NEW.value}
				activeKey={key}
				className="mb-3 tab"
				onSelect={(k) => {
					if (k === TAB.NEW.value || k === TAB.PROCESSING.value || k === TAB.CLOSED.value) {
						setKey(k);
					}
				}}
				mountOnEnter={true}
			>
				<Tab eventKey={TAB.NEW.value} title={TAB.NEW.label}>
					<L002_TABLE orderStatus={Number(TAB.NEW.value)} />
				</Tab>
				<Tab eventKey={TAB.PROCESSING.value} title={TAB.PROCESSING.label}>
					<L002_TABLE orderStatus={Number(TAB.PROCESSING.value)} />
				</Tab>
				<Tab eventKey={TAB.CLOSED.value} title={TAB.CLOSED.label}>
					<L002_TABLE orderStatus={Number(TAB.CLOSED.value)} />
				</Tab>
			</Tabs>
		</div>
	);
};

export default L002;

type SaveStorageFunction = (key: string, value: string) => void;
type LoadStorageFunction = (key: string) => string | null;
type RemoveStorageFunction = (key: string) => void;

let saveStorageFunction: SaveStorageFunction | null = null;
let loadStorageFunction: LoadStorageFunction | null = null;
let removeStorageFunction: RemoveStorageFunction | null = null;

export const setSaveStorageFunction = (f: SaveStorageFunction) => {
  saveStorageFunction = f;
};

export const setLoadStorageFunction = (f: LoadStorageFunction) => {
  loadStorageFunction = f;
};

export const setRemoveStorageFunction = (f: RemoveStorageFunction) => {
  removeStorageFunction = f;
};

export const saveStorage = (key: string, value: string) => {
  if (saveStorageFunction !== null) {
    saveStorageFunction(key, value);
  }
};

export const loadStorage = (key: string): string | null => {
  if (loadStorageFunction !== null) {
    return loadStorageFunction(key);
  }

  return null;
};

export const removeStorage = (key: string) => {
  if (removeStorageFunction !== null) {
    removeStorageFunction(key);
  }
};

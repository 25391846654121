import "./J001.scss";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  GeneralForListSchema,
  J001ReceiveSchema,
} from "../../generated";
import { authSelector } from "../../redux/selectors/auth.selector";
import {
  fetchFcFacilities,
  fetchGeneralsForListMulti,
  fetchJ001Receive,
  fetchJ001ReceiveCount,
} from "../../services/api.service";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import Title from "../../components/Title/Title"
import { LIST_NOTIFICATION_STATUS } from "../../constants";
import { J003Url, getForHistoryPush } from "../../helper/url.helper";
import CustomDate from "../../components/Calendar/CustomDate";
import { format } from "date-fns-tz";
import { userInfoSelector } from "../../redux/selectors/auth.selector";
import { initialGeneralForList } from "../../models/general.model";
import Status, { STATUS_RED, STATUS_WHITE, StatusSchema } from "../../components/Status/Status";
import { NOTIFICATION_STATUS } from "../../models/notification.model";
import { useAuth } from "../../helper/auth.helper";
import moment from "moment";
import { notifyError } from "../../helper/settings.helper";
import PaginationTableLite from "../../components/PaginationTableLite/PaginationTableLite";
import { fcListSelector } from "../../redux/selectors/list.selector";
import { listActions } from "../../redux/actions/list.actions";
import { FC_LIST_TYPE, getFcList, getFcListForRedux, getFcListFromRedux } from "../../helper/list.helper";

interface iSearch {
  facility_id: number;
  begin_date: string;
  end_date: string;
}

export const headquarter_value = -1;

const J001_SEND = () => {
  const { checkLoggedIn } = useAuth();
  const maxWidth = 1300;
  const userInfo = useSelector(userInfoSelector);
  const authState = useSelector(authSelector);
  const history = useHistory();
  const [data, setData] = useState<J001ReceiveSchema[]>([]);
  const [getData, setGetData] = useState(true);
  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const [search, setSearch] = useState<iSearch>({
    facility_id: 0,
    begin_date: moment().subtract(1, "months").format("yyyy-MM-DD"),
    end_date: "",
  });
  const [fcForSelect, setFcForSelect] = useState<GeneralForListSchema[]>([]);
  const [notificationStatusList, setNotificationStatusList] = useState<GeneralForListSchema[]>([]);
  const status_list: StatusSchema[] = [
    { num: NOTIFICATION_STATUS.UNREAD, class: STATUS_RED },
    { num: NOTIFICATION_STATUS.READ, class: STATUS_WHITE },
  ];
  const [currentPage, setCurrentPage] = useState(1);
  const [recordCount, setRecordCount] = useState(0);
  const limitCount = 10;
  const fcListState = useSelector(fcListSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      const keywords = [LIST_NOTIFICATION_STATUS];
      fetchGeneralsForListMulti(authState, keywords).then(res => {
        if (isMounted) {
          const resGenData = res.data as any;
          keywords.map(x => resGenData[x].unshift(initialGeneralForList()))
          setNotificationStatusList(resGenData[LIST_NOTIFICATION_STATUS]);
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (notificationStatusList.length == 0) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, notificationStatusList.length]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchFcFacilities(authState, "").then(res => {
        if (isMounted) {
          setFcForSelect(getFcList(FC_LIST_TYPE.ADD_INIT_HEAD, res.data));
          dispatch(listActions.makeFcList(getFcListForRedux(res.data)));
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (fcForSelect.length == 0) {
      if (fcListState.select_list.length == 0) {
        fetchData();
      } else {
        setFcForSelect(getFcListFromRedux(FC_LIST_TYPE.ADD_INIT_HEAD, fcListState.select_list));
      }
    }
    return () => {
      isMounted = false;
    }
  }, [authState, dispatch, changeIsLoading, checkLoggedIn, fcForSelect.length, fcListState.select_list]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      try {
        const [resCount, resData] = await Promise.all([
          fetchJ001ReceiveCount(authState, search.begin_date, search.end_date),
          fetchJ001Receive(authState, search.begin_date, search.end_date, (currentPage - 1) * limitCount, limitCount)
        ]);

        if (isMounted) {
          setRecordCount(resCount.data);
          setData(resData.data);
          setGetData(false);
        }
        changeIsLoading();
      } catch (err) {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      }
    };
    if (getData) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, search, getData, currentPage]);

  const getFacilityName = (facility_id: number) => {
    if (fcForSelect.filter(y => y.value == facility_id).length == 0) return "";
    return fcForSelect.filter(y => y.value == facility_id)[0].label;
  };

  const changeCurrentPage = (v: number) => {
    setGetData(true);
    setCurrentPage(v);
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Title title="新着情報一覧" />
      <div className="plate">
        <div className="on_same_row" style={{ maxWidth: maxWidth }}>
          <CustomDate val={search.begin_date} setter={(v: string) => {
            setSearch({ ...search, begin_date: v });
            setGetData(true);
          }} />
          <div className="wave_str">～</div>
          <CustomDate val={search.end_date} setter={(v: string) => {
            setSearch({ ...search, end_date: v });
            setGetData(true);
          }} />
        </div>
      </div>
      <PaginationTableLite
        currentPage={currentPage}
        setCurrentPage={changeCurrentPage}
        recordCount={recordCount}
        limit={limitCount}
        max_width={maxWidth}
        headers={["タイトル", "内容", "差出人", "受信日時", "ステータス"]}
        list={data.map((x, i) => (
          <tr key={i} onClick={() => history.push(getForHistoryPush(J003Url, [x.id]))}>
            <td className="item hide_over" style={{ maxWidth: "200px" }}>
              {x.title}
            </td>
            <td className="item hide_over" style={{ maxWidth: "200px" }}>
              {x.body}
            </td>
            <td className="item">
              {getFacilityName(x.facility_id ? x.facility_id : headquarter_value)}
            </td>
            <td className="item">
              {format(new Date(x.created_at), "yyyy/MM/dd HH:mm:ss")}
            </td>
            <td className="item" valign="middle" align="center">
              <Status
                label_list={notificationStatusList}
                color_list={status_list}
                status={x.read_flg ? 2 : 1}
              />
            </td>
          </tr>
        ))}
      />
    </>
  );
};

export default J001_SEND;

import { FormControl } from "react-bootstrap";
import "./IncDec.scss";

function IncDecForRow(props: any) {
  return (
    <div className={"on_same_row" + (props.className ? " " + props.className : "")}>
      <button className="inc_dec_button"
        onClick={() => {
          if (props.val !== 0) {
            props.dec(props.index)
          }
        }}
      >
        -
      </button>
      <FormControl type="number" className="text_box" value={props.val} disabled />
      <button className="inc_dec_button"
        onClick={() => {
          if (props.val !== props.max_val) {
            props.inc(props.index)
          }
        }}
      >
        +
      </button>
    </div>
  );
};

export default IncDecForRow;

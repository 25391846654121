import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { useSelector } from "react-redux";
import { authSelector, userInfoSelector } from "../../redux/selectors/auth.selector";
import { Table } from "react-bootstrap";
import "./C002.scss";

import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";

import { FCStaffInfoSchema, ParentDetailSchema } from "../../generated";
import {
  deleteParent,
  fetchAddress,
  fetchParent,
  putParent,
  putParentImage,
} from "../../services/api.service";

import { initialParentDetailModel, validationParentModel, } from "../../models/parent.model";
import { ValidationError, initialValidationError, } from "../../models/validate.model";
import ConfirmModal from "../../components/ConfirmModal";

import Title from "../../components/Title/Title";
import CustomRow from "../../components/CustomRow/CustomRow";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import TextBox from "../../components/TextBox/TextBox";
import CustomTextarea from "../../components/CustomTextarea/CustomTextarea";
import CustomButton, { CB_ALIGN, CB_CLASS, CB_SIZE } from "../../components/Button/CustomButton";
import Pict, { SIZES } from "../../components/Pict/User";

import { C001Url, D002Url, getForHistoryPush } from "../../helper/url.helper";
import { notify, notifyError } from "../../helper/settings.helper";
import { calcAge } from "../../helper/calculation.helper";
import CustomUserIcon from "../../components/CustomImage/CustomUserIcon";
import { STAFF } from "../../constants";
import { useAuth } from "../../helper/auth.helper";

interface iC002 {
  parent_id: number;
}

const C002 = () => {
  const { checkLoggedIn } = useAuth();
  const location = useLocation<iC002>();
  const authState = useSelector(authSelector);
  const history = useHistory();
  const [isLoading, changeIsLoading] = useLoadingIndicator(undefined);
  const parentId = location.state.parent_id;
  const [data, setData] = useState<ParentDetailSchema>(initialParentDetailModel);
  const [image, setImage] = useState<File | undefined>(undefined);
  const [showRegister, setShowRegister] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const userInfo = useSelector(userInfoSelector);
  const facilityId = userInfo?.user_type == STAFF ? (userInfo as FCStaffInfoSchema)?.facilities[0].facility_id : 0;

  const [validationError, setValidationError] = useState<ValidationError<ParentDetailSchema>>(initialValidationError());
  const [isValidated, setIsValidated] = useState<boolean | undefined>(undefined);
  const [reload, setReload] = useState(true);

  const setAddressForAPI = () => {
    changeIsLoading();
    fetchAddress(data.post_code).then((result) => {
      result !== null && setData({ ...data, address_line: result })
      changeIsLoading();
    }).catch((err) => {
      console.warn(err);
      changeIsLoading();
    });
  }

  useEffect(() => {
    if (reload) {
      let isMounted = true;
      const fetchData = async () => {
        changeIsLoading();
        fetchParent(parentId, facilityId, authState).then((res) => {
          if (isMounted) {
            if (res.data) {
              setData(res.data);
              setReload(false);
            } else {
              history.goBack();
            }
          }
          changeIsLoading();
        }).catch((err) => {
          if (isMounted) {
            checkLoggedIn(err);
            notifyError("データの取得に失敗しました。");
            console.error(err);
          }
          changeIsLoading();
        });
      };
      fetchData();
      return () => {
        isMounted = false;
      }
    }
  }, [authState, parentId, facilityId, changeIsLoading, checkLoggedIn, history, reload]);

  const clickRegister = () => {
    const validatedData = validationParentModel(data);
    if (validatedData.ok) {
      changeIsLoading();
      putParent(authState, data).then((res) => {
        if (typeof res.data === "number") {
          if (image) {
            putParentImage(res.data, image, authState).then((_) => {
              setShowRegister(false);
              notify("保護者情報を更新しました。");
              changeIsLoading();
              history.push(getForHistoryPush(C001Url, [0]));
            }).catch((err) => {
              notifyError("登録に失敗しました。");
              console.error(err);
              changeIsLoading();
            });
          } else {
            setShowRegister(false);
            notify("保護者情報を更新しました。");
            changeIsLoading();
            history.push(getForHistoryPush(C001Url, [0]));
          }
        } else {
          setShowRegister(false);
          notifyError(String(res.data.msg));
          changeIsLoading();
        }
      }).catch((err) => {
        checkLoggedIn(err);
        notifyError("登録に失敗しました。");
        console.error(err);
        changeIsLoading();
      });
    } else {
      setShowRegister(false);
      notifyError("入力不正があります。");
      setIsValidated(true);
      setValidationError(validatedData.validationError);
    }
  };

  const clickDelete = () => {
    changeIsLoading();
    deleteParent(parentId, authState).then((_) => {
      setShowDelete(false);
      notify("保護者を削除しました。");
      changeIsLoading();
      history.push(getForHistoryPush(C001Url, [0]));
    }).catch((err) => {
      checkLoggedIn(err);
      notifyError("削除に失敗しました。");
      console.error(err);
      changeIsLoading();
    });
  };

  useEffect(() => {
    if (isValidated) {
      const validatedData = validationParentModel(data);
      if (validatedData.ok) {
        setValidationError(initialValidationError());
      } else {
        setIsValidated(true);
        setValidationError(validatedData.validationError);
      }
    }
  }, [data, isValidated]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Title title="保護者編集" />
      <div className="plate t_align_center">
        <CustomUserIcon uploadFile={image} setUploadFile={setImage} imageUrl={data.profile_pic_url} />
      </div>
      <div className="plate">
        <CustomRow
          title="名前"
          required={true}
          error_msg={validationError.messageOf.family_name}
          content={
            <>
              <TextBox
                type="text"
                placeholder="姓"
                value={data.family_name}
                onChange={(v: any) => setData({ ...data, family_name: v })}
              />
              <TextBox
                type="text"
                placeholder="名"
                value={data.given_name}
                onChange={(v: any) => setData({ ...data, given_name: v })}
              />
            </>
          }
        />
        <CustomRow
          title="名前（フリガナ）"
          required={false}
          error_msg={validationError.messageOf.family_name_kana}
          content={
            <>
              <TextBox
                type="text"
                placeholder="セイ"
                value={data.family_name_kana}
                onChange={(v: any) => setData({ ...data, family_name_kana: v })}
              />
              <TextBox
                type="text"
                placeholder="メイ"
                value={data.given_name_kana}
                onChange={(v: any) => setData({ ...data, given_name_kana: v })}
              />
            </>
          }
        />
        <CustomRow
          title="メールアドレス"
          required={true}
          error_msg={validationError.messageOf.email}
          content={
            <TextBox
              type="email"
              placeholder="メールアドレス"
              value={data.email}
              onChange={(v: any) => setData({ ...data, email: v })}
            />
          }
        />
        <CustomRow
          title="新しいパスワード"
          required={false}
          error_msg={validationError.messageOf.password}
          content={
            <TextBox
              type="password"
              placeholder="パスワード"
              value={data.password}
              onChange={(v: any) => setData({ ...data, password: v })}
            />
          }
        />
        <CustomRow
          title="パスワード（確認）"
          required={false}
          error_msg={validationError.messageOf.password_check}
          content={
            <TextBox
              type="password"
              placeholder="パスワード（確認）"
              value={data.password_check}
              onChange={(v: any) => setData({ ...data, password_check: v })}
            />
          }
        />
        <CustomRow
          title="お子様"
          required={false}
          on_same_row={false}
          content={
            <>
              <Table>
                <thead>
                  <tr className="t_align_center">
                    <th className="w-25">アイコン</th>
                    <th className="w-25">お子様名</th>
                    <th className="w-25">年齢</th>
                  </tr>
                </thead>
                <tbody>
                  {data.children?.map((x, i) => (
                    <tr
                      key={i}
                      onClick={() =>
                        history.push(getForHistoryPush(D002Url, [x.id, 0]))
                      }
                    >
                      <td className="item t_align_center">
                        <Pict url={x.profile_pic_url} size={SIZES.M} />
                      </td>
                      <td className="item t_align_center">
                        {x.family_name + x.given_name}
                      </td>
                      <td className="item t_align_center">
                        {x.birthday && calcAge(x.birthday)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <CustomButton
                label={"追加"}
                class={[CB_CLASS.BLUE]}
                onClick={() =>
                  history.push(getForHistoryPush(D002Url, [0, data.id]))
                }
                align={CB_ALIGN.RIGHT}
                size={CB_SIZE.S}
              />
            </>
          }
        />
        <CustomRow
          title="電話番号"
          required={false}
          error_msg={validationError.messageOf.phone_number}
          content={
            <>
              <TextBox
                type="tel"
                placeholder="電話番号（ハイフン無し）"
                value={data.phone_number}
                onChange={(v: any) => setData({ ...data, phone_number: v })}
              />
            </>
          }
        />
        <CustomRow
          title="郵便番号"
          required={false}
          error_msg={validationError.messageOf.post_code}
          content={
            <>
              <TextBox
                type="text"
                placeholder="郵便番号（ハイフン無し）"
                value={data.post_code}
                onChange={(v: any) => setData({ ...data, post_code: v })}
              />
              <CustomButton
                label={"住所自動入力"}
                size={CB_SIZE.S}
                class={[CB_CLASS.BLUE]}
                onClick={() => { setAddressForAPI() }}
              />
            </>
          }
        />
        <CustomRow
          title="住所"
          required={false}
          error_msg={validationError.messageOf.address_line}
          content={
            <TextBox
              type="text"
              placeholder="住所"
              value={data.address_line}
              onChange={(v: any) => setData({ ...data, address_line: v })}
            />
          }
        />
        <CustomRow
          title="家族構成"
          required={false}
          error_msg={validationError.messageOf.family_structure}
          content={
            <TextBox
              type="text"
              placeholder="父、母、子"
              value={data.family_structure}
              onChange={(v: any) => setData({ ...data, family_structure: v })}
            />
          }
        />
        <CustomRow
          title="緊急連絡先"
          required={false}
          error_msg={validationError.messageOf.emergency_contact_name1}
          content={
            <>
              <Table>
                <thead>
                  <tr className="t_align_center">
                    <th>名前</th>
                    <th>名前（フリガナ）</th>
                    <th>電話番号</th>
                    <th>続柄</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="item">
                      <TextBox
                        type="text"
                        placeholder="名前"
                        value={data.emergency_contact_name1}
                        onChange={(v: any) =>
                          setData({ ...data, emergency_contact_name1: v })
                        }
                      />
                    </td>
                    <td className="item">
                      <TextBox
                        type="text"
                        placeholder="名前（フリガナ）"
                        value={data.emergency_contact_kana1}
                        onChange={(v: any) =>
                          setData({ ...data, emergency_contact_kana1: v })
                        }
                      />
                    </td>
                    <td className="item">
                      <TextBox
                        type="tel"
                        placeholder="電話番号"
                        value={data.emergency_contact_tel1}
                        onChange={(v: any) =>
                          setData({ ...data, emergency_contact_tel1: v })
                        }
                      />
                    </td>
                    <td className="item">
                      <TextBox
                        type="text"
                        placeholder="続柄"
                        value={data.emergency_contact_relationship1}
                        onChange={(v: any) =>
                          setData({ ...data, emergency_contact_relationship1: v })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="item">
                      <TextBox
                        type="text"
                        placeholder="名前"
                        value={data.emergency_contact_name2}
                        onChange={(v: any) =>
                          setData({ ...data, emergency_contact_name2: v })
                        }
                      />
                    </td>
                    <td className="item">
                      <TextBox
                        type="text"
                        placeholder="名前（フリガナ）"
                        value={data.emergency_contact_kana2}
                        onChange={(v: any) =>
                          setData({ ...data, emergency_contact_kana2: v })
                        }
                      />
                    </td>
                    <td className="item">
                      <TextBox
                        type="tel"
                        placeholder="電話番号"
                        value={data.emergency_contact_tel2}
                        onChange={(v: any) =>
                          setData({ ...data, emergency_contact_tel2: v })
                        }
                      />
                    </td>
                    <td className="item">
                      <TextBox
                        type="text"
                        placeholder="続柄"
                        value={data.emergency_contact_relationship2}
                        onChange={(v: any) =>
                          setData({ ...data, emergency_contact_relationship2: v })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="item">
                      <TextBox
                        type="text"
                        placeholder="名前"
                        value={data.emergency_contact_name3}
                        onChange={(v: any) =>
                          setData({ ...data, emergency_contact_name3: v })
                        }
                      />
                    </td>
                    <td className="item">
                      <TextBox
                        type="text"
                        placeholder="名前（フリガナ）"
                        value={data.emergency_contact_kana3}
                        onChange={(v: any) =>
                          setData({ ...data, emergency_contact_kana3: v })
                        }
                      />
                    </td>
                    <td className="item">
                      <TextBox
                        type="tel"
                        placeholder="電話番号"
                        value={data.emergency_contact_tel3}
                        onChange={(v: any) =>
                          setData({ ...data, emergency_contact_tel3: v })
                        }
                      />
                    </td>
                    <td className="item">
                      <TextBox
                        type="text"
                        placeholder="続柄"
                        value={data.emergency_contact_relationship3}
                        onChange={(v: any) =>
                          setData({ ...data, emergency_contact_relationship3: v })
                        }
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </>
          }
        />
        <CustomRow
          title="備考"
          required={false}
          error_msg={validationError.messageOf.remarks}
          content={
            <>
              <CustomTextarea
                placeholder="備考"
                value={data.remarks}
                onChange={(v: any) => setData({ ...data, remarks: v })}
              />
            </>
          }
        />
        <div className="plate d-flex flex-column align_center">
          <CustomButton
            label="登録"
            class={[CB_CLASS.RED]}
            onClick={() => setShowRegister(true)}
          />
          <ConfirmModal
            target="登録"
            show={showRegister}
            setShow={setShowRegister}
            func={clickRegister}
            confirmText={"登録してよろしいでしょうか。"}
          />
          <CustomButton
            label="削除"
            class={[CB_CLASS.RED]}
            onClick={() => setShowDelete(true)}
          />
          <ConfirmModal
            target="削除"
            show={showDelete}
            setShow={setShowDelete}
            func={clickDelete}
            confirmText={"削除してよろしいでしょうか。"}
          />
        </div>
      </div>
    </>
  );
};

export default C002;

import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
// import { fetchSubscriptionContents } from "../../services/api.service";
import Table from "react-bootstrap/Table";
import "./SubscriptionList.scss";
import { authSelector } from "../../redux/selectors/auth.selector";
// import { SubscriptionVideoSchema } from "../../generated";
import { subscriptionUrl } from "../../helper/url.helper";
import { makeShortText } from "../../helper/text.helper";
import { ToastContainer } from "react-toastify";
import { getStringSubscriptionContentStatus } from "../../helper/data.helper";
import { makeStringDateWithSlash } from "../../helper/time.helper";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";

const SubscriptionList = () => {
  const authState = useSelector(authSelector);
  const history = useHistory();
  // const [contents, setContents] = useState<SubscriptionVideoSchema[]>([]);
  const [isLoading, changeIsLoading] = useLoadingIndicator();

  useEffect(() => {
    // changeIsLoading();
    // fetchSubscriptionContents(authState)
    //   .then((res: any) => {
    //     console.log(res.data);
    //     // setContents(res.data.videos);
    //     changeIsLoading();
    //   })
    //   .catch((err: any) => {
    //     console.error(err);
    //     changeIsLoading();
    //   });
  }, [authState, changeIsLoading]);

  return (
    <div className="program_sheet_list_container">
      <LoadingSpinner isLoading={isLoading} />
      <div className="program_sheet_list_header_area">
        <h1 className="program_sheet_list_title">サブスクリプション動画一覧</h1>
        <button
          className="program_sheet_list_add_button"
          onClick={() => history.push(`${subscriptionUrl}/add_content`)}
        >
          + 動画追加
        </button>
      </div>
      <div className="program_sheet_list_inner">
        <Table striped bordered hover className="program_sheet_list_table">
          <thead>
            <tr>
              <th>タイトル</th>
              <th>説明</th>
              <th>作成日</th>
              <th>公開状況</th>
            </tr>
          </thead>
          <tbody>
            {/* {contents.map((content, key) => (
              <tr
                key={key}
                onClick={() => {
                  history.push({
                    pathname: `${subscriptionUrl}/${content.id}/edit_content`,
                    state: content.id,
                  });
                }}
              >
                <td>{content.title}</td>
                <td>{makeShortText(content.description, 20)}</td>
                <td>{makeStringDateWithSlash(content.created_at)}</td>
                <td>{getStringSubscriptionContentStatus(content.status)}</td>
              </tr>
            ))} */}
          </tbody>
        </Table>
      </div>
      <ToastContainer />
    </div>
  );
};

export default SubscriptionList;

import { L004Schema, L008Schema } from "../generated";
import { checkFlgObj, checkValue } from "../helper/validation.helper";
import { ValidatedData, initialValidationError } from "./validate.model";

export const MIN_ORDER_NUM = 1;
export const DELIVERY_STATUS = "受発注発送状況";

export const initialL008Model = (): L008Schema => ({
  facility_id: 0,
  cart_items: [{
    equipment_id: 0,
    num: 0,
  }]
});

export const initialL004Model = (): L004Schema => ({
  id: 0,
  name: "",
  serial: "",
  color: undefined,
  size: undefined,
  price: 0,
  stock: undefined,
  details: undefined,
  image_url: undefined,
  order_form_url: undefined,
});

export const validationEquipmentModel = (
  data: L004Schema
): ValidatedData<L004Schema> => {
  const error = initialValidationError<L004Schema>();

  const checkFlg: checkFlgObj = {
    isOk: true,
  };

  error.messageOf.name = checkValue(checkFlg, "備品名", data.name);

  error.messageOf.serial = checkValue(checkFlg, "品番", data.serial);

  error.messageOf.price = checkValue(checkFlg, "値段", data.price);

  if (checkFlg.isOk) {
    return {
      ok: true,
      validatedData: data,
    };
  }

  return {
    ok: false,
    validationError: error,
  };
};
import "./J001.scss";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  FCStaffInfoSchema,
  GeneralForListSchema,
  J001SendSchema,
} from "../../generated";
import { authSelector } from "../../redux/selectors/auth.selector";
import {
  fetchFcFacilities,
  fetchGeneralsForListMulti,
  fetchJ001Send,
  fetchJ001SendCount,
} from "../../services/api.service";
import Select from "../../components/Select/CustomSelect";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import CustomButton, { CB_CLASS } from "../../components/Button/CustomButton"
import Title from "../../components/Title/Title"
import { HEADQUARTER_USER, LIST_NOTIFICATION_RECIPIENT_TYPE, LIST_RANK, STAFF } from "../../constants";
import { J002Url, getForHistoryPush } from "../../helper/url.helper";
import Label from "../../components/Label/Label";
import CustomDate from "../../components/Calendar/CustomDate";
import { userInfoSelector } from "../../redux/selectors/auth.selector";
import { initialGeneralForList } from "../../models/general.model";
import { NOTIFICATION_SEND_STATUS, RECIPIENT_TYPE } from "../../models/notification.model";
import { format } from "date-fns-tz";
import { useAuth } from "../../helper/auth.helper";
import Status, { STATUS_RED, STATUS_WHITE, StatusSchema } from "../../components/Status/Status";
import moment from "moment";
import { notifyError } from "../../helper/settings.helper";
import PaginationTableLite from "../../components/PaginationTableLite/PaginationTableLite";
import { fcListSelector } from "../../redux/selectors/list.selector";
import { listActions } from "../../redux/actions/list.actions";
import { FC_LIST_TYPE, getFcList, getFcListForRedux, getFcListFromRedux } from "../../helper/list.helper";

interface iSearch {
  from: number;
  to_type: number;
  to: number;
  begin_date: string;
  end_date: string;
}

const J001_SEND = () => {
  const { checkLoggedIn } = useAuth();
  const maxWidth = 1300;
  const userInfo = useSelector(userInfoSelector);
  const authState = useSelector(authSelector);
  const history = useHistory();
  const [data, setData] = useState<J001SendSchema[]>([]);
  const [getData, setGetData] = useState(true);
  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const fcId = userInfo?.user_type == STAFF ? (userInfo as FCStaffInfoSchema).facilities[0].facility_id : 0;
  const [search, setSearch] = useState<iSearch>({
    from: fcId,
    to_type: 0,
    to: 0,
    begin_date: moment().subtract(1, "months").format("yyyy-MM-DD"),
    end_date: "",
  });
  const [toTypeForSelect, setToTypeForSelect] = useState<GeneralForListSchema[]>([]);
  const [fcForSelect, setFcForSelect] = useState<GeneralForListSchema[]>([]);
  const [toRankForSelect, setToRankForSelect] = useState<GeneralForListSchema[]>([]);
  const status_list: StatusSchema[] = [
    { num: NOTIFICATION_SEND_STATUS.UNSENT, class: STATUS_RED },
    { num: NOTIFICATION_SEND_STATUS.SENT, class: STATUS_WHITE },
  ];
  const notificationStatusList: GeneralForListSchema[] = [
    { value: NOTIFICATION_SEND_STATUS.UNSENT, label: "未" },
    { value: NOTIFICATION_SEND_STATUS.SENT, label: "済" }
  ];
  const [currentPage, setCurrentPage] = useState(1);
  const [recordCount, setRecordCount] = useState(0);
  const limitCount = 10;
  const fcListState = useSelector(fcListSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      const keywords = [LIST_NOTIFICATION_RECIPIENT_TYPE, LIST_RANK];
      fetchGeneralsForListMulti(authState, keywords).then(res => {
        if (isMounted) {
          const resGenData = res.data as any;
          keywords.map(x => resGenData[x].unshift(initialGeneralForList()))
          setToTypeForSelect(resGenData[LIST_NOTIFICATION_RECIPIENT_TYPE]);
          setToRankForSelect(resGenData[LIST_RANK]);
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (toTypeForSelect.length == 0) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, toTypeForSelect.length]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchFcFacilities(authState, "").then(res => {
        if (isMounted) {
          setFcForSelect(getFcList(FC_LIST_TYPE.ADD_INIT, res.data));
          dispatch(listActions.makeFcList(getFcListForRedux(res.data)));
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (fcForSelect.length == 0) {
      if (fcListState.select_list.length == 0) {
        fetchData();
      } else {
        setFcForSelect(getFcListFromRedux(FC_LIST_TYPE.ADD_INIT, fcListState.select_list));
      }
    }
    return () => {
      isMounted = false;
    }
  }, [authState, dispatch, changeIsLoading, checkLoggedIn, fcForSelect.length, fcListState.select_list]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      try {
        const [resCount, resData] = await Promise.all([
          fetchJ001SendCount(authState, search.from, search.to_type, search.to, search.begin_date, search.end_date),
          fetchJ001Send(authState, search.from, search.to_type, search.to, search.begin_date, search.end_date, (currentPage - 1) * limitCount, limitCount)
        ]);

        if (isMounted) {
          setRecordCount(resCount.data);
          setData(resData.data);
          setGetData(false);
        }
        changeIsLoading();
      } catch (err) {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      }
    };
    if (getData) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, search, getData, currentPage]);

  const getSelectValue = (val: number, selectList: GeneralForListSchema[]) => {
    if (val == 0) return null;
    return selectList.filter(x => x.value == val)[0];
  };

  const changeCurrentPage = (v: number) => {
    setGetData(true);
    setCurrentPage(v);
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Title title="新着情報一覧" />
      <div className="plate">
        <div className="on_same_row justify" style={{ maxWidth: maxWidth }}>
          <div className="on_same_row">
            {userInfo?.user_type === HEADQUARTER_USER &&
              <Select
                value={getSelectValue(search.to_type, toTypeForSelect)}
                options={toTypeForSelect}
                className="J001_select item"
                onChange={(e: any) => {
                  const newSearch = { ...search };
                  newSearch.to_type = e.value;
                  newSearch.to = 0;
                  setSearch(newSearch);
                  setGetData(true);
                }}
                placeholder="宛先"
              />
            }
            {search.to_type == RECIPIENT_TYPE.FC &&
              <Select
                value={getSelectValue(search.to, fcForSelect)}
                options={fcForSelect}
                className="J001_select item"
                onChange={(e: any) => {
                  setSearch({ ...search, to: e.value });
                  setGetData(true);
                }}
                placeholder="施設"
              />
            }
            {search.to_type == RECIPIENT_TYPE.RANK &&
              <Select
                value={getSelectValue(search.to, toRankForSelect)}
                options={toRankForSelect}
                className="J001_select item"
                onChange={(e: any) => {
                  setSearch({ ...search, to: e.value });
                  setGetData(true);
                }}
                placeholder="役職"
              />
            }
            <CustomDate val={search.begin_date} setter={(v: string) => {
              setSearch({ ...search, begin_date: v });
              setGetData(true);
            }} />
            <div className="wave_str">～</div>
            <CustomDate val={search.end_date} setter={(v: string) => {
              setSearch({ ...search, end_date: v });
              setGetData(true);
            }} />
          </div>
          <CustomButton
            label="＋新着情報を追加"
            min_width={180}
            class={[CB_CLASS.RED]}
            onClick={() => history.push(getForHistoryPush(J002Url, [0]))}
          />
        </div>
      </div>
      <PaginationTableLite
        currentPage={currentPage}
        setCurrentPage={changeCurrentPage}
        recordCount={recordCount}
        limit={limitCount}
        max_width={maxWidth}
        headers={["タイトル", "内容", "ステータス", "送信日時"]}
        list={data.map((x, i) => (
          <tr key={i} onClick={() => history.push(getForHistoryPush(J002Url, [x.id]))}>
            <td className="item hide_over" style={{ maxWidth: "200px" }}>
              {x.title}
            </td>
            <td className="item hide_over" style={{ maxWidth: "200px" }}>
              {x.body}
            </td>
            <td className="item" valign="middle" align="center">
              <Status
                label_list={notificationStatusList}
                color_list={status_list}
                status={x.sent_at ? 2 : 1}
              />
            </td>
            <td className="item">
              {x.sent_at &&
                <Label value={format(new Date(x.sent_at), "yyyy/MM/dd HH:mm:ss")} />
              }
            </td>
          </tr>
        ))}
      />
    </>
  );
};

export default J001_SEND;

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { authSelector } from "../../redux/selectors/auth.selector";
import "./N003.scss";
import { N003Schema, GeneralForListSchema } from "../../generated";
import { fetchN003, fetchGeneralsForListMulti, fetchN003Count } from "../../services/api.service";
import { getFileNameByUrl } from "../../helper/url.helper";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import Download from "../../components/Icon/Download";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import Title from "../../components/Title/Title";
import moment from "moment";
import Select from "../../components/Select/CustomSelect";
import TextBox from "../../components/TextBox/TextBox";
import ToolTip from "../../components/ToolTip/ToolTip";
import { UPLOAD_CATEGORY } from "../../models/uploadData.model";
import { initialGeneralForList } from "../../models/general.model";
import { useAuth } from "../../helper/auth.helper";
import { notifyError } from "../../helper/settings.helper";
import PaginationTableLite from "../../components/PaginationTableLite/PaginationTableLite";

interface iN003 {
  category: number | undefined,
  title: string | undefined,
}

const N003 = () => {
  const { checkLoggedIn } = useAuth();
  const authState = useSelector(authSelector);
  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const [data, setData] = useState<N003Schema[]>([]);
  const [getData, setGetData] = useState(true);
  const [categoryForSelect, setCategoryForSelect] = useState<GeneralForListSchema[]>([]);
  const [search, setSearch] = useState<iN003>({
    category: undefined,
    title: undefined,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [recordCount, setRecordCount] = useState(0);
  const limitCount = 9;

  const getSelectValue = (val: number, selectList: GeneralForListSchema[]) => {
    if (val == 0) return null;
    return selectList.filter(x => x.value == val)[0];
  };

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      try {
        const [resCount, resData] = await Promise.all([
          fetchN003Count(search.category, search.title, authState),
          fetchN003(search.category, search.title, authState, (currentPage - 1) * limitCount, limitCount)
        ]);

        if (isMounted) {
          setRecordCount(resCount.data);
          setData(resData.data);
          setGetData(false);
        }
        changeIsLoading();
      } catch (err) {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      }
    };
    if (getData) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, search, getData, currentPage]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchGeneralsForListMulti(authState, [UPLOAD_CATEGORY]).then((res: any) => {
        if (isMounted) {
          res.data[UPLOAD_CATEGORY].unshift(initialGeneralForList());
          setCategoryForSelect(res.data[UPLOAD_CATEGORY]);
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (categoryForSelect.length == 0) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, categoryForSelect.length]);

  const changeCurrentPage = (v: number) => {
    setGetData(true);
    setCurrentPage(v);
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Title title="ダウンロードデータ一覧" />
      <div className="plate">
        <div className="on_same_row">
          <Select
            value={search.category && getSelectValue(search.category, categoryForSelect)}
            options={categoryForSelect}
            className="N003_form item"
            onChange={(e: any) => {
              setSearch({ ...search, category: e.value });
              setGetData(true);
            }}
            placeholder="カテゴリ"
          />
          <TextBox
            placeholder="タイトル"
            value={search.title}
            width="200px"
            onChange={(value: string) => {
              setSearch({ ...search, title: value });
              setGetData(true);
            }}
          />
        </div>
      </div>
      <div>
        <PaginationTableLite
          currentPage={currentPage}
          setCurrentPage={changeCurrentPage}
          recordCount={recordCount}
          limit={limitCount}
          headers={["カテゴリ", "タイトル", "説明", "アップロード日", ""]}
          list={data.map((x, i) => (
            <tr key={i}>
              <td className="item">{getSelectValue(x.category, categoryForSelect)?.label}</td>
              <td className="item">{x.title}</td>
              <td className="item w-25 text-wrap">{x.description || "-"}</td>
              <td className="item">{moment(x.upload_date).format("YYYY/MM/DD")}</td>
              <td className="item">{x.file_url &&
                <ToolTip text={getFileNameByUrl(x.file_url)} content={
                  <Download url={String(x.file_url)} />
                } />
              }</td>
            </tr>
          ))}
        />
      </div>
    </>
  );
};

export default N003;

import jaLocale from "date-fns/locale/ja";
import { format } from "date-fns-tz";

export const getFormatDate = (dateStr: string) => {
  return format(new Date(dateStr), "yyyy年M月d日（EE）", { locale: jaLocale });
};

export const getYearMonth = (date: Date) => {
  return format(date, "yyyy年M月", { locale: jaLocale });
};

export const getFormatDateTime = (date: Date) => {
  return format(date, "yyyy年M月d日（EE） H時m分", { locale: jaLocale });
};

export const getYyyyMmDd = (date: Date) => {
  return format(date, "yyyy-MM-dd", { locale: jaLocale });
}

export const getLastDayOfTheWeekStr = (date: Date) => {
  let newDate = new Date(date);
  newDate.setDate(date.getDate() + 6);
  return format(newDate,  "yyyy-MM-dd");
}

export const getLastDayOfTheWeek = (date: Date) => {
  let newDate = new Date(date);
  newDate.setDate(date.getDate() + 6);
  return newDate;
}

export const getLastDayOfTheMonth = (date: Date) => {
  let newDate = new Date(date);
  newDate.setMonth(date.getMonth() + 1, 0);
  return newDate;
}

export const getDayForDisplayStr = (date: Date) => {
  return format(date, "M/d(EE)", { locale: jaLocale });
};

export const getFirstDayOfTheMonth = (date: Date) => {
  let newDate = new Date(date);
  newDate.setDate(1);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
}
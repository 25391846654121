import React from "react";
import { Route, Redirect } from "react-router-dom";
import ContainerWithSidebar from "../ContainerWithSidebar/ContainerWithSidebar";

interface IAuthenticatedRoute {
  isLoggedIn: boolean;
  path: string;
  exact?: boolean;
  component: React.ComponentClass | React.FunctionComponent;
}

const AuthenticatedRoute: React.FC<IAuthenticatedRoute> = ({
  isLoggedIn,
  path,
  exact,
  component,
}) => {
  if (isLoggedIn) {
    return (
      <ContainerWithSidebar>
        <Route path={path} exact={exact} component={component} />
      </ContainerWithSidebar>
    );
  } else {
    return <Redirect to="/login" />;
  }
};

export default AuthenticatedRoute;

import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  setLoadStorageFunction,
  setRemoveStorageFunction,
  setSaveStorageFunction,
} from "./helper/env.helper";

// setSaveStorageFunction((key, str) => localStorage.setItem(key, str));
// setLoadStorageFunction((key) => localStorage.getItem(key));
// setRemoveStorageFunction((key) => localStorage.removeItem(key));

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import moment from "moment";

export const numberToTime = (num: number) => {
  const hour = Math.floor(num / 3600);
  const minute = (num % 3600) / 60;
  const hourString = ("00" + hour).slice(-2);
  const minuteString = minute === 0 ? "00" : minute.toString();
  return hourString + ":" + minuteString;
};

export const makeStringDateWithSlash = (stringDatetime: string) => {
  return moment(stringDatetime).format("YYYY/MM/DD");
};

export const makeStringDateTime = (stringDatetime: string) => {
  return moment(stringDatetime).format("YYYY/MM/DD HH:mm");
};

export const addMinutes = (timeStr: string, minutes: number) => {
  let [hours, mins] = timeStr.split(":").map(Number);
  
  mins += minutes;
  hours += Math.floor(mins / 60);
  mins %= 60;
  hours %= 24;
  
  return `${String(hours).padStart(2, "0")}:${String(mins).padStart(2, "0")}`;
}
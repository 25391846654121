import actionCreatorFactory from "typescript-fsa";
import { GeneralForListSchema, ProgramItemForSelectSchema } from "../../generated";

const actionCreator = actionCreatorFactory();

export enum ListActionTypes {
  MAKE_FC_LIST = "MAKE_FC_LIST",
  CLEAR_FC_LIST = "CLEAR_FC_LIST",
  MAKE_PG_LIST = "MAKE_PG_LIST",
  CLEAR_PG_LIST = "CLEAR_PG_LIST",
};

export const listActions = {
  makeFcList: actionCreator<GeneralForListSchema[]>(ListActionTypes.MAKE_FC_LIST),
  clearFcList: actionCreator(ListActionTypes.CLEAR_FC_LIST),
  makePgList: actionCreator<ProgramItemForSelectSchema[]>(ListActionTypes.MAKE_PG_LIST),
  clearPgList: actionCreator(ListActionTypes.CLEAR_PG_LIST),
};

import {
  K005Schema,
  SRFCreateSchema,
} from "../generated";
import { format } from "date-fns-tz";
import { TIME_LIST } from "../constants.js"

export const SCHEDULE_DETAIL_STATUS = {
  RESERVED: 1,
  APPROVED: 2,
  CANCELED: 3,
  TEMPORARY: 4,
};

export const RESERVATION_FRAME_NOTIFIED_STATUS = {
  NOT_NOTIFIED: 1,
  NOTIFIED: 2,
};

/*
予約枠に紐づくレコード
*/
export const initialScheduleReservationFrameForScheduleListSchema = (): K005Schema => ({
  id: 0,
  facility_id: 0,
  date: "-",
  start_time: "-",
  end_time: "-",
  num: 0,
  schedule_details: []
});

export const initialScheduleReservationFrameCreateSchema = (): SRFCreateSchema => ({
  date: format(new Date(), "yyyy-MM-dd"),
  start_time: TIME_LIST[0],
  end_time: TIME_LIST[3],
  num: 0
});
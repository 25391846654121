import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { authSelector } from "../../redux/selectors/auth.selector";
import { D006Schema, GeneralForListSchema } from "../../generated";
import { INTERVIEWED, NOT_INTERVIEWED, NOT_UPLOADED, STATUS_ASM_INTERVIEW, STATUS_ASM_UPLOAD, UPLOADED, initialD006Model } from "../../models/assessment.model";
import { fetchAssessments, fetchGeneralsForListMulti } from "../../services/api.service";
import { D009Url, getForHistoryPush } from "../../helper/url.helper";
import { calcAge } from "../../helper/calculation.helper";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import ChildInfoTop from "../../components/ChildInfoTop/ChildInfoTop";
import CustomButton, { CB_CLASS } from "../../components/Button/CustomButton";
import Download from "../../components/Icon/Download";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import PaginationTable from "../../components/PaginationTable/PaginationTable";
import Status, { STATUS_RED, STATUS_WHITE, StatusSchema } from "../../components/Status/Status";
import Title from "../../components/Title/Title";
import moment from "moment";
import { useAuth } from "../../helper/auth.helper";
import { handleDownload } from "../../helper/download.helper.";
import { notifyError } from "../../helper/settings.helper";

interface iD006 {
  child_id: number;
}

const D006 = () => {
  const { checkLoggedIn } = useAuth();
  const maxWidth = 1300;
  const authState = useSelector(authSelector);
  const history = useHistory();
  const location = useLocation<iD006>();
  const childId = location.state.child_id;
  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const [data, setData] = useState<D006Schema>(initialD006Model);
  const [isUploadedForSelect, setIsUploadedForSelect] = useState<GeneralForListSchema[]>([]);
  const [isInterviewedForSelect, setIsInterviewedForSelect] = useState<GeneralForListSchema[]>([]);

  const status_list_is_interviewed: StatusSchema[] = [
    { num: NOT_INTERVIEWED, class: STATUS_RED },
    { num: INTERVIEWED, class: STATUS_WHITE },
  ];
  const status_list_is_uploaded: StatusSchema[] = [
    { num: NOT_UPLOADED, class: STATUS_RED },
    { num: UPLOADED, class: STATUS_WHITE },
  ];

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchAssessments(authState, childId).then((res) => {
        if (isMounted) {
          setData(res.data);
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      })
    };
    fetchData();
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, childId]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchGeneralsForListMulti(authState, [STATUS_ASM_INTERVIEW, STATUS_ASM_UPLOAD]).then((res: any) => {
        if (isMounted) {
          setIsUploadedForSelect(res.data[STATUS_ASM_INTERVIEW]);
          setIsInterviewedForSelect(res.data[STATUS_ASM_UPLOAD]);
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    fetchData();
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Title title="アセスメントシート一覧" />
      <div className="plate">
        <div className="d-flex justify" style={{ maxWidth: maxWidth }}>
          <ChildInfoTop
            user_type={authState.userInfo?.user_type}
            profile_pic_url={data.child_profile_pic_url}
            id={childId}
            name={data.child_name}
            name_kana={data.child_name_kana}
            facility_name={data.facility_name}
            no_underbar={true}
          />
          <div className="on_same_row">
            <CustomButton
              label="入力用ファイルダウンロード"
              class={[CB_CLASS.BLUE]}
              onClick={() => (data.template_url && handleDownload(data.template_url))}
            />
            <CustomButton
              label="＋アセスメントシートを追加"
              class={[CB_CLASS.RED]}
              onClick={() => history.push(getForHistoryPush(D009Url, [0, childId]))}
            />
          </div>
        </div>

      </div>
      <div>
        <PaginationTable
          limit={9}
          headers={["年齢", "面談ステータス", "アップロードステータス", "回答日", "更新日", ""]}
          list={data.assessments.map((x, i) => (
            <tr key={i} onClick={() => history.push(getForHistoryPush(D009Url, [x.id, childId]))}>
              <td className="item">{data.birthday && calcAge(data.birthday, x.answer_date)}</td>
              <td className="item" align="center">
                <Status
                  label_list={isUploadedForSelect}
                  color_list={status_list_is_interviewed}
                  status={x.is_interviewed ? 2 : 1}
                  max_width={200}
                />
              </td>
              <td className="item" align="center">
                <Status
                  label_list={isInterviewedForSelect}
                  color_list={status_list_is_uploaded}
                  status={x.is_uploaded ? 2 : 1}
                  max_width={200}
                />
              </td>
              <td className="item">{x.answer_date && moment(x.answer_date).format("YYYY/MM/DD")}</td>
              <td className="item">{moment(x.updated_at).format("YYYY/MM/DD")}</td>
              <td className="item">{x.is_uploaded && <Download url={x.file_url} />}</td>
            </tr>
          ))}
        />
      </div>
    </>
  );
};

export default D006;

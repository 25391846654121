import Delete from "../Icon/Delete";
import "./CustomVideo.scss";
import React from "react";

function CustomVideo(props: any) {
  return (
    <div className="custom_video_right">
      {props.url ? (
        <>
          {props.preparing ? (
            <p>動画準備中です。</p>
          ) : (
            <div className="on_same_row" style={{ alignItems: "end" }}>
              <video
                controls
                src={props.url}
                className="custom_video"
              />
              <Delete func={props.deleteVideo} />
            </div>
          )}
          <p>動画を変更する場合はファイルを選択</p>
        </>
      ) : (
        <p>動画がありません。</p>
      )}
      <input
        className="custom_video_input"
        type="file"
        accept="video/*"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (e.currentTarget.files !== null) {
            const file = e.currentTarget.files[0];
            props.setVideo(file);
          }
        }}
      />
    </div>
  );
};

export default CustomVideo;

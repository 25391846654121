import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import "./C001.scss";
import {
  ParentForParentListSchema,
  FCStaffInfoSchema,
  GeneralForListSchema,
  FCFacilitiesSchema,
  ChildForParentListSchema,
} from "../../generated";
import { initialGeneralForList } from "../../models/general.model";
import { HEADQUARTER_USER, STAFF } from "../../constants";
import C003 from "../C003/C003";
import { authSelector, userInfoSelector } from "../../redux/selectors/auth.selector";
import { fetchC001, fetchC001Count, fetchFcFacilities } from "../../services/api.service";
import { C002Url, D002Url, getForHistoryPush } from "../../helper/url.helper";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import CustomButton, { CB_CLASS, CB_ALIGN } from "../../components/Button/CustomButton";
import Title from "../../components/Title/Title";
import TextBox from "../../components/TextBox/TextBox";
import CheckBox from "../../components/CheckBox/CheckBox";
import Pict, { SIZES } from "../../components/Pict/User";
import Select from "../../components/Select/CustomSelect";
import Label from "../../components/Label/Label";
import { makeStringDateWithSlash } from "../../helper/time.helper";
import ToolTip from "../../components/ToolTip/ToolTip";
import { useAuth } from "../../helper/auth.helper";
import { notifyError } from "../../helper/settings.helper";
import PaginationTableLite from "../../components/PaginationTableLite/PaginationTableLite";
import { fcListSelector } from "../../redux/selectors/list.selector";
import { listActions } from "../../redux/actions/list.actions";
import { FC_LIST_TYPE, getFcList, getFcListForRedux, getFcListFromRedux } from "../../helper/list.helper";

interface C001Location {
  facility_id: number;
}

const C001 = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { checkLoggedIn } = useAuth();
  const maxWidth = 1300;
  const authState = useSelector(authSelector);
  const userInfo = useSelector(userInfoSelector);
  const history = useHistory();
  const location = useLocation<C001Location>();
  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const [parents, setParents] = useState<ParentForParentListSchema[]>([]);
  const facilityId = userInfo?.user_type == STAFF ? (userInfo as FCStaffInfoSchema)?.facilities[0].facility_id : (location.state.facility_id || 0);
  const [fcForSelect, setFcForSelect] = useState<GeneralForListSchema[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [getData, setGetData] = useState(true);
  const [search, setSearch] = useState({
    facilityId: facilityId,
    endFlg: false,
    parentName: "",
    childName: "",
  });
  const [recordCount, setRecordCount] = useState(0);
  const limitCount = 9;
  const fcListState = useSelector(fcListSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      try {
        const [resCount, resData] = await Promise.all([
          fetchC001Count(authState, search.facilityId, search.endFlg, search.parentName, search.childName),
          fetchC001(authState, search.facilityId, search.endFlg, search.parentName, search.childName, (currentPage - 1) * limitCount, limitCount)
        ]);

        if (isMounted) {
          setRecordCount(resCount.data);
          setParents(resData.data);
          setGetData(false);
        }
        changeIsLoading();
      } catch (err) {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      }
    };
    if (getData) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, search, getData, currentPage]);

  useEffect(() => {
    if (userInfo?.user_type == HEADQUARTER_USER) {
      let isMounted = true;
      const fetchData = async () => {
        changeIsLoading();
        fetchFcFacilities(authState, "").then((res) => {
          if (isMounted) {
            setFcForSelect(getFcList(FC_LIST_TYPE.ADD_INIT, res.data));
            dispatch(listActions.makeFcList(getFcListForRedux(res.data)));
          }
          changeIsLoading();
        }).catch((err) => {
          if (isMounted) {
            checkLoggedIn(err);
            notifyError("データの取得に失敗しました。");
            console.error(err);
          }
          changeIsLoading();
        });
      };
      if (fcForSelect.length == 0) {
        if (fcListState.select_list.length == 0) {
          fetchData();
        } else {
          setFcForSelect(getFcListFromRedux(FC_LIST_TYPE.ADD_INIT, fcListState.select_list));
        }
      }
      return () => {
        isMounted = false;
      }
    }
  }, [authState, dispatch, changeIsLoading, checkLoggedIn, userInfo, fcForSelect.length, fcListState.select_list]);

  const getSelectValue = (val: number, selectList: GeneralForListSchema[]) => {
    if (val == 0) return null;
    return selectList.filter(x => x.value == val)[0];
  };

  const getJoinDate = (children: ChildForParentListSchema[]) => {
    if (children.length == 0) return "";
    let join_date = children[0].join_date;
    children.map(x => {
      if (join_date > x.join_date) join_date = x.join_date;
    })
    return makeStringDateWithSlash(join_date);
  };

  const changeCurrentPage = (v: number) => {
    setGetData(true);
    setCurrentPage(v);
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Title title="保護者一覧" />
      <div className="plate">
        <div className="display_flex justify" style={{ maxWidth: maxWidth }}>
          <div className="on_same_row">
            {userInfo?.user_type == HEADQUARTER_USER &&
              <div className="item">
                <Select
                  value={getSelectValue(search.facilityId, fcForSelect)}
                  options={fcForSelect}
                  className="C001_select"
                  onChange={(e: any) => {
                    setSearch({ ...search, facilityId: e.value });
                    setGetData(true);
                  }}
                  placeholder="施設"
                />
              </div>
            }
            <TextBox
              placeholder="保護者名"
              value={search.parentName}
              onChange={(value: string) => {
                setSearch({ ...search, parentName: value });
                setGetData(true);
              }}
            />
            <TextBox
              placeholder="お子様名"
              value={search.childName}
              onChange={(value: string) => {
                setSearch({ ...search, childName: value });
                setGetData(true);
              }}
            />
            <CheckBox
              checked={search.endFlg}
              onChange={(value: any) => {
                setSearch({ ...search, endFlg: value.target.checked });
                setGetData(true);
              }}
              label="退会済の保護者を表示する"
            />
          </div>
          {userInfo?.user_type == STAFF &&
            <CustomButton
              label="＋保護者を追加"
              class={[CB_CLASS.RED]}
              align={CB_ALIGN.RIGHT}
              onClick={() => setShowModal(true)}
            />
          }
        </div>

      </div>
      <div>
        <PaginationTableLite
          currentPage={currentPage}
          setCurrentPage={changeCurrentPage}
          recordCount={recordCount}
          limit={limitCount}
          headers={userInfo?.user_type == HEADQUARTER_USER
            ? ["所属施設", "アイコン", "保護者名", "お子様のアイコン", "入会日"]
            : ["アイコン", "保護者名", "お子様のアイコン", "入会日"]
          }
          list={parents.map((x, i) => (
            <tr key={i}
              onClick={() => history.push(getForHistoryPush(C002Url, [x.id]))}
            >
              {userInfo?.user_type == HEADQUARTER_USER &&
                <td className="item">
                  {x.children.map(y => y.facility_name).filter((y, index, s) => s.indexOf(y) === index).map((facility_name, j) =>
                    <div key={j}>{facility_name}</div>
                  )}
                </td>
              }
              <td className="item">
                <Pict url={x.profile_pic_url} size={SIZES.M} />
              </td>
              <td className="item">
                {x.is_temp &&
                  <span style={{ color: "red" }}>● </span>
                }
                {x.family_name + x.given_name}
              </td>
              <td>
                <div className="item on_same_row justify_center">
                  {x.children?.map((y, j) => (
                    <div key={j}>
                      <ToolTip text={y.family_name + y.given_name} content={
                        <Pict
                          url={y.profile_pic_url}
                          size={SIZES.M}
                          func={() => history.push(getForHistoryPush(D002Url, [y.id]))}
                        />
                      } />
                    </div>
                  ))}
                </div>
              </td>
              <td className="item">
                <Label value={getJoinDate(x.children)} />
              </td>
            </tr>
          ))}
        />
      </div>
      <C003 showModal={showModal} setShowModal={setShowModal} setReload={setGetData} />
    </>
  );
};

export default C001;

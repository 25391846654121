import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../redux/selectors/auth.selector";
import { fetchUserInfoAction } from "../../redux/actions/auth.actions";
import "./F004.scss";
import {
  FCFacilitiesSchema,
  FCStaffAllInfoSchema,
  FCStaffInfoSchema,
  GeneralForListSchema,
  Result,
  TokenSchema,
  TokenWithIdSchema,
} from "../../generated";
import { ValidationError, initialValidationError } from "../../models/validate.model";
import ConfirmModal from "../../components/ConfirmModal";
import { initialStaffModel, validationStaffModel } from "../../models/staff.model";
import { initialGeneralForList } from "../../models/general.model";
import Select from "../../components/Select/CustomSelect";
import Title from "../../components/Title/Title";
import CustomRow from "../../components/CustomRow/CustomRow";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import TextBox from "../../components/TextBox/TextBox";
import CustomButton, { CB_CLASS } from "../../components/Button/CustomButton";
import CustomUserIcon from "../../components/CustomImage/CustomUserIcon";
import { raiseAction } from "../../helper/api.helper";
import { F003Url, getForHistoryPush } from "../../helper/url.helper";
import { notify, notifyError } from "../../helper/settings.helper";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import {
  deleteStaff,
  fetchFcFacilities,
  fetchGeneralsForList,
  fetchStaff,
  putStaff,
  putStaffImage,
} from "../../services/api.service";
import { useAuth } from "../../helper/auth.helper";
import { HEADQUARTER_USER, STAFF } from "../../constants";
import Label from "../../components/Label/Label";
import { fcListSelector } from "../../redux/selectors/list.selector";
import { listActions } from "../../redux/actions/list.actions";
import { FC_LIST_TYPE, getFcList, getFcListForRedux, getFcListFromRedux } from "../../helper/list.helper";

interface iF004 {
  staff_id: number;
}

const F004 = () => {
  const { checkLoggedIn } = useAuth();
  const dispatch = useDispatch();
  const location = useLocation<iF004>();
  const [staff_id, setStaff_id] = useState(location.state.staff_id);
  const history = useHistory();
  const [isLoading, changeIsLoading] = useLoadingIndicator(undefined);
  const authState = useSelector(authSelector);
  const fc = authState.userInfo?.user_type == STAFF ? (authState.userInfo as FCStaffInfoSchema)?.facilities[0] : null;
  const fcId = fc ? fc.facility_id : 0;
  const fcName = fc ? fc.facility_name : "";
  const [data, setData] = useState<FCStaffAllInfoSchema>(initialStaffModel(fcId));
  const [image, setImage] = useState<File | undefined>(undefined);
  const [rankForSelect, setRankForSelect] = useState<GeneralForListSchema[]>([]);
  const [validationError, setValidationError] = useState<ValidationError<FCStaffAllInfoSchema>>(initialValidationError());
  const [isValidated, setIsValidated] = useState<boolean | undefined>(undefined);
  const [showRegister, setShowRegister] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [fcForSelect, setFcForSelect] = useState<GeneralForListSchema[]>([]);
  const [reload, setReload] = useState(true);
  const fcListState = useSelector(fcListSelector);

  const getSelectValue = (val: number, selectList: GeneralForListSchema[]) => {
    if (val == 0) return;
    return selectList.filter((x) => x.value == val)[0];
  };

  useEffect(() => {
    if (staff_id && reload) {
      let isMounted = true;
      const fetchData = async () => {
        changeIsLoading();
        fetchStaff(staff_id, authState).then((res) => {
          if (isMounted) {
            setData(res.data);
            setReload(false);
          }
          changeIsLoading();
        }).catch((err) => {
          if (isMounted) {
            checkLoggedIn(err);
            notifyError("データの取得に失敗しました。");
            console.error(err);
          }
          changeIsLoading();
        });
      };
      fetchData();
      return () => {
        isMounted = false;
      }
    }
  }, [authState, staff_id, changeIsLoading, checkLoggedIn, reload]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchFcFacilities(authState, "").then((res) => {
        if (isMounted) {
          setFcForSelect(getFcList(FC_LIST_TYPE.DEFAULT, res.data));
          dispatch(listActions.makeFcList(getFcListForRedux(res.data)));
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (fcForSelect.length == 0) {
      if (fcListState.select_list.length == 0) {
        fetchData();
      } else {
        setFcForSelect(getFcListFromRedux(FC_LIST_TYPE.DEFAULT, fcListState.select_list));
      }
    }
    return () => {
      isMounted = false;
    }
  }, [authState, dispatch, changeIsLoading, checkLoggedIn, fcForSelect.length, fcListState.select_list]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchGeneralsForList(authState, "役職").then((res) => {
        if (isMounted) {
          const forSelect: GeneralForListSchema[] = [];
          res.data.map((x: GeneralForListSchema, i) => {
            forSelect.push({
              value: x.value,
              label: x.label,
            });
          });
          setRankForSelect(forSelect);
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (rankForSelect.length == 0) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, rankForSelect.length]);

  const success_register = (token: TokenSchema | undefined) => {
    setShowRegister(false);
    notify("スタッフ情報を登録しました。");
    changeIsLoading();
    if (token) {
      dispatch(fetchUserInfoAction(token, true))
    } else {
      history.goBack();
    }
  }

  const clickRegister = () => {
    const validatedData = validationStaffModel(data);
    if (validatedData.ok) {
      changeIsLoading();
      putStaff(authState, data).then((res) => {
        raiseAction(
          res.data,
          (v: TokenWithIdSchema) => {
            if (image) {
              putStaffImage(v.id, image, authState).then((_) => {
                success_register(v.token);
              }).catch((err) => {
                notifyError("登録に失敗しました。");
                console.error(err);
                changeIsLoading();
              });
            } else {
              success_register(v.token);
            }
          },
          (v: Result) => {
            notifyError(String(v.msg));
            setShowRegister(false);
            changeIsLoading();
          }
        );
      }).catch((err) => {
        checkLoggedIn(err);
        notifyError("登録に失敗しました。");
        console.error(err);
        changeIsLoading();
      });
    } else {
      setShowRegister(false);
      notifyError("入力不正があります。");
      setIsValidated(true);
      setValidationError(validatedData.validationError);
    }
  };

  const clickDelete = () => {
    changeIsLoading();
    deleteStaff(staff_id, authState)
      .then((_) => {
        setShowDelete(false);
        notify("スタッフを削除しました。");
        changeIsLoading();
        history.goBack();
      })
      .catch((err) => {
        notifyError("削除に失敗しました。");
        console.error(err);
        changeIsLoading();
      });
  };

  useEffect(() => {
    if (isValidated) {
      const validatedData = validationStaffModel(data);
      if (validatedData.ok) {
        setValidationError(initialValidationError());
      } else {
        setIsValidated(true);
        setValidationError(validatedData.validationError);
      }
    }
  }, [data, isValidated]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Title title={staff_id ? "スタッフ編集" : "スタッフ登録"} />
      <div className="plate t_align_center">
        <CustomUserIcon uploadFile={image} setUploadFile={setImage} imageUrl={data.profile_pic_url} />
      </div>
      <div className="plate">
        <CustomRow
          title="名前"
          required={true}
          error_msg={validationError.messageOf.family_name}
          content={
            <>
              <TextBox
                type="text"
                placeholder="姓"
                value={data.family_name}
                onChange={(v: any) => setData({ ...data, family_name: v })}
              />
              <TextBox
                type="text"
                placeholder="名"
                value={data.given_name}
                onChange={(v: any) => setData({ ...data, given_name: v })}
              />
            </>
          }
        />
        <CustomRow
          title="名前（フリガナ）"
          required={true}
          error_msg={validationError.messageOf.family_name_kana}
          content={
            <>
              <TextBox
                type="text"
                placeholder="セイ"
                value={data.family_name_kana}
                onChange={(v: any) => setData({ ...data, family_name_kana: v })}
              />
              <TextBox
                type="text"
                placeholder="メイ"
                value={data.given_name_kana}
                onChange={(v: any) => setData({ ...data, given_name_kana: v })}
              />
            </>
          }
        />
        <CustomRow
          title="施設"
          required={authState.userInfo?.user_type === HEADQUARTER_USER ? true : false}
          error_msg={validationError.messageOf.facility_id_list}
          content={
            authState.userInfo?.user_type === HEADQUARTER_USER ?
              <Select
                options={fcForSelect}
                value={getSelectValue(data.facility_id_list[0], fcForSelect)}
                className="F004_select"
                placeholder="施設"
                onChange={(e: any) => setData((prev: any) => ({
                  ...prev,
                  facility_id_list: [e.value],
                }))
                }
              />
              : <Label value={fcName} />
          }
        />
        <CustomRow
          title="役職"
          required={true}
          error_msg={validationError.messageOf.rank}
          content={
            <Select
              options={rankForSelect}
              value={getSelectValue(data.rank, rankForSelect)}
              className="F004_select"
              placeholder="役職"
              onChange={(e: any) => setData({ ...data, rank: e.value })}
            />
          }
        />
        <CustomRow
          title="研修内容（資格）"
          required={false}
          error_msg={validationError.messageOf.training}
          content={
            <TextBox
              type="text"
              placeholder="研修内容（資格）"
              value={data.training ? data.training : ""}
              onChange={(v: any) => setData({ ...data, training: v })}
            />
          }
        />
        <CustomRow
          title="メールアドレス"
          required={true}
          error_msg={validationError.messageOf.email}
          content={
            <TextBox
              type="email"
              placeholder="メールアドレス"
              value={data.email}
              onChange={(v: any) => setData({ ...data, email: v })}
            />
          }
        />
        {(authState.userInfo?.user_type === HEADQUARTER_USER && staff_id) &&
          <>
            <CustomRow
              title="新しいパスワード"
              required={false}
              error_msg={validationError.messageOf.password}
              content={
                <TextBox
                  type="password"
                  placeholder="パスワード"
                  value={data.password ? data.password : ""}
                  onChange={(v: any) => setData({ ...data, password: v })}
                />
              }
            />
            <CustomRow
              title="パスワード（確認）"
              required={false}
              error_msg={validationError.messageOf.password_check}
              content={
                <TextBox
                  type="password"
                  placeholder="パスワード（確認）"
                  value={data.password_check ? data.password_check : ""}
                  onChange={(v: any) => setData({ ...data, password_check: v })}
                />
              }
            />
          </>
        }

        <div className="plate d-flex flex-column align_center">
          <CustomButton
            label="登録"
            class={[CB_CLASS.RED]}
            onClick={() => setShowRegister(true)}
          />
          <ConfirmModal
            target="登録"
            show={showRegister}
            setShow={setShowRegister}
            func={clickRegister}
            confirmText={"登録してよろしいでしょうか。"}
          />
          {staff_id &&
            <>
              <CustomButton
                label="削除"
                class={[CB_CLASS.RED]}
                onClick={() => setShowDelete(true)}
              />
              <ConfirmModal
                target="削除"
                show={showDelete}
                setShow={setShowDelete}
                func={clickDelete}
                confirmText={"削除してよろしいでしょうか。"}
              />
            </>
          }
        </div>
      </div>
    </>
  );
};

export default F004;

import React from "react";
import { Oval } from "react-loader-spinner";
import "./LoadingSpinner.scss";
interface LoadingProps {
  isLoading: boolean;
}

const LoadingSpinner: React.VFC<LoadingProps> = ({ isLoading }) => {
  return (
    <>
      {isLoading && (
        <div className="loading">
          <Oval height="100" width="100" ariaLabel="loading" color="white" />
        </div>
      )}
    </>
  );
};

export default LoadingSpinner;

import { FCFacilitiesSchema, GeneralForListSchema, ProgramItemForSelectSchema } from "../generated";
import { initialGeneralForList } from "../models/general.model";
import { makeListModel, makePgListModel } from "../models/list.model";

export const makeList = (
  newList: GeneralForListSchema[],
) => {
  const listObj = makeListModel(newList);
  return listObj;
};

export const makePgList = (
  newList: ProgramItemForSelectSchema[],
) => {
  const listObj = makePgListModel(newList);
  return listObj;
};

export const getFcListForRedux = (
  fcList: FCFacilitiesSchema[],
) => {
  const forSelect: GeneralForListSchema[] = [];
  fcList.map(x => {
    forSelect.push({
      value: x.id,
      label: x.facility_name
    });
  });
  return forSelect;
};

export const FC_LIST_TYPE = {
  DEFAULT: 0,
  ADD_INIT: 1,
  ADD_HEAD: 2,
  ADD_INIT_HEAD: 3,
  ADD_ALL_FC: 4,
};

export const getFcList = (
  fcListType: number,
  fcList: FCFacilitiesSchema[],
) => {
  return getFcListFromRedux(fcListType, getFcListForRedux(fcList));
};

export const getFcListFromRedux = (
  fcListType: number,
  fcList: GeneralForListSchema[],
) => {
  let list: GeneralForListSchema[] = [];
  fcList.map(x => list.push(x));
  switch (fcListType) {
    case FC_LIST_TYPE.ADD_INIT:
      list.unshift(initialGeneralForList());
      break;
    case FC_LIST_TYPE.ADD_HEAD:
      list.unshift({ value: 0, label: "本部" });
      break;
    case FC_LIST_TYPE.ADD_INIT_HEAD:
      list.unshift({ value: -1, label: "本部" });
      list.unshift(initialGeneralForList());
      break;
    case FC_LIST_TYPE.ADD_ALL_FC:
      list.unshift({ value: 0, label: "全施設" });
      break;
    default:
      break;
  }
  return list;
};
import User, { SIZES } from "../../components/Pict/User"
import { useHistory } from "react-router";
import { D002Url, getForHistoryPush } from "../../helper/url.helper";
import { HEADQUARTER_USER } from "../../constants";

function ChildInfoTop(props: any) {
  const history = useHistory();
  return (
    <div className={props.no_underbar ? "row" : "custom_row row"}>
      <div className="on_same_row item">
        <User
          size={SIZES.L}
          url={props.profile_pic_url}
          func={() => history.push(getForHistoryPush(D002Url, [props.id]))}
        />
        <div className="item">
          {props.user_type === HEADQUARTER_USER && <><label>{props.facility_name}</label><br /></>}
          <label style={{ fontSize: 10 }}>{props.name_kana}</label><br />
          <label>{props.name}</label><br />
          {props.container}
        </div>
      </div>
    </div>
  );
}

export default ChildInfoTop;

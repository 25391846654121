import React, { useEffect, useState } from "react";
import { addMonths, format, getDate, getDay, subMonths } from "date-fns";
import "./K003.scss";
import { FLG_HOLIDAY, FLG_NOT_TARGET_MONTH, FLG_REGULAR_HOLIDAY, FLG_WORKING_DAY_AT_TARGET_MONTH } from "../../models/schedule.model";
import { getCalendarArray } from "../../helper/schedule.helper";
import { useLocation } from "react-router";
import { getState } from "../../helper/page.helper";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import Title from "../../components/Title/Title";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { MdNavigateNext } from "react-icons/md";
import { MdNavigateBefore } from "react-icons/md";
import Label from "../../components/Label/Label";
import CustomButton, { CB_ALIGN, CB_CLASS } from "../../components/Button/CustomButton";
import ConfirmModal from "../../components/ConfirmModal";
import Panel, { PANEL_COLOR } from "../../components/Panel/Panel";
import { fetchHolidaySettingTheMonth, putK003 } from "../../services/api.service";
import { useSelector } from "react-redux";
import { authSelector } from "../../redux/selectors/auth.selector";
import { K003Schema } from "../../generated";
import { useAuth } from "../../helper/auth.helper";
import { notify, notifyError } from "../../helper/settings.helper";
import { FLG_SATURDAY, FLG_SUNDAY } from "../../constants";
import FacilityName from "../../components/FacilityName/FacilityName";

interface K003Location {
  facility_Id: number,
  target_date: string,
  holiday: string,
}

const K003: React.VFC = () => {
  const location = useLocation<K003Location>();
  const { checkLoggedIn } = useAuth();
  const authState = useSelector(authSelector);
  const state = getState(location);
  const fcId = state.facility_id;
  const [targetDate, setTargetDate] = useState(new Date(state.target_date));
  const holiday = String(state.holiday);
  const calendar = getCalendarArray(targetDate);
  const holidayArray = (holiday.slice(-1) + holiday.slice(0, -1)).split("");
  const [getData, setGetData] = useState(true);
  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const [showRegister, setShowRegister] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [changeDateList, setChangeDateList] = useState<string[]>([]);
  const [registeredDate, setRegisteredDate] = useState<K003Schema[]>([]);
  const [isNextMonth, setIsNextMonth] = useState<boolean>();

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchHolidaySettingTheMonth(authState, fcId, targetDate.getFullYear(), targetDate.getMonth() + 1).then((res) => {
        if (isMounted) {
          setRegisteredDate(res.data);
          setChangeDateList([]);
          setGetData(false);
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (getData) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, fcId, targetDate, getData]);

  const selectPanelColor = (date: Date) => {
    let panelColor = PANEL_COLOR.NONE
    const foundRegisteredDate = registeredDate.find((oneDay) => oneDay.date == format(date, "yyyy-MM-dd"));
    const foundChangeDate = changeDateList.find((oneDay) => oneDay == format(date, "yyyy-MM-dd"))
    if (foundRegisteredDate != undefined) {
      if (foundRegisteredDate.status == FLG_HOLIDAY) panelColor = PANEL_COLOR.RED;
    } else {
      if (date.getDay() == FLG_SATURDAY || date.getDay() == FLG_SUNDAY) panelColor = PANEL_COLOR.RED;
    }

    if (foundChangeDate != undefined) {
      panelColor = panelColor ==  PANEL_COLOR.NONE ? PANEL_COLOR.RED : PANEL_COLOR.NONE;
    }
    return panelColor
  };

  const handleClick = (date: Date) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    setChangeDateList(prevList => {
      if (prevList.includes(formattedDate)) {
        return prevList.filter(item => item !== formattedDate);
      } else {
        return [...prevList, formattedDate];
      }
    });
  };

  const judgeDateStatus = (date: Date) => {
    let dateStatus = FLG_WORKING_DAY_AT_TARGET_MONTH
    if (date.getMonth() + 1 != targetDate.getMonth() + 1) {
      dateStatus = FLG_NOT_TARGET_MONTH;
    } else if (holidayArray[date.getDay()] == FLG_HOLIDAY) {
      dateStatus = FLG_REGULAR_HOLIDAY;
    }
    return dateStatus
  };

  const clickRegister = async () => {
    changeIsLoading();
    try {
      await putK003(authState, fcId, targetDate.getFullYear(), targetDate.getMonth() + 1, changeDateList);
      setShowRegister(false);
      notify("祝日設定を更新しました。");
      setChangeDateList([]);
      setGetData(true);
    } catch (err) {
      checkLoggedIn(err);
      setShowRegister(false);
      notifyError("登録に失敗しました。");
    } finally {
      changeIsLoading();
    }
  };

  const changeCurrentMonth = (pushMoveToNextMonth: boolean) => {
    if (changeDateList.length > 0) {
      setIsNextMonth(pushMoveToNextMonth);
      setShowConfirm(true);
    } else {
      pushMoveToNextMonth ? setTargetDate((current) => addMonths(current, 1)) : setTargetDate((current) => subMonths(current, 1));
      setGetData(true);
    }
  };

  const acceptChangeMonth = () => {
    setShowConfirm(false);
    setChangeDateList([]);
    isNextMonth ? setTargetDate((current) => addMonths(current, 1)) : setTargetDate((current) => subMonths(current, 1));
    setGetData(true);
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Title title="祝日設定" />
      <FacilityName fcId={fcId} authState={authState}/>
      <div className="plate">
        <Label value="休日の時間帯でトレーニングを実施する日にちを選択してください。" />
          <div className="on_same_row">
            <MdNavigateBefore size="40px" className="move_calendar" onClick={() => {
              changeCurrentMonth(false);
            }}/>
            <MdNavigateNext size="40px" className="move_calendar" onClick={() => {
              changeCurrentMonth(true);}
            }/>
            <h1 className="title-year-month">{format(targetDate, "y年M月")}</h1>
          </div>
          <div className="calender-main">
            <table className="table-calender">
              <thead>
                <tr className="headers">
                  <th className="header_title">日</th>
                  <th className="header_title">月</th>
                  <th className="header_title">火</th>
                  <th className="header_title">水</th>
                  <th className="header_title">木</th>
                  <th className="header_title">金</th>
                  <th className="header_title">土</th>
                </tr>
              </thead>
              <tbody>
                {calendar.map((weekRow, rowNum) => (
                  <tr key={rowNum}>
                    {weekRow.map((date) => {

                      return (
                        <td key={getDay(date)} className="item-day">
                          {judgeDateStatus(date) != FLG_WORKING_DAY_AT_TARGET_MONTH ?
                            <Panel
                              label={getDate(date)}
                              disabled={true}
                              color={judgeDateStatus(date) == FLG_NOT_TARGET_MONTH ? PANEL_COLOR.MORE_GRAY : PANEL_COLOR.GRAY}
                            />
                          :
                            <Panel
                              label={getDate(date)}
                              disabled={false}
                              color={selectPanelColor(date)}
                              onClick={() => handleClick(date)}
                            />
                          }
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        <CustomButton label={"登録"} class={[CB_CLASS.RED, "my-3"]} align={CB_ALIGN.CENTER} onClick={() => setShowRegister(true)} />
        <ConfirmModal target="登録" show={showRegister} setShow={setShowRegister} func={clickRegister}
          confirmText={"登録してよろしいでしょうか。"}
        />
        <ConfirmModal target="月度の移動" show={showConfirm} setShow={setShowConfirm} func={acceptChangeMonth}
          confirmText={"当月の登録未完了です。変更を破棄して月度を移動してよろしいでしょうか。"}
        />
      </div>
      
    </>
  );
};

export default K003;

import "./RadioButton.scss";
import { Form } from "react-bootstrap";

function RadioButton(props: any) {
  return (
    <Form className="item on_same_row ">
      {props.values.map((x: any, i: number) => (
        <div key={i}>
          <Form.Check
            inline
            label={x.label}
            name={props.name}
            type="radio"
            value={x.value}
            checked={props.selectValue == x.value}
            onChange={(e: any) => props.onChange(e.target.value)}
          />
        </div>
      ))}
    </Form>
  );
}

export default RadioButton;

import React from "react";
import Download from "../Icon/Download";
import Label from "../Label/Label";
import { getFileNameByUrl } from "../../helper/url.helper";

function FileUploader(props: any) {
  const url = props.file ? URL.createObjectURL(props.file) : props.url;
  let fileName = "";
  if (props.url) {
    fileName = getFileNameByUrl(props.url);
  } else if (props.file) {
    fileName = props.file.name;
  }

  const setFile = (file: File) => {
    props.setMsg("");
    if (/[() ]/.test(file.name)) {
      props.setMsg("ファイル名に括弧、空白を含むものはアップロード出来ません。");
      return false
    }
    return true;
  };

  return (
    <div className="item on_same_row">
      {!props.noChangeFile &&
        <input
          type="file"
          accept="*/*"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.currentTarget.files !== null && e.currentTarget.files.length > 0) {
              let file = e.currentTarget.files[0];
              if (setFile(file)) {
                props.setFile(file);
              } else {
                props.setFile(undefined);
                e.currentTarget.value = "";
              }
            } else {
              props.setFile(undefined);
            }
          }}
        />
      }
      {props.url &&
        <div className="on_same_row">
          <Download url={url} />
          <Label value={fileName} />
        </div>
      }
    </div>
  );
};

export default FileUploader;

import {
  P002Schema,
} from "../generated";
import { validationMessage } from "../config/validationMessage";
import { initialValidationError, ValidatedData } from "./validate.model";

export const PROGRAM_ITEM_STATUS_PUBLISHED = 1;
export const PROGRAM_ITEM_STATUS_UNPUBLISHED = 2;

export const initialP002Model = (): P002Schema => ({
  title: "",
  step: 0,
  status: PROGRAM_ITEM_STATUS_UNPUBLISHED,
  training_tools: [],
  reflections: [],
  video_preparing: true,
});


export const validationProgramItemModel = (
  programItem: P002Schema
): ValidatedData<P002Schema> => {
  const error = initialValidationError<P002Schema>();
  let ok = true;

  if (programItem.title === "") {
    error.messageOf.title = validationMessage.emptyTitle;
    ok = false;
  }
  if (programItem.step === 0) {
    error.messageOf.step = validationMessage.emptyStep;
    ok = false;
  }
  if (programItem.reflections.filter(x => x.reflection > 0).length === 0) {
    error.messageOf.reflections = validationMessage.emptyReflection;
    ok = false;
  }
  if (programItem.status === 0) {
    error.messageOf.status = validationMessage.upSelectedPublicationStatus;
    ok = false;
  }

  if (ok) {
    return {
      ok: true,
      validatedData: programItem,
    };
  }

  return {
    ok: false,
    validationError: error,
  };
};

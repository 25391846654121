import "./J002.scss";
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import {
  FCStaffInfoSchema,
  GeneralForListSchema, J002Schema, WebNotificationRecipientSchema,
} from "../../generated";
import { authSelector } from "../../redux/selectors/auth.selector";
import {
  deleteJ002,
  fetchFcFacilities,
  fetchGeneralsForListMulti,
  fetchJ002,
  putJ002,
  putJ002Image,
  sendJ002,
} from "../../services/api.service";
import Select from "../../components/Select/CustomSelect";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import CustomButton, { CB_CLASS, CB_SIZE } from "../../components/Button/CustomButton"
import Title from "../../components/Title/Title"
import { HEADQUARTER_USER, LIST_NOTIFICATION_RECIPIENT_TYPE, LIST_RANK, STAFF } from "../../constants";
import CustomRow from "../../components/CustomRow/CustomRow";
import TextBox from "../../components/TextBox/TextBox";
import { ValidationError, initialValidationError } from "../../models/validate.model";
import ConfirmModal from "../../components/ConfirmModal";
import { userInfoSelector } from "../../redux/selectors/auth.selector";
import Label from "../../components/Label/Label";
import CustomImage from "../../components/CustomImage/CustomImage";
import { RECIPIENT_TYPE, initialJ002ModelForHeadQuarterUser, initialJ002ModelForStaff, validationJ002Model } from "../../models/notification.model";
import CustomTextarea from "../../components/CustomTextarea/CustomTextarea";
import { notify, notifyError } from "../../helper/settings.helper";
import { useAuth } from "../../helper/auth.helper";
import { format } from "date-fns-tz";
import { fcListSelector } from "../../redux/selectors/list.selector";
import { listActions } from "../../redux/actions/list.actions";
import { FC_LIST_TYPE, getFcList, getFcListForRedux, getFcListFromRedux } from "../../helper/list.helper";

interface iLocation {
  notification_make_id: number;
}

const J002 = () => {
  const { checkLoggedIn } = useAuth();
  const location = useLocation<iLocation>();
  const [notificationMakeId, setNotificationMakeId] = useState(location.state.notification_make_id);
  const authState = useSelector(authSelector);
  const userInfo = useSelector(userInfoSelector);
  const fcId = userInfo?.user_type == STAFF ? (userInfo as FCStaffInfoSchema).facilities[0].facility_id : undefined;
  const fcName = userInfo?.user_type == STAFF ? (userInfo as FCStaffInfoSchema).facilities[0].facility_name : "本部";
  const [data, setData] = useState<J002Schema>(userInfo?.user_type == STAFF ? initialJ002ModelForStaff(fcId, fcName) : initialJ002ModelForHeadQuarterUser(fcId, fcName));
  const [fcForSelect, setFcForSelect] = useState<GeneralForListSchema[]>([]);
  const [recipientTypeForSelect, setRecipientTypeForSelect] = useState<GeneralForListSchema[]>([]);
  const [recipientSelected, setRecipientSelected] = useState(0);
  const [rankForSelect, setRankForSelect] = useState<GeneralForListSchema[]>([]);
  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const [validationError, setValidationError] = useState<ValidationError<J002Schema>>(initialValidationError());
  const [isValidated, setIsValidated] = useState<boolean | undefined>(undefined);
  const [showRegister, setShowRegister] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showSend, setShowSend] = useState(false);
  const history = useHistory();
  const [uploadFile, setUploadFile] = useState<File | undefined>(undefined);
  const [reload, setReload] = useState(true);
  const fcListState = useSelector(fcListSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (notificationMakeId && reload) {
      let isMounted = true;
      const fetchData = async () => {
        changeIsLoading();
        fetchJ002(authState, notificationMakeId).then((res) => {
          if (isMounted) {
            setData(res.data);
            setReload(false);
          }
          changeIsLoading();
        }).catch((err) => {
          if (isMounted) {
            checkLoggedIn(err);
            notifyError("データの取得に失敗しました。");
            console.error(err);
          }
          changeIsLoading();
        });
      };
      fetchData();
      return () => {
        isMounted = false;
      }
    }
  }, [authState, changeIsLoading, checkLoggedIn, notificationMakeId, reload]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      const keywords = [LIST_NOTIFICATION_RECIPIENT_TYPE, LIST_RANK];
      fetchGeneralsForListMulti(authState, keywords).then(res => {
        if (isMounted) {
          const resGenData = res.data as any;
          setRecipientTypeForSelect(resGenData[LIST_NOTIFICATION_RECIPIENT_TYPE].filter((x: GeneralForListSchema) => x.label !== "施設"));
          setRankForSelect(resGenData[LIST_RANK]);
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (rankForSelect.length == 0) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, rankForSelect.length]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchFcFacilities(authState, "").then(res => {
        if (isMounted) {
          setFcForSelect(getFcList(FC_LIST_TYPE.ADD_ALL_FC, res.data));
          dispatch(listActions.makeFcList(getFcListForRedux(res.data)));
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (fcForSelect.length == 0) {
      if (fcListState.select_list.length == 0) {
        fetchData();
      } else {
        setFcForSelect(getFcListFromRedux(FC_LIST_TYPE.ADD_ALL_FC, fcListState.select_list));
      }
    }
    return () => {
      isMounted = false;
    }
  }, [authState, dispatch, changeIsLoading, checkLoggedIn, fcForSelect.length, fcListState.select_list]);

  useEffect(() => {
    if (isValidated) {
      const validatedData = validationJ002Model(data);
      if (validatedData.ok) {
        setValidationError(initialValidationError());
      } else {
        setIsValidated(true);
        setValidationError(validatedData.validationError);
      }
    }
  }, [data, isValidated]);

  const clickRegister = () => {
    if (userInfo?.user_type == STAFF) {
      setFcValueForStaff();
    }
    const validatedData = validationJ002Model(data);
    if (validatedData.ok) {
      changeIsLoading();
      putJ002(authState, data).then((res) => {
        if (uploadFile) {
          putJ002Image(authState, res.data, uploadFile).then((_) => {
            setShowRegister(false);
            notify("新着情報を登録しました。");
            changeIsLoading();
            setNotificationMakeId(res.data);
            setReload(true);
          }).catch((err) => {
            notifyError("登録に失敗しました。");
            console.error(err);
            changeIsLoading();
          });
        } else {
          setShowRegister(false);
          notify("新着情報を登録しました。");
          changeIsLoading();
          setNotificationMakeId(res.data);
          setReload(true);
        }
      }).catch((err) => {
        checkLoggedIn(err);
        notifyError("登録に失敗しました。");
        console.error(err);
        changeIsLoading();
      });
    } else {
      setShowRegister(false);
      notifyError("入力不正があります。");
      setIsValidated(true);
      setValidationError(validatedData.validationError);
    }
  };

  const clickDelete = () => {
    changeIsLoading();
    deleteJ002(authState, notificationMakeId).then((_) => {
      setShowDelete(false);
      notify("新着情報を削除しました。");
      changeIsLoading();
      history.goBack();
    }).catch((err) => {
      checkLoggedIn(err);
      notifyError("削除に失敗しました。");
      console.error(err);
      changeIsLoading();
    });
  };

  const clickSend = () => {
    if (userInfo?.user_type == STAFF) {
      setFcValueForStaff();
    }
    const validatedData = validationJ002Model(data);
    if (validatedData.ok) {
      changeIsLoading();
      putJ002(authState, data).then((res) => {
        if (uploadFile) {
          putJ002Image(authState, res.data, uploadFile).then((_) => {
            sendJ002(authState, res.data).then((__) => {
              setShowSend(false);
              notify("新着情報を送信しました。");
              changeIsLoading();
              history.goBack();
            });
          }).catch((err) => {
            notifyError("新着情報送信に失敗しました。");
            console.error(err);
            changeIsLoading();
          });
        } else {
          sendJ002(authState, res.data).then((_) => {
            setShowSend(false);
            notify("新着情報を送信しました。");
            changeIsLoading();
            history.goBack();
          });
        }
      }).catch((err) => {
        checkLoggedIn(err);
        notifyError("新着情報送信に失敗しました。");
        console.error(err);
        changeIsLoading();
      });
    } else {
      setShowSend(false);
      notifyError("入力不正があります。");
      setIsValidated(true);
      setValidationError(validatedData.validationError);
    }
  };

  const setRecipientTypeValue = (v: number) => {
    setRecipientSelected(v);
    if (v == RECIPIENT_TYPE.PARENT) {
      if (data.notification_recipients.filter(x => x.type == v).length == 0) {
        const dataPrev = { ...data };
        dataPrev.notification_recipients.push({ type: v, recipient: 0 });
        setData(dataPrev);
      }
    }
  };

  const setFcValue = (v: number) => {
    const fcAll = 0;
    if (v == fcAll) {
      const dataPrev = { ...data };
      dataPrev.notification_recipients = dataPrev.notification_recipients.filter(x => x.type !== RECIPIENT_TYPE.FC);
      dataPrev.notification_recipients.push({ type: RECIPIENT_TYPE.FC, recipient: v });
      setData(dataPrev);
    } else {
      const filteredTypes = data.notification_recipients.filter(x => x.type == RECIPIENT_TYPE.FC);
      if (filteredTypes.filter(x => x.recipient == fcAll).length > 0) return;
      if (filteredTypes.length == 0 || filteredTypes.filter(x => x.recipient == v).length == 0) {
        const dataPrev = { ...data };
        dataPrev.notification_recipients.push({ type: RECIPIENT_TYPE.FC, recipient: v });
        setData(dataPrev);
      }
    }
  };

  const setRecipientValue = (v: number, recipientType: number) => {
    const filteredTypes = data.notification_recipients.filter(x => x.type == recipientType);
    if (filteredTypes.length == 0 || filteredTypes.filter(x => x.recipient == v).length == 0) {
      const dataPrev = { ...data };
      dataPrev.notification_recipients.push({ type: recipientType, recipient: v });
      setData(dataPrev);
    }
  };

  const getSelectedRecipientName = (recipient: WebNotificationRecipientSchema) => {
    switch (recipient.type) {
      case RECIPIENT_TYPE.PARENT:
        return recipientTypeForSelect.filter(x => x.value == recipient.type).length > 0 ? recipientTypeForSelect.filter(x => x.value == recipient.type)[0].label : "";
      case RECIPIENT_TYPE.FC:
        return fcForSelect.filter(x => x.value == recipient.recipient).length > 0 ? fcForSelect.filter(x => x.value == recipient.recipient)[0].label : "";
      case RECIPIENT_TYPE.RANK:
        return rankForSelect.filter(x => x.value == recipient.recipient).length > 0 ? rankForSelect.filter(x => x.value == recipient.recipient)[0].label : "";
    }
  };

  const getFcName = () => {
    if (fcForSelect.filter(x => x.value == fcId).length == 0) return null;
    return fcForSelect.filter(x => x.value == fcId)[0].label;
  };

  const setFcValueForStaff = () => {
    const dataPrev = { ...data };
    if (dataPrev.notification_recipients.filter(value => value.recipient === fcId).length === 0) {
      dataPrev.notification_recipients.push({ type: RECIPIENT_TYPE.FC, recipient: fcId });
      setData(dataPrev);
    }
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Title title="新着送信情報詳細" />
      {data.sent_at ? (
        <div className="plate">
          <CustomRow title="タイトル" content={
            <Label value={data.title} className="text-wrap" />
          } />
          <CustomRow title="差出人" content={
            <Label value={data.facility_name} />
          } />
          <CustomRow title="内容" content={
            <CustomTextarea value={data.body} disabled={true} className="text-wrap" />
          } />
          <CustomRow title="画像" content={
            <CustomImage imageUrl={data.image_url} readOnly={true} />
          } />
          {data.url &&
            <CustomRow title="アンケートURL" content={
              <div className="item">
                <a href={data.url} target="_blank">{data.url}</a>
              </div>
            } />
          }
          <CustomRow title="宛先" on_same_row={false} content={
            <>
              <div className="mx-2 on_same_row" style={{ flexWrap: "wrap" }}>
                {data.notification_recipients.filter(x => x.type == RECIPIENT_TYPE.FC).map((x, i) =>
                  <div key={i} className="mb-2">
                    <CustomButton label={getSelectedRecipientName(x)} class={[CB_CLASS.BLUE]} size={CB_SIZE.S} disabled={true} />
                  </div>
                )}
              </div>
              <div className="mx-2 on_same_row" style={{ flexWrap: "wrap" }}>
                {data.notification_recipients.filter(x => x.type != RECIPIENT_TYPE.FC).map((x, i) =>
                  <div key={i} className="mb-2">
                    <CustomButton label={getSelectedRecipientName(x)} class={[CB_CLASS.BLUE]} size={CB_SIZE.S} disabled={true} />
                  </div>
                )}
              </div>
            </>
          } />
          <CustomRow title="送信日時" content={
            <Label value={format(new Date(data.sent_at), "yyyy/MM/dd HH:mm:ss")} className="text-wrap" />
          } />
        </div>
      ) : (
        <div className="plate">
          <CustomRow title="タイトル" required={true} error_msg={validationError.messageOf.title} content={
            <TextBox
              type="text"
              placeholder="タイトル"
              value={data.title}
              onChange={(v: string) => setData({ ...data, title: v })}
            />
          } />
          {userInfo?.user_type == HEADQUARTER_USER &&
            <CustomRow title="差出人" required={false} content={<Label value="本部" />} />
          }
          {userInfo?.user_type == STAFF &&
            <CustomRow title="差出人" required={false} content={<Label value={getFcName()} />} />
          }
          <CustomRow title="内容" on_same_row={false} error_msg={validationError.messageOf.body} content={
            <CustomTextarea placeholder="内容" value={data.body} onChange={(v: string) => setData({ ...data, body: v })} />
          } />
          <CustomRow title="画像" content={
            <CustomImage imageUrl={data.image_url} uploadFile={uploadFile} setUploadFile={setUploadFile} />
          } />
          <CustomRow title="アンケートURL" required={false} content={
            <TextBox type="text" placeholder="アンケートURL" value={data.url}
              onChange={(v: string) => setData({ ...data, url: v })}
            />
          } />
          <CustomRow title="宛先" required={userInfo?.user_type === HEADQUARTER_USER ? true : false} on_same_row={false} error_msg={validationError.messageOf.notification_recipients} content={
            userInfo?.user_type === HEADQUARTER_USER ?
              <>
                <div className="mx-2 on_same_row" style={{ flexWrap: "wrap" }}>
                  {data.notification_recipients.filter(x => x.type == RECIPIENT_TYPE.FC).map((x, i) =>
                    <div key={i} className="mb-2">
                      <CustomButton label={getSelectedRecipientName(x)} class={[CB_CLASS.BLUE]} size={CB_SIZE.S}
                        onDoubleClick={() => setData({ ...data, notification_recipients: data.notification_recipients.filter(y => y !== x) })} />
                    </div>
                  )}
                </div>
                <div className="on_same_row">
                  <Select
                    options={fcForSelect}
                    className="mx-2 J002_select"
                    onChange={(e: any) => setFcValue(e.value)}
                    placeholder="施設"
                  />
                </div>
                <div className="mt-2 mx-2 on_same_row" style={{ flexWrap: "wrap" }}>
                  {data.notification_recipients.filter(x => x.type != RECIPIENT_TYPE.FC).map((x, i) =>
                    <div key={i} className="mb-2">
                      <CustomButton label={getSelectedRecipientName(x)} class={[CB_CLASS.BLUE]} size={CB_SIZE.S}
                        onDoubleClick={() => setData({ ...data, notification_recipients: data.notification_recipients.filter(y => y !== x) })} />
                    </div>
                  )}
                </div>
                <div className="on_same_row">
                  <Select
                    options={recipientTypeForSelect}
                    className="mx-2 J002_select"
                    onChange={(e: any) => setRecipientTypeValue(e.value)}
                    placeholder="通知対象"
                  />
                  {recipientSelected == RECIPIENT_TYPE.RANK &&
                    <Select
                      options={rankForSelect}
                      className="mx-2 J002_select"
                      onChange={(e: any) => setRecipientValue(e.value, RECIPIENT_TYPE.RANK)}
                      placeholder="役職"
                    />
                  }
                </div>
              </>
              : <Label value="保護者" />
          } />
          <div className="item">
            <CustomButton label="登録" class={[CB_CLASS.RED]} onClick={() => setShowRegister(true)} />
            <ConfirmModal target="登録" show={showRegister} setShow={setShowRegister} func={clickRegister}
              confirmText={"登録してよろしいでしょうか。"}
            />
            <CustomButton label="登録して送信" class={[CB_CLASS.RED]} onClick={() => setShowSend(true)} />
            <ConfirmModal target="登録して送信" show={showSend} setShow={setShowSend} func={clickSend}
              confirmText={"送信してよろしいでしょうか。"}
            />
            {notificationMakeId &&
              <>
                <CustomButton label="削除" class={[CB_CLASS.RED]} onClick={() => setShowDelete(true)} />
                <ConfirmModal target="削除" show={showDelete} setShow={setShowDelete} func={clickDelete}
                  confirmText={"送信済新着情報も削除されます。削除してよろしいでしょうか。"}
                />
              </>
            }
          </div>
        </div>
      )}
    </>
  );
};

export default J002;

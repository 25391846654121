import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { userInfoSelector } from "../../redux/selectors/auth.selector";
import Q002 from "../Q002/Q002";
import "./Q001.scss";
import { CHANGE_EMAIL, CHANGE_IMAGE, CHANGE_NAME, CHANGE_PASSWORD } from "../../models/user_setting.model";
import Title from "../../components/Title/Title";
import CustomRow from "../../components/CustomRow/CustomRow";
import CustomButton, { CB_CLASS } from "../../components/Button/CustomButton";
import Label from "../../components/Label/Label";
import Pict, { SIZES } from "../../components/Pict/User";
import Plus from "../../components/Icon/Plus";

const Q001 = () => {
  const userInfo = useSelector(userInfoSelector);
  const imageRef = useRef<HTMLInputElement | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [keyword, setKeyword] = useState<number>(0);
  const [localImageSrc, setLocalImageSrc] = useState("");
  const [uploadFile, setUploadFile] = useState<File | undefined>(undefined);

  const clickChange = (CHANGE_KEYWORD: number) => {
    setKeyword(CHANGE_KEYWORD);
    setShowModal(true);
  };

  const onChangeInputFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event: any) => {
        setLocalImageSrc(event.target.result);
      };
      reader.readAsDataURL(file);
      setUploadFile(file);
      setKeyword(CHANGE_IMAGE);
      setShowModal(true);
    }
  };

  return (
    <>
      <Title title={"マイページ"} />
      <div className="plate d-flex justify-content-center">
        <div className="position-relative">
          <img
            src={userInfo?.profile_pic_url || `${process.env.PUBLIC_URL}/img/staff_register.png`}
            width={SIZES.LL}
            height={SIZES.LL}
            style={{ objectFit: "contain" }}
          />
          <div className="position-absolute Q001-icon-button">
            <Plus
              func={(e: any) => {
                imageRef.current?.click();
              }}
            />
            <input
              type="file"
              ref={imageRef}
              hidden
              accept="image/*"
              onChange={onChangeInputFile}
            />
          </div>
        </div>
      </div>
      <div className="plate">
        <CustomRow
          title="名前"
          required={false}
          on_same_row={true}
          content={
            <div className="d-flex justify w-100">
              <Label value={userInfo && userInfo.family_name + userInfo.given_name} className="item" />
              <CustomButton
                label="変更する"
                class={[CB_CLASS.RED]}
                onClick={() => clickChange(CHANGE_NAME)}
              />
            </div>
          }
        />
        <CustomRow
          title="メールアドレス"
          required={false}
          on_same_row={true}
          content={
            <div className="d-flex justify w-100">
              <Label value={userInfo && userInfo?.email} className="item" />
              <CustomButton
                label="変更する"
                class={[CB_CLASS.RED]}
                onClick={() => clickChange(CHANGE_EMAIL)}
              />
            </div>
          }
        />
        <CustomRow
          title="パスワード"
          required={false}
          on_same_row={true}
          content={
            <div className="d-flex justify w-100">
              <Label value={"********"} className="item" />
              <CustomButton
                label="変更する"
                class={[CB_CLASS.RED]}
                onClick={() => clickChange(CHANGE_PASSWORD)}
              />
            </div>
          }
        />
      </div>
      <Q002
        showModal={showModal}
        setShowModal={setShowModal}
        keyword={keyword}
        uploadFile={uploadFile}
        localImageSrc={localImageSrc}
      />
    </>
  );
};

export default Q001;

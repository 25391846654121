import React, { useState, useEffect } from "react";
import "./FacilityName.scss";
import { useSelector } from "react-redux";
import { userInfoSelector } from "../../redux/selectors/auth.selector";
import { HEADQUARTER_USER } from "../../constants.js";
import {
  fetchFacilityName,
} from "../../services/api.service";
function FacilityName(props: any) {

  const userInfo = useSelector(userInfoSelector);
  const [fcName, setFcName] = useState("");

  useEffect(() => {
    fetchFacilityName(
      props.authState,
      props.fcId,
    )
      .then((res) => {
        setFcName(res.data);
      })
      .catch((err) => {
        console.warn(err);
      });
  }, [props]);

  return (
    <>
      {userInfo?.user_type == HEADQUARTER_USER && (
        <div className="FacilityName_title_area">
          <h3>{fcName}</h3>
        </div>
      )}
    </>
  );
};

export default FacilityName;

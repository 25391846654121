import "./Pict.scss";
export const SIZES = { LL: 120, L: 90, M: 50, S: 38 };

function PictUser(props: any) {
  let class_name_img = "pict_img";
  return (
    <div
      className="pict_div"
      onClick={(event) => {
        if (props.func) {
          event.stopPropagation();
          props.func();
        }
      }}
    >
      <img
        className={class_name_img + (props.className || "")}
        src={props.url || `${process.env.PUBLIC_URL}/img/staff_register.png`}
        width={props.size || SIZES.M}
        height={props.size || SIZES.M}
        style={{ objectFit: "contain" }}
      />
    </div>
  );
}

export default PictUser;

import React from "react";
import { Button } from "react-bootstrap";
import "./CustomButton.scss";

export const RB_COLOR = {
  YELLOW: "button_yellow",
  BLUE: "button_blue",
  RED: "button_red",
  GREEN: "button_green",
  NONE: "button_none",
};

function RoundButton(props: any) {
  return (
    <div>
      <Button
        className={"round_button " + (props.is_none ? RB_COLOR.NONE : props.color)}
        onClick={(event) => {
          event.stopPropagation();
          props.onClick();
        }}
      >
        {props.label}
      </Button>
    </div>
  );
};

export default RoundButton;

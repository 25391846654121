import { ParentChildrenCreateSchema, ParentDetailSchema } from "../generated";
import { validationMessage } from "../config/validationMessage";
import { initialValidationError, ValidatedData } from "./validate.model";
import {
  checkFlgObj,
  checkValue,
  checkEmail,
  checkValueNotRequire,
  checkNumberNotRequire,
  checkValueKanaNotRequire,
  checkPasswordNotRequire,
} from "../helper/validation.helper";
import { format } from "date-fns-tz";

export const initialParentDetailModel = (): ParentDetailSchema => ({
  family_name: "",
  given_name: "",
  id: 0,
  email: "",
  password: undefined,
  password_check: undefined,
  profile_pic_url: "",
  children: [
    {
      family_name: "",
      family_name_kana: "",
      given_name: "",
      given_name_kana: "",
      id: 0,
      profile_pic: 0,
      birthday: "",
      profile_pic_url: "",
    },
  ],
  family_name_kana: "",
  given_name_kana: "",
  phone_number: "",
  post_code: "",
  address_line: "",
  family_structure: "",
  remarks: "",
  is_temp: true,
});

export const initialParentModel = () => ({
  family_name: "",
  given_name: "",
  id: 0,
  email: "",
  profile_pic_url: "",
});

export const initialCreateParentChildModel =
  (fcId: number): ParentChildrenCreateSchema => ({
    family_name: "",
    given_name: "",
    email: "",
    children: [
      {
        family_name: "",
        given_name: "",
        facility_id: fcId,
        join_date: format(new Date(), "yyyy-MM-dd"),
      },
    ],
  });

export const validationParentModel = (
  parent: ParentDetailSchema
): ValidatedData<ParentDetailSchema> => {
  const error = initialValidationError<ParentDetailSchema>();

  const checkFlg: checkFlgObj = {
    isOk: true,
  };

  let errorMsg: string | undefined;

  error.messageOf.family_name =  [
    checkValue(checkFlg, "姓", parent.family_name, 30),
    checkValue(checkFlg, "名", parent.given_name, 30)
  ].filter(x => x != undefined).join(" ");

  error.messageOf.family_name_kana = [
    checkValueKanaNotRequire(checkFlg, "姓（フリガナ）", parent.family_name_kana, 30),
    checkValueKanaNotRequire(checkFlg, "名（フリガナ）", parent.given_name_kana, 30),
  ].filter(x => x != undefined).join(" ");

  errorMsg = checkPasswordNotRequire(
    checkFlg,
    "新しいパスワード",
    parent.password,
    parent.password_check,
    8
  );
  validationMessage.emptyRePassword === errorMsg ||
  validationMessage.inconsistentPassword === errorMsg
    ? (error.messageOf.password_check = errorMsg)
    : (error.messageOf.password = errorMsg);

  error.messageOf.email = checkEmail(checkFlg, parent.email);

  error.messageOf.phone_number = checkNumberNotRequire(
    checkFlg,
    "電話番号",
    parent.phone_number,
    11
  );

  error.messageOf.post_code = checkNumberNotRequire(
    checkFlg,
    "郵便番号",
    parent.post_code,
    7
  );

  error.messageOf.address_line = checkValueNotRequire(
    checkFlg,
    "住所",
    parent.address_line,
    100
  );

  error.messageOf.family_structure = checkValueNotRequire(
    checkFlg,
    "家族構成",
    parent.family_structure,
    30
  );

  error.messageOf.emergency_contact_name1 = [
    checkValueNotRequire(checkFlg, "（1列目）緊急連絡先（名前）", parent.emergency_contact_name1, 30),
    checkValueKanaNotRequire(checkFlg, "（1列目）緊急連絡先（フリガナ）", parent.emergency_contact_kana1, 30),
    checkNumberNotRequire(checkFlg, "（1列目）緊急連絡先（電話番号）", parent.emergency_contact_tel1, 11),
    checkValueNotRequire(checkFlg, "（1列目）緊急連絡先（続柄）", parent.emergency_contact_relationship1, 10),

    checkValueNotRequire(checkFlg, "（2列目）緊急連絡先（名前）", parent.emergency_contact_name2, 30),
    checkValueKanaNotRequire(checkFlg, "（2列目）緊急連絡先（フリガナ）", parent.emergency_contact_kana2, 30),
    checkNumberNotRequire(checkFlg, "（2列目）緊急連絡先（電話番号）", parent.emergency_contact_tel2, 11),
    checkValueNotRequire(checkFlg, "（2列目）緊急連絡先（続柄）", parent.emergency_contact_relationship2, 10),

    checkValueNotRequire(checkFlg, "（3列目）緊急連絡先（名前）", parent.emergency_contact_name3, 30),
    checkValueKanaNotRequire(checkFlg, "（3列目）緊急連絡先（フリガナ）", parent.emergency_contact_kana3, 30),
    checkNumberNotRequire(checkFlg, "（3列目）緊急連絡先（電話番号）", parent.emergency_contact_tel3, 11),
    checkValueNotRequire(checkFlg, "（3列目）緊急連絡先（続柄）", parent.emergency_contact_relationship3, 10),
  ].filter(x => x != undefined).join(" ");

  error.messageOf.remarks = checkValueNotRequire(checkFlg, "備考", parent.remarks, 50);

  if (checkFlg.isOk) {
    return {
      ok: true,
      validatedData: parent,
    };
  }

  return {
    ok: false,
    validationError: error,
  };
};

export const validationCreateParentChildModel = (
  parent: ParentChildrenCreateSchema
): ValidatedData<ParentChildrenCreateSchema> => {
  const error = initialValidationError<ParentChildrenCreateSchema>();

  const checkFlg: checkFlgObj = {
    isOk: true,
  };

  let errorMsg: string | undefined;

  error.messageOf.family_name = [
    checkValue(checkFlg, "姓", parent.family_name, 30),
    checkValue(checkFlg, "名", parent.given_name, 30)
  ].filter(x => x != undefined).join(" ");

  error.messageOf.email = checkEmail(checkFlg, parent.email);

  error.messageOf.children = parent.children.map((x, i) => {
    const result = [
      checkValue(checkFlg, "お子様姓", x.family_name, 30), 
      checkValue(checkFlg, "お子様名", x.given_name, 30),
      checkValue(checkFlg, "入会日", x.join_date)]
      .filter(y => y !== undefined).join(" ");
    return result;
  })
  .filter(z => z !== "")
  .join(" ");

  if (checkFlg.isOk) {
    return {
      ok: true,
      validatedData: parent,
    };
  }

  return {
    ok: false,
    validationError: error,
  };
};

import { State } from "../reducers";

export const authSelector = (state: State) => state.authReducer;

export const loggedInSelector = (state: State) => state.authReducer.loggedIn;

export const loggingInSelector = (state: State) => state.authReducer.loggingIn;

export const fetchUserInfoErrorSelector = (state: State) =>
  state.authReducer.fetchUserInfoError;

export const userInfoSelector = (state: State) => state.authReducer.userInfo;
export const userNameSelector = (state: State) =>
  (state.authReducer.userInfo?.family_name ?? "") +
    (state.authReducer.userInfo?.given_name ?? "") || "";
export const userEmailSelector = (state: State) =>
  state.authReducer.userInfo?.email || "";

export const errorStateSelector = (state: State) => state.errorStateReducer;

import "./M002.scss";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import {
  GeneralForListSchema,
  FCStaffInfoSchema,
  AuditDocM002Schema,
} from "../../generated";
import { authSelector, userInfoSelector } from "../../redux/selectors/auth.selector";
import {
  deleteAuditDoc,
  fetchFcFacilities,
  fetchGeneralsForList, fetchM002, putAuditDocImage, putM002,
} from "../../services/api.service";
import Select from "../../components/Select/CustomSelect";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import CustomButton, { CB_CLASS } from "../../components/Button/CustomButton"
import Title from "../../components/Title/Title"
import { HEADQUARTER_USER, LIST_AUDIT_DOC, STAFF } from "../../constants";
import CustomRow from "../../components/CustomRow/CustomRow";
import ChildInfoTop from "../../components/ChildInfoTop/ChildInfoTop";
import FileUploader from "../../components/FileUploader/FileUploader";
import { AUDIT_DOC_SELF_CREATE, AUDIT_DOC_TYPE, initialM002Model, validationM002Model } from "../../models/audit_doc.model";
import { initialGeneralForList } from "../../models/general.model";
import CustomDate from "../../components/Calendar/CustomDate";
import ConfirmModal from "../../components/ConfirmModal";
import { notify, notifyError } from "../../helper/settings.helper";
import { ValidationError, initialValidationError } from "../../models/validate.model";
import { validationMessage } from "../../config/validationMessage";
import { getState } from "../../helper/page.helper";
import { useAuth } from "../../helper/auth.helper";
import { H003Url, getForHistoryPush } from "../../helper/url.helper";
import { fcListSelector } from "../../redux/selectors/list.selector";
import { listActions } from "../../redux/actions/list.actions";
import { FC_LIST_TYPE, getFcList, getFcListForRedux, getFcListFromRedux } from "../../helper/list.helper";

interface iLocation {
  audit_doc_id: number;
}

const M002 = () => {
  const { checkLoggedIn } = useAuth();
  const location = useLocation<iLocation>();
  const state = getState(location);
  const auditDocId = state.audit_doc_id;
  const authState = useSelector(authSelector);
  const history = useHistory();
  const userInfo = useSelector(userInfoSelector);
  const [fcForSelect, setFcForSelect] = useState<GeneralForListSchema[]>([]);
  const fcId = userInfo?.user_type == STAFF ? (userInfo as FCStaffInfoSchema)?.facilities[0].facility_id : 0;
  const [docTypeForSelect, setDocTypeForSelect] = useState<GeneralForListSchema[]>([]);
  const [docTypeList, setDocTypeList] = useState<GeneralForListSchema[]>([]);
  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const [uploadFile, setUploadFile] = useState();
  const [showRegister, setShowRegister] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [isValidated, setIsValidated] = useState<boolean | undefined>(undefined);
  const [validationError, setValidationError] = useState<ValidationError<AuditDocM002Schema>>(initialValidationError());
  const [msgUploadFile, setMsgUploadFile] = useState("");

  const [data, setData] = useState<AuditDocM002Schema>(initialM002Model(fcId));
  const [reload, setReload] = useState(true);
  const fcListState = useSelector(fcListSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (auditDocId && reload) {
      let isMounted = true;
      const fetchData = async () => {
        changeIsLoading();
        fetchM002(authState, auditDocId).then((res) => {
          if (isMounted) {
            setData(res.data);
            setReload(false);
          }
          changeIsLoading();
        }).catch((err) => {
          if (isMounted) {
            checkLoggedIn(err);
            notifyError("データの取得に失敗しました。");
            console.error(err);
          }
          changeIsLoading();
        });
      };
      fetchData();
      return () => {
        isMounted = false;
      }
    }
  }, [authState, changeIsLoading, checkLoggedIn, auditDocId, reload]);

  useEffect(() => {
    if (userInfo?.user_type == HEADQUARTER_USER) {
      let isMounted = true;
      const fetchData = async () => {
        changeIsLoading();
        fetchFcFacilities(authState, "").then((res) => {
          if (isMounted) {
            setFcForSelect(getFcList(FC_LIST_TYPE.DEFAULT, res.data));
            dispatch(listActions.makeFcList(getFcListForRedux(res.data)));
          }
          changeIsLoading();
        }).catch((err) => {
          if (isMounted) {
            checkLoggedIn(err);
            notifyError("データの取得に失敗しました。");
            console.error(err);
          }
          changeIsLoading();
        });
      };
      if (fcForSelect.length == 0) {
        if (fcListState.select_list.length == 0) {
          fetchData();
        } else {
          setFcForSelect(getFcListFromRedux(FC_LIST_TYPE.DEFAULT, fcListState.select_list));
        }
      }
      return () => {
        isMounted = false;
      }
    }
  }, [authState, dispatch, changeIsLoading, checkLoggedIn, userInfo, fcForSelect.length, fcListState.select_list]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchGeneralsForList(authState, LIST_AUDIT_DOC).then((res) => {
        if (isMounted) {
          setDocTypeList(res.data);
          setDocTypeForSelect(res.data.filter(x => AUDIT_DOC_SELF_CREATE.indexOf(x.value) > -1));
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (docTypeForSelect.length == 0) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, docTypeForSelect.length]);

  const getSelectValue = (val: number, selectList: GeneralForListSchema[]) => {
    if (val == 0) return null;
    return selectList.filter(x => x.value == val)[0];
  };

  const getDocType = () => {
    if (docTypeList.filter(x => x.value == data.doc_type).length > 0) {
      return docTypeList.filter(x => x.value == data.doc_type)[0].label
    }
    return "";
  };

  const clickRegister = () => {
    const validatedData = validationM002Model(data);
    if (!data.file_url && !uploadFile) {
      validatedData.ok = false;
      setMsgUploadFile(validationMessage.emptyUploadFile);
    }
    if (validatedData.ok) {
      changeIsLoading();
      setMsgUploadFile("");
      putM002(authState, data).then((res) => {
        if (uploadFile) {
          putAuditDocImage(authState, res.data, uploadFile).then((_) => {
            setShowRegister(false);
            notify("監査ドキュメント情報を登録しました。");
            changeIsLoading();
            history.goBack();
          }).catch((err) => {
            notifyError("登録に失敗しました。");
            console.error(err);
            changeIsLoading();
          });
        } else {
          setShowRegister(false);
          notify("監査ドキュメント情報を登録しました。");
          changeIsLoading();
          history.goBack();
        }
      }).catch((err) => {
        checkLoggedIn(err);
        notifyError("登録に失敗しました。");
        console.error(err);
        changeIsLoading();
      });
    } else {
      setShowRegister(false);
      notifyError("入力不正があります。");
      setIsValidated(true);
      setValidationError(validatedData.validationError);
    }
  };

  const clickDelete = () => {
    changeIsLoading();
    deleteAuditDoc(authState, auditDocId).then((_) => {
      setShowDelete(false);
      notify("ドキュメントを削除しました。");
      changeIsLoading();
      history.goBack();
    }).catch((err) => {
      checkLoggedIn(err);
      notifyError("削除に失敗しました。");
      console.error(err);
      changeIsLoading();
    });
  };

  useEffect(() => {
    if (isValidated) {
      setMsgUploadFile("");
      const validatedData = validationM002Model(data);
      if (!data.file_url && !uploadFile) {
        validatedData.ok = false;
        setMsgUploadFile(validationMessage.emptyUploadFile);
      }
      if (validatedData.ok) {
        setValidationError(initialValidationError());
      } else {
        setIsValidated(true);
        setValidationError(validatedData.validationError);
      }
    }
  }, [data, isValidated, uploadFile]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Title title="監査ドキュメント詳細" />
      <div className="plate">
        {data.child_id ? (
          <ChildInfoTop
            user_type={authState.userInfo?.user_type}
            profile_pic_url={data.child_profile_pic_url}
            id={data.child_id}
            name={data.child_name}
            name_kana={data.child_name_kana}
            facility_name={data.facility_name}
            container={
              <label>{getDocType()}</label>
            } />
        ) : (
          <>
            {userInfo?.user_type == HEADQUARTER_USER &&
              <CustomRow title="施設" required={true} error_msg={validationError?.messageOf?.facility_id} content={
                <Select
                  value={getSelectValue(data.facility_id, fcForSelect)}
                  options={fcForSelect}
                  className="M002_select item"
                  onChange={(e: any) => setData({ ...data, facility_id: e.value })}
                  placeholder="施設"
                />
              } />
            }
            <CustomRow title="帳票" required={true} error_msg={validationError?.messageOf?.doc_type} content={
              <Select
                value={getSelectValue(data.doc_type, docTypeForSelect)}
                options={docTypeForSelect}
                className="M002_select item"
                onChange={(e: any) => setData({ ...data, doc_type: e.value })}
                placeholder="帳票"
              />
            } />
          </>
        )}
        <CustomRow title="ドキュメント" required={true} error_msg={msgUploadFile} content={
          <FileUploader setFile={setUploadFile} file={uploadFile} url={data.file_url} setMsg={setMsgUploadFile} />
        } />
        <CustomRow title="作成日" required={true} error_msg={validationError?.messageOf?.make_date} content={
          <div className="item">
            <CustomDate val={data.make_date} setter={(v: string) => setData({ ...data, make_date: v })} width="90px" />
          </div>
        } />
        <div className="item">
          {data.doc_type == AUDIT_DOC_TYPE.TRAINING_RESULT &&
            <CustomButton label="トレーニング結果へ" class={[CB_CLASS.BLUE]} min_width={200} onClick={() => history.push(getForHistoryPush(H003Url, [data.training_id, "M002"]))} />
          }
          <CustomButton label="登録" class={[CB_CLASS.RED]} min_width={200} onClick={() => setShowRegister(true)} />
          <ConfirmModal target="登録" show={showRegister} setShow={setShowRegister} func={clickRegister}
            confirmText={"登録してよろしいでしょうか。"}
          />
          {docTypeForSelect.filter(x => x.value == data.doc_type).length > 0 &&
            <>
              <CustomButton label="削除" class={[CB_CLASS.RED]} min_width={200} onClick={() => setShowDelete(true)} />
              <ConfirmModal target="削除" show={showDelete} setShow={setShowDelete} func={clickDelete}
                confirmText={"削除してよろしいでしょうか。"}
              />
            </>
          }
        </div>
      </div>
    </>
  );
};

export default M002;

import { validationMessage } from "../config/validationMessage";
import { W002Schema } from "../generated";
import { initialValidationError, ValidatedData } from "./validate.model";
import { format } from "date-fns-tz";

export const initialW002Model = (): W002Schema => ({
  id: 0,
  doc_type: 1,
  publication_date: format(new Date(), "yyyy-MM-dd")
});

export const validationW002Model = (
  data: W002Schema
): ValidatedData<W002Schema> => {
  const error = initialValidationError<W002Schema>();
  let ok = true;

  if (!data.publication_date) {
    error.messageOf.publication_date = validationMessage.emptyPublicationDate;
    ok = false;
  }

  if (ok) {
    return {
      ok: true,
      validatedData: data,
    };
  }

  return {
    ok: false,
    validationError: error,
  };
};

import { L008CartItemSchema } from "../generated";

export const ORDER_DETAIL_DELIVERY_STATUS = {
  MITAIOU: 1,   // 未対応
  TAIOUCHU: 2,  // 対応中（このステータスへの変更で在庫数が減る）
  ARRIVED: 3,   // 発送済
}

export const initialCartModel = (): CartModel => ({
  order_list: [],
});

export interface CartModel {
  order_list: L008CartItemSchema[];
}

export const makeCartModel: (list: L008CartItemSchema[]) => CartModel = (
  list
) => ({
  order_list: list,
});
const excelExtensions: string[] = [
  "xlsx",
  "xls",
];

export const isExcelFile = (fileName: string): boolean => {
  return excelExtensions.some((excelExtension: string) => {
    return fileName.toLocaleLowerCase().endsWith(`.${excelExtension}`);
  });
};

export const isPdfFile = (fileName: string): boolean => {
  return fileName.toLocaleLowerCase().endsWith(".pdf");
};

import React from "react";
import { Alert, Form } from "react-bootstrap";
import RequiredBadge from "../RequiredBadge";
import "./CustomRowSplitTwo.scss";
import Label from "../Label/Label";

function CustomRowSplitTwo(props: any) {
  const on_same_row_left =
    props.on_same_row_left == undefined ? true : props.on_same_row_left;
  const on_same_row_right =
    props.on_same_row_right == undefined ? true : props.on_same_row_right;
  return (
    <div className="custom_row_split_two row py-3">
      <div className="row_title_30 on_same_row">
        <Label value={props.title_left} />
        {props.required_left && <RequiredBadge />}
      </div>
      <div className="content_20">
        {props.error_msg_left && (
          <div className="item">
            <Alert
              variant="danger"
              style={{ padding: 5, fontSize: 14, margin: 0 }}
            >
              <p>{props.error_msg_left}</p>
            </Alert>
          </div>
        )}
        <div className={on_same_row_left ? "on_same_row" : ""}>
          {props.content_left}
        </div>
      </div>
      <div className="row_title_30 on_same_row">
        <Label value={props.title_right} />
        {props.required_right && <RequiredBadge />}
      </div>
      <div className="content_20">
        {props.error_msg_right && (
          <div className="item">
            <Alert
              variant="danger"
              style={{ padding: 5, fontSize: 14, margin: 0 }}
            >
              <p>{props.error_msg_right}</p>
            </Alert>
          </div>
        )}
        <div className={on_same_row_right ? "on_same_row" : ""}>
          {props.content_right}
        </div>
      </div>
    </div>
  );
}

export default CustomRowSplitTwo;

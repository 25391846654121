import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../redux/selectors/auth.selector";
import { fetchUserInfoAction } from "../../redux/actions/auth.actions";
import { HEADQUARTER_USER } from "../../constants";
import {
  CHANGE_EMAIL,
  CHANGE_IMAGE,
  CHANGE_NAME,
  CHANGE_PASSWORD,
  ChangeEmailSchema,
  ChangeNameSchema,
  ChangePasswordSchema,
  initialSettingModel,
  validationChangeEmailModel,
  validationChangeNameModel,
  validationChangePasswordModel,
} from "../../models/user_setting.model";
import { ValidationError, initialValidationError } from "../../models/validate.model";
import {
  changeHeadquarterEmail,
  changeHeadquarterName,
  changeHeadquarterPassword,
  changeStaffEmail,
  changeStaffImage,
  changeStaffName,
  changeStaffPassword,
  updateHeadquarterUserImage,
} from "../../services/api.service";
import { Result, TokenSchema } from "../../generated";

import CustomRow from "../../components/CustomRow/CustomRow";
import TextBox from "../../components/TextBox/TextBox";
import CustomButton, { CB_CLASS } from "../../components/Button/CustomButton";
import Pict, { SIZES } from "../../components/Pict/User";
import CustomModal from "../../components/CustomModal/CustomModal";
import Label from "../../components/Label/Label";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { notify, notifyError } from "../../helper/settings.helper";
import { raiseAction } from "../../helper/api.helper";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import { useAuth } from "../../helper/auth.helper";

interface Q002Props {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  keyword: number;
  uploadFile: File | undefined;
  localImageSrc: string;
}

const Q002: React.VFC<Q002Props> = ({
  showModal,
  setShowModal,
  keyword,
  uploadFile,
  localImageSrc,
}) => {
  const { checkLoggedIn } = useAuth();
  const dispatch = useDispatch();
  const [isLoading, changeIsLoading] = useLoadingIndicator(undefined);
  const authState = useSelector(authSelector);
  const token = authState.token;

  const [userInfo, setUserInfo] = useState(initialSettingModel);
  const [validationErrorName, setValidationErrorName] = useState<ValidationError<ChangeNameSchema>>(initialValidationError());
  const [validationErrorEmail, setValidationErrorEmail] = useState<ValidationError<ChangeEmailSchema>>(initialValidationError());
  const [validationErrorPassword, setValidationErrorPassword] = useState<ValidationError<ChangePasswordSchema>>(initialValidationError());
  const [isValidated, setIsValidated] = useState<boolean | undefined>(undefined);

  const success = () => {
    setShowModal(false);
    setUserInfo(initialSettingModel);
    notify("ログインユーザーの情報を更新しました。");
    changeIsLoading();
    if (token) dispatch(fetchUserInfoAction(token, true));
  };

  const error = (v: string | undefined) => {
    notifyError(String(v));
    changeIsLoading();
  };

  const failure = () => {
    notifyError("入力不正があります。");
    setIsValidated(true);
  };

  const validateFailure = (err: any) => {
    checkLoggedIn(err);
    notifyError("登録に失敗しました。");
    console.error(err);
    changeIsLoading();
  };

  const clickRegister = () => {
    switch (keyword) {
      case CHANGE_IMAGE:
        if (uploadFile) {
          if (authState.userInfo?.user_type === HEADQUARTER_USER) {
            changeIsLoading();
            updateHeadquarterUserImage(uploadFile, authState).then((_) => {
              success();
            }).catch((err) => {
              validateFailure(err);
            });
          } else {
            changeIsLoading();
            changeStaffImage(uploadFile, authState).then((_) => {
              success();
            }).catch((err) => {
              validateFailure(err);
            });
          }
        }
        break;
      case CHANGE_NAME:
        const validatedDataName = validationChangeNameModel(userInfo);
        if (validatedDataName.ok) {
          if (authState.userInfo?.user_type === HEADQUARTER_USER) {
            changeIsLoading();
            changeHeadquarterName(
              userInfo.family_name,
              userInfo.given_name,
              userInfo.family_name_kana,
              userInfo.given_name_kana,
              authState
            ).then((_) => {
              success();
            }).catch((err) => {
              validateFailure(err);
            });
          } else {
            changeIsLoading();
            changeStaffName(
              userInfo.family_name,
              userInfo.given_name,
              userInfo.family_name_kana,
              userInfo.given_name_kana,
              authState
            ).then((_) => {
              success();
            }).catch((err) => {
              validateFailure(err);
            });
          }
        } else {
          failure();
        }
        break;
      case CHANGE_EMAIL:
        const validatedDataEmail = validationChangeEmailModel(userInfo);
        if (validatedDataEmail.ok) {
          if (authState.userInfo?.user_type === HEADQUARTER_USER) {
            changeIsLoading();
            changeHeadquarterEmail(userInfo.email, authState).then((res) => {
              raiseAction(res.data, (v: TokenSchema) => {
                success();
                dispatch(fetchUserInfoAction(v, true));
              }, (v: Result) => {
                error(v.msg);
              })
            }).catch((err) => {
              validateFailure(err);
            });
          } else {
            changeIsLoading();
            changeStaffEmail(userInfo.email, authState).then((res) => {
              raiseAction(res.data, (v: TokenSchema) => {
                success();
                dispatch(fetchUserInfoAction(v, true));
              }, (v: Result) => {
                error(v.msg);
              })
            }).catch((err) => {
              validateFailure(err);
            });
          }
        } else {
          failure();
        }
        break;
      case CHANGE_PASSWORD:
        const validatedDataPassword = validationChangePasswordModel(userInfo);
        if (validatedDataPassword.ok) {
          if (authState.userInfo?.user_type === HEADQUARTER_USER) {
            changeIsLoading();
            changeHeadquarterPassword(
              userInfo.password,
              userInfo.new_password,
              authState
            ).then((res) => {
              raiseAction(res.data, (v: Result) => {
                success();
              }, (v: Result) => {
                error(v.msg);
              });
            }).catch((err) => {
              validateFailure(err);
            });
          } else {
            changeIsLoading();
            changeStaffPassword(
              userInfo.password,
              userInfo.new_password,
              authState
            ).then((res) => {
              raiseAction(res.data, (v: Result) => {
                success();
              }, (v: Result) => {
                error(v.msg);
              });
            }).catch((err) => {
              validateFailure(err);
            });
          }
        } else {
          failure();
        }
        break;
    }
  };

  useEffect(() => {
    if (isValidated) {
      switch (keyword) {
        case CHANGE_NAME:
          const validatedDataName = validationChangeNameModel(userInfo);
          if (validatedDataName.ok) {
            setValidationErrorName(initialValidationError());
          } else {
            setIsValidated(true);
            setValidationErrorName(validatedDataName.validationError);
          }
          break;
        case CHANGE_EMAIL:
          const validatedDataEmail = validationChangeEmailModel(userInfo);
          if (validatedDataEmail.ok) {
            setValidationErrorEmail(initialValidationError());
          } else {
            setIsValidated(true);
            setValidationErrorEmail(validatedDataEmail.validationError);
          }
          break;
        case CHANGE_PASSWORD:
          const validatedDataPassword = validationChangePasswordModel(userInfo);
          if (validatedDataPassword.ok) {
            setValidationErrorPassword(initialValidationError());
          } else {
            setIsValidated(true);
            setValidationErrorPassword(validatedDataPassword.validationError);
          }
          break;
      }
    }
  }, [userInfo, isValidated, keyword]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <CustomModal
        show={showModal}
        setShow={setShowModal}
        size={"lg"}
        contents={
          <>
            <div className="plate">
              {keyword === CHANGE_IMAGE && (
                <>
                  <Label value="アイコン変更" className="t_align_center w-100" />
                  <div className="t_align_center w-100">
                    <Pict url={`${localImageSrc}`} size={SIZES.LL} />
                  </div>
                </>
              )}
              {keyword === CHANGE_NAME && (
                <>
                  <Label value="名前変更" className="t_align_center w-100" />
                  <CustomRow
                    title="新しい名前"
                    required={true}
                    error_msg={validationErrorName.messageOf.family_name}
                    content={
                      <>
                        <TextBox
                          type="text"
                          placeholder="姓"
                          value={userInfo.family_name}
                          onChange={(v: any) =>
                            setUserInfo({ ...userInfo, family_name: v })
                          }
                        />
                        <TextBox
                          type="text"
                          placeholder="名"
                          value={userInfo.given_name}
                          onChange={(v: any) =>
                            setUserInfo({ ...userInfo, given_name: v })
                          }
                        />
                      </>
                    }
                  />
                  <CustomRow
                    title="フリガナ"
                    required={true}
                    error_msg={validationErrorName.messageOf.family_name_kana}
                    content={
                      <>
                        <TextBox
                          type="text"
                          placeholder="セイ"
                          value={userInfo.family_name_kana}
                          onChange={(v: any) =>
                            setUserInfo({ ...userInfo, family_name_kana: v })
                          }
                        />
                        <TextBox
                          type="text"
                          placeholder="メイ"
                          value={userInfo.given_name_kana}
                          onChange={(v: any) =>
                            setUserInfo({ ...userInfo, given_name_kana: v })
                          }
                        />
                      </>
                    }
                  />
                </>
              )}
              {keyword === CHANGE_EMAIL && (
                <>
                  <Label value="メールアドレス変更" className="t_align_center w-100" />
                  <CustomRow
                    title="メールアドレス"
                    required={true}
                    error_msg={validationErrorEmail.messageOf.email}
                    content={
                      <TextBox
                        type="email"
                        placeholder="メールアドレス"
                        value={userInfo.email}
                        onChange={(v: any) =>
                          setUserInfo({ ...userInfo, email: v })
                        }
                      />
                    }
                  />
                </>
              )}
              {keyword === CHANGE_PASSWORD && (
                <>
                  <Label value="パスワード変更" className="t_align_center w-100" />
                  <CustomRow
                    title="現在のパスワード"
                    required={true}
                    error_msg={validationErrorPassword.messageOf.password}
                    content={
                      <TextBox
                        type="text"
                        placeholder="現在のパスワード"
                        value={userInfo.password}
                        onChange={(v: any) =>
                          setUserInfo({ ...userInfo, password: v })
                        }
                      />
                    }
                  />
                  <CustomRow
                    title="新しいパスワード"
                    required={true}
                    error_msg={validationErrorPassword.messageOf.new_password}
                    content={
                      <TextBox
                        type="text"
                        placeholder="新しいパスワード"
                        value={userInfo.new_password}
                        onChange={(v: any) =>
                          setUserInfo({ ...userInfo, new_password: v })
                        }
                      />
                    }
                  />
                  <CustomRow
                    title="パスワード(確認)"
                    required={true}
                    error_msg={validationErrorPassword.messageOf.new_password_check}
                    content={
                      <TextBox
                        type="text"
                        placeholder="パスワード（確認）"
                        value={userInfo.new_password_check}
                        onChange={(v: any) =>
                          setUserInfo({ ...userInfo, new_password_check: v })
                        }
                      />
                    }
                  />
                </>
              )}
              <div className="plate d-flex justify_center">
                <CustomButton
                  label="キャンセル"
                  class={[CB_CLASS.GRAY]}
                  onClick={() => setShowModal(false)}
                />
                <CustomButton
                  label="登録"
                  class={[CB_CLASS.RED]}
                  onClick={() => clickRegister()}
                />
              </div>
            </div>
          </>
        }
      />
    </>
  );
};
export default Q002;

import { FormControl } from "react-bootstrap";
import "./IncDec.scss";

function IncDec(props: any) {
  return (
    <div className="on_same_row">
      <button className="inc_dec_button"
        onClick={() => {
          if (props.val !== props.min_val) {
            props.dec()
          }
        }}
      >
        -
      </button>
      <FormControl type="number" className="text_box" value={props.val} disabled />
      <button className="inc_dec_button"
        onClick={() => {
          if (props.val !== props.max_val) {
            props.inc()
          }
        }}
      >
        +
      </button>
    </div>
  );
};

export default IncDec;

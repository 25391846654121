import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { authSelector, userInfoSelector } from "../../redux/selectors/auth.selector";
import {
  D002Schema,
  GeneralForListSchema,
  FCFacilitiesSchema,
  DisabilitySchema,
  FCStaffInfoSchema,
  ChildReflectionSchema,
} from "../../generated";
import {
  BLOOD_TYPE,
  DISABILITIES,
  GENDER,
  GENDER_SELECT_OTHER,
  initialChildModel,
  validationChildModel,
} from "../../models/child.model";
import { initialGeneralForList } from "../../models/general.model";
import { ValidationError, initialValidationError } from "../../models/validate.model";
import { AUDIT_DOC_TYPE } from "../../models/audit_doc.model";
import ConfirmModal from "../../components/ConfirmModal";
import "./D002.scss";
import { HEADQUARTER_USER, LIST_REFLECTION, STAFF } from "../../constants";
import CustomRow from "../../components/CustomRow/CustomRow";
import CustomRowSplitTwo from "../../components/CustomRowSplitTwo/CustomRowSplitTwo";
import CustomDate from "../../components/Calendar/CustomDate";
import CustomTextarea from "../../components/CustomTextarea/CustomTextarea";
import CheckBox from "../../components/CheckBox/CheckBox";
import Label from "../../components/Label/Label";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import TextBox from "../../components/TextBox/TextBox";
import Title from "../../components/Title/Title";
import CustomButton, { CB_CLASS, CB_SIZE } from "../../components/Button/CustomButton";
import RadioButton from "../../components/RadioButton/RadioButton";
import CustomUserIcon from "../../components/CustomImage/CustomUserIcon";
import Select from "../../components/Select/CustomSelect";
import { makeStringDateWithSlash } from "../../helper/time.helper";
import { calcAge } from "../../helper/calculation.helper";
import { notify, notifyError } from "../../helper/settings.helper";
import { D003Url, D006Url, D007Url, D010Url, E001Url, H001Url, M002Url, getForHistoryPush } from "../../helper/url.helper";
import { getState } from "../../helper/page.helper";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import {
  deleteChild,
  fetchAddress,
  fetchD002,
  fetchFcFacilities,
  fetchGeneralsForListMulti,
  putD002,
  putChildImage,
} from "../../services/api.service";
import { useAuth } from "../../helper/auth.helper";
import { fcListSelector } from "../../redux/selectors/list.selector";
import { listActions } from "../../redux/actions/list.actions";
import { FC_LIST_TYPE, getFcList, getFcListForRedux, getFcListFromRedux } from "../../helper/list.helper";

interface iD002 {
  child_id: number;
  parent_id: number | undefined;
}

const D002 = () => {
  const { checkLoggedIn } = useAuth();
  const location = useLocation<iD002>();
  const state = getState(location);
  const childId = state.child_id;
  const parentId = state.parent_id;
  const userInfo = useSelector(userInfoSelector);
  const fc = userInfo?.user_type == STAFF ? (userInfo as FCStaffInfoSchema)?.facilities[0] : null;
  const fcId = fc ? fc.facility_id : 0;
  const fcName = fc ? fc.facility_name : "";
  const history = useHistory();
  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const authState = useSelector(authSelector);
  const [data, setData] = useState<D002Schema>(initialChildModel(fcId, parentId));
  const [image, setImage] = useState<File | undefined>(undefined);
  const [fcForSelect, setFcForSelect] = useState<GeneralForListSchema[]>([]);
  const [disabilitiesForSelect, setDisabilitiesForSelect] = useState<GeneralForListSchema[]>([]);
  const [reflectionsForSelect, setReflectionsForSelect] = useState<GeneralForListSchema[]>([]);
  const [gendersForSelect, setGendersForSelect] = useState<GeneralForListSchema[]>([]);
  const fcListState = useSelector(fcListSelector);
  const dispatch = useDispatch();

  const setDisabilityValue = (v: number) => {
    if (data.disabilities.filter((x) => x.item == v).length == 0) {
      const prev = { ...data };
      const newDisabilitySchema: DisabilitySchema = { item: v };
      prev.disabilities.push(newDisabilitySchema);
      setData(prev);
    }
  };

  const getSelectedDisabilityName = (item: number) => {
    let name = "";
    if (disabilitiesForSelect.length !== 0) {
      name = disabilitiesForSelect.filter((x) => x.value == item)[0].label;
    }
    return name;
  };

  const setReflectionValue = (v: number) => {
    if (data.reflections.filter((x) => x.item == v).length == 0) {
      const prev = { ...data };
      const newReflection: ChildReflectionSchema = { item: v };
      prev.reflections.push(newReflection);
      setData(prev);
    }
  };

  const getSelectedReflectionName = (item: number) => {
    let name = "";
    if (reflectionsForSelect.length !== 0) {
      name = reflectionsForSelect.filter((x) => x.value == item)[0].label;
    }
    return name;
  };

  const [selectGender, setSelectGender] = useState<number>();
  const [selectBloods, setSelectBloods] = useState<GeneralForListSchema[]>([]);
  const [validationError, setValidationError] = useState<ValidationError<D002Schema>>(initialValidationError());
  const [isValidated, setIsValidated] = useState<boolean | undefined>(undefined);
  const [showRegister, setShowRegister] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [reload, setReload] = useState(true);

  const getSelectValue = (val: number | undefined, list: GeneralForListSchema[]) => {
    if (val == undefined || val == 0) return null;
    if (list.filter(x => x.value == val).length == 0) return null;
    return list.filter(x => x.value == val)[0];
  };

  const setAddressForAPI = () => {
    changeIsLoading();
    fetchAddress(data.post_code).then((result) => {
      result !== null && setData({ ...data, address: result })
      changeIsLoading();
    }).catch((err) => {
      console.warn(err);
      changeIsLoading();
    });
  };

  const goScrollTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    })
  };

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      if (childId && reload) {
        changeIsLoading();
        fetchD002(childId, authState).then((res) => {
          if (isMounted) {
            setData(res.data);
            setSelectGender(res.data.gender);
            setReload(false);
          }
          changeIsLoading();
        }).catch((err) => {
          if (isMounted) {
            checkLoggedIn(err);
            notifyError("データの取得に失敗しました。");
            console.error(err);
          }
          changeIsLoading();
        });
      }
    };
    fetchData();
    return () => {
      isMounted = false;
    }
  }, [authState, childId, changeIsLoading, checkLoggedIn, reload]);

  useEffect(() => {
    if (userInfo?.user_type == HEADQUARTER_USER) {
      let isMounted = true;
      const fetchData = async () => {
        changeIsLoading();
        fetchFcFacilities(authState, "").then((res) => {
          if (isMounted) {
            setFcForSelect(getFcList(FC_LIST_TYPE.DEFAULT, res.data));
            dispatch(listActions.makeFcList(getFcListForRedux(res.data)));
          }
          changeIsLoading();
        }).catch((err) => {
          if (isMounted) {
            checkLoggedIn(err);
            notifyError("データの取得に失敗しました。");
            console.error(err);
          }
          changeIsLoading();
        });
      };
      if (fcForSelect.length == 0) {
        if (fcListState.select_list.length == 0) {
          fetchData();
        } else {
          setFcForSelect(getFcListFromRedux(FC_LIST_TYPE.DEFAULT, fcListState.select_list));
        }
      }
      return () => {
        isMounted = false;
      }
    }
  }, [authState, dispatch, changeIsLoading, checkLoggedIn, userInfo, fcForSelect.length, fcListState.select_list]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchGeneralsForListMulti(authState, [DISABILITIES, GENDER, BLOOD_TYPE, LIST_REFLECTION]).then((res: any) => {
        if (isMounted) {
          res.data[BLOOD_TYPE].unshift(initialGeneralForList());
          setDisabilitiesForSelect(res.data[DISABILITIES]);
          setGendersForSelect(res.data[GENDER]);
          setSelectBloods(res.data[BLOOD_TYPE]);
          setReflectionsForSelect(res.data[LIST_REFLECTION]);
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (reflectionsForSelect.length == 0) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, reflectionsForSelect.length]);

  const clickRegister = () => {
    const validatedData = validationChildModel(data);
    if (validatedData.ok) {
      changeIsLoading();
      goScrollTop();
      putD002(authState, data).then((_) => {
        if (image) {
          putChildImage(childId, image, authState).then((__) => {
            setShowRegister(false);
            notify("お子様情報を更新しました。");
            changeIsLoading();
            history.goBack();
          }).catch((err) => {
            notifyError("登録に失敗しました。");
            console.error(err);
            changeIsLoading();
          });
        } else {
          setShowRegister(false);
          notify("お子様情報を更新しました。");
          changeIsLoading();
          history.goBack();
        }
      }).catch((err) => {
        checkLoggedIn(err);
        notifyError("登録に失敗しました。");
        console.error(err);
        changeIsLoading();
      });
    } else {
      setShowRegister(false);
      notifyError("入力不正があります。");
      setIsValidated(true);
      setValidationError(validatedData.validationError);
    }
  };

  const clickDelete = () => {
    changeIsLoading();
    deleteChild(childId, authState).then((_) => {
      setShowDelete(false);
      notify("お子様を削除しました。");
      changeIsLoading();
      history.goBack();
    }).catch((err) => {
      checkLoggedIn(err);
      notifyError("削除に失敗しました。");
      console.error(err);
      changeIsLoading();
    });
  };

  useEffect(() => {
    if (isValidated) {
      const validatedData = validationChildModel(data);
      if (validatedData.ok) {
        setValidationError(initialValidationError());
      } else {
        setIsValidated(true);
        setValidationError(validatedData.validationError);
      }
    }
  }, [data, isValidated]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Title title={childId ? "お子様編集" : "お子様登録"} />
      <div className="plate t_align_center">
        {(childId != undefined && data.id > 0) &&
          <div className="on_same_row justify_center m-2 flex-wrap">
            <CustomButton
              label={"受給者証"}
              size={CB_SIZE.M}
              class={[CB_CLASS.BLUE]}
              onClick={() => { history.push(getForHistoryPush(D007Url, [data.id])) }}
            />
            <CustomButton
              label={"個別支援計画"}
              size={CB_SIZE.M}
              class={[CB_CLASS.BLUE]}
              onClick={() => history.push(getForHistoryPush(E001Url, [data.id]))}
            />
            <CustomButton
              label={"アセスメントシート"}
              size={CB_SIZE.M}
              class={[CB_CLASS.BLUE]}
              onClick={() => history.push(getForHistoryPush(D006Url, [data.id]))}
            />
            <CustomButton
              label={"トレーニング履歴"}
              size={CB_SIZE.M}
              class={[CB_CLASS.BLUE]}
              onClick={() => history.push(getForHistoryPush(H001Url, [data.id, data.facility_id]))}
            />
            <CustomButton
              label={"身長・体重履歴"}
              size={CB_SIZE.M}
              class={[CB_CLASS.BLUE]}
              onClick={() => history.push(getForHistoryPush(D003Url, [data.id]))}
            />
            <CustomButton
              label={"契約書"}
              size={CB_SIZE.M}
              class={[CB_CLASS.BLUE]}
              onClick={() => history.push(getForHistoryPush(M002Url, [data.audit_docs.filter(x => x.doc_type == AUDIT_DOC_TYPE.CONTRACT_DOCUMENT)[0].id]))}
            />
            <CustomButton
              label={"重要事項説明書"}
              size={CB_SIZE.M}
              class={[CB_CLASS.BLUE]}
              onClick={() => history.push(getForHistoryPush(M002Url, [data.audit_docs.filter(x => x.doc_type == AUDIT_DOC_TYPE.IMPORTANT_INFORMATION_MANUAL)[0].id]))}
            />
            <CustomButton
              label={"固定日時入力"}
              size={CB_SIZE.M}
              class={[CB_CLASS.BLUE]}
              onClick={() => history.push(getForHistoryPush(D010Url, [data.id]))}
            />
          </div>}
        <CustomUserIcon uploadFile={image} setUploadFile={setImage} imageUrl={data.profile_pic_url} />
      </div>
      <div className="plate">
        <CustomRow
          title="名前"
          required={true}
          error_msg={validationError.messageOf.family_name}
          content={
            <>
              <TextBox
                type="text"
                placeholder="姓"
                value={data.family_name}
                onChange={(v: any) => setData({ ...data, family_name: v })}
              />
              <TextBox
                type="text"
                placeholder="名"
                value={data.given_name}
                onChange={(v: any) => setData({ ...data, given_name: v })}
              />
            </>
          }
        />
        <CustomRow
          title="名前（フリガナ）"
          required={false}
          error_msg={validationError.messageOf.family_name_kana}
          content={
            <>
              <TextBox
                type="text"
                placeholder="セイ"
                value={data.family_name_kana}
                onChange={(v: any) => setData({ ...data, family_name_kana: v })}
              />
              <TextBox
                type="text"
                placeholder="メイ"
                value={data.given_name_kana}
                onChange={(v: any) => setData({ ...data, given_name_kana: v })}
              />
            </>
          }
        />
        <CustomRow
          title="施設"
          required={true}
          error_msg={validationError.messageOf.facility_id}
          content={
            userInfo?.user_type == HEADQUARTER_USER ? (
              <Select
                options={fcForSelect}
                value={getSelectValue(data.facility_id, fcForSelect)}
                className="D002_select"
                placeholder="施設"
                onChange={(e: any) => setData({ ...data, facility_id: e.value })}
              />
            ) : (
              <div className="item">
                {fcName}
              </div>
            )}
        />
        <CustomRow
          title="障がい"
          required={false}
          on_same_row={false}
          error_msg={validationError.messageOf.disabilities}
          content={
            <>
              <div className="mx-2 on_same_row" style={{ flexWrap: "wrap" }}>
                {data.disabilities.map((x, i) => (
                  <div className="mb-2" key={i}>
                    <CustomButton
                      label={getSelectedDisabilityName(x.item)}
                      class={[CB_CLASS.BLUE]}
                      size={CB_SIZE.S}
                      onDoubleClick={() =>
                        setData({
                          ...data,
                          disabilities: data.disabilities.filter(
                            (y) => y.item !== x.item
                          ),
                        })
                      }
                    />
                  </div>
                ))}
              </div>
              <Select
                options={disabilitiesForSelect}
                className="mx-2 D002_select"
                onChange={(e: any) => setDisabilityValue(e.value)}
                placeholder="選択"
              />
            </>
          }
        />
        <CustomRow
          title="反射"
          required={false}
          on_same_row={false}
          error_msg={validationError.messageOf.reflections}
          content={
            <>
              <div className="mx-2 on_same_row" style={{ flexWrap: "wrap" }}>
                {data.reflections.map((x, i) => (
                  <div className="mb-2" key={i}>
                    <CustomButton
                      label={getSelectedReflectionName(x.item)}
                      class={[CB_CLASS.BLUE]}
                      size={CB_SIZE.S}
                      onDoubleClick={() =>
                        setData({
                          ...data,
                          reflections: data.reflections.filter(
                            (y) => y.item !== x.item
                          ),
                        })
                      }
                    />
                  </div>
                ))}
              </div>
              <Select
                options={reflectionsForSelect}
                className="mx-2 D002_select"
                onChange={(e: any) => setReflectionValue(e.value)}
                placeholder="選択"
              />
            </>
          }
        />
        <CustomRowSplitTwo
          title_left="生年月日"
          required_left={false}
          error_msg_left={validationError.messageOf.birthday}
          content_left={
            <CustomDate
              className="mx-2"
              setter={(v: string) => setData({ ...data, birthday: v })}
              val={data.birthday}
            />
          }
          title_right="年齢"
          required_right={false}
          content_right={
            <Label value={data.birthday && calcAge(data.birthday) + " 才"} />
          }
        />
        <CustomRow
          title="性別"
          required={false}
          error_msg={validationError.messageOf.gender}
          content={
            <>
              <RadioButton
                values={gendersForSelect}
                name="gender"
                selectValue={selectGender}
                onChange={(v: any) => {
                  setData({ ...data, gender: v });
                  setSelectGender(v);
                }}
              />
              {data.gender == GENDER_SELECT_OTHER &&
                <TextBox
                  type="text"
                  placeholder="その他を選択した場合はこちらに詳細を入力してください"
                  value={data.gender_remarks}
                  onChange={(v: any) => setData({ ...data, gender_remarks: v })}
                />
              }
            </>
          }
        />
        <CustomRow
          title="血液型"
          required={false}
          error_msg={validationError.messageOf.blood_type}
          content={
            <Select
              options={selectBloods}
              value={getSelectValue(data.blood_type, selectBloods)}
              className="D002_select"
              placeholder="選択"
              onChange={(e: any) => setData({ ...data, blood_type: e.value == 0 ? undefined : e.value })}
            />
          }
        />
        <CustomRow
          title="保護者の続き柄"
          required={false}
          error_msg={validationError.messageOf.family_structure}
          content={
            <TextBox
              type="text"
              placeholder="長女"
              value={data.family_structure}
              onChange={(v: any) => setData({ ...data, family_structure: v })}
            />
          }
        />
        <CustomRow
          title="学校"
          required={false}
          error_msg={validationError.messageOf.school}
          content={
            <TextBox
              type="text"
              placeholder="学校名"
              value={data.school}
              onChange={(v: any) => setData({ ...data, school: v })}
            />
          }
        />
        <CustomRow
          title="学校電話番号"
          required={false}
          error_msg={validationError.messageOf.phone_number}
          content={
            <TextBox
              type="tel"
              placeholder="ハイフン無し"
              value={data.phone_number}
              onChange={(v: any) => setData({ ...data, phone_number: v })}
            />
          }
        />
        <CustomRow
          title={data.same_address_as_parent ? "住所" : "郵便番号"}
          required={false}
          error_msg={validationError.messageOf.post_code}
          content={
            <>
              <CheckBox
                checked={data.same_address_as_parent}
                onChange={(e: any) =>
                  setData({
                    ...data,
                    same_address_as_parent: e.target.checked,
                    post_code: e.target.checked ? undefined : data.post_code,
                    address: e.target.checked ? undefined : data.address,
                  })
                }
                label="保護者と一緒"
              />
              {!data.same_address_as_parent &&
                <>
                  <TextBox
                    type="text"
                    placeholder="ハイフン無し"
                    value={data.post_code}
                    onChange={(v: any) => setData({ ...data, post_code: v })}
                  />
                  <CustomButton
                    label={"住所自動入力"}
                    size={CB_SIZE.S}
                    class={[CB_CLASS.BLUE]}
                    onClick={() => { setAddressForAPI() }}
                  />
                </>
              }
            </>
          }
        />
        {!data.same_address_as_parent &&
          <CustomRow
            title="住所"
            required={false}
            error_msg={validationError.messageOf.address}
            content={
              <>
                <TextBox
                  type="text"
                  placeholder="住所"
                  value={data.address}
                  onChange={(v: any) => setData({ ...data, address: v })}
                />
              </>
            }
          />
        }
        <CustomRow
          title="出生時身長"
          required={false}
          error_msg={validationError.messageOf.birth_height}
          content={
            <>
              <TextBox
                type="number"
                width="150px"
                placeholder="出生時身長"
                value={data.birth_height}
                onChange={(v: any) => setData({ ...data, birth_height: v == "" ? undefined : v })}
              />
              <Label value="cm" />
            </>
          }
        />
        <CustomRow
          title="出生時体重"
          required={false}
          error_msg={validationError.messageOf.birth_weight}
          content={
            <>
              <TextBox
                type="number"
                width="150px"
                placeholder="出生時体重"
                value={data.birth_weight}
                onChange={(v: any) => setData({ ...data, birth_weight: v == "" ? undefined : v })}
              />
              <Label value="g" />
            </>
          }
        />
        <CustomRow
          title="妊娠周期"
          required={false}
          error_msg={validationError.messageOf.pregnancy_week}
          content={
            <>
              <TextBox
                type="number"
                width="150px"
                placeholder="妊娠周期"
                value={data.pregnancy_week}
                onChange={(v: any) => setData({ ...data, pregnancy_week: v })}
              />
              <Label value="週" />
            </>
          }
        />
        <CustomRow
          title="妊娠中の服薬"
          required={false}
          error_msg={validationError.messageOf.taking_medicine}
          content={
            <TextBox
              type="text"
              placeholder="ウテメリン、ダクチラン、ズファジラン、リトドリン"
              value={data.taking_medicine}
              onChange={(v: any) => setData({ ...data, taking_medicine: v })}
            />
          }
        />
        <CustomRow
          title="入会日"
          required={true}
          error_msg={validationError.messageOf.join_date}
          content={
            <CustomDate
              className="mx-2"
              val={data.join_date}
              setter={(v: string) => setData({ ...data, join_date: v })}
            />
          }
        />
        <CustomRow
          title="退会日"
          required={false}
          content={
            <CustomDate
              className="mx-2"
              val={data.end_date}
              setter={(v: string) => setData({ ...data, end_date: v })}
            />
          }
        />
        <CustomRow
          title="備考"
          required={false}
          error_msg={validationError.messageOf.remarks}
          content={
            <CustomTextarea
              placeholder="備考"
              value={data.remarks}
              onChange={(v: any) => setData({ ...data, remarks: v })}
            />
          }
        />
        {childId &&
          <CustomRow
            title="最終更新日"
            required={false}
            content={
              data.updated_at && (
                <Label
                  value={makeStringDateWithSlash(data.updated_at)}
                />
              )
            }
          />}
        <div className="plate d-flex flex-column align_center">
          <CustomButton
            label="登録"
            class={[CB_CLASS.RED]}
            onClick={() => setShowRegister(true)}
          />
          <ConfirmModal
            target="登録"
            show={showRegister}
            setShow={setShowRegister}
            func={clickRegister}
            confirmText={"登録してよろしいでしょうか。"}
          />
          {childId &&
            <>
              <CustomButton
                label="削除"
                class={[CB_CLASS.RED]}
                onClick={() => setShowDelete(true)}
              />
              <ConfirmModal
                target="削除"
                show={showDelete}
                setShow={setShowDelete}
                func={clickDelete}
                confirmText={"削除してよろしいでしょうか。"}
              />
            </>
          }
        </div>
      </div>
    </>
  );
};

export default D002;

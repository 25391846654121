import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { useSelector } from "react-redux";
import { authSelector } from "../../redux/selectors/auth.selector";
import "./D009.scss";
import { D009Schema, GeneralForListSchema, Result } from "../../generated";
import { INTERVIEWED, NOT_INTERVIEWED, STATUS_ASM_INTERVIEW, UPLOADED, initialD009Model, validationAssessmentModel } from "../../models/assessment.model";
import { ValidationError, initialValidationError } from "../../models/validate.model";
import {
	deleteAssessment,
	fetchAssessment,
	fetchGeneralsForList,
	putAssessment,
	putDoc,
} from "../../services/api.service";
import ChildInfoTop from "../../components/ChildInfoTop/ChildInfoTop";
import ConfirmModal from "../../components/ConfirmModal";
import CustomButton, { CB_CLASS } from "../../components/Button/CustomButton";
import CustomDate from "../../components/Calendar/CustomDate";
import CustomRow from "../../components/CustomRow/CustomRow";
import FileUploader from "../../components/FileUploader/FileUploader";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import Select from "../../components/Select/CustomSelect";
import Title from "../../components/Title/Title";
import { raiseAction } from "../../helper/api.helper";
import { notify, notifyError } from "../../helper/settings.helper";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import { useAuth } from "../../helper/auth.helper";
import { validationMessage } from "../../config/validationMessage";

interface iD009 {
	assessment_id: number;
	child_id: number;
}

const D009 = () => {
	const { checkLoggedIn } = useAuth();
	const location = useLocation<iD009>();
	const assessmentId = location.state.assessment_id;
	const childId = location.state.child_id;
	const history = useHistory();
	const authState = useSelector(authSelector);
	const [isLoading, changeIsLoading] = useLoadingIndicator(undefined);
	const [data, setData] = useState<D009Schema>(initialD009Model);
	const [validationError, setValidationError] = useState<ValidationError<D009Schema>>(initialValidationError());
	const [isValidated, setIsValidated] = useState<boolean | undefined>(undefined);
	const [showRegister, setShowRegister] = useState(false);
	const [showDelete, setShowDelete] = useState(false);
	const [statusForSelect, setStatusForSelect] = useState<GeneralForListSchema[]>([]);
	const [uploadFile, setUploadFile] = useState<File>();
	const [msgUploadFile, setMsgUploadFile] = useState("");
	const [reload, setReload] = useState(true);

	const getSelectValue = (val: number, selectList: GeneralForListSchema[]) => {
		if (val == 0) return;
		return selectList.filter((x) => x.value == val)[0];
	};

	useEffect(() => {
		if (reload) {
			let isMounted = true;
			const fetchData = async () => {
				changeIsLoading();
				fetchAssessment(authState, childId, assessmentId).then((res) => {
					if (isMounted) {
						setData(res.data);
						setReload(false);
					}
					changeIsLoading();
				}).catch((err) => {
					if (isMounted) {
						checkLoggedIn(err);
						notifyError("データの取得に失敗しました。");
						console.error(err);
					}
					changeIsLoading();
				});
			};
			fetchData();
			return () => {
				isMounted = false;
			}
		}
	}, [authState, changeIsLoading, checkLoggedIn, childId, assessmentId, reload]);

	useEffect(() => {
		let isMounted = true;
		const fetchData = async () => {
			changeIsLoading();
			fetchGeneralsForList(authState, STATUS_ASM_INTERVIEW).then((res) => {
				if (isMounted) {
					setStatusForSelect(res.data);
				}
				changeIsLoading();
			}).catch((err) => {
				if (isMounted) {
					checkLoggedIn(err);
					notifyError("データの取得に失敗しました。");
					console.error(err);
				}
				changeIsLoading();
			});
		};
		if (statusForSelect.length == 0) {
			fetchData();
		}
		return () => {
			isMounted = false;
		}
	}, [authState, changeIsLoading, checkLoggedIn, statusForSelect.length]);

	const clickRegister = () => {
		const validatedData = validationAssessmentModel(data);
		if (!data.file_url && !uploadFile) {
			validatedData.ok = false;
			setMsgUploadFile(validationMessage.emptyUploadFile);
		}
		if (validatedData.ok) {
			changeIsLoading();
			setMsgUploadFile("");
			putAssessment(data, authState).then((res) => {
				if (uploadFile) {
					putDoc(res.data, uploadFile, authState).then((__) => {
						setShowRegister(false);
						notify("アセスメントシートを更新しました。");
						changeIsLoading();
						history.goBack();
					}).catch((err) => {
						notifyError("登録に失敗しました。");
						console.error(err);
						changeIsLoading();
					});
				} else {
					setShowRegister(false);
					notify("アセスメントシートを更新しました。");
					changeIsLoading();
					history.goBack();
				}
			}).catch((err) => {
				checkLoggedIn(err);
				notifyError("登録に失敗しました。");
				console.error(err);
				changeIsLoading();
			});
		} else {
			setShowRegister(false);
			notifyError("入力不正があります。");
			setIsValidated(true);
			setValidationError(validatedData.validationError);
		}
	};

	const success_delete = () => {
		setShowDelete(false);
		notify("アセスメントシートを削除しました。");
		changeIsLoading();
		history.goBack();
	}

	const clickDelete = () => {
		changeIsLoading();
		deleteAssessment(assessmentId, authState).then((res) => {
			raiseAction(
				res.data,
				() => success_delete(),
				(v: Result) => {
					notifyError(String(v.msg));
					setShowDelete(false);
					changeIsLoading();
				});
		}).catch((err) => {
			checkLoggedIn(err);
			notifyError("削除に失敗しました。");
			console.error(err);
			changeIsLoading();
		});
	};

	useEffect(() => {
		if (isValidated) {
			setMsgUploadFile("");
			const validatedData = validationAssessmentModel(data);
			if (!data.file_url && !uploadFile) {
				validatedData.ok = false;
				setMsgUploadFile(validationMessage.emptyUploadFile);
			}
			if (validatedData.ok) {
				setValidationError(initialValidationError());
			} else {
				setIsValidated(true);
				setValidationError(validatedData.validationError);
			}
		}
	}, [data, isValidated, uploadFile]);

	return (
		<>
			<LoadingSpinner isLoading={isLoading} />
			<Title title={assessmentId ? "アセスメントシート編集" : "アセスメントシート登録"} />
			<div className="plate">
				<ChildInfoTop
					user_type={authState.userInfo?.user_type}
					profile_pic_url={data?.child_profile_pic_url}
					id={data.child_id}
					name={data.child_name}
					name_kana={data.child_name_kana}
					facility_name={data.facility_name}
					no_underbar={false}
				/>
				<CustomRow
					title="回答日"
					required={true}
					error_msg={validationError?.messageOf?.answer_date}
					content={<CustomDate val={data.answer_date} className="mx-2" setter={(v: string) => setData({ ...data, answer_date: v })} />}
				/>
				<CustomRow
					title="ファイル"
					required={true}
					error_msg={msgUploadFile}
					content={<FileUploader setFile={(file: File) => setUploadFile(file)} url={data.file_url} setMsg={setMsgUploadFile} />}
				/>
				<CustomRow
					title="面談ステータス"
					required={true}
					content={
						<Select
							value={getSelectValue(data.is_interviewed ? INTERVIEWED : NOT_INTERVIEWED, statusForSelect)}
							options={statusForSelect}
							className="D009_select mx-2"
							onChange={(e: any) => {
								setData({ ...data, is_interviewed: e.value === UPLOADED ? true : false });
							}}
							placeholder="ステータス"
						/>
					}
				/>
				<div className="plate d-flex flex-column align_center">
					<CustomButton
						label="登録"
						class={[CB_CLASS.RED]}
						onClick={() => setShowRegister(true)}
					/>
					<ConfirmModal
						target="登録"
						show={showRegister}
						setShow={setShowRegister}
						func={clickRegister}
						confirmText={"登録してよろしいでしょうか。"}
					/>
					{assessmentId &&
						<>
							<CustomButton
								label="削除"
								class={[CB_CLASS.RED]}
								onClick={() => setShowDelete(true)}
							/>
							<ConfirmModal
								target="削除"
								show={showDelete}
								setShow={setShowDelete}
								func={clickDelete}
								confirmText={"削除してよろしいでしょうか。"}
							/>
						</>
					}
				</div>
			</div>
		</>
	)
};

export default D009;

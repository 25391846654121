import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { authSelector, userInfoSelector } from "../../redux/selectors/auth.selector";
import "./E001.scss";
import { ISP_CLOSED, ISP_PLAN, ISP_PROCESSING_, ISP_PROCESSING_DONE_MONITORING, STATUS_INDIVIDUAL_SUPPORT_PLAN } from "../../models/individual_support_plan.model";
import {
	FCStaffInfoSchema,
	GeneralForListSchema,
	FCFacilitiesSchema,
	E001Schema,
} from "../../generated";
import { initialGeneralForList } from "../../models/general.model";
import { HEADQUARTER_USER, STAFF } from "../../constants";
import {
	fetchFcFacilities,
	fetchGeneralsForList,
	fetchE001,
	fetchE001ByChildId,
	fetchE001Count,
} from "../../services/api.service";
import { D002Url, E002Url, getForHistoryPush } from "../../helper/url.helper";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import ChildInfoTop from "../../components/ChildInfoTop/ChildInfoTop";
import CustomButton, { CB_CLASS } from "../../components/Button/CustomButton";
import Title from "../../components/Title/Title";
import TextBox from "../../components/TextBox/TextBox";
import Pict, { SIZES } from "../../components/Pict/User";
import Status, { STATUS_BLUE, STATUS_GRAY, STATUS_GREEN, STATUS_RED, StatusSchema } from "../../components/Status/Status";
import Select from "../../components/Select/CustomSelect";
import moment from "moment";
import { useAuth } from "../../helper/auth.helper";
import { handleDownload } from "../../helper/download.helper.";
import CustomDate from "../../components/Calendar/CustomDate";
import { notifyError } from "../../helper/settings.helper";
import PaginationTableLite from "../../components/PaginationTableLite/PaginationTableLite";
import { fcListSelector } from "../../redux/selectors/list.selector";
import { listActions } from "../../redux/actions/list.actions";
import { FC_LIST_TYPE, getFcList, getFcListForRedux, getFcListFromRedux } from "../../helper/list.helper";

interface iE001 {
	child_id: number | undefined;
}

interface iSearch {
	facilityId: number;
	childName: string | undefined;
	beginDate: string | undefined;
	endDate: string | undefined;
}

const E001 = () => {
	const maxWidth = 1300;
	const { checkLoggedIn } = useAuth();
	const authState = useSelector(authSelector);
	const userInfo = useSelector(userInfoSelector);
	const history = useHistory();
	const location = useLocation<iE001>();
	const childId = location.state.child_id;
	const [isLoading, changeIsLoading] = useLoadingIndicator();
	const [data, setData] = useState<E001Schema[]>([]);
	const [getData, setGetData] = useState(true);
	const facilityId = userInfo?.user_type == STAFF ? (userInfo as FCStaffInfoSchema)?.facilities[0].facility_id : 0;
	const [fcForSelect, setFcForSelect] = useState<GeneralForListSchema[]>([]);
	const [search, setSearch] = useState<iSearch>({
		facilityId: facilityId,
		childName: "",
		beginDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
		endDate: undefined,
	});
	const [statusForSelect, setStatusForSelect] = useState<GeneralForListSchema[]>([]);
	const status_list: StatusSchema[] = [
		{ num: ISP_PLAN, class: STATUS_RED },
		{ num: ISP_PROCESSING_, class: STATUS_GREEN },
		{ num: ISP_PROCESSING_DONE_MONITORING, class: STATUS_BLUE },
		{ num: ISP_CLOSED, class: STATUS_GRAY },
	];
	const [currentPage, setCurrentPage] = useState(1);
	const [recordCount, setRecordCount] = useState(0);
	const limitCount = 9;
	const fcListState = useSelector(fcListSelector);
	const dispatch = useDispatch();

	useEffect(() => {
		if (userInfo?.user_type == HEADQUARTER_USER) {
			let isMounted = true;
			const fetchData = async () => {
				changeIsLoading();
				fetchFcFacilities(authState, "").then((res) => {
					if (isMounted) {
						setFcForSelect(getFcList(FC_LIST_TYPE.ADD_INIT, res.data));
						dispatch(listActions.makeFcList(getFcListForRedux(res.data)));
					}
					changeIsLoading();
				}).catch((err) => {
					notifyError("データの取得に失敗しました。");
					console.error(err);
					changeIsLoading();
				});
			};
			if (fcForSelect.length == 0) {
				if (fcListState.select_list.length == 0) {
					fetchData();
				} else {
					setFcForSelect(getFcListFromRedux(FC_LIST_TYPE.ADD_INIT, fcListState.select_list));
				}
			}
			return () => {
				isMounted = false;
			}
		}
	}, [authState, dispatch, changeIsLoading, checkLoggedIn, userInfo, fcForSelect.length, fcListState.select_list]);

	useEffect(() => {
		let isMounted = true;
		const fetchData = async () => {
			changeIsLoading();
			fetchGeneralsForList(authState, STATUS_INDIVIDUAL_SUPPORT_PLAN).then((res) => {
				if (isMounted) {
					setStatusForSelect(res.data);
				}
				changeIsLoading();
			}).catch((err) => {
				if (isMounted) {
					checkLoggedIn(err);
					notifyError("データの取得に失敗しました。");
					console.error(err);
				}
				changeIsLoading();
			});
		};
		if (statusForSelect.length == 0) {
			fetchData();
		}
		return () => {
			isMounted = false;
		}
	}, [authState, changeIsLoading, checkLoggedIn, statusForSelect.length]);

	useEffect(() => {
		let isMounted = true;
		const fetchData = async () => {
			changeIsLoading();
			if (childId) {
				fetchE001ByChildId(authState, childId).then((res) => {
					if (isMounted) {
						setData(res.data);
					}
					changeIsLoading();
				}).catch((err) => {
					if (isMounted) {
						checkLoggedIn(err);
						notifyError("データの取得に失敗しました。");
						console.error(err);
					}
					changeIsLoading();
				});
			} else {

				try {
					const [resCount, resData] = await Promise.all([
						fetchE001Count(authState, search.facilityId, search.beginDate, search.endDate, search.childName),
						fetchE001(authState, search.facilityId, search.beginDate, search.endDate, search.childName, (currentPage - 1) * limitCount, limitCount)
					]);

					if (isMounted) {
						setRecordCount(resCount.data);
						setData(resData.data);
						setGetData(false);
					}
					changeIsLoading();
				} catch (err) {
					if (isMounted) {
						checkLoggedIn(err);
						notifyError("データの取得に失敗しました。");
						console.error(err);
					}
					changeIsLoading();
				}
			}
		};
		if (getData) {
			fetchData();
		}
		return () => {
			isMounted = false;
		}
	}, [authState, changeIsLoading, checkLoggedIn, search, childId, getData, currentPage]);

	useEffect(() => {
		if (userInfo?.user_type == HEADQUARTER_USER) {
			let isMounted = true;
			const fetchData = async () => {
				changeIsLoading();
				fetchFcFacilities(authState, "").then((res) => {
					if (isMounted) {
						const forSelect: GeneralForListSchema[] = [initialGeneralForList()];
						res.data.map((x: FCFacilitiesSchema, i) => {
							forSelect.push({
								value: x.id,
								label: x.facility_name,
							});
						});
						setFcForSelect(forSelect);
					}
					changeIsLoading();
				}).catch((err) => {
					if (isMounted) {
						checkLoggedIn(err);
						notifyError("データの取得に失敗しました。");
						console.error(err);
					}
					changeIsLoading();
				});
			};
			if (fcForSelect.length == 0) {
				fetchData();
			}
			return () => {
				isMounted = false;
			}
		}
	}, [authState, changeIsLoading, checkLoggedIn, userInfo, fcForSelect.length]);

	const getSelectValue = (val: number, selectList: GeneralForListSchema[]) => {
		if (val == 0) return null;
		return selectList.filter(x => x.value == val)[0];
	};

	const changeCurrentPage = (v: number) => {
		setGetData(true);
		setCurrentPage(v);
	};

	return (
		<>
			<LoadingSpinner isLoading={isLoading} />
			<Title title="個別支援計画一覧" />
			<div className="plate">
				<div className="display_flex justify" style={{ maxWidth: maxWidth }}>
					{(childId && data.length > 0) ? (
						<ChildInfoTop
							user_type={authState.userInfo?.user_type}
							profile_pic_url={data[0].profile_pic_url}
							id={childId}
							name={data[0].child_name}
							name_kana={data[0].child_name_kana}
							facility_name={data[0].facility_name}
							no_underbar={true}
						/>
					) : (
						<div className="d-flex on_same_row flex-wrap">
							{userInfo?.user_type == HEADQUARTER_USER &&
								<div className="item">
									<Select
										value={getSelectValue(search.facilityId, fcForSelect)}
										options={fcForSelect}
										className="E001_select"
										onChange={(e: any) => {
											setSearch({ ...search, facilityId: e.value });
											setGetData(true);
										}}
										placeholder="施設"
									/>
								</div>
							}
							<div className="d-flex mx-2">
								<CustomDate val={search.beginDate} setter={(v: string) => {
									setSearch({ ...search, beginDate: v });
									setGetData(true);
								}} />
								<div className="wave_str">～</div>
								<CustomDate val={search.endDate} setter={(v: string) => {
									setSearch({ ...search, endDate: v });
									setGetData(true);
								}} />
							</div>
							<TextBox
								class="item"
								width="200px"
								placeholder="お子様名"
								value={search.childName}
								onChange={(value: string) => {
									setSearch({ ...search, childName: value });
									setGetData(true);
								}}
							/>
						</div>
					)}
					{childId &&
						<div className="on_same_row">
							<CustomButton
								label="入力用ファイルダウンロード"
								class={[CB_CLASS.BLUE]}
								onClick={() => (data[0].template_url && handleDownload(data[0].template_url))}
							/>
							<CustomButton
								label="＋個別支援計画を追加"
								class={[CB_CLASS.RED]}
								onClick={() => history.push(getForHistoryPush(E002Url, [0, childId]))}
							/>
						</div>
					}
				</div>
			</div>

			<div>
				<PaginationTableLite
					currentPage={currentPage}
					setCurrentPage={changeCurrentPage}
					recordCount={recordCount}
					limit={limitCount}
					headers={childId ?
						["対象期間", "ステータス", "更新日", "更新者"]
						: userInfo?.user_type == HEADQUARTER_USER
							? ["所属施設", "アイコン", "お子様名", "対象期間", "ステータス", "更新日", "更新者"]
							: ["アイコン", "お子様名", "対象期間", "ステータス", "更新日", "更新者"]
					}
					list={data?.map((x, i) => (
						<tr key={i} onClick={() => history.push(getForHistoryPush(E002Url, [x.id, x.child_id]))}>
							{(userInfo?.user_type == HEADQUARTER_USER && !childId) &&
								<td className="item">{x.facility_name}</td>
							}
							{!childId &&
								<>
									<td className="item"><Pict url={x.profile_pic_url} size={SIZES.M} func={() => history.push(getForHistoryPush(D002Url, [x.child_id]))} /></td>
									<td className="item">{x.child_name}</td>
								</>
							}
							<td className="item">
								{moment(x.start_date).format("YYYY/M/D") + "~" + moment(x.start_date).add(6, "months").add(-1, "days").format("YYYY/M/D")}
							</td>
							<td className="item" align="center">
								<Status
									label_list={statusForSelect}
									color_list={status_list}
									status={x.status}
									max_width={250}
								/>
							</td>
							<td className="item">{moment(x.updated_at).format("YYYY/M/D")}</td>
							<td className="item">{x.updated_by}</td>
						</tr>
					))}
				/>
			</div>
		</>
	);
};

export default E001;

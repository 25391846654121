import "./Icon.scss";
import { useState, useRef } from "react";
import { CgPlayButtonR } from "react-icons/cg";
import { COLOR_BLUE } from "../../constants";
import ToolTip from "../ToolTip/ToolTip";

function Play(props: any) {
  const [isVideoVisible, setVideoVisible] = useState(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const videoElement = document.getElementById("video");
  videoElement?.addEventListener("webkitendfullscreen", () => {
    setVideoVisible(false);
  });

  return (
    <>
      {props.video_url &&
        <>
          <div>
            {props.video_preparing ? (
              <ToolTip text="準備中" content={
                <CgPlayButtonR
                  color="gray"
                  size={36}
                  style={{ marginLeft: 10, marginRight: 10 }}
                  onClick={(event) => {
                    event.stopPropagation();
                  }} />
              } />
            ) : (
              <CgPlayButtonR
                className="icon hover_blue"
                color={COLOR_BLUE}
                size={36}
                onClick={(event) => {
                  event.stopPropagation();
                  setVideoVisible(true);
                  if (videoRef.current) {
                    videoRef.current.play();
                    videoRef.current.requestFullscreen();
                    videoRef.current.addEventListener("fullscreenchange", () => {
                      if (!document.fullscreenElement) {
                        setVideoVisible(false);
                      }
                    });
                  }
                }} />
            )}
          </div>
          <video
            id="video"
            ref={videoRef}
            controls
            src={props.video_url}
            hidden={!isVideoVisible}
          />
        </>
      }
    </>
  );
};

export default Play;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { authSelector } from "../../redux/selectors/auth.selector";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useLocation } from "react-router-dom";
import { SRFBaseSchema } from "../../generated";
import CustomButton, { CB_ALIGN, CB_CLASS } from "../../components/Button/CustomButton"
import Title from "../../components/Title/Title"
import SelectForRow from "../../components/Select/SelectForRow"
import Delete from "../../components/Icon/Delete"
import IncDecForRow from "../../components/IncDec/IncDecForRow"
import CustomDateForRow from "../../components/Calendar/CustomDateForRow"
import { Alert } from "react-bootstrap";
import {
  SRFCreateSchema
} from "../../generated";
import { DEFAULT_TRAINING_MINUTE } from "../../constants.js"
import {
  TRAIN_MAX_NUM_IN_A_FRAME
} from "../../constants.js"
import FacilityName from "../../components/FacilityName/FacilityName";
import {
  initialScheduleReservationFrameCreateSchema,
} from "../../models/scheduleReservationFrame.model";
import { fetchSRFList, putSRF } from "../../services/api.service";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import { format } from "date-fns-tz";
import ConfirmModal from "../../components/ConfirmModal";
import { notify, notifyError } from "../../helper/settings.helper";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { K002Url, getForHistoryPush } from "../../helper/url.helper";
import { useAuth } from "../../helper/auth.helper";
import CustomTime from "../../components/Calendar/CustomTime";
import { addMinutes } from "../../helper/time.helper";

interface K006Location {
  facility_id: number;
}

const K006: React.VFC = () => {
  const { checkLoggedIn } = useAuth();
  const authState = useSelector(authSelector);
  const history = useHistory();
  const location = useLocation<K006Location>();
  const fcId = location.state.facility_id;

  const [items, setItems] = useState<SRFCreateSchema[]>([initialScheduleReservationFrameCreateSchema()]);
  const [existsList, setExistsList] = useState<SRFBaseSchema[]>([]);
  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const [errorList, setErrorList] = useState<string[]>([]);
  const [showRegister, setShowRegister] = useState(false);
  const [isError, setIsError] = useState(false);
  const [reload, setReload] = useState(true);

  useEffect(() => {
    if (reload) {
      let isMounted = true;
      const fetchData = async () => {
        changeIsLoading();
        fetchSRFList(authState, fcId).then((res) => {
          if (isMounted) {
            setExistsList(res.data);
            setReload(false);
          }
          changeIsLoading();
        }).catch((err) => {
          if (isMounted) {
            checkLoggedIn(err);
            notifyError("データの取得に失敗しました。");
            console.error(err);
          }
          changeIsLoading();
        });
      };
      fetchData();
      return () => {
        isMounted = false;
      }
    }
  }, [authState, changeIsLoading, checkLoggedIn, fcId, reload]);

  const deleteItem = (index: number) => {
    const item_list = [...items];
    item_list[index] = initialScheduleReservationFrameCreateSchema();
    item_list.splice(index, 1);
    setItems(item_list);
  };

  const setStartTime = (v: string, i: number) => {    
    const updatedItems = [...items];
    updatedItems[i] = {
      ...items[i], 
      start_time: v, 
      end_time: addMinutes(v, DEFAULT_TRAINING_MINUTE),
    };
    setItems(updatedItems);
  };

  const setEndTime = (v: string, index: number) => {
    const item_list = [...items];
    const item = item_list[index];
    item.end_time = v;
    setItems(item_list);
  };

  const setDate = (e: any, index: number) => {
    // 過去の日付は入力不可
    if (new Date(e.target.value) < new Date(format(new Date(), "yyyy-MM-dd"))) return;
    const item_list = [...items];
    const item = item_list[index];
    item.date = e.target.value;
    setItems(item_list);
  };

  const incrementNum = (index: number) => {
    const item_list = [...items];
    const item = item_list[index];
    item.num++;
    setItems(item_list);
  };

  const decrementNum = (index: number) => {
    const item_list = [...items];
    const item = item_list[index];
    item.num--;
    setItems(item_list);
  };

  useEffect(() => {
    setIsError(false);
    setErrorList(items.map(x => {
      const filteredByDate = existsList.filter(y => y.date == x.date && y.start_time == x.start_time && y.end_time == x.end_time);
      if (filteredByDate.length > 0) {
        setIsError(true);
        return `${x.date} ${x.start_time}-${x.end_time}は既に登録されています。`;
      }
      if (items.filter(y => y.date == x.date && y.start_time == x.start_time && y.end_time == x.end_time).length > 1) {
        setIsError(true);
        return "同一日時が選択されています。";
      }
      return "";
    }));
  }, [items, existsList]);

  const clickRegister = () => {
    if (!isError) {
      changeIsLoading();
      putSRF(authState, fcId, items).then((_) => {
        setShowRegister(false);
        notify("予約枠情報を登録しました。");
        changeIsLoading();
        history.push(getForHistoryPush(K002Url, [fcId, 0, 0, 0]));
      }).catch((err) => {
        checkLoggedIn(err);
        setShowRegister(false);
        notifyError("登録に失敗しました。");
        changeIsLoading();
        setReload(true);
      });
    } else {
      setShowRegister(false);
      notifyError("入力不正があります。");
    }
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Title title="予約枠追加" />
      <FacilityName fcId={fcId} authState={authState} />
      <div className="plate">
        <table>
          <thead>
            <tr>
              <th>日付</th>
              <th>時間</th>
              <th>追加枠数</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {items.map((x, i) => (
              <React.Fragment key={i}>
                {errorList[i] ? (
                  <>
                    <tr className="hover_white">
                      <td className="item" colSpan={3}>
                        <Alert
                          variant="danger"
                          style={{ padding: 5, fontSize: 14, margin: 0 }}
                        >
                          <p className="text-wrap">{errorList[i]}</p>
                        </Alert>
                      </td>
                    </tr>
                  </>
                ) : (<></>)}
                <tr className="hover_white">
                  <td className="item">
                    <CustomDateForRow val={x.date} index={i} setter={setDate} width="90px" />
                  </td>
                  <td className="on_same_row item cursor_default" >
                    <CustomTime val={x.start_time} setter={(v: string) => setStartTime(v, i)} />
                    <div className="wave_str">～</div>
                    <CustomTime val={x.end_time} setter={(v: string) => setEndTime(v, i)} />
                  </td>
                  <td className="item">
                    <IncDecForRow val={x.num} index={i} inc={incrementNum} dec={decrementNum} max_val={TRAIN_MAX_NUM_IN_A_FRAME} />
                  </td>
                  <td className="item">
                    {i > 0 &&
                      <Delete func={() => { deleteItem(i) }} />
                    }
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      <div className="plate">
        <CustomButton label="＋行を追加" align={CB_ALIGN.LEFT} class={[CB_CLASS.RED]} onClick={() => setItems([...items, initialScheduleReservationFrameCreateSchema()])} />
        <CustomButton label="登録" align={CB_ALIGN.LEFT} class={[CB_CLASS.RED]} onClick={() => setShowRegister(true)} />
        <ConfirmModal target="登録" show={showRegister} setShow={setShowRegister} func={clickRegister}
          confirmText={"登録してよろしいでしょうか。"}
        />
      </div>
    </>
  );
};

export default K006;

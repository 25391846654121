import React from "react";
import { Navbar } from "react-bootstrap";
import "./Header.scss";

const Header = () => {
  return (
    <Navbar className="header" expand="lg" variant="dark">
      <h1 style={{ color: "white", textAlign: "center", letterSpacing: "0.2rem" }}>S.I.C.KIDS</h1>
    </Navbar>
  );
};

export default Header;

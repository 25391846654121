import { validationMessage } from "../config/validationMessage";
import { EventV003Schema, EventV002Schema } from "../generated";
import { initialValidationError, ValidatedData } from "./validate.model";

export const EVENT_STATUS = {
  UNPUBLISHED: 1,
  PUBLISHED: 2,
  END: 3
};

export const EVENT_DETAIL_STATUS = {
  RESERVED: 1,
  APPROVED: 2,
  CANCELED: 3,
};

export interface EventCreateModel {
  main_title: string;
  sub_title?: string;
  description: string;
  dates: string;
  limit?: number;
  start_time: string;
  end_time: string;
}

export const initialEventV002Model = (): EventV002Schema => ({
  id: 0,
  main_title: "",
  sub_title: undefined,
  description: "",
  dates: "",
  limit: 0,
  start_time: "",
  end_time: "",
  status: 1,
  num: 0,
  event_details: []
});

export const initialEventV003Model = (fcId: number | undefined): EventV003Schema => ({
  id: 0,
  facility_id: fcId,
  main_title: "",
  sub_title: undefined,
  description: "",
  dates: "",
  limit: 0,
  start_time: "10:00",
  end_time: "10:00",
  status: 1,
});

export const validationV003Model = (
  data: EventV003Schema
): ValidatedData<EventV003Schema> => {
  const error = initialValidationError<EventV003Schema>();
  let ok = true;

  if (!data.main_title) {
    error.messageOf.main_title = validationMessage.emptyEventTitle;
    ok = false;
  }

  if (data.main_title.length > 40) {
    error.messageOf.main_title = validationMessage.limitEventTitleLength;
    ok = false;
  }

  if (data.facility_id == 0) {
    error.messageOf.facility_id = validationMessage.noEnteredCreatedBy;
    ok = false;
  }

  if (data.description.length > 400) {
    error.messageOf.description = validationMessage.limitEventDescriptionLength;
    ok = false;
  }

  if (data.limit > 999) {
    error.messageOf.limit = validationMessage.outOfMaxRangeEventLimit;
    ok = false;
  }

  if (!data.dates) {
    error.messageOf.dates = validationMessage.emptyEventDate;
    ok = false;
  }

  if (ok) {
    return {
      ok: true,
      validatedData: data,
    };
  }

  return {
    ok: false,
    validationError: error,
  };
};

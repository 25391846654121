import "./ChatBubble.scss";
import Pict, { SIZES } from "../../components/Pict/User";
import Linkify from "linkify-react";

function ChatBubble(props: any) {
	let myTalk: boolean = props.isTalkMe;
	let message: string = props.message;
	return (
		<>
			{props.sendDate &&
				<div className="send_date">
					<p>{props.sendDate}</p>
				</div>
			}
			<div className={myTalk ? "chat_mime" : "chat_you"}>
				{!myTalk && <Pict url={props.profile_pic_url} size={SIZES.M}/>}
				<p className="bubble" style={{whiteSpace: "pre-line"}}>
					<Linkify options={{ target: "_blank", rel: "noopener noreferrer"}}>
						{message}
					</Linkify>
				</p>
				<div className="send_info">
					<p>{props.isRead && "既読"}</p>
					<p>{props.sendTime}</p>
				</div>
			</div>
		</>
		
  )
}

export default ChatBubble;

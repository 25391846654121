import React, { useRef, useState } from "react";
import "./CustomImage.scss";
import Pict, { SIZES } from "../../components/Pict/User";
import Plus from "../Icon/Plus";

function CustomUserIcon(props: any) {
  const imageRef = useRef<HTMLInputElement | null>(null);
  const [imageUrl, setImageUrl] = useState("");

  const onChangeInputFile = (e: React.ChangeEvent<HTMLInputElement>, p: any) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      p.setUploadFile(file);
      setImageUrl(URL.createObjectURL(file));
    }
  };

  return (
    <>
      <div className="d-flex justify-content-center">
        <div className="position-relative">
          <img
            src={(props.uploadFile ? imageUrl : props.imageUrl) || `${process.env.PUBLIC_URL}/img/staff_register.png`}
            width={SIZES.LL}
            height={SIZES.LL}
            style={{ objectFit: "contain" }}
          />
          <Plus
            class="position-absolute custom-user-icon"
            func={(e: any) => {
              imageRef.current?.click();
            }}
            color={"blue"}
          />
          <input
            type="file"
            ref={imageRef}
            hidden
            accept="image/*"
            onChange={(e) => onChangeInputFile(e, props)}
          />
        </div>
      </div>
    </>
  );
}

export default CustomUserIcon;

import { applyMiddleware, compose, createStore } from "redux";
import rootReducer, { getEntities, getQueries } from "./reducers";
import { queryMiddleware } from "redux-query";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { load, save } from "redux-localstorage-simple";

const loggerMiddleware = createLogger({
  collapsed: true,
  diff: true,
});

const middlewareApplied =
  process.env.REACT_APP_DEBUG_MODE === "false"
    ? applyMiddleware(thunkMiddleware)
    : applyMiddleware(thunkMiddleware, loggerMiddleware);

const createStoreWithMiddleware = applyMiddleware(save());

const configureStore = () => {
  const enhancer = compose(middlewareApplied, createStoreWithMiddleware);
  return createStore(rootReducer, load(), enhancer);
};

const store = configureStore();

export default store;

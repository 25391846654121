import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authSelector, userInfoSelector } from "../../redux/selectors/auth.selector";
import "./L008.scss";
import { fetchOrdersInCart, putOrders } from "../../services/api.service";
import { FCStaffInfoSchema, L008EquipmentSchema } from "../../generated";
import CustomButton, { CB_CLASS } from "../../components/Button/CustomButton";
import CustomModal from "../../components/CustomModal/CustomModal";
import Label from "../../components/Label/Label";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { notify, notifyError } from "../../helper/settings.helper";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import EquipmentPict from "../../components/Pict/EquipmentPict";
import IncDecForRow from "../../components/IncDec/IncDecForRow";
import { cartSelector } from "../../redux/selectors/cart.selector";
import PaginationTable from "../../components/PaginationTable/PaginationTable";
import Delete from "../../components/Icon/Delete";
import Download from "../../components/Icon/Download";
import { cartActions } from "../../redux/actions/cart.actions";
import { useAuth } from "../../helper/auth.helper";
import { CALC_TAX_RATE } from "../../constants";

interface L008Props {
  showL008Modal: boolean;
  setShowL008Modal: React.Dispatch<React.SetStateAction<boolean>>;
}

const L008: React.VFC<L008Props> = ({
  showL008Modal,
  setShowL008Modal,
}) => {
  const { checkLoggedIn } = useAuth();
  const dispatch = useDispatch();
  const authState = useSelector(authSelector);
  const userInfo = useSelector(userInfoSelector);
  const facilityId = (userInfo as FCStaffInfoSchema)?.facilities[0].facility_id || 0;
  const cartState = useSelector(cartSelector);
  const [isLoading, changeIsLoading] = useLoadingIndicator(undefined);
  const [data, setData] = useState<L008EquipmentSchema[]>([]);
  const reducer = (sum: number, currentValue: number) => sum + currentValue;
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchOrdersInCart(cartState.order_list.map((x) => x.equipment_id), authState).then((res) => {
        if (isMounted) {
          setData(res.data);
          setAmount(res.data.map((x) => Math.floor(x.price * CALC_TAX_RATE) * cartState.order_list.filter(order => order.equipment_id == x.id)[0].num).reduce(reducer, 0));
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    fetchData();
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, cartState]);

  const clickRegister = () => {
    changeIsLoading();
    putOrders({ facility_id: facilityId, cart_items: cartState.order_list }, authState).then((res) => {
      changeIsLoading();
      setShowL008Modal(false);
      dispatch(cartActions.clearCart());
      notify("備品情報を更新しました。");
    }).catch((err) => {
      checkLoggedIn(err);
      console.error(err);
      notifyError("エラーが発生しました。");
      changeIsLoading();
    })
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <CustomModal
        show={showL008Modal}
        setShow={setShowL008Modal}
        size={"xl"}
        contents={
          cartState.order_list.length > 0 ?
            <>
              <PaginationTable
                limit={9}
                headers={["画像", "備品名", "品番", "大きさ", "色", "値段", "税込", "数量", "", ""]}
                max_width={1100}
                isUnnecessary={true}
                list={data.map((x, i) => (
                  <tr key={i}>
                    <td className="item"><EquipmentPict url={x.image_url} /></td>
                    <td className="item hide_over" style={{ maxWidth: "150px" }}>{x.name}</td>
                    <td className="item hide_over" style={{ maxWidth: "150px" }}>{x.serial}</td>
                    <td className="item hide_over" style={{ maxWidth: "100px" }}>{x.size || "設定なし"}</td>
                    <td className="item hide_over" style={{ maxWidth: "100px" }}>{x.color || "設定なし"}</td>
                    <td className="item L008-control">{x.price.toLocaleString()}</td>
                    <td className="item L008-control">{Math.floor(x.price * CALC_TAX_RATE).toLocaleString()}</td>
                    <td className="item">
                      <IncDecForRow
                        className="justify-content-center"
                        val={cartState.order_list.filter(order => order.equipment_id == x.id)[0]?.num || 0}
                        inc={() => dispatch(cartActions.incrementCartItemNum(x.id))}
                        dec={() => dispatch(cartActions.decrementCartItemNum(x.id))}
                      />
                    </td>
                    <td className="item icon-width"><Delete func={() => dispatch(cartActions.deleteCart(x.id))} /></td>
                  </tr>
                ))}
              />
              <div className="d-flex flex-column align_center">
                {data.length > 0 &&
                  <div className="amount_label">
                    {"合計金額（税込） : " + amount.toLocaleString() + " 円 （送料別途）"}
                  </div>
                }
                <CustomButton
                  label="発注"
                  class={[CB_CLASS.RED]}
                  onClick={() => clickRegister()}
                />
              </div>
            </>
            : <Label className="w-100 t_align_center" value={"カートに商品がありません。"} />}
      />
    </>
  )
}
export default L008;

export const HEADQUARTER_USER = "headquarter_user";
export const STAFF = "staff";
export const PARENT = "parent";
export const TIME_LIST = [
  "8:00",
  "8:15",
  "8:30",
  "8:45",
  "9:00",
  "9:15",
  "9:30",
  "9:45",
  "10:00",
  "10:15",
  "10:30",
  "10:45",
  "11:00",
  "11:15",
  "11:30",
  "11:45",
  "12:00",
  "12:15",
  "12:30",
  "12:45",
  "13:00",
  "13:15",
  "13:30",
  "13:45",
  "14:00",
  "14:15",
  "14:30",
  "14:45",
  "15:00",
  "15:15",
  "15:30",
  "15:45",
  "16:00",
  "16:15",
  "16:30",
  "16:45",
  "17:00",
  "17:15",
  "17:30",
  "17:45",
  "18:00",
  "18:15",
  "18:30",
  "18:45",
  "19:00",
  "19:15",
  "19:30",
  "19:45",
  "20:00",
];
export const TRAIN_MAX_NUM_IN_A_FRAME = 3;
export const LIST_RESERVATION_STATUS = "スケジュール予約状況";
export const LIST_TRAINING_STATUS = "トレーニング実施状況";
export const LIST_DOC_TYPE = "帳票";
export const LIST_AUDIT_DOC_STATUS = "監査ドキュメント登録状況";
export const LIST_STEP = "Step";
export const LIST_REFLECTION = "反射";
export const LIST_TRAINING_TOOL = "教具";
export const LIST_PG_STATUS = "PG公開状況";
export const LIST_NOTIFICATION_RECIPIENT_TYPE = "新着情報通知対象";
export const LIST_RANK = "役職";
export const LIST_NOTIFICATION_TYPE = "新着情報送信者";
export const LIST_NOTIFICATION_STATUS = "既読ステータス";
export const LIST_AUDIT_DOC = "帳票";
export const LIST_EVENT_STATUS = "イベント公開状況";
export const LIST_EVENT_DETAIL_STATUS = "イベント予約状況";
export const LIST_DISABILITY_CERTIFICATE_SUPPORT_TYPE = "受給者証支援の種類";
export const LIST_SERVICE_PROVISION_STATUS = "サービス提供の状況";
export const LIST_CONSULTATION_SUPPORT_ADDITION = "家族支援加算";
export const LIST_SPECIALIST_SUPPORT_ADDITION = "専門的支援実施加算";
export const LIST_CHILDCARE_SUPPORT_ADDITION = "子育てサポート加算";
export const LIST_PROVIDED_FORM = "提供形態";
export const LIST_SIGNATURE_REQUEST = "署名依頼帳票";
export const LIST_ORDER_DETAIL_DELIVERY_STATUS = "受発注発送状況";
export const COLOR_RED = "#f25767";
export const COLOR_BLUE = "#84c1f6";
export const DAYS_OF_WEEK = ["日", "月", "火", "水", "木", "金", "土"];
export const CALC_TAX_RATE = 1.1
export const TAKE_LIMIT_XS = 5;
export const TAKE_LIMIT_SS = 10;
export const TAKE_LIMIT_S = 20;
export const TAKE_LIMIT_M = 30;
export const RECORD_LIMIT = 100;
export const FLG_SUNDAY = 0
export const FLG_MONDAY = 1
export const FLG_TUESDAY = 2
export const FLG_WEDNESDAY = 3
export const FLG_THURSDAY = 4
export const FLG_FRIDAY = 5
export const FLG_SATURDAY = 6
export const DEFAULT_TRAINING_MINUTE = 45
import "./V003.scss";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import {
  EventV003Schema,
  FCStaffInfoSchema,
  GeneralForListSchema,
} from "../../generated";
import { authSelector, userInfoSelector } from "../../redux/selectors/auth.selector";
import {
  deleteEvent,
  fetchGeneralsForList,
  fetchV003,
  putEventImage,
  putV003,
} from "../../services/api.service";
import Select from "../../components/Select/CustomSelect";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import CustomButton, { CB_CLASS } from "../../components/Button/CustomButton"
import Title from "../../components/Title/Title"
import { LIST_EVENT_STATUS, STAFF, TIME_LIST } from "../../constants";
import CustomRow from "../../components/CustomRow/CustomRow";
import TextBox from "../../components/TextBox/TextBox";
import { ValidationError, initialValidationError } from "../../models/validate.model";
import ConfirmModal from "../../components/ConfirmModal";
import CustomImage from "../../components/CustomImage/CustomImage";
import CustomTextarea from "../../components/CustomTextarea/CustomTextarea";
import CustomDate from "../../components/Calendar/CustomDate";
import SelectSimple from "../../components/Select/SelectSimple";
import NumBox, { NUM_BOX_TYPE } from "../../components/TextBox/NumBox";
import { initialEventV003Model, validationV003Model } from "../../models/event.model";
import { notify, notifyError } from "../../helper/settings.helper";
import { useAuth } from "../../helper/auth.helper";

interface iLocation {
  event_id: number;
  from: string;
}

const V003 = () => {
  const { checkLoggedIn } = useAuth();
  const location = useLocation<iLocation>();
  const eventId = location.state.event_id;
  const from = location.state.from;
  const authState = useSelector(authSelector);

  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const [validationError, setValidationError] = useState<ValidationError<EventV003Schema>>(initialValidationError());
  const [isValidated, setIsValidated] = useState<boolean | undefined>(undefined);
  const [showRegister, setShowRegister] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const history = useHistory();
  const [uploadFile, setUploadFile] = useState<File | undefined>(undefined);
  const [statusForSelect, setStatusForSelect] = useState<GeneralForListSchema[]>([]);
  const userInfo = useSelector(userInfoSelector);
  const fcId = userInfo?.user_type == STAFF ? (userInfo as FCStaffInfoSchema).facilities[0].facility_id : undefined;
  const [data, setData] = useState<EventV003Schema>(initialEventV003Model(fcId));
  const [reload, setReload] = useState(true);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchGeneralsForList(authState, LIST_EVENT_STATUS).then((res) => {
        if (isMounted) {
          setStatusForSelect(res.data);
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (statusForSelect.length == 0) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, statusForSelect.length]);

  useEffect(() => {
    if (eventId && reload) {
      let isMounted = true;
      const fetchData = async () => {
        changeIsLoading();
        fetchV003(authState, eventId).then((res) => {
          if (isMounted) {
            setData(res.data);
            setReload(false);
          }
          changeIsLoading();
        }).catch((err) => {
          if (isMounted) {
            checkLoggedIn(err);
            notifyError("データの取得に失敗しました。");
            console.error(err);
          }
          changeIsLoading();
        });
      };
      fetchData();
      return () => {
        isMounted = false;
      }
    }
  }, [authState, eventId, changeIsLoading, checkLoggedIn, reload]);

  useEffect(() => {
    if (isValidated) {
      const validatedData = validationV003Model(data);
      if (validatedData.ok) {
        setValidationError(initialValidationError());
      } else {
        setIsValidated(true);
        setValidationError(validatedData.validationError);
      }
    }
  }, [data, isValidated]);

  const clickRegister = () => {
    const validatedData = validationV003Model(data);
    if (validatedData.ok) {
      changeIsLoading();
      putV003(authState, data).then((res) => {
        if (uploadFile) {
          putEventImage(authState, res.data, uploadFile).then((_) => {
            setShowRegister(false);
            notify("イベント情報を登録しました。");
            changeIsLoading();
            history.goBack();
          }).catch((err) => {
            notifyError("登録に失敗しました。");
            console.error(err);
            changeIsLoading();
          });
        } else {
          setShowRegister(false);
          notify("イベント情報を登録しました。");
          changeIsLoading();
          history.goBack();
        }
      }).catch((err) => {
        checkLoggedIn(err);
        notifyError("登録に失敗しました。");
        console.error(err);
        changeIsLoading();
      });
    } else {
      setShowRegister(false);
      notifyError("入力不正があります。");
      setIsValidated(true);
      setValidationError(validatedData.validationError);
    }
  };

  const clickDelete = () => {
    changeIsLoading();
    deleteEvent(authState, eventId).then((_) => {
      setShowRegister(false);
      notify("イベントを削除しました。");
      changeIsLoading();
      if (from == "V002") {
        history.go(-2);
      } else {
        history.goBack();
      }
    }).catch((err) => {
      checkLoggedIn(err);
      notifyError("削除に失敗しました。");
      console.error(err);
      changeIsLoading();
    });
  };

  const getSelectValue = (val: number, selectList: GeneralForListSchema[], returnNum: number) => {
    if (val == returnNum) return null;
    return selectList.filter(x => x.value == val)[0];
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Title title="イベント詳細" />
      <div className="plate">
        <CustomRow title="イベント名" required={true} error_msg={validationError.messageOf.main_title} content={
          <TextBox
            type="text"
            placeholder="イベント名"
            value={data.main_title}
            onChange={(v: string) => setData({ ...data, main_title: v })}
          />
        } />
        <CustomRow title="サブタイトル" content={
          <TextBox
            type="text"
            placeholder="サブタイトル"
            value={data.sub_title}
            onChange={(v: string) => setData({ ...data, sub_title: v })}
          />
        } />
        <CustomRow title="開催日" required={true} error_msg={validationError.messageOf.dates} content={
          <CustomDate className="mx-2" val={data.dates} setter={(val: any) => setData({ ...data, dates: val })} />
        } />
        <CustomRow title="開始時間" required={true} error_msg={validationError.messageOf.start_time} content={
          <SelectSimple className="mx-2" list={TIME_LIST} val={data.start_time} width="100%" onChange={(index: number) => setData({ ...data, start_time: TIME_LIST[index] })} />
        } />
        <CustomRow title="終了時間" required={true} error_msg={validationError.messageOf.end_time} content={
          <SelectSimple className="mx-2" list={TIME_LIST} val={data.end_time} width="100%" onChange={(index: number) => setData({ ...data, end_time: TIME_LIST[index] })} />
        } />
        <CustomRow title={<>{"参加上限人数"}<br />{"(上限なし:0)"}</>} required={true} error_msg={validationError.messageOf.limit} content={
          <div className="mt-3">
            <NumBox value={data.limit} type={NUM_BOX_TYPE.THOUSAND} onChange={(value: any) => setData({ ...data, limit: value == 0 ? value : value.replace(/^0+/, "") })} />
          </div>
        } />
        <CustomRow title="内容" content={
          <CustomTextarea placeholder="内容" value={data.description} onChange={(v: string) => setData({ ...data, description: v })} />
        } />
        <CustomRow title="フライヤー" content={
          <CustomImage uploadFile={uploadFile} setUploadFile={setUploadFile} imageUrl={data.image_url} />
        } />
        <CustomRow title="公開状況" required={true} error_msg={validationError.messageOf.status} content={
          <Select
            value={getSelectValue(data.status, statusForSelect, 0)}
            options={statusForSelect}
            className="mx-2 V003_select"
            onChange={(e: any) => setData({ ...data, status: e.value })}
            placeholder="公開状況"
          />
        } />
        <div className="item">
          <CustomButton label="登録" class={[CB_CLASS.RED]} onClick={() => setShowRegister(true)} />
          <ConfirmModal target="登録" show={showRegister} setShow={setShowRegister} func={clickRegister}
            confirmText={"登録してよろしいでしょうか。"}
          />
          {eventId &&
            <>
              <CustomButton label="削除" class={[CB_CLASS.RED]} onClick={() => setShowDelete(true)} />
              <ConfirmModal target="削除" show={showDelete} setShow={setShowDelete} func={clickDelete}
                confirmText={"削除してよろしいでしょうか。"}
              />
            </>
          }
        </div>
      </div>
    </>
  );
};

export default V003;

import actionCreatorFactory from "typescript-fsa";
import { TokenSchema, HeadquarterUserInfoSchema, FCStaffInfoSchema } from "../../generated";
import { oauth2Api } from "../../helper/api.helper";
import { setToken } from "../../helper/token.helper";
import { LoginModel } from "../../models/login.model";
import { io, Socket } from "socket.io-client";
import { BASE_PATH } from "../../generated/base";
import { connectWebsocketAction } from "./ws.actions";
import { loggedInErrorState } from "../reducers/auth.reducer";

export enum AuthActonTypes {
  FETCH_USER_INFO = "FETCH_USER_INFO",
  FETCH_ROLES = "FETCH_ROLES",
  FETCH_USER_ROLES = "FETCH_USER_ROLES",
  LOGOUT = "LOGOUT",
}

const actionCreator = actionCreatorFactory();

const socket = io(BASE_PATH, {
  transports: ["websocket"],
});

const fetchUserInfoActions = actionCreator.async<
  { loggingIn: boolean; loggedIn: boolean },
  { token: TokenSchema; userInfo: HeadquarterUserInfoSchema | FCStaffInfoSchema },
  Error | null
>(AuthActonTypes.FETCH_USER_INFO);

export const authActions = {
  fetchUserInfoStarted: fetchUserInfoActions.started,
  fetchUserInfoDone: fetchUserInfoActions.done,
  fetchUserInfoFailed: fetchUserInfoActions.failed,
  logout: actionCreator(AuthActonTypes.LOGOUT),
};

export const fetchUserInfoAction = (token: TokenSchema, login: boolean) => {
  return async (dispatch: Function) => {
    dispatch(
      authActions.fetchUserInfoStarted({
        loggingIn: login,
        loggedIn: !login,
      })
    );
    try {
      const response = await oauth2Api.userInfo({
        headers: {
          Authorization: "Bearer " + token.access_token,
        },
      });
      const userInfo = response.data as HeadquarterUserInfoSchema | FCStaffInfoSchema;
      console.log(userInfo);
      dispatch(
        authActions.fetchUserInfoDone({
          params: { loggingIn: false, loggedIn: true },
          result: { token, userInfo },
        })
      );
      dispatch(errorStateAction.changeStatus(false));

      if (login) {
        setToken(token);
        dispatch(connectWebsocketAction());
      }
    } catch (err) {
      dispatch(authActions.logout());
    }
  };
};

export const getTokenAction = (loginData: LoginModel) => {
  return async (dispatch: Function) => {
    dispatch(
      authActions.fetchUserInfoStarted({
        loggingIn: true,
        loggedIn: false,
      })
    );
    try {
      const tokenResponse = await oauth2Api.token(
        loginData.email,
        loginData.password,
        undefined,
        undefined,
        "headquarter_user"
      );

      const token = tokenResponse.data as TokenSchema;
      dispatch(fetchUserInfoAction(token, true));
    } catch (error: any) {
      dispatch(
        authActions.fetchUserInfoFailed({
          params: { loggingIn: false, loggedIn: false },
          error,
        })
      );
    }
  };
};

export const errorStateAction = {
  changeStatus: actionCreator<boolean>("CHANGE_ERROR_STATUS"),
};
import {
  Oauth2Api,
  HeadquartersApi,
  ParentsApi,
  FcFacilitiesApi,
  ChildrenApi,
  FcStaffsApi,
  ProgramItemsApi,
  NotificationsApi,
  EventsApi,
  EquipmentsApi,
  ScheduleApi,
  GeneralsApi,
  TrainingsApi,
  AuditDocsApi,
  ChatMessagesApi,
  TemplatesApi,
  SignatureRequestsApi,
  UploadsApi,
  TestApi,
  BatchApi,
  ContactsApi,
  HolidayPlannerApi,
} from "../generated";
import { AuthState } from "../redux/reducers/auth.reducer";

export const oauth2Api = new Oauth2Api();
export const headquartersApi = new HeadquartersApi();
export const parentsApi = new ParentsApi();
export const fcFacilitiesApi = new FcFacilitiesApi();
export const fcStaffsApi = new FcStaffsApi();
export const childrenApi = new ChildrenApi();
export const programItemsApi = new ProgramItemsApi();
export const notificationsApi = new NotificationsApi();
export const eventsApi = new EventsApi();
// UsersApiはユーザーの種類ごとでエンドポイントを分ける必要のないapiが入っている（パスワードの変更など）
export const equipmentsApi = new EquipmentsApi();
export const scheduleApi = new ScheduleApi();
export const generalsApi = new GeneralsApi();
export const trainingsApi = new TrainingsApi();
export const auditDocsApi = new AuditDocsApi();
export const chatMessagesApi = new ChatMessagesApi();
export const signatureRequestsApi = new SignatureRequestsApi();
export const templatesApi = new TemplatesApi();
export const uploadsApi = new UploadsApi();
export const testsApi = new TestApi();
export const batchApi = new BatchApi();
export const contactApi = new ContactsApi();
export const holidayPlannerApi = new HolidayPlannerApi();

export const makeHeaderOptionFromToken = (authState: AuthState) => {
  const oauth = authState.token;
  const accessToken = oauth ? oauth.access_token : "";
  return {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
    timeout: 900000,
  };
};

export const raiseAction = (data: any, success: Function, error: Function) => {
  if ("result" in data && "msg" in data && !data.result) {
    error(data);
  } else {
    success(data);
  }
};
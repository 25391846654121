import { validationMessage } from "../config/validationMessage";
import { isValidEmail } from "../helper/email.helper";
import { initialValidationError } from "./validate.model";
import { ValidatedData } from "./validate.model";

/*
ログインするユーザーのタイプ
Pythonのmodelsディレクトリに書かれている定数と合わせてあります。変えないで。
*/
export const USER_TYPE_HEADQUARTER_USER = "headquarter_user";
export const USER_TYPE_STAFF = "staff";
export const USER_TYPE_PARENT = "parent";

export interface LoginModel {
  email: string;
  password: string;
}

export const initialLoginModel = (): LoginModel => ({
  email: "",
  password: "",
});

export const validateLoginModel = (
  loginData: LoginModel
): ValidatedData<LoginModel> => {
  const error = initialValidationError<LoginModel>();
  let ok = true;

  if (loginData.email === "") {
    error.messageOf.email = validationMessage.emptyEmail;
    ok = false;
  }
  if (loginData.email && !isValidEmail(loginData.email)) {
    error.messageOf.email = validationMessage.wrongFormEmail;
    ok = false;
  }
  if (loginData.email.length > 256) {
    error.messageOf.email = validationMessage.limitEmailLength;
    ok = false;
  }

  if (loginData.password === "") {
    error.messageOf.password = validationMessage.emptyPassword;
    ok = false;
  }

  if (loginData.password && loginData.password.length < 8) {
    error.messageOf.password = validationMessage.minimumPasswordLength;
    ok = false;
  }

  if (loginData.password.length > 30) {
    error.messageOf.password = validationMessage.limitPasswordLength;
    ok = false;
  }

  if (ok) {
    return {
      ok: true,
      validatedData: loginData,
    };
  }

  return {
    ok: false,
    validationError: error,
  };
};

import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form"
import "./K001.scss";
import {
  initialMonthYear,
  MonthYear,
  DEFAULT_HOLIDAY,
} from "../../models/schedule.model";
import {
  fetchFcFacilities,
  fetchHolidaySettingTheMonth,
  getChildrenUnregisterDisabilityCertificate,
  getChildrenUnregisterFts,
  getFacilityHoliday,
  getLastReservationRequestMonth,
  putReservationFrameOnFixedTimeSlot,
  updateSRFNotified,
} from "../../services/api.service";
import { useDispatch, useSelector } from "react-redux";
import { authSelector, userInfoSelector } from "../../redux/selectors/auth.selector";
import Calendar from "./Calendar";
import "./Calendar.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useLocation } from "react-router-dom";
import {
  FCStaffInfoSchema,
  GeneralForListSchema,
  Result,
} from "../../generated";
import {
  K002Url, V001Url, K006Url, getForHistoryPush,
  K003Url,
} from "../../helper/url.helper";
import CustomButton, { CB_CLASS, CB_ALIGN } from "../../components/Button/CustomButton"
import Title from "../../components/Title/Title"
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { HEADQUARTER_USER, STAFF } from "../../constants";
import Select from "../../components/Select/CustomSelect";
import { useAuth } from "../../helper/auth.helper";
import { notify, notifyError } from "../../helper/settings.helper";
import { fcListSelector } from "../../redux/selectors/list.selector";
import { listActions } from "../../redux/actions/list.actions";
import { FC_LIST_TYPE, getFcList, getFcListForRedux, getFcListFromRedux } from "../../helper/list.helper";
import ConfirmModal from "../../components/ConfirmModal";
import Weekly from "./Weekly";
import { format } from "date-fns";
import { getStartDayOfTheWeek } from "../../helper/schedule.helper";
import { getDayForDisplayStr, getFirstDayOfTheMonth, getLastDayOfTheMonth, getLastDayOfTheWeek } from "../../helper/date.helper";
import ConfirmModalCustomContents from "../../components/ConfirmModalCustumContents";
import { PiWarning } from "react-icons/pi";
import ErrorMsg from "../../components/ErrorMsg/ErrorMsg";
import { useMediaQuery } from "react-responsive";

interface K001Location {
  facility_id: number;
}

const Schedule: React.VFC = () => {
  const { checkLoggedIn } = useAuth();
  const authState = useSelector(authSelector);
  const history = useHistory();
  const location = useLocation<K001Location>();
  const [my, _] = useState<MonthYear>(initialMonthYear());
  const [getData, setGetData] = useState(true);
  const [targetDate, setTargetDate] = useState(new Date(my.year, my.month));
  const [endDate, setEndDate] = useState<Date>(new Date(my.year, my.month));
  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const userInfo = useSelector(userInfoSelector);
  const [fcId, setFcId] = useState(userInfo?.user_type == STAFF ? (userInfo as FCStaffInfoSchema)?.facilities[0].facility_id : location.state.facility_id);
  const [fcForSelect, setFcForSelect] = useState<GeneralForListSchema[]>([]);
  const [holiday, setHoliday] = useState(DEFAULT_HOLIDAY);
  const fcListState = useSelector(fcListSelector);
  const dispatch = useDispatch();
  const [showModalSendSlotsTheMonth, setShowModalSendSlotsTheMonth] = useState(false);
  const [showModalNotifyEmptySlots, setShowModalNotifyEmptySlots] = useState(false);
  const [lastReservationRequestMonth, setLastReservationRequestMonth] = useState<string>("2000-1-1");
  const [monthForSendReservationRequest, setMonthForSendReservationRequest] = useState<number>(targetDate.getMonth() + 1);
  const [isMonthly, setIsMonthly] = useState(true);
  const maxWidthLimit = 1710;
  const [maxWidth, setMaxWidth] = useState(`${maxWidthLimit}px`);
  const [unregisteredChildren, setUnregisteredChildren] = useState<string>("");
  const [isNotSettingHoliday, setIsNotSettingHoliday] = useState<boolean>(false);
  const [unregisteredChildrenDC, setUnregisteredChildrenDC] = useState<string>("");
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1200px)" });

  useEffect(() => {
    const updateMaxWidth = () => {
      const widthOneCol = 220;
      const holidayArray = (holiday.slice(-1) + holiday.slice(0, -1)).split("");
      const holidayCount = holidayArray.filter(x => x == "1").length;
      const width = isTabletOrMobile ? window.innerWidth + 400 : window.innerWidth;
      const widthAdjust = 8;
      let colMinusCount = 0;

      if (width < 1200) {
        colMinusCount = 5;
      } else if (width < 1500) {
        colMinusCount = 4;
      } else if (width < 1710) {
        colMinusCount = 3;
      } else if (width < 1900) {
        colMinusCount = 2;
      } else if (width < 2100) {
        colMinusCount = 1;
      }
      if (colMinusCount < holidayCount) colMinusCount = holidayCount;
      setMaxWidth(`${maxWidthLimit - widthOneCol * colMinusCount - widthAdjust}px`);
    };

    // 初期値の設定
    updateMaxWidth();

    // リサイズイベントリスナーを追加
    window.addEventListener("resize", updateMaxWidth);

    // クリーンアップ関数でリスナーを削除
    return () => window.removeEventListener("resize", updateMaxWidth);
  }, [holiday, isTabletOrMobile]);

  useEffect(() => {
    if (userInfo?.user_type == HEADQUARTER_USER) {
      let isMounted = true;
      const fetchData = async () => {
        changeIsLoading();
        fetchFcFacilities(authState, "").then((res) => {
          if (isMounted) {
            setFcForSelect(getFcList(FC_LIST_TYPE.DEFAULT, res.data));
            dispatch(listActions.makeFcList(getFcListForRedux(res.data)));
            if (fcId == undefined) {
              setFcId(res.data[0].id);
            }
          }
          changeIsLoading();
        }).catch((err) => {
          if (isMounted) {
            checkLoggedIn(err);
            notifyError("データの取得に失敗しました。");
            console.error(err);
          }
          changeIsLoading();
        });
      };
      if (fcForSelect.length == 0) {
        if (fcListState.select_list.length == 0) {
          fetchData();
        } else {
          setFcForSelect(getFcListFromRedux(FC_LIST_TYPE.DEFAULT, fcListState.select_list));
          if (fcId == undefined) {
            setFcId(fcListState.select_list[0].value);
          }
        }
      }
      return () => {
        isMounted = false;
      }
    }
  }, [authState, dispatch, changeIsLoading, checkLoggedIn, userInfo, fcId, fcForSelect.length, fcListState.select_list]);

  useEffect(() => {
    if (fcId) {
      let isMounted = true;
      const fetchData = async () => {
        changeIsLoading();
        try {
          const [resLastReservationRequestMonth, resFacilityHoliday] = await Promise.all([
            getLastReservationRequestMonth(authState, fcId),
            getFacilityHoliday(fcId, authState),
          ]);
          if (isMounted) {
            setLastReservationRequestMonth(resLastReservationRequestMonth.data);
            setHoliday(resFacilityHoliday.data);
            setGetData(false);
          }
          changeIsLoading();
        } catch (err) {
          if (isMounted) {
            checkLoggedIn(err);
            notifyError("データの取得に失敗しました。");
            console.error(err);
          }
          changeIsLoading();
        }
      };
      if (getData) {
        fetchData();
      }
      return () => {
        isMounted = false;
      }
    }
  }, [authState, changeIsLoading, checkLoggedIn, fcId, targetDate, getData]);

  useEffect(() => {
    if (new Date(lastReservationRequestMonth) <= getFirstDayOfTheMonth(targetDate) && fcId) {
      getChildrenUnregisterDisabilityCertificate(authState, fcId, targetDate.getFullYear(), targetDate.getMonth() + 1).then((res) => {
        setUnregisteredChildrenDC(res.data.join("、"));
      }).catch((err) => {
        notifyError("データの取得に失敗しました。");
      });
    }
  }, [authState, fcId, targetDate, lastReservationRequestMonth]);

  useEffect(() => {
    setMonthForSendReservationRequest(targetDate.getMonth() + 1);
    if (isMonthly) {
      setEndDate(getLastDayOfTheMonth(targetDate));
      setGetData(true);
    } else {
      setEndDate(getLastDayOfTheWeek(targetDate));
      setGetData(true);
    };
  }, [targetDate, isMonthly]);

  const clickSendSlotsTheMonth = () => {
    changeIsLoading();
    putReservationFrameOnFixedTimeSlot(authState, fcId, targetDate.getFullYear(), monthForSendReservationRequest).then(() => {
      setShowModalSendSlotsTheMonth(false);
      notify(monthForSendReservationRequest + "月のトレーニング予約依頼通知をしました。");
      setGetData(true);
      changeIsLoading();
    }).catch((err) => {
      checkLoggedIn(err);
      setShowModalSendSlotsTheMonth(false);
      notifyError("登録に失敗しました。");
      changeIsLoading();
    });
  };

  const clickNotifyEmptySlots = () => {
    changeIsLoading();
    updateSRFNotified(authState, fcId, format(targetDate, "yyyy-MM-dd"), format(endDate, "yyyy-MM-dd")).then((res) => {
      if (res.data.result) {
        notify("空き枠を通知しました。");
      } else {
        notifyError(res.data.msg || "登録に失敗しました。");
      }
      setShowModalNotifyEmptySlots(false);
      setGetData(true);
      changeIsLoading();
    }).catch((err) => {
      checkLoggedIn(err);
      setShowModalNotifyEmptySlots(false);
      notifyError("登録に失敗しました。");
      changeIsLoading();
    });
  };

  const getConfirmMessages = async () => {
    try {
      // 固定日時未登録のお子様を取得
      const resChildren = await getChildrenUnregisterFts(authState, fcId);
      const children = resChildren.data.map((x) => x.child_name);
      if (children.length > 0) {
        setUnregisteredChildren(children.join("、"));
      }
      // 当月の祝日設定を取得
      const res = await fetchHolidaySettingTheMonth(authState, fcId, targetDate.getFullYear(), targetDate.getMonth() + 1);
      if (res.data.length === 0) {
        setIsNotSettingHoliday(true);
      }
      setShowModalSendSlotsTheMonth(true);
    } catch (err) {
      checkLoggedIn(err);
      notifyError("データの取得に失敗しました。");
    }
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Title title="スケジュール管理" />
      <div className="plate">
        <div className="on_same_row">
          {userInfo?.user_type == HEADQUARTER_USER &&
            <div className="item">
              <Select
                value={fcForSelect.filter(x => x.value === fcId)[0]}
                options={fcForSelect}
                className="K001_select"
                placeholder="施設"
                onChange={(e: any) => {
                  setFcId(e.value);
                  setGetData(true);
                }} />
            </div>
          }
        </div>
        <div className="on_same_row justify flex-wrap" style={{ maxWidth: maxWidth }}>
          <div className="on_same_row">
            <CustomButton label="スケジュール一覧" class={[CB_CLASS.BLUE]} onClick={() => history.push(getForHistoryPush(K002Url, [fcId, 0, 0, 0]))} />
            <CustomButton label="イベント一覧" class={[CB_CLASS.BLUE]} onClick={() => history.push(getForHistoryPush(V001Url, [fcId, 0, 0]))} />
            <CustomButton label="祝日設定" class={[CB_CLASS.BLUE]} onClick={() => history.push(getForHistoryPush(K003Url, [fcId, targetDate, holiday]))} />
          </div>
          {fcId != undefined &&
            <div className="on_same_row justify">
              <CustomButton label={"予約枠空き枠通知"} class={[CB_CLASS.BLUE]} align={CB_ALIGN.RIGHT} onClick={() => setShowModalNotifyEmptySlots(true)} />
              <ConfirmModal target="通知" show={showModalNotifyEmptySlots} setShow={setShowModalNotifyEmptySlots} func={clickNotifyEmptySlots}
                confirmText={getDayForDisplayStr(targetDate) + " ～ " + getDayForDisplayStr(endDate) + "の予約空き枠を保護者に通知してもよろしいでしょうか。"}
              />
              {userInfo?.user_type == STAFF &&
                <CustomButton label={monthForSendReservationRequest + "月のトレーニング予約依頼"} class={[CB_CLASS.BLUE]} align={CB_ALIGN.RIGHT} onClick={() => getConfirmMessages()} disabled={(new Date(lastReservationRequestMonth) <= getFirstDayOfTheMonth(targetDate) && unregisteredChildrenDC == "") ? false : true} />
              }
              <ConfirmModalCustomContents 
                target="通知"
                show={showModalSendSlotsTheMonth}
                setShow={setShowModalSendSlotsTheMonth}
                func={clickSendSlotsTheMonth}
                confirmMessage={monthForSendReservationRequest + "月のトレーニング予約通知を保護者に通知してもよろしいでしょうか。"}
                contents={
                  <div className="warning-contents">
                    {(isNotSettingHoliday || unregisteredChildren) &&
                      <PiWarning size={50} color={"#000"}/>
                    }
                    {isNotSettingHoliday &&
                      <p className="warning-text">{monthForSendReservationRequest + "月の祝日設定が登録されていません。"}</p>
                    }
                    {unregisteredChildren &&
                      <>
                        <p className="warning-text">下記お子様の固定時間が登録されていません。</p>
                        <p>{unregisteredChildren}</p>
                      </>
                    }
                  </div>
                }
              />
              <CustomButton label={"予約枠を作成"} class={[CB_CLASS.RED]} align={CB_ALIGN.RIGHT} onClick={() => history.push(getForHistoryPush(K006Url, [fcId]))} />
            </div>
          }
        </div>
        {(new Date(lastReservationRequestMonth) <= getFirstDayOfTheMonth(targetDate) && unregisteredChildrenDC != "") &&
        <div style={{ maxWidth: maxWidth }}>
          <ErrorMsg error_msg={unregisteredChildrenDC + "の受給者証が給付期間外です。登録を完了すると、当月のトレーニング予約依頼ができるようになります。"}/>
        </div>}
        <div className="on_same_row">
          <div className="switch_button">
            <Form.Check
              type="radio"
              id={"monthlyOn"}
              label={"月"}
              checked={isMonthly}
              onChange={() => {
                setIsMonthly(true);
                setTargetDate(new Date(targetDate.getFullYear(), targetDate.getMonth()))
                setGetData(true);
              }}
            />
            <Form.Check
              type="radio"
              id={"weeklyOn"}
              label={"週"}
              checked={isMonthly ? false : true}
              onChange={() => {
                setIsMonthly(false);
                setTargetDate((currentDate) => getStartDayOfTheWeek(currentDate));
                setGetData(true);
              }}
            />
          </div>
        </div>
      </div>
      <ToastContainer />
      <div className="plate">
        <div style={{ width: maxWidth }}>
          {isMonthly ?
            <Calendar
              targetDate={targetDate}
              setTargetDate={setTargetDate}
              fcId={fcId}
              holiday={holiday}
            />
            :
            <Weekly
              targetDate={targetDate}
              setTargetDate={setTargetDate}
              getData={getData}
              setGetData={setGetData}
              fcId={fcId}
            />
          }
        </div>
      </div>
    </>
  );
};

export default Schedule;

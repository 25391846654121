export const validationMessage = {
  empty: "を入力して下さい。",
  limitLength: "文字以内で入力して下さい。",
  shortageLength: "文字以上で入力して下さい。",
  wrongNumber: "半角数字で入力して下さい。",
  wrongStringKana: "カタカナで入力して下さい。",
  wrongAlphanumeric: "半角英数字で入力して下さい。",
  select: "を選択して下さい。",

  emptyName: "* 名前を入力して下さい。",
  limitNameLength: "* 名前は30文字以内で入力して下さい。",
  emptyNameKatakana: "* 名前(フリガナ)を入力して下さい。",
  invalidNameKatakana: "* フリガナをカタカナで入力してください。",
  limitNameKatakanaLength: "* 名前(フリガナ)は30文字以内で入力して下さい。",

  emptyEmail: "* メールアドレスを入力して下さい。",
  wrongEmail: "* 正しいメールアドレスを入力してください。",
  limitEmailLength: "* メールアドレスは256文字以内で入力して下さい。",
  wrongFormEmail: "* メールアドレスの形式が間違っています。",
  existingEmail: "* このメールアドレスは既に登録されています。",

  emptyPassword: "* パスワードを入力して下さい。",
  limitPasswordLength: "* パスワードは30文字以下で入力して下さい。",
  minimumPasswordLength: "* パスワードは8文字以上で入力して下さい。",
  invalidEmailOrPassword: "* メールアドレスまたはパスワードが違います。",
  rePassword: "* パスワードを再入力して下さい。",
  emptyRePassword: "* パスワード(確認)を入力して下さい。",
  inconsistentPassword: "* パスワードが一致しませんでした。",

  emptyPhoneNumber: "* 電話番号を入力して下さい。",
  emptyPostCode: "* 郵便番号を入力して下さい。",
  emptyAddressLine: "* 住所を入力して下さい。",
  emptyFamilyStructure: "* 家族構成を入力して下さい。",
  emptyEmergencyContacts: "* 緊急連絡先を1件以上入力して下さい。",

  emptyRole: "* 役職を入力して下さい。",
  emptyRoleInput: "* 役職を入力して下さい。",
  limitRoleInputLength: "* 役職は20文字以内で入力して下さい。",

  upSelectedPublicationStatus: "公開状況を選択して下さい。",
  limitTextLength: "* 本文は400文字以内で入力して下さい",

  // ログイン
  loginFailed:
    "* ログインに失敗しました。もう一度メールアドレスとパスワードを入力して下さい。",
  // サブスクリプション動画
  emptyTitle: "* タイトルを入力して下さい。",
  limitTitleLength: "* タイトルは40文字以内で入力して下さい",
  emptyText: "* 本文を入力して下さい。",
  emptyDescription: "* 説明文を入力して下さい。",
  limitDescriptionLength: "* 説明文は400文字以内で入力して下さい",
  emptyVideo: "* 動画を選択して下さい。",
  publicationStatus: "* 公開状況を選択して下さい。",
  // 施設登録
  emptyFacilityName: "* 施設名を入力して下さい。",
  limitFacilityNameLength: "* 施設名は30文字以内で入力して下さい。",
  emptyFacilityManagerName: "* 管理者名を入力して下さい。",
  limitFacilityManagerNameLength: "* 管理者名は30文字以内で入力して下さい。",
  emptyFacilityManagerNameKatakana: "* 管理者名（フリガナ）を入力して下さい。",
  limitFacilityManagerNameKatakanaLength:
    "* 管理者名（フリガナ）は30文字以内で入力して下さい。",
  emptyFacilityPhoneNumber: "* 施設電話番号を入力して下さい。",
  limitFacilityPhoneNumberLength:
    "* 施設電話番号は9~15桁の数字(ハイフンなし)で入力して下さい。",
  // emptyPostCode: "* 郵便番号を入力して下さい。",
  limitPostCodeLength:
    "* 郵便番号は7桁の数字（ハイフンなし）で入力して下さい。",
  // emptyFacilityAddress1: "* 住所１を入力して下さい。",
  // limitAddress1Length: "* 住所１は50文字以内で入力して下さい。",
  // limitAddress2Length: "* 住所２は50文字以内で入力して下さい。",
  // noEnteredWeekDayStart: "* 平日の営業開始時間を選択して下さい。",
  // noEnteredWeekDayEnd: "* 平日の営業終了時間を選択して下さい。",
  // noEnteredHoliDayStart: "* 学校休業日の営業開始時間を選択して下さい。",
  // noEnteredHoliDayEnd: "* 学校休業日の営業終了時間を選択して下さい。",
  // プログラム追加
  emptyTrainingType: "* 種別を選択して下さい。",
  emptyReflection: "* 反射を選択して下さい。",
  emptyGuidanceMethod: "* 指導方法を入力して下さい。",
  limitGuidanceMethodLength: "* 指導方法は50文字以内で入力して下さい。",
  // 新着情報
  noEnteredTarget: "* 対象を選択して下さい。",
  noEnteredTargetFacility: "* 対象の施設を選択して下さい。",
  limitNotificationTextLength: "* 内容は255文字以内で入力して下さい。",
  limitNotificationTitleLength: "* タイトルは255文字以内で入力して下さい。",
  // イベント
  emptyEventTitle: "* イベント名を入力して下さい。",
  limitEventTitleLength: "* タイトルは40文字以内で入力して下さい",
  emptyEventDate: "* 開催日を入力して下さい。",
  emptyEventDescription: "* 内容を入力して下さい。",
  limitEventDescriptionLength: "* 内容は400文字以内で入力して下さい。",
  outOfMaxRangeEventLimit: "* 上限人数は999以下の数値を入力して下さい",
  emptyEventStartTime: "* 開始時間を入力して下さい。",
  emptyEventEndTime: "* 終了時間を入力して下さい。",
  noEnteredCreatedBy: "* 主催者を選択して下さい。",
  // 設定
  emptyCurrentPassword: "* 現在のパスワードを入力して下さい。",
  emptyNewPassword: "* 新しいパスワードを入力して下さい。",
  minimumNewPasswordLength: "* 新しいパスワードは8文字以上で入力して下さい。",
  limitNewPasswordLength: "* 新しいパスワードは30文字以下で入力して下さい。",
  wrongCurrentPassword: "* 現在のパスワードが間違っています",
  emptyNewPosition: "* 新しい役職を入力して下さい。",
  limitNewPositionLength: "* 新しい役職は10文字以内で入力して下さい。",
  emptyEquipmentName: "* 備品名を入力して下さい。",
  emptyEquipmentPrice: "* 金額を入力して下さい。",
  outOfRangeEquipmentStock:
    "* 在庫数を入力して下さい。(指定のない場合は0を入力)",
  // サブスクリプション動画
  emptyDisabilityItem: "* 障害名を入力して下さい。",

  emptyTrainingDate: "* 実施日時を入力して下さい。",
  emptyTrainingAttendance: "* 出欠タイプを選択して下さい。",
  emptyTrainingTime: "* 実施時間を入力して下さい。",
  noEnteredStaff: "* 担当者を選択して下さい。",
  noEnteredProgram: "* プログラムを選択して下さい。",
  noEnteredChild: "* お子様を選択して下さい。",
  emptyTrainingMinute: "* 実施したプログラムには時間を入力して下さい。",
  noEnteredConsultationSupportAddition: "* 家族支援加算を選択して下さい。",

  noEnteredDocType: "* 帳票種別を選択して下さい。",
  emptyMakeDate: "* 作成日を入力して下さい。",
  emptyUploadFile: "* アップロードドキュメントを選択して下さい。",

  emptyDisabilityCertificateNumber: "* 受給者証番号を入力して下さい。",
  emptyDisabilityCertificateContractDays: "* 契約日数を入力して下さい。",
  emptyDisabilityCertificateDates: "* 給付期間を入力して下さい。",

  noEnteredRecipientsFc: "* 送信先施設を選択して下さい。",
  noEnteredRecipients: "* 宛先を選択して下さい。",

  noEnteredProvidedForm: "* 提供形態を選択して下さい。",

  emptyPublicationDate: "* 作成日を入力して下さい。",
  emptyStep: "* Stepを選択して下さい。",

  noDisabilityCertificate: "* 該当日付の受給者証情報が登録されていません。",
  emptyDisabilityCertificate: "* 該当日付の受給者証番号が空です。",

  noChoseOnlyPdfFile: "* Pdfファイルのみアップロード可能です。",

  duplicateWeekday: "* 曜日が重複しています。",
};

import { combineReducers, createStore } from "redux";
import { entitiesReducer, queriesReducer } from "redux-query";
import { authReducer, errorStateReducer } from "./auth.reducer";
import { wsReducer } from "./ws.reducer";
import { chatReloadReducer } from "./chatReload.reducer";
import { cartReducer } from "./cart.reducer";
import { fcListReducer, pgListReducer } from "./list.reducer";


const rootReducer = combineReducers({
  entities: entitiesReducer,
  queries: queriesReducer,
  authReducer,
  wsReducer,
  chatReloadReducer,
  cartReducer,
  errorStateReducer,
  fcListReducer,
  pgListReducer,
});

export const store = createStore(rootReducer);
export type GetState = typeof store.getState;
export type State = ReturnType<GetState>;

export const getQueries = (state: State) => state.queries;
export const getEntities = (state: State) => state.entities;

export default rootReducer;

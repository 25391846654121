import { FormLabel } from "react-bootstrap";
import { GeneralForListSchema } from "../../generated";
import "./Status.scss";

export const STATUS_RED = "status_red";
export const STATUS_BLUE = "status_blue";
export const STATUS_GREEN = "status_green";
export const STATUS_GRAY = "status_gray";
export const STATUS_WHITE = "status_white";

export interface StatusSchema {
  /**
   * ステータス番号
   * @type {number}
   * @memberof StatusSchema
   */
  "num": number;
  /**
   * 枠線配色クラス名
   * @type {string}
   * @memberof StatusSchema
   */
  "class": string;
}

function Status(props: any) {
  const label = (props.label_list as GeneralForListSchema[]).find(x => x.value == props.status)?.label
  const color = (props.color_list as StatusSchema[]).find(x => x.num == props.status)?.class
  return (
    <div
      className={"status_div " + color}
      onClick={(event) => { event.stopPropagation() }}
      style={{ maxWidth: props.max_width || 200, minWidth: props.min_width || 120 }}>
      <FormLabel className="status">{label}</FormLabel>
    </div>
  );
};

export default Status;

import "./PrivacyPolicy.scss";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";

const PrivacyPolicy = () => {
  const [isLoading, changeIsLoading] = useLoadingIndicator();

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <div id="pp_bg">
        <h4 className="sub_title">保護者様向けモバイルアプリ【S.I.C.KIDS】</h4>
        <h2 className="main_title">プライバシーポリシー</h2>
        <div id="pp_main">
        
          <div className="chapter">
            <div className="paragraph_title">1.情報を取得するアプリ提供者</div>
            <p>株式会社S.I.C.</p>
          </div>
          
          <div className="chapter">
            <div className="paragraph_title">2.取得するユーザー情報・取得方法・利用目的</div>
            <div className="clause">
              <div className="paragraph_subtitle">2-1.端末に関する情報</div>
              <div className="section">
                <div className="sub_section">2-1-1.取得内容</div>
                <p>本アプリケーションを利用する端末の、OS・バージョンに関する情報</p>
                <div className="sub_section">2-1-2.取得方法</div>
                <p>本アプリケーションの機能により自動取得します。</p>
                <div className="sub_section">2-1-3.利用目的</div>
                <p>アプリケーションの機能を適切に提供するため</p>
              </div>
            </div>

            <div className="clause">
              <div className="paragraph_subtitle">2-2.利用者様の個人情報および画像情報</div>
              <div className="section">
                <div className="sub_section">2-2-1.取得内容</div>
                <p>株式会社S.I.C.をご利用の保護者様およびお子様に関する基本情報（メールアドレス、氏名、生年月日、性別、血液型、画像）など</p>
                <div className="sub_section">2-2-2.取得方法</div>
                <p>利用者様がご自身で登録された情報を取得いたします。</p>
                <div className="sub_section">2-2-3.利用目的</div>
                <p>・利用者様への適切な支援の提供のため</p>
                <p>・事業所事務・管理を適切に行うため</p>
                <p>・法令・行政上の業務のため</p>
                <p>・保険請求業務のため</p>
                <p>・ご家族への説明のため</p>
                <p>・療育開発並びにエビデンスの構築のため</p>
              </div>
            </div>
          </div>

          <div className="chapter">
            <div className="paragraph_title">3.本アプリケーションが要求する機能</div>
            <div className="clause">
              <div className="paragraph_subtitle">3-1.カメラ</div>
              <p>本アプリケーションは、デバイスのカメラ機能を利用して画像を撮影する機能を有します。また撮影された画像を当社が利用するサーバーにアップロードする機能を有します。</p>
              <div className="paragraph_subtitle">3-2.写真</div>
              <p>本アプリケーションは、デバイスの写真フォルダにアクセスする機能を有します。また利用者様が選択した画像を当社が利用するサーバーにアップロードする機能を有します。</p>
              <div className="paragraph_subtitle">3-3.通知</div>
              <p>本アプリケーションは、デバイスの通知機能を利用して、株式会社S.I.C.からのお知らせを通知する機能を有します。</p>
            </div>
          </div>

          <div className="chapter">
            <div className="paragraph_title">4.通知・公表または同意取得の方法・利用者関与の方法</div>
            <div className="clause">
              <div className="paragraph_subtitle">4-1.通知・公表</div>
              <p>本ポリシーに関する通知・公表は、書面でお伝えするもしくは、本アプリケーションまたは当社のホームページに掲載する方法で行います。</p>
              <p>S.I.C.株式会社プライバシーポリシーに関する通知・公表は本アプリケーションまたは当社のホームページに掲載する方法で行います。</p>
              <div className="paragraph_subtitle">4-2.同意取得の方法</div>
              <p>同意の取得は、アプリケーション利用前に書面にて行います。</p>
              <div className="paragraph_subtitle">4-3.利用者関与の方法</div>
              <p>端末情報の取得は、本アプリケーションをアンインストールすることで中止できます。</p>
              <p>利用者様の個人情報および画像情報の取得は、利用者様ご自身がアプリケーション内で登録した情報に限ります。</p>
            </div>
          </div>

          <div className="chapter">
            <div className="paragraph_title">5.外部送信・第三者提供・情報収集モジュール</div>
            <div className="clause">
              <div className="paragraph_subtitle">5-1.外部送信</div>
              <p>取得した利用者様の個人情報および画像情報については、当社が管理を委託するシステム会社が利用するサーバーに送信されます。</p>
              <div className="paragraph_subtitle">5-2.第三者提供</div>
              <p>利用者様の個人情報は、あらかじめ利用者様の同意をいただくことなく、外部に提供することはありません。ただし、以下の利用目的に該当する場合は、利用者様から特にお申し出がない限り、サービスを提供するための通常業務として必要な範囲において、利用者様の個人情報を第三者に提供する場合があります。</p>
              <p>（ｱ）   支援の提供のため、他の相談支援事業所等との連携を図ること</p>
              <p>（ｲ）   支援の提供のため、外部の医師等の意見・助言を求めること</p>
              <p>（ｳ）   療育開発並びにエビデンスの構築のため、S.I.C.KIDS本部にアセスメント情報等を提供する場合</p>
              <p>（ｴ）   利用者への支援の提供に際して、家族等への説明を行うこと</p>
            </div>
          </div>
            
          <div className="chapter">
            <div className="paragraph_title">6.お問い合わせ先</div>
            <p>当社のプライバシーポリシーに関する、ご意見、ご質問、苦情の申出その他ユーザー情報の取り扱いに関するお問い合わせは、以下の窓口にご連絡ください。</p>
            <p>個人情報相談窓口：S.I.C.KIDS本部</p>
          </div>

          <div className="chapter">
            <div className="paragraph_title">7.改訂</div>
            <p>当社は、当社の裁量に基づいて、本ポリシーを変更します。但し、取得するユーザー情報、利用目的、第三者提供に変更が発生した場合、本アプリケーションまたは当社のホームページで通知するとともに、本ポリシー変更後、改めて書面にてユーザーからの同意を取得します。</p>
          </div>

          <div className="chapter">
            <div className="paragraph_title">8.株式会社S.I.C.プライバシーポリシー</div>
            <p>本ポリシーに定めのない事項については、株式会社S.I.C.プライバシーポリシーが適用されます。</p>
            <a href="https://sic-kids.co.jp/pp/" target="_blank" rel="noopener noreferrer" className="pp_link">株式会社S.I.C.プライバシーポリシーはこちら</a>
          </div>

          <div className="chapter">
            <div className="paragraph_title">9.改定日・制定日</div>
            <p>制定：2024年03月25日</p>
          </div>

        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;

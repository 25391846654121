import "./J003.scss";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import {
  GeneralForListSchema, J003Schema, WebNotificationRecipientSchema
} from "../../generated";
import { authSelector } from "../../redux/selectors/auth.selector";
import {
  fetchFcFacilities,
  fetchGeneralsForListMulti,
  fetchJ003,
} from "../../services/api.service";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import CustomButton, { CB_CLASS, CB_SIZE } from "../../components/Button/CustomButton"
import Title from "../../components/Title/Title"
import { LIST_NOTIFICATION_RECIPIENT_TYPE, LIST_RANK } from "../../constants";
import CustomRow from "../../components/CustomRow/CustomRow";
import Label from "../../components/Label/Label";
import CustomImage from "../../components/CustomImage/CustomImage";
import { RECIPIENT_TYPE, initialJ003Model } from "../../models/notification.model";
import CustomTextarea from "../../components/CustomTextarea/CustomTextarea";
import { useAuth } from "../../helper/auth.helper";
import { notifyError } from "../../helper/settings.helper";
import { fcListSelector } from "../../redux/selectors/list.selector";
import { listActions } from "../../redux/actions/list.actions";
import { FC_LIST_TYPE, getFcList, getFcListForRedux, getFcListFromRedux } from "../../helper/list.helper";

interface iLocation {
  notification_id: number;
}

const J003 = () => {
  const { checkLoggedIn } = useAuth();
  const location = useLocation<iLocation>();
  const notification_id = location.state.notification_id;
  const authState = useSelector(authSelector);
  const [fcForSelect, setFcForSelect] = useState<GeneralForListSchema[]>([]);
  const [recipientTypeForSelect, setRecipientTypeForSelect] = useState<GeneralForListSchema[]>([]);
  const [rankForSelect, setRankForSelect] = useState<GeneralForListSchema[]>([]);
  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const [data, setData] = useState<J003Schema>(initialJ003Model());
  const [reload, setReload] = useState(true);
  const fcListState = useSelector(fcListSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (reload) {
      let isMounted = true;
      const fetchData = async () => {
        changeIsLoading();
        fetchJ003(authState, notification_id).then((res) => {
          if (isMounted) {
            setData(res.data);
            setReload(false);
          }
          changeIsLoading();
        }).catch((err) => {
          if (isMounted) {
            checkLoggedIn(err);
            notifyError("データの取得に失敗しました。");
            console.error(err);
          }
          changeIsLoading();
        });
      };
      fetchData();
      return () => {
        isMounted = false;
      }
    }
  }, [authState, changeIsLoading, checkLoggedIn, notification_id, reload]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      const keywords = [LIST_NOTIFICATION_RECIPIENT_TYPE, LIST_RANK];
      fetchGeneralsForListMulti(authState, keywords).then(res => {
        if (isMounted) {
          const resGenData = res.data as any;
          setRecipientTypeForSelect(resGenData[LIST_NOTIFICATION_RECIPIENT_TYPE].filter((x: GeneralForListSchema) => x.label !== "施設"));
          setRankForSelect(resGenData[LIST_RANK]);
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (rankForSelect.length == 0) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, rankForSelect.length]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchFcFacilities(authState, "").then(res => {
        if (isMounted) {
          setFcForSelect(getFcList(FC_LIST_TYPE.ADD_ALL_FC, res.data));
          dispatch(listActions.makeFcList(getFcListForRedux(res.data)));
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (fcForSelect.length == 0) {
      if (fcListState.select_list.length == 0) {
        fetchData();
      } else {
        setFcForSelect(getFcListFromRedux(FC_LIST_TYPE.ADD_ALL_FC, fcListState.select_list));
      }
    }
    return () => {
      isMounted = false;
    }
  }, [authState, dispatch, changeIsLoading, checkLoggedIn, fcForSelect.length, fcListState.select_list]);

  const getSelectedRecipientName = (recipient: WebNotificationRecipientSchema) => {
    let filteredList: GeneralForListSchema[] = [];
    switch (recipient.type) {
      case RECIPIENT_TYPE.PARENT:
        filteredList = recipientTypeForSelect.filter(x => x.value == recipient.type);
        break;
      case RECIPIENT_TYPE.FC:
        filteredList = fcForSelect.filter(x => x.value == recipient.recipient);
        break;
      case RECIPIENT_TYPE.RANK:
        filteredList = rankForSelect.filter(x => x.value == recipient.recipient);
        break;
    }
    return filteredList.length > 0 && filteredList[0].label;
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Title title="新着受信情報詳細" />
      <div className="plate">
        <CustomRow title="タイトル" content={
          <Label value={data.title} className="text-wrap" />
        } />
        <CustomRow title="差出人" content={
          <Label value={data.facility_name} />
        } />
        <CustomRow title="内容" content={
          <CustomTextarea value={data.body} disabled={true} className="text-wrap" />
        } />
        <CustomRow title="画像" content={
          <CustomImage imageUrl={data.image_url} readOnly={true} />
        } />
        {data.url &&
          <CustomRow title="アンケートURL" content={
            <div className="item">
              <a href={data.url} target="_blank">{data.url}</a>
            </div>
          } />
        }
        <CustomRow title="宛先" on_same_row={false} content={
          <>
            <div className="mx-2 on_same_row" style={{ flexWrap: "wrap" }}>
              {data.notification_recipients.filter(x => x.type == RECIPIENT_TYPE.FC).map((x, i) =>
                <div key={i} className="mb-2">
                  <CustomButton label={getSelectedRecipientName(x)} class={[CB_CLASS.BLUE]} size={CB_SIZE.S} disabled={true} />
                </div>
              )}
            </div>
            <div className="mx-2 on_same_row" style={{ flexWrap: "wrap" }}>
              {data.notification_recipients.filter(x => x.type != RECIPIENT_TYPE.FC).map((x, i) =>
                <div key={i} className="mb-2">
                  <CustomButton label={getSelectedRecipientName(x)} class={[CB_CLASS.BLUE]} size={CB_SIZE.S} disabled={true} />
                </div>
              )}
            </div>
          </>
        } />
      </div>
    </>
  );
};

export default J003;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import {
  GeneralForListSchema, P002Schema,
} from "../../generated";
import { authSelector } from "../../redux/selectors/auth.selector";
import {
  deleteP002,
  deleteP002Movie,
  fetchGeneralsForListMulti,
  fetchP002,
  putP002,
  putP002Movie,
} from "../../services/api.service";
import Select from "../../components/Select/CustomSelect";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import CustomButton, { CB_ALIGN, CB_CLASS, CB_SIZE } from "../../components/Button/CustomButton"
import Title from "../../components/Title/Title"
import { LIST_PG_STATUS, LIST_REFLECTION, LIST_STEP, LIST_TRAINING_TOOL } from "../../constants";
import CustomRow from "../../components/CustomRow/CustomRow";
import TextBox from "../../components/TextBox/TextBox";
import Delete from "../../components/Icon/Delete";
import CustomVideo from "../../components/CustomVideo/CustomVideo";
import { initialP002Model, validationProgramItemModel } from "../../models/programItem.model";
import { notify, notifyError } from "../../helper/settings.helper";
import { ValidationError, initialValidationError } from "../../models/validate.model";
import ConfirmModal from "../../components/ConfirmModal";
import { useAuth } from "../../helper/auth.helper";
import { listActions } from "../../redux/actions/list.actions";
import { getState } from "../../helper/page.helper";

interface iLocation {
  program_item_id: number;
}

const P002 = () => {
  const { checkLoggedIn } = useAuth();
  const location = useLocation<iLocation>();
  const state = getState(location);
  const programItemId = state.program_item_id;
  const authState = useSelector(authSelector);
  const [stepForSelect, setStepForSelect] = useState<GeneralForListSchema[]>([]);
  const [reflectionForSelect, setReflectionForSelect] = useState<GeneralForListSchema[]>([]);
  const [trainingToolForSelect, setTrainingToolForSelect] = useState<GeneralForListSchema[]>([]);
  const [statusForSelect, setStatusForSelect] = useState<GeneralForListSchema[]>([]);

  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const [data, setData] = useState<P002Schema>(initialP002Model());
  const [video, setVideo] = useState<File | undefined>(undefined);
  const [validationError, setValidationError] = useState<ValidationError<P002Schema>>(initialValidationError());
  const [isValidated, setIsValidated] = useState<boolean | undefined>(undefined);
  const [showRegister, setShowRegister] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const history = useHistory();
  const [deleteVideo, setDeleteVideo] = useState(false);
  const [reload, setReload] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (reload && programItemId) {
      let isMounted = true;
      const fetchData = async () => {
        changeIsLoading();
        fetchP002(authState, programItemId).then((res) => {
          if (isMounted) {
            setData(res.data);
            setReload(false);
          }
          changeIsLoading();
        }).catch((err) => {
          if (isMounted) {
            checkLoggedIn(err);
            notifyError("データの取得に失敗しました。");
            console.error(err);
          }
          changeIsLoading();
        });
      };
      fetchData();
      return () => {
        isMounted = false;
      }
    }
  }, [authState, changeIsLoading, checkLoggedIn, programItemId, reload]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      const keywords = [LIST_PG_STATUS, LIST_STEP, LIST_REFLECTION, LIST_TRAINING_TOOL];
      fetchGeneralsForListMulti(authState, keywords).then((res: any) => {
        if (isMounted) {
          setStatusForSelect(res.data[LIST_PG_STATUS]);
          setStepForSelect(res.data[LIST_STEP]);
          setReflectionForSelect(res.data[LIST_REFLECTION]);
          setTrainingToolForSelect(res.data[LIST_TRAINING_TOOL]);
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (trainingToolForSelect.length == 0) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, trainingToolForSelect.length]);

  const setTrainingToolValue = (val: number, index: number) => {
    let dataPrev = { ...data };
    if (dataPrev.training_tools)
      dataPrev.training_tools[index].training_tool = val;
    setData(dataPrev);
  };

  const clickAddRow = () => {
    let dataPrev = { ...data };
    if (dataPrev.training_tools)
      dataPrev.training_tools.push({
        training_tool: 0,
      });
    setData(dataPrev);
  };

  const deleteItem = (index: number) => {
    let dataPrev = { ...data };
    if (dataPrev.training_tools)
      dataPrev.training_tools.splice(index, 1);
    setData(dataPrev);
  };

  const setVideoData = (file: File) => {
    setVideo(file);
    setDeleteVideo(false);
  };

  const deleteVideoData = () => {
    setData({ ...data, video_url: undefined });
    setDeleteVideo(true);
  };

  const getSelectValue = (val: number, selectList: GeneralForListSchema[]) => {
    if (val == 0) return;
    return selectList.filter(x => x.value == val)[0];
  };

  const clickRegister = () => {
    const validatedData = validationProgramItemModel(data);
    if (validatedData.ok) {
      changeIsLoading();
      putP002(authState, data).then((res) => {
        if (video) {
          putP002Movie(authState, res.data, video).then((_) => {
            setShowRegister(false);
            notify("PG情報を登録しました。");
            dispatch(listActions.clearPgList());
            changeIsLoading();
            history.goBack();
          }).catch((err) => {
            notifyError("登録に失敗しました。");
            console.error(err);
            changeIsLoading();
          });
        } else if (deleteVideo) {
          deleteP002Movie(authState, res.data).then((_) => {
            setShowRegister(false);
            notify("PG情報を登録しました。");
            dispatch(listActions.clearPgList());
            changeIsLoading();
            history.goBack();
          }).catch((err) => {
            notifyError("登録に失敗しました。");
            console.error(err);
            changeIsLoading();
          });
        } else {
          setShowRegister(false);
          notify("PG情報を登録しました。");
          dispatch(listActions.clearPgList());
          changeIsLoading();
          history.goBack();
        }
      }).catch((err) => {
        checkLoggedIn(err);
        notifyError("登録に失敗しました。");
        console.error(err);
        changeIsLoading();
      });
    } else {
      setShowRegister(false);
      notifyError("入力不正があります。");
      setIsValidated(true);
      setValidationError(validatedData.validationError);
    }
  };

  const clickDelete = () => {
    changeIsLoading();
    deleteP002(authState, programItemId).then((_) => {
      setShowDelete(false);
      notify("動画を削除しました。");
      changeIsLoading();
      history.goBack();
    }).catch((err) => {
      checkLoggedIn(err);
      notifyError("削除に失敗しました。");
      console.error(err);
      changeIsLoading();
    });
  };

  useEffect(() => {
    if (isValidated) {
      const validatedData = validationProgramItemModel(data);
      if (validatedData.ok) {
        setValidationError(initialValidationError());
      } else {
        setIsValidated(true);
        setValidationError(validatedData.validationError);
      }
    }
  }, [data, isValidated]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Title title="PG詳細" />
      <div className="plate">
        <CustomRow title="タイトル" required={true} error_msg={validationError.messageOf.title} content={
          <TextBox
            type="text"
            placeholder="タイトル"
            value={data.title}
            onChange={(v: string) => setData({ ...data, title: v })}
          />
        } />

        <CustomRow title="Step" required={true} error_msg={validationError.messageOf.step} content={
          <Select
            value={getSelectValue(data.step, stepForSelect)}
            options={stepForSelect}
            className="w-100 mx-2"
            onChange={(e: any) => setData({ ...data, step: e.value })}
            placeholder="Step"
          />
        } />
        <CustomRow title="反射" required={true} error_msg={validationError.messageOf.reflections} on_same_row={false} content={
          <>
            <div className="mx-2 on_same_row" style={{ flexWrap: "wrap" }}>
              {data.reflections.map((x, i) =>
                <div className="mb-2" key={i}>
                  <CustomButton label={getSelectValue(x.reflection, reflectionForSelect)?.label} class={[CB_CLASS.BLUE]} size={CB_SIZE.S}
                    onDoubleClick={() => setData({ ...data, reflections: data.reflections.filter(y => y !== x) })} />
                </div>
              )}
            </div>
            <Select
              options={reflectionForSelect}
              className="w-100 mx-2"
              onChange={(e: any) => {
                if (e.value > 0 && data.reflections.filter(x => x.reflection == e.value).length == 0) {
                  setData({ ...data, reflections: [...data.reflections, { reflection: e.value }] });
                }
              }}
              placeholder="反射"
            />
          </>
        } />
        <CustomRow title="教具" on_same_row={false} content={
          <>
            {data.training_tools?.map((x, i) =>
              <div className="on_same_row mb-2" key={i}>
                <Select
                  value={getSelectValue(x.training_tool, trainingToolForSelect)}
                  options={trainingToolForSelect}
                  className="w-50 mx-2"
                  onChange={(e: any) => setTrainingToolValue(e.value, i)}
                  placeholder="教具"
                />
                <TextBox
                  type="text"
                  width="50%"
                  placeholder="備考"
                  value={x.remarks}
                  onChange={(v: string) => {
                    const newData = { ...data };
                    const newTrainingTool = { ...newData.training_tools }
                    newTrainingTool[i].remarks = v;
                    setData(newData);
                  }}
                />
                <Delete func={() => { deleteItem(i) }} />
              </div>
            )}
            <CustomButton label="＋行を追加" class={[CB_CLASS.RED]} align={CB_ALIGN.LEFT} onClick={clickAddRow} />
          </>
        } />
        <CustomRow title="トレーニング動画" content={
          <CustomVideo url={data.video_url} setVideo={setVideoData} preparing={data.video_preparing} deleteVideo={deleteVideoData} />
        } />
        <CustomRow title="公開状況" required={true} error_msg={validationError.messageOf.status} content={
          <Select
            value={getSelectValue(data.status, statusForSelect)}
            options={statusForSelect}
            className="w-100 mx-2"
            onChange={(e: any) => setData({ ...data, status: e.value })}
            placeholder="公開状況"
          />
        } />
        <div className="item">
          <CustomButton label="登録" class={[CB_CLASS.RED]} onClick={() => setShowRegister(true)} />
          <ConfirmModal target="登録" show={showRegister} setShow={setShowRegister} func={clickRegister}
            confirmText={"登録してよろしいでしょうか。"}
          />
          {programItemId &&
            <>
              <CustomButton label="削除" class={[CB_CLASS.RED]} onClick={() => setShowDelete(true)} />
              <ConfirmModal target="削除" show={showDelete} setShow={setShowDelete} func={clickDelete}
                confirmText={"削除してよろしいでしょうか。"}
              />
            </>
          }
        </div>
      </div>
    </>
  );
};

export default P002;

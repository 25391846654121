import { HeadquarterUserR002Schema } from "../generated";
import { initialValidationError, ValidatedData } from "./validate.model";
import { validationMessage } from "../config/validationMessage";
import {
  checkFlgObj,
  checkValue,
  checkEmail,
  checkPasswordNotRequire,
  checkValueKana,
} from "../helper/validation.helper";

export const initialHeadQuarterUserModel = (): HeadquarterUserR002Schema => ({
  family_name: "",
  family_name_kana: "",
  given_name: "",
  given_name_kana: "",
  id: 0,
  email: "",
  password: "",
  password_check: "",
  profile_pic_url: "",
});

export const validationHeadQuarterUserModel = (
  headquarter_user: HeadquarterUserR002Schema
): ValidatedData<HeadquarterUserR002Schema> => {
  const error = initialValidationError<HeadquarterUserR002Schema>();

  const checkFlg: checkFlgObj = {
    isOk: true,
  };

  let errorMsg: string | undefined;

  error.messageOf.family_name = [
    checkValue(checkFlg, "姓", headquarter_user.family_name, 30),
    checkValue(checkFlg, "名", headquarter_user.given_name, 30)
  ].filter(x => x != undefined).join(" ");

  error.messageOf.family_name_kana = [
    checkValueKana(checkFlg, "姓（フリガナ）", headquarter_user.family_name_kana, 30),
    checkValueKana(checkFlg, "名（フリガナ）", headquarter_user.given_name_kana, 30)
  ].filter(x => x != undefined).join(" ");

  error.messageOf.email = checkEmail(checkFlg, headquarter_user.email);

  errorMsg = checkPasswordNotRequire(
    checkFlg,
    "新しいパスワード",
    headquarter_user.password,
    headquarter_user.password_check,
    8
  );
  validationMessage.emptyRePassword === errorMsg ||
  validationMessage.inconsistentPassword === errorMsg
    ? (error.messageOf.password_check = errorMsg)
    : (error.messageOf.password = errorMsg);

  if (checkFlg.isOk) {
    return {
      ok: true,
      validatedData: headquarter_user,
    };
  }

  return {
    ok: false,
    validationError: error,
  };
};

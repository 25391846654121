import { loadStorage, removeStorage, saveStorage } from "./env.helper";
import { TokenSchema } from "../generated";
import { useMemo } from "react";

const accessTokenKey = "ACCESS_TOKEN_KEY";

export const setToken = (token: TokenSchema) => {
  saveStorage(accessTokenKey, JSON.stringify(token));
};

export const removeToken = () => {
  removeStorage(accessTokenKey);
};

export const getAccessToken = (): TokenSchema | null => {
  const tokenJson = loadStorage(accessTokenKey);
  if (tokenJson) {
    return JSON.parse(tokenJson) as TokenSchema;
  }
  return null;
};

export const useAccessToken = (): TokenSchema | null => {
  const tokenJson = loadStorage(accessTokenKey);
  return useMemo(() => {
    if (tokenJson) {
      try {
        return JSON.parse(tokenJson) as TokenSchema;
      } catch (error) {
        console.warn(error);
        removeStorage(accessTokenKey);
      }
    }
    return null;
  }, [tokenJson]);
};

import React from "react";
import Select from "react-select";
import "./WeeklyNameButton.scss";
import { GeneralForListSchema } from "../../generated";
import Label from "../Label/Label";

export const WNB_CLASS = {
  BLUE: "wnb_blue",
  RED: "wnb_red",
  YELLOW: "wnb_yellow",
  GRAY: "wnb_gray",
  NONE: "wnb_none",
  PINK: "wnb_pink",
};

export const SC_CLASS = {
  YELLOW: "sc_yellow",
  NONE: "sc_none"
};

interface WeeklyNameButtonProps {
  onDoubleClick?: () => void;
  onChangeStaff?: (e: any) => void;
  options: GeneralForListSchema[];
  staff: number;
  color: typeof WNB_CLASS[keyof typeof WNB_CLASS];
  childName: string | undefined;
  staffColor: string;
}

const WeeklyNameButton: React.FC<WeeklyNameButtonProps> = (props) => {
  return (
    <div onDoubleClick={(event) => {
      event.stopPropagation();
      props.onDoubleClick && props.onDoubleClick();
    }} className={"weekly_name_button d-flex my-2 " + props.color}>
      <div className="weekly_name_label">{props.childName || undefined}</div>
      {props.color == WNB_CLASS.BLUE &&
        <Select
          options={props.options}
          value={props.staff == 0 ? null : (props.options as GeneralForListSchema[]).filter(x => x.value == props.staff)[0]}
          className={"select_option"}
          onChange={(e: any) => {
            if (props.onChangeStaff) {
              props.onChangeStaff(e);
            }
          }}
          placeholder={"担当"}
          classNamePrefix={props.staffColor}
          components={{
            IndicatorSeparator: () => null,
            IndicatorsContainer: () => null,
          }}
        />
      }
    </div>
  );
};

export default WeeklyNameButton;

import { useHistory } from "react-router-dom";
import CustomButton, { CB_ALIGN, CB_CLASS, CB_SIZE } from "../Button/CustomButton";
import "./Title.scss";

function Title(props: any) {
  const history = useHistory();
  const goBack = props.go_back == false ? false : true;
  return (
    <div className="title_area">
      <h1>{props.title}</h1>
      {goBack &&
        <CustomButton label="戻る" class={[CB_CLASS.BLUE]} align={CB_ALIGN.LEFT} size={CB_SIZE.S} min_width={50} onClick={() => history.goBack()} />
      }
    </div>
  );
};

export default Title;

import { FormControl } from "react-bootstrap";
import "./CustomDate.scss";

function CustomDate(props: any) {
  return (
    <FormControl
      type="date"
      min={"1900-01-01"}
      max={"2100-12-31"}
      className={"custom_date " + props.className || ""}
      placeholder="年/月/日"
      value={props.val || ""}
      onChange={(e) => props.setter(e.target.value === "" ? undefined : e.target.value)}
      style={{ width: props.width || 140 }}
    />
  );
};

export default CustomDate;

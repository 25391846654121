import { N002Schema } from "../generated";
import { checkFlgObj, checkSelectOrRadio, checkValue, checkValueNotRequire } from "../helper/validation.helper";
import { initialValidationError, ValidatedData } from "./validate.model";
import { format } from "date-fns-tz";

export const UPLOAD_CATEGORY = "アップロードカテゴリ";
export const UPLOAD_STATUS = "アップロード公開状況";

export const initialN002Model = (): N002Schema => ({
  id: 0,
  category: 0,
  title: "",
  status: 0,
  description: undefined,
  file_url: "",
});

export const validationUploadDataModel = (
  uploadData: N002Schema
): ValidatedData<N002Schema> => {
  const error = initialValidationError<N002Schema>();

  const checkFlg: checkFlgObj = {
    isOk: true,
  };

  error.messageOf.title = checkValue(checkFlg, "タイトル", uploadData.title);

  error.messageOf.description= checkValueNotRequire(checkFlg, "説明", uploadData.description, 50);

  error.messageOf.category = checkSelectOrRadio(checkFlg, "カテゴリ", uploadData.category);

  error.messageOf.status = checkSelectOrRadio(checkFlg, "公開状況", uploadData.status);

  if (checkFlg.isOk) {
    return {
      ok: true,
      validatedData: uploadData,
    };
  }

  return {
    ok: false,
    validationError: error,
  };
};
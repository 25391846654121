import "./PaginationTable.scss";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";

function PaginationTable(props: any) {
  const [currentPage, setCurrentPage] = useState(1);
  let totalPages = Math.ceil(props.list.length / props.limit);
  if (totalPages == 1) totalPages = 0;
  const indexOfLastItem = currentPage * props.limit;
  const indexOfFirstItem = indexOfLastItem - props.limit;
  const currentItems = props.list.slice(indexOfFirstItem, indexOfLastItem);
  const maxButtonCount = 10;
  const halfMax = Math.floor(maxButtonCount / 2);
  const startPage = Math.max(currentPage - halfMax, 1);
  const endPage = Math.min(startPage + maxButtonCount - 1, totalPages);
  const pageNumbers = Array.from(
    { length: endPage - startPage + 1 },
    (_, i) => startPage + i
  );

  // ページ数に変更があった場合に、最初のページに遷移
  useEffect(() => {
    setCurrentPage(1);
  }, [totalPages]);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < Math.ceil(props.list.length / props.limit)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      <div className={props.isUnnecessary ? "" : "plate"} style={{ textAlign: "center" }}>
        <Table
          id={
            props.tr_select == false
              ? "pagination_table_no_select"
              : "pagination_table_select"
          }
          style={{
            maxWidth: props.max_width || 1300,
          }}
        >
          <thead>
            <tr>
              {props.headers.map((x: string, i: number) => (
                <th key={i}>{x}</th>
              ))}
            </tr>
          </thead>
          <tbody>{currentItems}</tbody>
        </Table>
        {pageNumbers.length > 0 && (
          <div
            className="d-flex justify-content-center"
            style={{ maxWidth: props.max_width }}
          >
            <Pagination>
              {currentPage > 1 && (
                <>
                  <Pagination.Prev onClick={handlePrevPage} />
                  {startPage > 1 && (
                    <>
                      <Pagination.Item onClick={() => handlePageChange(1)}>
                        1
                      </Pagination.Item>
                      {startPage > 2 && <Pagination.Ellipsis disabled />}
                    </>
                  )}
                </>
              )}
              {pageNumbers.map((pageNumber) => (
                <Pagination.Item
                  key={pageNumber}
                  active={pageNumber === currentPage}
                  onClick={() => {
                    handlePageChange(pageNumber);
                  }}
                >
                  {pageNumber}
                </Pagination.Item>
              ))}
              {endPage < totalPages && (
                <>
                  {endPage < totalPages - 1 && <Pagination.Ellipsis disabled />}
                  {currentPage !== totalPages && (
                    <Pagination.Item onClick={() => handlePageChange(totalPages)}>
                      {totalPages}
                    </Pagination.Item>
                  )}
                  <Pagination.Next onClick={handleNextPage} />
                </>
              )}
            </Pagination>
          </div>
        )}
      </div>
    </>
  );
}

export default PaginationTable;

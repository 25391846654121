import { Modal } from "react-bootstrap";
import React, { VFC } from "react";

type CustomModalProps = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  contents: any;
  size: "sm" | "lg" | "xl" | undefined;
  headerContents?: any;
};

const CustomModal: VFC<CustomModalProps> = ({
  show,
  setShow,
  contents,
  size,
  headerContents,
}) => {
  return (
    <Modal show={show} onHide={() => setShow(false)} size={size}>
      <Modal.Header closeButton>{headerContents}</Modal.Header>
      <Modal.Body>{contents}</Modal.Body>
    </Modal>
  );
};

export default CustomModal;

import "./Sidebar.scss";
import { useSelector } from "react-redux";
import { userInfoSelector } from "../../redux/selectors/auth.selector";
import { getForHistoryPush } from "../../helper/url.helper";
import { useHistory } from "react-router-dom";

function SidebarItem(props: any) {
  const userInfo = useSelector(userInfoSelector);
  const show = props.showUser ? userInfo?.user_type == props.showUser : true;
  const history = useHistory();

  return (
    <>
      {show && (
        <div
          onClick={() => {
            props.args
              ? history.push(getForHistoryPush(props.url, props.args))
              : history.push(props.url)
          }
          }
          className={
            location.pathname.split("/")[1][0] === props.url.split("/")[1][0]
              ? "sidebar-item-active sidebar-item"
              : "sidebar-item"
          }
        >
          {props.icon}
          <p className="menu_title">{props.title}</p>
        </div>
      )}
    </>
  );
};

export default SidebarItem;

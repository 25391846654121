export const calcAge = (date: string, compareDate?: string): number => {
  
  const currentDate = compareDate ? new Date(compareDate) : new Date();
  const birthDate = new Date(date);
  const thisYearBirthDate = new Date(
    currentDate.getFullYear(),
    birthDate.getMonth(),
    birthDate.getDate()
  );

  let ageDiff = currentDate.getFullYear() - birthDate.getFullYear();
  currentDate < thisYearBirthDate && ageDiff--;

  return ageDiff;
};

import "./WeeklyNameButton.scss";
import { AiFillPlusCircle } from "react-icons/ai";


export const SC_CLASS = {
  YELLOW: "sc_yellow",
};

const WeeklyAddSlotButton = (props: any) => {
  return (
    <div onClick={(event) => {
      event.stopPropagation();
      props.onClick && props.onClick();
    }} className={"weekly_add_slot_button my-2"}>
      <AiFillPlusCircle
        color={"#84c1f6"}
        size={35}
      />
    </div>
  );
};

export default WeeklyAddSlotButton;

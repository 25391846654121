import { D006Schema, D009Schema, E002Schema } from "../generated";
import { checkFlgObj, checkValue } from "../helper/validation.helper";
import { ValidatedData, initialValidationError } from "./validate.model";

export const NOT_INTERVIEWED = 1;
export const INTERVIEWED = 2;

export const NOT_UPLOADED = 1;
export const UPLOADED = 2;

export const STATUS_ASM_INTERVIEW = "アセスメント面談";
export const STATUS_ASM_UPLOAD = "アセスメントアップロード";

export const initialD006Model = (): D006Schema => ({
  facility_name: "",
  child_id: 0,
  child_name: "",
  child_name_kana: "",
  birthday: "",
  child_profile_pic_url: "",
  assessments: [],
});

export const initialD009Model = (): D009Schema => ({
  id: 0,
  facility_name: "",
  child_id: 0,
  child_name: "",
  child_name_kana: "",
  child_profile_pic_url: "",
  is_interviewed: false,
});

export const validationAssessmentModel = (
  data: D009Schema
): ValidatedData<D009Schema> => {
  const error = initialValidationError<D009Schema>();

  const checkFlg: checkFlgObj = {
    isOk: true,
  };

  error.messageOf.answer_date = checkValue(checkFlg, "回答日", data.answer_date);


  if (checkFlg.isOk) {
    return {
      ok: true,
      validatedData: data,
    };
  }

  return {
    ok: false,
    validationError: error,
  };
};
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../redux/selectors/auth.selector";
import { fetchUserInfoAction } from "../../redux/actions/auth.actions";
import {
  FCStaffAllInfoSchema,
  HeadquarterUserR002Schema,
  Result,
  TokenSchema,
  TokenWithIdSchema,
} from "../../generated";
import {
  deleteHeadQuarterUser,
  fetchHeadQuarterUser,
  putHeadQuarterUser,
  putHeadQuarterUserImage,
} from "../../services/api.service";
import { initialHeadQuarterUserModel, validationHeadQuarterUserModel } from "../../models/headquarter_user.model";
import { ValidationError, initialValidationError } from "../../models/validate.model";
import ConfirmModal from "../../components/ConfirmModal";
import Title from "../../components/Title/Title";
import CustomRow from "../../components/CustomRow/CustomRow";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import TextBox from "../../components/TextBox/TextBox";
import CustomButton, { CB_CLASS } from "../../components/Button/CustomButton";
import CustomUserIcon from "../../components/CustomImage/CustomUserIcon";
import { notify, notifyError } from "../../helper/settings.helper";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import { raiseAction } from "../../helper/api.helper";
import { useAuth } from "../../helper/auth.helper";

interface iR002 {
  headquarter_user_id: number | undefined;
}

const R002 = () => {
  const { checkLoggedIn } = useAuth();
  const dispatch = useDispatch();
  const location = useLocation<iR002>();
  const headquarter_user_id = location.state.headquarter_user_id;
  const history = useHistory();
  const authState = useSelector(authSelector);
  const [isLoading, changeIsLoading] = useLoadingIndicator(undefined);
  const [data, setData] = useState<HeadquarterUserR002Schema>(initialHeadQuarterUserModel);
  const [image, setImage] = useState<File | undefined>(undefined);
  const [validationError, setValidationError] = useState<ValidationError<FCStaffAllInfoSchema>>(initialValidationError());
  const [isValidated, setIsValidated] = useState<boolean | undefined>(undefined);
  const [showRegister, setShowRegister] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [reload, setReload] = useState(true);

  useEffect(() => {
    if (headquarter_user_id && reload) {
      let isMounted = true;
      const fetchData = async () => {
        changeIsLoading();
        fetchHeadQuarterUser(headquarter_user_id, authState).then((res) => {
          if (isMounted) {
            setData(res.data);
            setReload(false);
          }
          changeIsLoading();
        }).catch((err) => {
          if (isMounted) {
            checkLoggedIn(err);
            notifyError("データの取得に失敗しました。");
            console.error(err);
          }
          changeIsLoading();
        });
      };
      fetchData();
      return () => {
        isMounted = false;
      }
    }
  }, [authState, headquarter_user_id, changeIsLoading, checkLoggedIn, reload]);

  const success_register = (token: TokenSchema | undefined) => {
    setShowRegister(false);
    notify("本部スタッフ情報を登録しました。");
    changeIsLoading();
    if (token) {
      dispatch(fetchUserInfoAction(token, true))
    } else {
      history.goBack();
    }
  }

  const error_register = (v: string | undefined) => {
    setShowRegister(false);
    notifyError(String(v));
    changeIsLoading();
  }

  const clickRegister = () => {
    const validatedData = validationHeadQuarterUserModel(data);
    if (validatedData.ok) {
      changeIsLoading();
      putHeadQuarterUser(authState, data).then((res) => {
        raiseAction(res.data, (v: TokenWithIdSchema) => {
          if (image) {
            putHeadQuarterUserImage(v.id, image, authState).then((_) => {
              success_register(v.token);
            }).catch((err) => {
              notifyError("登録に失敗しました。");
              console.error(err);
              changeIsLoading();
            });
          } else {
            success_register(v.token);
          }
        }, (v: Result) => {
          error_register(v.msg);
        });
      }).catch((err) => {
        checkLoggedIn(err);
        notifyError("登録に失敗しました。");
        console.error(err);
        changeIsLoading();
      });
    } else {
      setShowRegister(false);
      notifyError("入力不正があります。");
      setIsValidated(true);
      setValidationError(validatedData.validationError);
    }
  };

  const clickDelete = () => {
    if (headquarter_user_id !== undefined) {
      deleteHeadQuarterUser(headquarter_user_id, authState).then((_) => {
        setShowDelete(false);
        notify("本部スタッフを削除しました。");
        changeIsLoading();
        history.goBack();
      }).catch((err) => {
        checkLoggedIn(err);
        notifyError("削除に失敗しました。");
        console.error(err);
        changeIsLoading();
      });
    }
  };

  useEffect(() => {
    if (isValidated) {
      const validatedData = validationHeadQuarterUserModel(data);
      if (validatedData.ok) {
        setValidationError(initialValidationError());
      } else {
        setIsValidated(true);
        setValidationError(validatedData.validationError);
      }
    }
  }, [data, isValidated]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Title title={headquarter_user_id ? "本部スタッフ編集" : "本部スタッフ登録"} />
      <div className="plate t_align_center">
        <CustomUserIcon uploadFile={image} setUploadFile={setImage} imageUrl={data.profile_pic_url} />
      </div>
      <div className="plate">
        <CustomRow
          title="名前"
          required={true}
          error_msg={validationError.messageOf.family_name}
          content={
            <>
              <TextBox
                type="text"
                placeholder="姓"
                value={data.family_name}
                onChange={(v: any) => setData({ ...data, family_name: v })}
              />
              <TextBox
                type="text"
                placeholder="名"
                value={data.given_name}
                onChange={(v: any) => setData({ ...data, given_name: v })}
              />
            </>
          }
        />
        <CustomRow
          title="名前（フリガナ）"
          required={true}
          error_msg={validationError.messageOf.family_name_kana}
          content={
            <>
              <TextBox
                type="text"
                placeholder="セイ"
                value={data.family_name_kana}
                onChange={(v: any) => setData({ ...data, family_name_kana: v })}
              />
              <TextBox
                type="text"
                placeholder="メイ"
                value={data.given_name_kana}
                onChange={(v: any) => setData({ ...data, given_name_kana: v })}
              />
            </>
          }
        />
        <CustomRow
          title="メールアドレス"
          required={true}
          error_msg={validationError.messageOf.email}
          content={
            <TextBox
              type="email"
              placeholder="メールアドレス"
              value={data.email}
              onChange={(v: any) => setData({ ...data, email: v })}
            />
          }
        />
        {headquarter_user_id !== undefined &&
          <>
            <CustomRow
              title="新しいパスワード"
              required={false}
              error_msg={validationError.messageOf.password}
              content={
                <TextBox
                  type="password"
                  placeholder="パスワード"
                  value={data.password}
                  onChange={(v: any) => setData({ ...data, password: v })}
                />
              }
            />
            <CustomRow
              title="パスワード（確認）"
              required={false}
              error_msg={validationError.messageOf.password_check}
              content={
                <TextBox
                  type="password"
                  placeholder="パスワード（確認）"
                  value={data.password_check}
                  onChange={(v: any) => setData({ ...data, password_check: v })}
                />
              }
            />
          </>
        }
        <div className="plate d-flex flex-column align_center">
          <CustomButton
            label="登録"
            class={[CB_CLASS.RED]}
            onClick={() => setShowRegister(true)}
          />
          <ConfirmModal
            target="登録"
            show={showRegister}
            setShow={setShowRegister}
            func={clickRegister}
            confirmText={"登録してよろしいでしょうか。"}
          />
          {headquarter_user_id !== undefined &&
            <>
              <CustomButton
                label="削除"
                class={[CB_CLASS.RED]}
                onClick={() => setShowDelete(true)}
              />
              <ConfirmModal
                target="削除"
                show={showDelete}
                setShow={setShowDelete}
                func={clickDelete}
                confirmText={"削除してよろしいでしょうか。"}
              />
            </>
          }
        </div>
      </div>
    </>
  );
};

export default R002;

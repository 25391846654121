import ReactTextareaAutosize from "react-textarea-autosize";

function CustomTextarea(props: any) {
  return (
    <ReactTextareaAutosize
      minRows={3}
      className={"mx-2 form-control " + props.className}
      placeholder={props.placeholder}
      value={props.value || ""}
      disabled={props.disabled}
      onChange={(e) => {
        const inputLineCount = e.target.value.split("\n").length;
        if (!props.maxRows || inputLineCount <= props.maxRows) {
          props.onChange(e.target.value);
        }
      }}
      style={{ width: "98%", resize: "none" }}
    />
  );
}

export default CustomTextarea;

import { useCallback, useState } from "react";

// useEffect内で用いる際、無限ループが発生するため、useCallbackで囲っている。
// 参考リンク
// https://blog.uhy.ooo/entry/2021-02-23/usecallback-custom-hooks/

export const useLoadingIndicator = (initialLoadingState: boolean = false) => {
  const [isLoading, changeIsLoading] = useState(initialLoadingState);
  const changeLoadingIndicator = useCallback(() => {
    changeIsLoading((i) => !i);
  }, [changeIsLoading]);

  return [isLoading, changeLoadingIndicator] as const;
};

import { COLOR_RED } from "../constants";

const RequiredBadge = () => (
  <span
    className="badge"
    style={{ backgroundColor: COLOR_RED, height: "22px", margin: "3px 16px", display: "flex", alignItems: "center" }}
  >
    必須
  </span>
);

export default RequiredBadge;

import "./Weekly.scss";
import { WNB_CLASS } from "../../components/Button/WeeklyNameButton";
import Label from "../../components/Label/Label";
import { AiFillPlusCircle } from "react-icons/ai";
import { PiMouse } from "react-icons/pi";

const DescribeWeekly = () => {

  return (
    <div className="mx-auto w-75">
      <div className="on_same_row my-2">
        <div className={"weekly_name_button describe_icon " + WNB_CLASS.BLUE}>
          <div className="weekly_name_label">お子様名</div>
          <div className="help_modal_select_staff">担当</div>
        </div>
        <div className="describe_text ms-3">
          <div className="fw-bold">予約承認済み</div>
          <div><PiMouse size="20px" color="#ff8484"/>：トレーニング詳細画面へ遷移</div>
        </div>
      </div>
      <div className="on_same_row my-2">
        <div className={"weekly_name_button describe_icon reserved " + WNB_CLASS.NONE}>お子様名</div>
        <div className="describe_text ms-3">
          <div className="fw-bold">予約承認待ち</div>
          <div><PiMouse size="20px" color="#ff8484"/>：スケジュール詳細画面へ遷移</div>
        </div>
      </div>
      <div className="on_same_row my-2">
        <div className={"weekly_name_button describe_icon " + WNB_CLASS.GRAY}>
          <div className="weekly_name_label">お子様名</div>
        </div>
        <div className="describe_text ms-3">
          <div className="fw-bold">仮予約</div>
          <div><PiMouse size="20px" color="#ff8484"/>：スケジュール詳細画面へ遷移</div>
        </div>
      </div>
      <div className="on_same_row my-2">
        <div className={"weekly_name_button describe_icon " + WNB_CLASS.PINK}>
          <div className="weekly_name_label">お子様名</div>
        </div>
        <div className="describe_text ms-3">
          <div className="fw-bold">お子様の固定時間枠</div>
          <div><PiMouse size="20px" color="#ff8484"/>：固定時間入力画面へ遷移</div>
        </div>
      </div>
      <div className="on_same_row my-2">
        <div className={"weekly_add_slot_button describe_icon"}>
          <AiFillPlusCircle
            color={"#84c1f6"}
            size={35}
          />
        </div>
        <div className="describe_text ms-3">
          <div className="fw-bold">予約枠追加ボタン</div>
          <div><PiMouse size="20px" color="#8484ff"/>：予約枠を追加</div>
        </div>
      </div>
      <div className="my-4">
        <span className="fw-bold">予約空き枠</span><span>（<PiMouse size="20px" color="#ff8484"/>：予約枠削除）</span>
        <div className="on_same_row my-2">
          <div className={"weekly_name_button describe_icon " + WNB_CLASS.NONE}>
          </div>
          <div className="describe_text ms-3">
            保護者通知済み
          </div>
        </div>
        <div className="on_same_row my-2">
          <div className={"weekly_name_button describe_icon " + WNB_CLASS.YELLOW}>
          </div>
          <div className="describe_text ms-3">
            保護者未通知
          </div>
        </div>
        <div className="on_same_row my-2">
          <div className={"weekly_name_button describe_icon " + WNB_CLASS.RED}>
          </div>
          <div className="describe_text ms-3">
            強調表示
          </div>
        </div>
      </div>
    </div>
  );
};

export default DescribeWeekly;
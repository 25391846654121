import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { FCFacilitiesSchema, GeneralForListSchema } from "../../generated";
import { authSelector } from "../../redux/selectors/auth.selector";
import { fetchFcFacilities } from "../../services/api.service";
import "./F001.scss";
import Select from "../../components/Select/CustomSelect";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import CustomButton, { CB_CLASS } from "../../components/Button/CustomButton";
import PaginationTable from "../../components/PaginationTable/PaginationTable";
import Title from "../../components/Title/Title";
import { initialGeneralForList } from "../../models/general.model";
import {
  C001Url,
  F002Url,
  F003Url,
  K001Url,
  getForHistoryPush,
} from "../../helper/url.helper";
import { useAuth } from "../../helper/auth.helper";
import { notifyError } from "../../helper/settings.helper";
import { fcListSelector } from "../../redux/selectors/list.selector";
import { listActions } from "../../redux/actions/list.actions";
import { FC_LIST_TYPE, getFcList, getFcListForRedux, getFcListFromRedux } from "../../helper/list.helper";

const F001 = () => {
  const { checkLoggedIn } = useAuth();
  const maxWidth = 800;
  const authState = useSelector(authSelector);
  const history = useHistory();

  const [fcList, setFcList] = useState<FCFacilitiesSchema[]>([]);
  const [getData, setGetData] = useState(true);
  const [fcForSelect, setFcForSelect] = useState<
    GeneralForListSchema[]
  >([]);
  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const [search, setSearch] = useState<string>("");
  const fcListState = useSelector(fcListSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchFcFacilities(authState, search).then((res) => {
        if (isMounted) {
          setFcList(res.data);
        }
        changeIsLoading();
        setGetData(false);
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (getData) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, search, getData]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchFcFacilities(authState, "").then((res) => {
        if (isMounted) {
          setFcForSelect(getFcList(FC_LIST_TYPE.ADD_INIT, res.data));
          dispatch(listActions.makeFcList(getFcListForRedux(res.data)));
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (fcForSelect.length == 0) {
      if (fcListState.select_list.length == 0) {
        fetchData();
      } else {
        setFcForSelect(getFcListFromRedux(FC_LIST_TYPE.ADD_INIT, fcListState.select_list));
      }
    }
    return () => {
      isMounted = false;
    }
  }, [authState, dispatch, changeIsLoading, checkLoggedIn, fcForSelect.length, fcListState.select_list]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Title title="FC施設一覧" />
      <div className="plate">
        <div className="on_same_row justify" style={{ maxWidth: maxWidth }}>
          <div className="item">
            <Select
              value={fcForSelect.filter((x) => x.label == search)[0] || null}
              options={fcForSelect}
              className="F001_fc_select"
              onChange={(e: any) => {
                setSearch(e.value == 0 ? "" : fcForSelect.filter((x) => x.value == e.value)[0].label);
                setGetData(true);
              }}
              placeholder="施設"
            />
          </div>
          <CustomButton
            label="＋施設を追加"
            class={[CB_CLASS.RED]}
            onClick={() => history.push(getForHistoryPush(F002Url, [0]))}
          />
        </div>
      </div>
      <PaginationTable
        limit={10}
        max_width={maxWidth}
        headers={["施設名", "", "", ""]}
        list={fcList.map((x, i) => (
          <tr key={i} onClick={() => history.push(getForHistoryPush(F002Url, [x.id]))}>
            <td className="item" style={{ textAlign: "left" }}>
              {x.facility_name}
            </td>
            <td className="item">
              <CustomButton
                label="カレンダー"
                class={[CB_CLASS.BLUE]}
                onClick={() => history.push(getForHistoryPush(K001Url, [x.id]))}
              />
            </td>
            <td className="item">
              <CustomButton
                label="スタッフ一覧"
                class={[CB_CLASS.BLUE]}
                onClick={() => history.push(getForHistoryPush(F003Url, [x.id]))}
              />
            </td>
            <td className="item">
              <CustomButton
                label="保護者一覧"
                class={[CB_CLASS.BLUE]}
                onClick={() => history.push(getForHistoryPush(C001Url, [x.id]))}
              />
            </td>
          </tr>
        ))}
      />
    </>
  );
};

export default F001;

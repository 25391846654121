import "./Icon.scss";
import { BsFillTrashFill } from "react-icons/bs";
import { COLOR_RED } from "../../constants";

function Delete(props: any) {
  return (
    <div>
      <BsFillTrashFill
        className="icon hover_red"
        color={COLOR_RED} size={30}
        onClick={(event) => {
          event.stopPropagation();
          props.func();
        }} />
    </div>
  );
};

export default Delete;

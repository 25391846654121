import { Tab, Tabs } from "react-bootstrap";
import { useTab } from "../../helper/tab.helper";
import J001_SEND from "./J001_SEND";
import J001_RECEIVE from "./J001_RECEIVE";
import { useSelector } from "react-redux";
import { userInfoSelector } from "../../redux/selectors/auth.selector";
import { HEADQUARTER_USER } from "../../constants";

const TAB = {
  SEND: { value: "1", label: "送信" },
  RECEIVE: { value: "2", label: "受信" },
};

const J001 = () => {
  const { key, setKey } = useTab(TAB.SEND.value);
  const userInfo = useSelector(userInfoSelector);
  return (
    (userInfo?.user_type == HEADQUARTER_USER) ? (
      <J001_SEND />
    ) : (
      <>
        <Tabs
          defaultActiveKey={TAB.SEND.value}
          activeKey={key}
          className="mb-3 mt-5 tab"
          onSelect={(k) => {
            if (k === TAB.SEND.value || k === TAB.RECEIVE.value) {
              setKey(k);
            }
          }}
          mountOnEnter={true}
        >
          <Tab eventKey={TAB.SEND.value} title={TAB.SEND.label}>
            <J001_SEND />
          </Tab>
          <Tab eventKey={TAB.RECEIVE.value} title={TAB.RECEIVE.label}>
            <J001_RECEIVE />
          </Tab>
        </Tabs>
      </>
    ));
};

export default J001;

import { Alert } from "react-bootstrap";

function ErrorMsg(props: any) {
  return (
    <>
      {props.error_msg && (
        <div className="item">
          <Alert
            variant="danger"
            style={{ padding: 5, fontSize: 14, margin: 0 }}
          >
            <p className="text-wrap">{props.error_msg}</p>
          </Alert>
        </div>
      )}
    </>
  );
};

export default ErrorMsg;

import "./Contact.scss";
import React, { useEffect, useState } from "react";
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { contactSendMessage } from "../../services/api.service";
import CustomRow from "../../components/CustomRow/CustomRow";
import TextBox from "../../components/TextBox/TextBox";
import CustomTextarea from "../../components/CustomTextarea/CustomTextarea";
import CustomButton, { CB_CLASS } from "../../components/Button/CustomButton";
import ConfirmModal from "../../components/ConfirmModal";
import { iContact, initialContactModel, validationContactModel } from "./Contact.model";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import { notify, notifyError } from "../../helper/settings.helper";
import { ValidationError, initialValidationError } from "../../models/validate.model";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";

const Cotact = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_SITE_KEY ?? ""}>
      <ContactForm />
    </GoogleReCaptchaProvider>
  );
}

const ContactForm = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [data, setData] = useState(initialContactModel);
  const [showRegister, setShowRegister] = useState(false);
  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const [isValidated, setIsValidated] = useState<boolean | undefined>(undefined);
  const [validationError, setValidationError] = useState<ValidationError<iContact>>(initialValidationError());

  const clickRegister = async () => {
    const validatedData = validationContactModel(data);
    if (validatedData.ok) {
      changeIsLoading();
      if (!executeRecaptcha) {
        changeIsLoading();
        setShowRegister(false);
        notifyError("送信に失敗しました。");
        return;
      }

      try {
        const recaptchaToken = await executeRecaptcha();

        contactSendMessage(data.email, data.name, data.message, {
          "Content-Type": "application/json",
          // トークンをヘッダーに追加
          "Authorization": `Bearer ${recaptchaToken}`
        }).then((res) => {
          if (res.data.result) {
            notify("送信しました。");
          } else {
            notifyError(res.data.msg || "送信に失敗しました。");
          }
          setShowRegister(false);
          changeIsLoading();
        }).catch((err) => {
          setShowRegister(false);
          notifyError("送信に失敗しました。");
          console.error(err);
          changeIsLoading();
        });
      } catch (e) {
        setShowRegister(false);
        notifyError("送信に失敗しました。");
        console.error(e);
        changeIsLoading();
      }
    } else {
      setShowRegister(false);
      notifyError("入力不正があります。");
      setIsValidated(true);
      setValidationError(validatedData.validationError);
    }
  };

  useEffect(() => {
    if (isValidated) {
      const validatedData = validationContactModel(data);
      if (validatedData.ok) {
        setValidationError(initialValidationError());
      } else {
        setIsValidated(true);
        setValidationError(validatedData.validationError);
      }
    }
  }, [data, isValidated]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <div id="contact_bg">
        <h1 id="contact_title">お問い合わせ</h1>
        <h6 className="t_align_center">S.I.C.KIDSアプリについてのお問い合わせフォームです。</h6>
        <h6 className="t_align_center">頂いたメールアドレスに後日回答させて頂きます。</h6>
        <div id="main">
          <CustomRow
            title="メールアドレス"
            full_width={true}
            error_msg={validationError.messageOf.email}
            content={
              <TextBox
                type="email"
                placeholder="メールアドレス"
                value={data.email}
                onChange={(v: any) => setData({ ...data, email: v })}
              />
            }
          />
          <CustomRow
            title="名前"
            full_width={true}
            error_msg={validationError.messageOf.name}
            content={
              <TextBox
                type="text"
                placeholder="名前"
                value={data.name}
                onChange={(v: any) => setData({ ...data, name: v })}
              />
            }
          />
          <CustomRow
            title="お問い合わせ内容"
            full_width={true}
            error_msg={validationError.messageOf.message}
            content={
              <CustomTextarea
                placeholder="お問い合わせ内容"
                value={data.message}
                onChange={(v: string) => setData({ ...data, message: v })} />
            } />
          <div className="item">
            <CustomButton label="送信" class={[CB_CLASS.RED]} min_width={100} onClick={() => setShowRegister(true)} />
            <ConfirmModal target="送信" show={showRegister} setShow={setShowRegister} func={clickRegister}
              confirmText={"送信してよろしいでしょうか。"}
            />
          </div>
        </div>
      </div>
      <div className="t_align_center">
        <a href="mailto:sic@ranasoft.co.jp">メールでの問い合わせはこちら</a>
      </div>
    </>
  );
};

export default Cotact;

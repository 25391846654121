import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import "./ReactBurgerMenu.scss";

const SidebarInResponsive = (props: any) => {
  return (
    <>
      <Menu>
        <div className="side-bar-container-responsive">
          <div className="menu-item">
            <p className="title">
            {props.fcName ? (
                <Link to="/B001">{props.fcName}</Link>
              ) : (
                <Link to="/B001">S.I.C.KIDS本部</Link>
              )}
            </p>
            <Sidebar />
          </div>
        </div>
      </Menu>
    </>
  );
};

export default SidebarInResponsive;

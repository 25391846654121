import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../redux/selectors/auth.selector";
import { L004Schema, L008CartItemSchema } from "../../generated";

import CustomRow from "../../components/CustomRow/CustomRow";
import CustomButton, { CB_CLASS } from "../../components/Button/CustomButton";
import CustomModal from "../../components/CustomModal/CustomModal";
import Label from "../../components/Label/Label";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { notify, notifyError } from "../../helper/settings.helper";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import { initialL004Model } from "../../models/equipment.model";
import EquipmentPict, { SIZES } from "../../components/Pict/EquipmentPict";
import IncDecForRow from "../../components/IncDec/IncDecForRow";
import { cartSelector } from "../../redux/selectors/cart.selector";
import { cartActions } from "../../redux/actions/cart.actions";
import { fetchEquipment } from "../../services/api.service";
import { useAuth } from "../../helper/auth.helper";
import FileUploader from "../../components/FileUploader/FileUploader";

interface L007Props {
  showL007Modal: boolean;
  setShowL007Modal: React.Dispatch<React.SetStateAction<boolean>>;
  equipmentId: number;
}

const L007: React.VFC<L007Props> = ({
  showL007Modal,
  setShowL007Modal,
  equipmentId,
}) => {
  const { checkLoggedIn } = useAuth();
  const dispatch = useDispatch();
  const [isLoading, changeIsLoading] = useLoadingIndicator(undefined);
  const authState = useSelector(authSelector);
  const cartState = useSelector(cartSelector);
  const [data, setData] = useState<L004Schema>(initialL004Model);

  const checkCartThisEquipment = () => {
    const targetIndex = cartState.order_list.findIndex(order => order.equipment_id === equipmentId);
    return targetIndex > -1 ? cartState.order_list[targetIndex].num : 1;
  }
  const [item, setItem] = useState<L008CartItemSchema>({ equipment_id: equipmentId, num: checkCartThisEquipment() });
  const [reload, setReload] = useState(true);

  useEffect(() => {
    if (reload) {
      let isMounted = true;
      const fetchData = async () => {
        changeIsLoading();
        fetchEquipment(equipmentId, authState).then((res) => {
          if (isMounted) {
            setData(res.data);
            setReload(false);
          }
          changeIsLoading();
        }).catch((err) => {
          if (isMounted) {
            checkLoggedIn(err);
            notifyError("データの取得に失敗しました。");
            console.error(err);
          }
          changeIsLoading();
        });
      };
      fetchData();
      return () => {
        isMounted = false;
      }
    }
  }, [authState, changeIsLoading, checkLoggedIn, equipmentId, reload]);

  const clickRegister = () => {
    cartState.order_list.find(order => order.equipment_id === equipmentId) ?
      dispatch(cartActions.updateCartItem(item))
      : dispatch(cartActions.addToCart(item));
    setShowL007Modal(false);
    notify("カートに商品を追加しました。");
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <CustomModal
        show={showL007Modal}
        setShow={setShowL007Modal}
        size={"lg"}
        contents={
          <>
            <div className="plate">
              <div className="t_align_center w-100">
                <EquipmentPict url={data.image_url} size={SIZES.LL} />
              </div>
              <CustomRow
                title="名前"
                required={false}
                content={
                  <div className="text-wrap item">
                    <div className="text-break">{data.name}</div>
                  </div>
                }
              />
              <CustomRow
                title="品番"
                required={false}
                content={
                  <div className="text-wrap item">
                    <div className="text-break">{data.serial}</div>
                  </div>
                }
              />
              <CustomRow
                title="大きさ"
                required={false}
                content={
                  <div className="text-wrap item">
                    <div className="text-break">{data.size || "設定なし"}</div>
                  </div>
                }
              />
              <CustomRow
                title="色"
                required={false}
                content={
                  <div className="text-wrap item">
                    <div className="text-break">{data.color || "設定なし"}</div>
                  </div>
                }
              />
              <CustomRow
                title="値段"
                required={false}
                content={<Label value={data.price.toLocaleString() + " 円"} />}
              />
              <CustomRow
                title="数量"
                required={false}
                content={
                  <IncDecForRow
                    val={item.num}
                    inc={() => setItem({ ...item, num: item.num + 1 })}
                    dec={() => setItem({ ...item, num: item.num - 1 })}
                  />
                }
              />
              <CustomRow
                title="合計金額"
                required={false}
                content={<Label value={(data.price * item.num).toLocaleString() + " 円"} />}
              />
              {data.order_form_url &&
              <>
                <div className="text-wrap small mt-3">
                  <div className="text-break">{"カートに追加の上で別途以下のファイルの記入が必要になります。"}</div>
                  <div className="text-break">{"ダウンロードしてご記入いただき、本部までFAXにて送付してください。"}</div>
                </div>
                <CustomRow
                  title="詳細入力ファイル"
                  required={false}
                  on_same_row={false}
                  content={
                      <>
                        <FileUploader noChangeFile={true} url={data.order_form_url} />
                      </>
                  }
                />
              </>
              }
            </div>
            <div className="d-flex justify_center my-3">
              <CustomButton
                label="＋カートに追加"
                class={[CB_CLASS.RED]}
                disabled={item.num > 0 ? false : true}
                onClick={() => clickRegister()}
              />
            </div>
          </>
        }
      />
    </>
  )
}
export default L007;

import { Switch, Route } from "react-router";
import { subscriptionUrl } from "../../helper/url.helper";
import SubscriptionList from "./SubscriptionList";
import SubscriptionEdit from "./SubscriptionContentEdit";
import AddSubscriptionContents from "./AddSubscriptionContents";

const SubscriptionRoutes = () => {
  return (
    <Switch>
      <Route exact path={subscriptionUrl} component={SubscriptionList} />
      <Route
        path={`${subscriptionUrl}/add_content`}
        component={AddSubscriptionContents}
      />
      <Route
        path={`${subscriptionUrl}/:id/edit_content`}
        component={SubscriptionEdit}
      />
    </Switch>
  );
};

export default SubscriptionRoutes;

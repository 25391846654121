import { D002Schema, ChildD007Schema, ChildD008Schema, D003Schema, D004Schema, D010Schema } from "../generated";
import { initialValidationError, ValidatedData } from "./validate.model";
import {
  checkFlgObj,
  checkValueNotRequire,
  checkNumberNotRequire,
  checkSelectOrRadio,
  checkValue,
  checkValueKanaNotRequire,
} from "../helper/validation.helper";
import { validationMessage } from "../config/validationMessage";
import { format } from "date-fns-tz";
import { FLG_FRIDAY, FLG_MONDAY, FLG_SATURDAY, FLG_SUNDAY, FLG_THURSDAY, FLG_TUESDAY, FLG_WEDNESDAY, TIME_LIST} from "../constants";

export const GENDER_SELECT_OTHER = 3;
export const DISABILITY_CERTIFICATE_SUPPORT_TYPE = {
  CHILD_DEVELOPMENT_SUPPORT: 1, // 自動発達支援
  DAY_SERVICE: 2, // 放課後デイサービス
};
export const DISABILITIES = "障がい";
export const GENDER = "性別";
export const BLOOD_TYPE = "血液型";

export const ORDER_NAME = 1;
export const ORDER_JOIN_DATE = 2;
export const ORDER_AGE = 3;
export const ORDER_ASC = 1;
export const ORDER_DESC = 2;
export const WEEK_DAY_LIST = [
  { value: FLG_SUNDAY, label: "日曜日" },
  { value: FLG_MONDAY, label: "月曜日" },
  { value: FLG_TUESDAY, label: "火曜日" },
  { value: FLG_WEDNESDAY, label: "水曜日" },
  { value: FLG_THURSDAY, label: "木曜日" },
  { value: FLG_FRIDAY, label: "金曜日" },
  { value: FLG_SATURDAY, label: "土曜日" },
];

export const initialChildModel = (fcId: number, parentId: number): D002Schema => ({
  family_name: "",
  family_name_kana: "",
  given_name: "",
  given_name_kana: "",
  id: 0,
  file_info: undefined,
  birthday: undefined,
  profile_pic: undefined,
  profile_pic_url: undefined,
  parent_id: parentId,
  facility_id: fcId,
  gender: undefined,
  gender_remarks: undefined,
  blood_type: undefined,
  family_structure: undefined,
  school: undefined,
  phone_number: undefined,
  post_code: undefined,
  address: undefined,
  birth_height: undefined,
  birth_weight: undefined,
  pregnancy_week: undefined,
  taking_medicine: undefined,
  join_date: format(new Date(), "yyyy-MM-dd"),
  end_date: undefined,
  remarks: undefined,
  updated_at: undefined,
  disabilities: [],
  reflections: [],
  audit_docs: [],
});

export const initialD003Model = (): D003Schema => ({
  facility_name: "",
  child_id: 0,
  child_name: "",
  child_name_kana: "",
  child_profile_pic_url: undefined,
});

export const initialD004Model = (): D004Schema => ({
  id: 0,
  facility_name: "",
  child_id: 0,
  child_name: "",
  child_name_kana: "",
  child_profile_pic_url: undefined,
  measurement_date: format(new Date(), "yyyy-MM-dd"),
  height: undefined,
  weight: undefined,
});

export const initialChildD007Schema = (): ChildD007Schema => ({
  facility_name: "",
  child_id: 0,
  child_name: "",
  child_name_kana: "",
  child_profile_pic_url: "",
  disability_certificates: [],
});

export const initialChildD008Schema = (): ChildD008Schema => ({
  id: 0,
  number: "",
  support_type: 0,
  date_count: 0,
  date_from: undefined,
  date_end: undefined,
  facility_name: "",
  child_id: 0,
  child_name: "",
  child_name_kana: "",
  child_profile_pic_url: "",
});

export const initialD010Schema = (): D010Schema => ({
  id: 0,
  child_id: 0,
  week_day: 0,
  start_time: "10:00",
  end_time: "10:45"
});

export const validationChildModel = (
  child: D002Schema
): ValidatedData<D002Schema> => {
  const error = initialValidationError<D002Schema>();

  const checkFlg: checkFlgObj = {
    isOk: true,
  };

  error.messageOf.family_name = [
    checkValue(checkFlg, "姓", child.family_name, 30),
    checkValue(checkFlg, "名", child.given_name, 30)
  ].filter(x => x != undefined).join(" ");

  error.messageOf.family_name_kana = [
    checkValueKanaNotRequire(checkFlg, "姓（フリガナ）", child.family_name_kana, 30),
    checkValueKanaNotRequire(checkFlg, "名（フリガナ）", child.given_name_kana, 30)
  ].filter(x => x != undefined).join(" ");

  error.messageOf.facility_id = checkSelectOrRadio(checkFlg, "施設", child.facility_id);

  error.messageOf.family_structure = checkValueNotRequire(checkFlg, "保護者の続柄", child.family_structure, 10);

  error.messageOf.school = checkValueNotRequire(checkFlg, "学校", child.school, 40);

  error.messageOf.phone_number = checkNumberNotRequire(checkFlg, "学校電話番号", child.phone_number, 11);

  error.messageOf.post_code = checkNumberNotRequire(checkFlg, "郵便番号", child.post_code, 7);

  error.messageOf.address = checkValueNotRequire(checkFlg, "住所", child.address, 100);

  error.messageOf.birth_height = checkNumberNotRequire(checkFlg, "出生時身長", child.birth_height, 2);

  error.messageOf.birth_weight = checkNumberNotRequire(checkFlg, "出生時体重", child.birth_weight, 4);

  error.messageOf.pregnancy_week = checkNumberNotRequire(checkFlg, "妊娠周期", child.pregnancy_week, 2);

  error.messageOf.taking_medicine = checkValueNotRequire(checkFlg, "妊娠中の服薬", child.taking_medicine, 30);

  error.messageOf.join_date = checkValue(checkFlg, "入会日", child.join_date);

  error.messageOf.remarks = checkValueNotRequire(checkFlg, "備考", child.remarks, 50);

  if (checkFlg.isOk) {
    return {
      ok: true,
      validatedData: child,
    };
  }

  return {
    ok: false,
    validationError: error,
  };
};

export const validationHealthRecordModel = (
  data: D004Schema
): ValidatedData<D004Schema> => {
  const error = initialValidationError<D004Schema>();

  const checkFlg: checkFlgObj = {
    isOk: true,
  };

  error.messageOf.measurement_date = checkValue(checkFlg, "日付", data.measurement_date);

  if (checkFlg.isOk) {
    return {
      ok: true,
      validatedData: data,
    };
  }

  return {
    ok: false,
    validationError: error,
  };
};

export const validationChildD008Model = (
  data: ChildD008Schema
): ValidatedData<ChildD008Schema> => {
  const error = initialValidationError<ChildD008Schema>();
  let ok = true;

  if (data.number.length > 10) {
    error.messageOf.number = "* 受給者証番号は10" + validationMessage.limitLength;
    ok = false;
  }

  if (!data.date_count) {
    error.messageOf.date_count = validationMessage.emptyDisabilityCertificateContractDays;
    ok = false;
  }

  if (!data.date_from || !data.date_end) {
    error.messageOf.date_from = validationMessage.emptyDisabilityCertificateDates;
    ok = false;
  }

  if (ok) {
    return {
      ok: true,
      validatedData: data,
    };
  }

  return {
    ok: false,
    validationError: error,
  };
};

export const validationD010Model = (items: D010Schema[]) => {
  let ok = true;

  if (items.length > 0) {
    const weekDaysSet = new Set();
    items.forEach((x, i) => {
      if (weekDaysSet.has(x.week_day)) {
        ok = false;
      }
      weekDaysSet.add(x.week_day);
    });
  }

  if (ok) {
    return {
      ok: true,
    };
  }

  return {
    ok: false,
  };
};

import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { useSelector } from "react-redux";
import { authSelector } from "../../redux/selectors/auth.selector";
import { L004Schema } from "../../generated";
import { ValidationError, initialValidationError } from "../../models/validate.model";
import ConfirmModal from "../../components/ConfirmModal";
import Title from "../../components/Title/Title";
import CustomRow from "../../components/CustomRow/CustomRow";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import CustomButton, { CB_CLASS } from "../../components/Button/CustomButton";
import { notify, notifyError } from "../../helper/settings.helper";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import {
	deleteEquipment,
	fetchEquipment,
	putEquipment,
	putEquipmentImageAndFile,
} from "../../services/api.service";
import TextBox from "../../components/TextBox/TextBox";
import CustomImage from "../../components/CustomImage/CustomImage";
import { initialL004Model, validationEquipmentModel } from "../../models/equipment.model";
import CustomTextarea from "../../components/CustomTextarea/CustomTextarea";
import { useAuth } from "../../helper/auth.helper";
import FileUploader from "../../components/FileUploader/FileUploader";
import NumBox, { NUM_BOX_TYPE } from "../../components/TextBox/NumBox";
import { getState } from "../../helper/page.helper";

interface iL004 {
	equipment_id: number;
}

interface iImageAndFile {
	image: File | undefined;
	file: File | undefined;
}

const L004 = () => {
	const { checkLoggedIn } = useAuth();
	const location = useLocation<iL004>();
	const state = getState(location);
	const equipmentId = state.equipment_id;
	const history = useHistory();
	const authState = useSelector(authSelector);
	const [isLoading, changeIsLoading] = useLoadingIndicator(undefined);
	const [data, setData] = useState<L004Schema>(initialL004Model);
	const [validationError, setValidationError] = useState<ValidationError<L004Schema>>(initialValidationError());
	const [isValidated, setIsValidated] = useState<boolean | undefined>(undefined);
	const [showRegister, setShowRegister] = useState(false);
	const [showDelete, setShowDelete] = useState(false);
	const [uploadImageAndFile, setUploadImageAndFile] = useState<iImageAndFile>({
		image: undefined,
		file: undefined,
	});
	const [reload, setReload] = useState(true);
	const [msgUploadFile, setMsgUploadFile] = useState("");

	useEffect(() => {
		if (equipmentId && reload) {
			let isMounted = true;
			const fetchData = async () => {
				changeIsLoading();
				fetchEquipment(equipmentId, authState).then((res) => {
					if (isMounted) {
						setData(res.data);
						setReload(false);
					}
					changeIsLoading();
				}).catch((err) => {
					if (isMounted) {
						checkLoggedIn(err);
						notifyError("データの取得に失敗しました。");
						console.error(err);
					}
					changeIsLoading();
				});
			};
			fetchData();
			return () => {
				isMounted = false;
			}
		}
	}, [authState, changeIsLoading, checkLoggedIn, equipmentId, reload]);

	const clickRegister = () => {
		const validatedData = validationEquipmentModel(data);
		if (validatedData.ok) {
			changeIsLoading();
			putEquipment(data, authState).then((res) => {
				if (uploadImageAndFile.file || uploadImageAndFile.image) {
					putEquipmentImageAndFile(res.data, uploadImageAndFile.image, uploadImageAndFile.file, authState).then((_) => {
						setShowRegister(false);
						notify("備品情報を更新しました。");
						changeIsLoading();
						history.goBack();
					}).catch((err) => {
						checkLoggedIn(err);
						notifyError("登録に失敗しました。");
						console.error(err);
						changeIsLoading();
					});
				} else {
					setShowRegister(false);
					notify("備品情報を更新しました。");
					changeIsLoading();
					history.goBack();
				}
			})
		} else {
			setShowRegister(false);
			notifyError("入力不正があります。");
			setIsValidated(true);
			setValidationError(validatedData.validationError);
		}
	};

	const clickDelete = () => {
		changeIsLoading();
		deleteEquipment(equipmentId, authState).then((res) => {
			setShowDelete(false);
			notify("備品情報を削除しました。");
			changeIsLoading();
			history.goBack();
		}).catch((err) => {
			checkLoggedIn(err);
			notifyError("削除に失敗しました。");
			console.error(err);
			changeIsLoading();
		});
	};

	useEffect(() => {
		if (isValidated) {
			const validatedData = validationEquipmentModel(data);
			if (validatedData.ok) {
				setValidationError(initialValidationError());
			} else {
				setIsValidated(true);
				setValidationError(validatedData.validationError);
			}
		}
	}, [data, isValidated]);

	return (
		<>
			<LoadingSpinner isLoading={isLoading} />
			<Title title={equipmentId ? "備品情報編集" : "備品情報登録"} />
			<div className="plate">
				<CustomRow
					title="備品名"
					required={true}
					error_msg={validationError.messageOf.name}
					content={
						<TextBox
							type="text"
							placeholder="備品名"
							value={data.name}
							onChange={(v: any) => setData({ ...data, name: v })}
						/>
					}
				/>
				<CustomRow
					title="品番"
					required={true}
					error_msg={validationError.messageOf.serial}
					content={
						<TextBox
							type="text"
							placeholder="品番"
							value={data.serial}
							onChange={(v: any) => setData({ ...data, serial: v })}
						/>
					}
				/>
				<CustomRow
					title="大きさ"
					required={false}
					content={
						<TextBox
							type="text"
							placeholder="大きさ"
							value={data.size}
							onChange={(v: any) => setData({ ...data, size: v })}
						/>
					}
				/>
				<CustomRow
					title="色"
					required={false}
					content={
						<TextBox
							type="text"
							placeholder="色"
							value={data.color}
							onChange={(v: any) => setData({ ...data, color: v })}
						/>
					}
				/>
				<CustomRow
					title="値段"
					required={true}
					error_msg={validationError.messageOf.price}
					content={
						<NumBox
							value={data.price}
							placeholder="値段"
							type={NUM_BOX_TYPE.PRICE}
							width="120px"
							onChange={(v: any) => setData({ ...data, price: data.price === 0 ? v.slice(1) : v })}
						/>
					}
				/>
				<CustomRow
					title={<>{"在庫数"}<br />{"(上限なし:空白)"}</>}
					required={false}
					content={
						<NumBox
							value={data.stock}
							placeholder="在庫数"
							type={NUM_BOX_TYPE.THOUSAND}
							width="120px"
							onChange={(v: any) => setData({ ...data, stock: v == "" ? undefined : v })}
						/>
					}
				/>
				<CustomRow
					title="画像"
					required={false}
					content={
						<CustomImage uploadFile={uploadImageAndFile.image} setUploadFile={(selectImage: File) => setUploadImageAndFile({ ...uploadImageAndFile ?? {}, image: selectImage })} imageUrl={data.image_url} />
					}
				/>
				<CustomRow
					title="商品詳細"
					required={false}
					error_msg={validationError.messageOf.details}
					content={
						<CustomTextarea
							placeholder="商品詳細"
							value={data.details}
							onChange={(v: any) => setData({ ...data, details: v })}
						/>
					}
				/>
				<CustomRow
					title="ファイル"
					required={false}
					error_msg={msgUploadFile}
					content={<FileUploader setFile={(selectFile: File) => setUploadImageAndFile({ ...uploadImageAndFile ?? {}, file: selectFile })} url={data.order_form_url} setMsg={setMsgUploadFile} />}
				/>
				<div className="plate d-flex flex-column align_center">
					<CustomButton
						label="登録"
						class={[CB_CLASS.RED]}
						onClick={() => setShowRegister(true)}
					/>
					<ConfirmModal
						target="登録"
						show={showRegister}
						setShow={setShowRegister}
						func={clickRegister}
						confirmText={"登録してよろしいでしょうか。"}
					/>
					{equipmentId &&
						<>
							<CustomButton
								label="削除"
								class={[CB_CLASS.RED]}
								onClick={() => setShowDelete(true)}
							/>
							<ConfirmModal
								target="削除"
								show={showDelete}
								setShow={setShowDelete}
								func={clickDelete}
								confirmText={"削除してよろしいでしょうか。"}
							/>
						</>
					}
				</div>
			</div>
		</>
	);
};

export default L004;

import "./ToolTip.scss";

function ToolTip(props: any) {
  let text: string = props.text;
  return (
    <div className="balloonOya">
      {props.content}      
      <span className="balloon text-break">
        {text.length > 150 ? text.substring(0, 150) + "..." : text}
      </span>
    </div>
  );
}

export default ToolTip;

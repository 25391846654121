import "./W001.scss";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import {
  GeneralForListSchema, W001Schema,
} from "../../generated";
import { authSelector, userInfoSelector } from "../../redux/selectors/auth.selector";
import {
  fetchFcFacilities,
  fetchGeneralsForList,
  fetchW001,
  fetchW001Count,
} from "../../services/api.service";
import Select from "../../components/Select/CustomSelect";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import CustomButton, { CB_ALIGN, CB_CLASS } from "../../components/Button/CustomButton"
import Title from "../../components/Title/Title"
import { initialGeneralForList } from "../../models/general.model";
import {
  HEADQUARTER_USER,
  LIST_SIGNATURE_REQUEST,
} from "../../constants";
import { W002Url, getForHistoryPush } from "../../helper/url.helper";
import { getFormatDate } from "../../helper/date.helper";
import { useAuth } from "../../helper/auth.helper";
import { notifyError } from "../../helper/settings.helper";
import PaginationTableLite from "../../components/PaginationTableLite/PaginationTableLite";
import { fcListSelector } from "../../redux/selectors/list.selector";
import { listActions } from "../../redux/actions/list.actions";
import { FC_LIST_TYPE, getFcList, getFcListForRedux, getFcListFromRedux } from "../../helper/list.helper";

interface iLocation {
  facility_id: number;
}

const W001 = () => {
  const { checkLoggedIn } = useAuth();
  const maxWidth = 1300;
  const location = useLocation<iLocation>();
  const [fcId, setFcId] = useState(location.state.facility_id);
  const authState = useSelector(authSelector);
  const history = useHistory();
  const userInfo = useSelector(userInfoSelector);
  const [data, setData] = useState<W001Schema[]>([]);
  const [getData, setGetData] = useState(true);
  const [fcForSelect, setFcForSelect] = useState<GeneralForListSchema[]>([]);
  const [docTypeForSelect, setDocTypeForSelect] = useState<GeneralForListSchema[]>([]);
  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const [currentPage, setCurrentPage] = useState(1);
  const [recordCount, setRecordCount] = useState(0);
  const limitCount = 10;
  const fcListState = useSelector(fcListSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      try {
        const [resCount, resData] = await Promise.all([
          fetchW001Count(authState, fcId),
          fetchW001(authState, fcId, (currentPage - 1) * limitCount, limitCount)
        ]);

        if (isMounted) {
          setRecordCount(resCount.data);
          setData(resData.data);
          setGetData(false);
        }
        changeIsLoading();
      } catch (err) {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      }
    };
    if (getData) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, fcId, getData, currentPage]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchGeneralsForList(authState, LIST_SIGNATURE_REQUEST).then(res => {
        if (isMounted) {
          setDocTypeForSelect(res.data);
        }
        changeIsLoading();

      });
    };
    if (docTypeForSelect.length == 0) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, docTypeForSelect.length]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchFcFacilities(authState, "").then((res) => {
        if (isMounted) {
          setFcForSelect(getFcList(FC_LIST_TYPE.ADD_INIT, res.data));
          dispatch(listActions.makeFcList(getFcListForRedux(res.data)));
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (fcForSelect.length == 0) {
      if (fcListState.select_list.length == 0) {
        fetchData();
      } else {
        setFcForSelect(getFcListFromRedux(FC_LIST_TYPE.ADD_INIT, fcListState.select_list));
      }
    }
    return () => {
      isMounted = false;
    }
  }, [authState, dispatch, changeIsLoading, checkLoggedIn, fcForSelect.length, fcListState.select_list]);

  const getSelectValue = (val: number, selectList: GeneralForListSchema[]) => {
    if (val == 0) return null;
    return selectList.filter(x => x.value == val)[0];
  };

  const changeCurrentPage = (v: number) => {
    setGetData(true);
    setCurrentPage(v);
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Title title="署名依頼帳票一覧" />
      <div className="plate">
        <div style={{ maxWidth: maxWidth }}>
          {userInfo?.user_type == HEADQUARTER_USER ? (
            <div className="on_same_row" style={{ maxWidth: maxWidth }}>
              <div className="item">
                <Select
                  value={getSelectValue(fcId, fcForSelect)}
                  options={fcForSelect}
                  className="W001_select"
                  onChange={(e: any) => {
                    setFcId(e.value);
                    setGetData(true);
                  }}
                  placeholder="施設"
                />
              </div>
            </div>
          ) : (
            <CustomButton
              label="＋署名依頼帳票追加"
              align={CB_ALIGN.RIGHT}
              min_width={200}
              class={[CB_CLASS.RED]}
              onClick={() => history.push(getForHistoryPush(W002Url, [0]))}
            />
          )}
        </div>

      </div>
      <PaginationTableLite
        currentPage={currentPage}
        setCurrentPage={changeCurrentPage}
        recordCount={recordCount}
        limit={limitCount}
        max_width={maxWidth}
        headers={userInfo?.user_type == HEADQUARTER_USER
          ? ["施設", "帳票種別", "登録日", "通知者数", "未署名数"]
          : ["帳票種別", "登録日", "通知者数", "未署名数"]
        }
        list={data.map((x, i) => (
          <tr key={i} onClick={() => history.push(getForHistoryPush(W002Url, [x.id]))}>
            {userInfo?.user_type == HEADQUARTER_USER &&
              <td className="item hide_over" style={{ maxWidth: 200 }}>
                {x.facility_name}
              </td>
            }
            <td className="item">
              {x.doc_name}
            </td>
            <td className="item">
              {getFormatDate(x.publication_date)}
            </td>
            <td className="item">
              {x.notified_num}
            </td>
            {x.no_signature_num > 0 ? (
              <td className="item" style={{ color: "red" }}>
                {x.no_signature_num}
              </td>
            ) : (
              <td className="item">
                {x.no_signature_num}
              </td>
            )}
          </tr>
        ))}
      />
    </>
  );
};

export default W001;

import React from "react";
import Select from "react-select";

export interface SelectStatusInfo {
  value: number,
  label: string,
  color: string,
}

export const COLOR_CLASS = {
  BLUE: "select_blue",
  RED: "select_red",
  GRAY: "select_gray",
  GREEN: "select_green"
};

function SelectForTable(props: any) {
  const color = (props.options as SelectStatusInfo[]).find(x => x.value == props.status)?.color;
  const selectValue = (props.options as SelectStatusInfo[]).filter(x => x.value == props.status)[0];

  return (
    <Select
      options={props.options}
      classNamePrefix={color}
      onChange={(event) => {
        props.onChange(event);
      }}
      value={selectValue}
    />
  );
}

export default SelectForTable;

import { wsActions } from "../redux/actions/ws.actions";
import { GetState } from "../redux/reducers";
import { Socket } from "socket.io-client";
import { WsConnectionOpenResult } from "../models/ws.model";
import { USER_TYPE_HEADQUARTER_USER } from "../models/login.model";

export const setupWebSocketActionHandlers = (
  socket: Socket,
  dispatch: Function,
  getState: GetState
) => {
  socket.on("connect", () => {
    console.log("ws connected");
  });

  socket.on("connection_open", (res: WsConnectionOpenResult) => {
    console.log("connection_open:", res);
    const authState = getState().authReducer;
    socket.emit("login", {
      user_type: USER_TYPE_HEADQUARTER_USER,
      id: authState.userInfo?.id,
    });
    dispatch(
      wsActions.connectDone({
        params: {},
        result: {
          sid: res.sid,
          socket,
        },
      })
    );
  });

  socket.on("disconnect", () => {
    console.log("ws disconnected");
    dispatch(wsActions.disconnect());
  });

  socket.on("error", () => {
    console.warn("ws can not open");
    dispatch(
      wsActions.connectFailed({
        params: {},
        error: "websocketの接続に失敗しました。",
      })
    );
  });
};

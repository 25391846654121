import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router";
import { authSelector, userInfoSelector } from "../../redux/selectors/auth.selector";
import "./F003.scss";
import { FCStaffForListSchema, GeneralForListSchema } from "../../generated";
import { fetchGeneralsForList, fetchStaffById } from "../../services/api.service";
import { F004Url, getForHistoryPush } from "../../helper/url.helper";
import { HEADQUARTER_USER } from "../../constants.js";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import CustomButton, { CB_CLASS, CB_ALIGN } from "../../components/Button/CustomButton";
import Title from "../../components/Title/Title";
import PaginationTable from "../../components/PaginationTable/PaginationTable";
import FacilityName from "../../components/FacilityName/FacilityName";
import Pict, { SIZES } from "../../components/Pict/User";
import Label from "../../components/Label/Label";
import { useLoadingIndicator } from "../../hooks/LoadingIndicator.hooks";
import { useAuth } from "../../helper/auth.helper";
import { notifyError } from "../../helper/settings.helper";

interface iF003 {
  fc_id: number;
}

const F003 = () => {
  const { checkLoggedIn } = useAuth();
  const maxWidth = 900;
  const authState = useSelector(authSelector);
  const location = useLocation<iF003>();
  const history = useHistory();
  const userInfo = useSelector(userInfoSelector);
  const facility_id = location.state.fc_id;
  const [isLoading, changeIsLoading] = useLoadingIndicator();
  const [data, setData] = useState<FCStaffForListSchema[]>([]);
  const [getData, setGetData] = useState(true);
  const [rankForSelect, setRankForSelect] = useState<GeneralForListSchema[]>([]);

  const getSelectValue = (val: number, selectList: GeneralForListSchema[]) => {
    if (val == 0) return;
    return selectList.filter((x) => x.value == val)[0];
  };

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchGeneralsForList(authState, "役職").then((res) => {
        if (isMounted) {
          const forSelect: GeneralForListSchema[] = [];
          res.data.map((x: GeneralForListSchema, i) => {
            forSelect.push({
              value: x.value,
              label: x.label,
            });
          });
          setRankForSelect(forSelect);
        }
        changeIsLoading();
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (rankForSelect.length == 0) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [authState, changeIsLoading, checkLoggedIn, rankForSelect.length]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      changeIsLoading();
      fetchStaffById(authState, facility_id).then((res) => {
        if (isMounted) {
          setData(res.data);
        }
        changeIsLoading();
        setGetData(false);
      }).catch((err) => {
        if (isMounted) {
          checkLoggedIn(err);
          notifyError("データの取得に失敗しました。");
          console.error(err);
        }
        changeIsLoading();
      });
    };
    if (getData) {
      fetchData();
    }
    return () => {
      isMounted = false;
    }
  }, [facility_id, authState, changeIsLoading, checkLoggedIn, getData]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Title title="スタッフ一覧" />
      {userInfo?.user_type == HEADQUARTER_USER && (
        <FacilityName fcId={facility_id} authState={authState} />
      )}
      <div className="plate" >
        <div style={{ maxWidth: maxWidth }}>
          <CustomButton
            label="＋スタッフを追加"
            class={[CB_CLASS.RED]}
            align={CB_ALIGN.RIGHT}
            onClick={() => history.push(getForHistoryPush(F004Url, [0]))}
          />
        </div>
      </div>
      <PaginationTable
        limit={13}
        max_width={maxWidth}
        headers={["アイコン", "スタッフ名", "役職"]}
        list={data.map((x, i) => (
          <tr key={i} onClick={() => history.push(getForHistoryPush(F004Url, [x.id]))}>
            <td className="item" valign="middle" align="center">
              <Pict size={SIZES.M} url={x.profile_pic_url} />
            </td>
            <td className="item">{x.family_name + x.given_name}</td>
            <td className="item" valign="middle" align="center">
              <Label value={getSelectValue(x.rank, rankForSelect)?.label} />
            </td>
          </tr>
        ))}
      />
    </>
  );
};

export default F003;
